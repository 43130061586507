import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox.component';
import { RadioComponent } from './radio.component';
import { AutoSearchComponent } from './autosearch.component';
import { ToggleComponent } from './toggle.component';
import { NewToggleComponent } from './toggle.componentV1';
import { QueueSelectorComponent } from './queue-selector/queue-selector.component';
import { DatePickerDirective } from './datepicker/datepicker.directive';

@NgModule({
    imports: [CommonModule, BrowserModule, FormsModule, ReactiveFormsModule],
    declarations: [CheckboxComponent, RadioComponent, AutoSearchComponent, ToggleComponent, NewToggleComponent, QueueSelectorComponent, DatePickerDirective],
    exports: [CheckboxComponent, RadioComponent, AutoSearchComponent, ToggleComponent, NewToggleComponent, QueueSelectorComponent, DatePickerDirective]
})
export class UIModule {}
