import { Injectable } from "@angular/core";
import { IContacto } from "../_interfaces/contacto";
import { Observable, of, from, timer, throwError } from "rxjs";
import {
   tap,
   filter,
   toArray,
   map,
   flatMap,
   retryWhen,
   defaultIfEmpty,
} from "rxjs/operators";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { HeaderService } from "./header.service";

@Injectable({
   providedIn: "root",
})
export class ContactosService {
   private readonly CONTACTOS_URL = "addressbook/contacts";
   contactosSnapshot: (IContacto & Object)[] = [];

   constructor(private $http: HttpClient, private $headers: HeaderService) {}

   getContactos(idbdb: string): Observable<(IContacto & Object)[]> {
      return this.$http
         .get<(IContacto & Object)[]>(
            `${this.$headers.getAPIurl()}/${this.CONTACTOS_URL}/${idbdb}`,
            { headers: this.$headers.getHttpHeaders() }
         )
         .pipe(
            tap((contactosInDb) => (this.contactosSnapshot = contactosInDb))
         );
   }

   getContacto(idcontacto: string) {
      return this.$http.get<IContacto & Object>(
         `${this.$headers.getAPIurl()}/${this.CONTACTOS_URL}/s/${idcontacto}`,
         { headers: this.$headers.getHttpHeaders() }
      );
   }

   buscarContacto(iddb: string, numero: string, _id?: string) {
      return of();
   }

   buscarContactPorTelefono(iddb: string, numero: string) {
      return this.$http
         .get<IContacto[]>(
            `${this.$headers.getAPIurl()}/${this.CONTACTOS_URL.replace(
               "/contacts",
               ""
            )}/${iddb}/search/phone/${numero}`,
            { headers: this.$headers.getHttpHeaders() }
         )
         .pipe(
            filter((contactos) => contactos.length > 0),
            defaultIfEmpty()
         );
   }

   guardarContactos(
      contactos: (IContacto & Object)[],
      campoCondicional: string,
      campoTelefono: string
   ) {
      const dbContactosSnapshot = this.contactosSnapshot.map((c) =>
         JSON.stringify({
            _id: c._id,
            [campoCondicional]: c[campoCondicional],
         })
      );

      return from(contactos).pipe(
         map((contacto) => {
            return {
               indexKey: JSON.stringify({
                  _id: contacto._id,
                  [campoCondicional]: contacto[campoCondicional],
               }),
               object: contacto,
            };
         }),
         // Tomar los contactos que no esten en el snapshot
         filter((contacto) => !dbContactosSnapshot.includes(contacto.indexKey)),
         map((contacto) => contacto.object),
         toArray(),
         // Enviar los nuevos contactos al backend.
         map((contactos) => ({
            contactos: contactos,
            busqueda: { index: campoCondicional, telefono: campoTelefono },
         })),
         flatMap((cuerpo) => {
            const headers = this.$headers.getHttpHeaders();
            return this.$http.post(
               `${this.$headers.getAPIurl()}/${this.CONTACTOS_URL}`,
               cuerpo,
               {
                  headers: headers,
                  responseType: "text",
                  observe: "events",
               }
            );
         }),
         filter((evento) => evento.type === HttpEventType.Response)
      );
   }

   guardarContacto(
      contacto: IContacto,
      campoCondicional: string,
      campoTelefono: string
   ) {
      let intentos = 0;
      const body =
         !!campoCondicional || !!campoTelefono
            ? {
                 contacto,
                 busqueda: { index: campoCondicional, telefono: campoTelefono },
              }
            : { contacto };

      return this.$http
         .post<IContacto>(
            `${this.$headers.getAPIurl()}/${this.CONTACTOS_URL}/${
               contacto._id
            }`,
            body,
            { headers: this.$headers.getHttpHeaders() }
         )
         .pipe(
            retryWhen((err) =>
               err.pipe(
                  tap((err) => console.error("Error registrado", err)),
                  flatMap((err) => {
                     intentos++;
                     return intentos > 2
                        ? timer(intentos * 1000)
                        : throwError(err);
                  })
               )
            )
         );
   }

   eliminarTodos(iddb: string) {
      return this.eliminarContactos(iddb, this.contactosSnapshot);
   }

   eliminarContactos(iddb: string, contactos: IContacto[]) {
      const _contactos = contactos.map((c) => ({ id: c._id }));
      const headers = this.$headers.getHttpHeaders();
      return this.$http
         .post(
            `${this.$headers.getAPIurl()}/${this.CONTACTOS_URL}/delete/${iddb}`,
            _contactos,
            {
               headers: headers,
               responseType: "text",
               observe: "events",
               reportProgress: true,
            }
         )
         .pipe(filter((evento) => evento.type === HttpEventType.Response));
   }
}
