<div class="panel panel-primary">
    <div class="panel-heading">
        <i class="fa fa-headphones"></i> Resultados por agentes
    </div>
    <ul class="list-group">
        <li class="list-group-item" *ngFor="let agente of agentes$ | async">
            Agente {{agente.idagente}}
            <div style="float: right;">
                <div class="label label-default" style="margin-right: 5px;">
                    <i class="fa fa-hourglass-1" title="Llamadas pendientes"></i> {{agente.stats.pendientes || 0}}</div>
                <div class="label label-info" style="margin-right: 5px;">
                    <i class="fa fa-calendar" title="Llamadas para reintento"></i> {{agente.stats.reIntentos || 0}}</div>
                <div class="label label-success" style="margin-right: 5px;">
                    <i class="fa fa-check-square-o" title="Llamadas con contacto efectivo"></i> {{agente.stats.finalizados || 0}}</div>
                <div class="label label-danger">
                    <i class="fa fa-times-circle" title="Llamadas fallidas"></i> {{agente.stats.fallidos || 0}}</div>
            </div>
        </li>
    </ul>
</div>