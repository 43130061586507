 import { Pipe, PipeTransform } from '@angular/core';
import { EUsuarioRol } from 'src/app/_interfaces/usuario';
 
 @Pipe({
     name: 'queuesAllowed'
 })
 export class QueuesAllowedPipe implements PipeTransform {
     transform(queues: string[], usr: { permisos: EUsuarioRol, colas: string[] }): any {
        if (usr.permisos <= EUsuarioRol.MANAGER) {
            return queues;
        } else {
            if (Array.isArray(queues)) {
                return queues.filter(q => usr.colas.includes(q));
            } else {
                return [];
            }
        }
     }
 }