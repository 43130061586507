import * as dayjs from "dayjs";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PaginationInstance } from "ngx-pagination";
import { Observable, Subject, combineLatest, of } from "rxjs";
import { catchError, finalize, map, switchMap, tap } from "rxjs/operators";
import { ILead, ILeadStatus } from "src/app/_interfaces/lead.entity";
import { FileHandlerService } from "src/app/_services/file.service";
import { LeadService } from "src/app/_services/lead.service";
import { PipelineService } from "src/app/_services/pipeline.service";
import { AgenteService } from "src/app/_services/agente.service";

@Component({
   selector: "app-leads",
   templateUrl: "./leads.component.html",
   styleUrls: ["./leads.component.css"],
})
export class LeadsComponent implements OnInit, AfterViewInit {
   fecha = dayjs().format("MMMM DD, YYYY HH:mm ");
   query = new FormGroup({
      start: new FormControl(""),
      end: new FormControl(""),
   });
   queryResults$: Observable<ILead[]>;

   sinAsignacion$ = new Subject<number>();
   asigando$ = new Subject<number>();
   abandonado$ = new Subject<number>();
   enPipe$ = new Subject<number>();

   totales$ = combineLatest([
      this.sinAsignacion$,
      this.asigando$,
      this.abandonado$,
   ]).pipe(
      map(([sinAsignacion, asignados, abandonados]) => {
         return sinAsignacion + asignados + abandonados;
      })
   );

   fases$ = this.$pipe.getPipelines();
   agentes$ = this.$agente.getAgentes();

   leadAIniciar: ILead;

   public config: PaginationInstance = {
      id: "custom",
      itemsPerPage: 20,
      currentPage: 1,
   };

   constructor(
      private $lead: LeadService,
      private $file: FileHandlerService,
      private $pipe: PipelineService,
      private $agente: AgenteService
   ) {}

   ngOnInit(): void {
      this.ejecutarBusqueda({ start: dayjs().format("YYYY-MM-DD") });
   }

   ngAfterViewInit(): void {
      setTimeout(() => {
         this.query.patchValue({
            start: dayjs().format("YYYY-MM-DD"),
         });
      }, 10);
   }

   ejecutarBusqueda(fecha: { start: string; end?: string } = this.query.value) {
      this.fecha = dayjs().format("MMMM DD, YYYY HH:mm ");
      this.queryResults$ = this.$lead.getLeadsRange(fecha).pipe(
         tap((resultados) => {
            this.sinAsignacion$.next(
               resultados.filter((r) => r.estado === ILeadStatus.UNASSIGNED)
                  .length
            );
            this.asigando$.next(
               resultados.filter((r) => r.estado === ILeadStatus.ASSIGNED)
                  .length
            );
            this.abandonado$.next(
               resultados.filter((r) => r.estado === ILeadStatus.ABANDON).length
            );
            this.enPipe$.next(
               resultados.filter((r) => r.fasePipeline!!).length
            );
         })
      );
   }

   iniciarEnPipeline(lead: ILead) {
      this.leadAIniciar = { ...lead };
      $("#iniciarEnPipe").modal("show");
   }

   actualizarLead(lead: ILead, campos: any) {
      const leadUpdated = { ...lead, ...campos };
      this.leadAIniciar = undefined;
      of(leadUpdated)
         .pipe(
            switchMap((data) => this.$lead.updateLead(data)),
            catchError((err) => {
               console.error(err);
               return of(err);
            }),
            finalize(() => {
               $("#iniciarEnPipe").modal("hide");
               this.ejecutarBusqueda();
            })
         )
         .subscribe();
   }

   descargar(leads: ILead[]) {
      this.$file.exportCSVFile(
         [
            "idlead",
            "fuente",
            "fuente_dst",
            "estado",
            "agenteAsignado",
            "fecha",
         ],
         leads,
         `LeadHistory_${
            this.query.controls["start"].value
         }_${new Date().getTime()}`
      );
   }
}
