import { Pipe, PipeTransform } from '@angular/core';
import { IQueueStats } from 'src/app/_interfaces/monitor';

@Pipe({
    name: 'inProm'
})
export class InboundAveragePipe implements PipeTransform {
    transform(stat: IQueueStats): any {
        if ((stat.inbound) !== 0) {
            return stat.talkInbound / stat.inbound;
        }
        return 0;
    }
}