<div *ngIf="leadBH$ | async as leads; else leadSearch" style="max-height: 390px; overflow-y: auto;">
   <div class="panel panel-default">
      <div class="panel-body">
         <div class="row">
            <div class="col-xs-4 text-center">
               <i class="fa fa-check-square text-success" *ngIf="leadBH.length > 0" aria-hidden="true"></i>
               <i class="fa fa-square-o text-muted" *ngIf="leadBH.length === 0" aria-hidden="true"></i>
               Lead Creado
            </div>
            <div class="col-xs-4 text-center">
               <i class="fa fa-check-square text-success" *ngIf="!!contactoBH" aria-hidden="true"></i>
               <i class="fa fa-square-o text-muted" *ngIf="!contactoBH" aria-hidden="true"></i>
               Contacto asignado
            </div>
            <div class="col-xs-4 text-center">
               <i class="fa fa-check-square text-success" *ngIf="!!tipificacionBH" aria-hidden="true"></i>
               <i class="fa fa-square-o text-muted" *ngIf="!tipificacionBH" aria-hidden="true"></i>
               Tipificación
            </div>
         </div>
         <div style="margin-top: 10px;" *ngIf="leadBH.length > 0">
            <label>Selecciona uno de los leads</label>
            <select [formControl]="leadAIniciar" class="form-control">
               <option style="font-style: italic;">Selecciona uno...</option>
               <option *ngFor="let lead of leadBH" [value]="lead.idlead">{{lead.idlead}} -
                  Fuente: {{lead.fuente}}
               </option>
            </select>
         </div>
      </div>
   </div>
   <ng-container *ngIf="leads.length > 0">
      <div class="panel panel-info" style="margin-top: 15px;" *ngIf="leadAIniciar$ | async">
         <div class="panel-heading">
            <h3 class="panel-title"> <i class="fa fa-plus" aria-hidden="true"></i> Ingreso de Lead a Pipeline</h3>
         </div>
         <div class="panel-body">
            <form autocomplete="off" novalidate [formGroup]="frmInitPipe">

               <input type="hidden" formControlName="leadid">

               <label>Fase en pipeline</label>
               <select class="form-control" style="margin-bottom: 10px;" formControlName="faseid">
                  <option *ngFor="let pipe of pipes$ | async" [value]="pipe.id">
                     {{pipe.avance > 0? pipe.avance + ' %' : 'X'}} - {{pipe.nombre}}
                  </option>
               </select>

               <label>Nombre en pipeline</label>
               <input type="text" class="form-control" style="margin-bottom: 10px;" formControlName="nombre">

               <label>Notas iniciales</label>
               <textarea class="form-control" style="min-height: 60px; margin-bottom: 10px;"
                  formControlName="notas"></textarea>

               <div class="text-right">
                  <button type="button" class="btn btn-info" [disabled]="frmInitPipe.invalid || saving"
                     (click)="iniciarLeadInPipeline(frmInitPipe.value)">
                     <ng-container *ngIf="!saving; else savingProcess">
                        <i class="fa fa-star-half-o" aria-hidden="true"></i> Iniciar en
                        pipeline
                     </ng-container>
                     <ng-template #savingProcess>
                        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Iniciando en
                        pipeline
                     </ng-template>
                  </button>
               </div>
            </form>

         </div>
      </div>
   </ng-container>

</div>
<ng-template #leadSearch>
   <div style="margin: 40px 10px 50px 10px" class="text-muted text-center">
      En esta sección podrás tener acceso a leads e iniciarlos en pipelines.<br>
      <small>Se activará tan pronto hagas o recibas llamadas</small>
   </div>
</ng-template>