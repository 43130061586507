import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IContacto } from 'src/app/_interfaces/contacto';
import { PaginationInstance } from 'ngx-pagination';
import { ICampoDirectorio, ETipoCampoDirectorio } from 'src/app/_interfaces/directorio';
import { from, Observable, Observer, of, empty } from 'rxjs';
import { map, filter, flatMap, tap, bufferCount, toArray, debounceTime, switchMap, finalize } from 'rxjs/operators';
import { EEstadoContacto, IContactoReparto, EEstadoContactoDialer, ETipoCampanaDialer } from 'src/app/_interfaces/dialer';
import { ObjectID } from 'src/app/_services/_objectID';
import { DialerService } from 'src/app/_services/dialer.service';

interface ISimpleChanges extends SimpleChanges {
    dirID?: ISimpleChange;
    cola?: ISimpleChange;
    tipoDialer?: ISimpleChange;
} 

interface ISimpleChange extends SimpleChange {
    currentValue: string;
    previousValue: string;
}

@Component({
    selector: 'app-reparto',
    templateUrl: './reparto.component.html',
    styleUrls: ['./reparto.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: RepartoComponent }
    ]
})
export class RepartoComponent implements OnInit, OnChanges, ControlValueAccessor {

    @ViewChild('seleccionTodos') 
    seleccionTodos: ElementRef;

    inProgress = false;
    contactosSeleccionados: string[] = []; 
    private isDisabled = false;
    private onChange: (value: any) => void;
    private agentesSeleccionados: string[] = []; 

    config: PaginationInstance = {
        id: '_id',
        itemsPerPage: 20,
        currentPage: 1
    };
    
    reparto: IContactoReparto[] = [];
    @Input() cola: string;
    @Input() dirID: string;
    @Input() tipoDialer: ETipoCampanaDialer
    @Input() agentes: { idagente: string }[];
    @Input() contactos: { campos: ICampoDirectorio[], contactos: IContacto[] };

    get isProgressive() { 
        return this.tipoDialer === ETipoCampanaDialer.MANUAL || this.tipoDialer === ETipoCampanaDialer.PROGRESIVO 
    }

    constructor(private $dialer: DialerService) { }
    
    ngOnInit() {
    }

    ngOnChanges(changes: ISimpleChanges) {
        const { dirID, cola, tipoDialer } = changes;
        const cambioCola = !!cola && cola.currentValue !== cola.previousValue;
        const cambioDirectorio = !!dirID && dirID.currentValue !== dirID.previousValue;
        const cambioTipoMarcacion = !!tipoDialer && tipoDialer.currentValue !== tipoDialer.previousValue;
        
        if (cambioCola || cambioDirectorio || cambioTipoMarcacion) {
            this.reparto = [];
            this.contactosSeleccionados = [];
            if (!!this.seleccionTodos) {
                this.seleccionTodos.nativeElement.checked = false;
            }
            if (!!this.onChange) {
                this.onChange(this.reparto);
            }
        }
    }

    writeValue(obj: any): void {
        this.reparto = [...obj];
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    actualizarSeleccionAgentes(checked: boolean, agenteid: string) {
        if (checked) {
            this.agentesSeleccionados = [...this.agentesSeleccionados, agenteid];
        } else {
            this.agentesSeleccionados = [...this.agentesSeleccionados.filter(a => a !== agenteid)];
        }
        console.log(this.agentesSeleccionados);
    }

    seleccionarContactos(idContacto: string, checked: boolean) {

        if (checked) {
            this.contactosSeleccionados = [...this.contactosSeleccionados, idContacto];
        } else {
            this.contactosSeleccionados = [...this.contactosSeleccionados.filter(a => a !== idContacto)];
        }
    }

    seleccionaTodos(checked: boolean) {
        const { contactos } = this.contactos;
        if (checked) {
            this.contactosSeleccionados = contactos.map(r => r._id);
        } else {
            this.contactosSeleccionados = [];
        }
    }

    eliminarContacto() {
        // Eliminar contacto de la campaña.
        this.contactosSeleccionados.forEach(c => {
            this.reparto = [...this.reparto.filter(r => r.idContacto !== c)]
        });
        this.contactosSeleccionados = [];
        this.seleccionTodos.nativeElement.checked = false;
        this.onChange(this.reparto);
    }

    crearRepartoAutomatico() {
        let cantidadPorAgente = 0;
        const { contactos } = this.contactos;
        const agentes = this.agentes.map(a => a.idagente);
        // Asignación de cantidades
        const divisor = this.agentes.length > 0 ? this.agentes.length : 1;
        const residuo = contactos.length % divisor;
        if (residuo === 0 ) {
            cantidadPorAgente = contactos.length / divisor;
        } else {
            cantidadPorAgente = Math.trunc(contactos.length / divisor) + 1;
        }

        of(true).pipe(
            tap(() => this.inProgress = true),
            debounceTime(200),
            switchMap(() => this.$dialer.crearContactosDialer(this.contactos)),
            bufferCount(cantidadPorAgente),
            map(asignados => ([ asignados, agentes.length > 0 ? agentes.shift() : undefined ])),
            // Asignarle al grupo el mismo agente
            flatMap(([ contactosDialer, agente ]) => from(contactosDialer as IContactoReparto[]).pipe(
                map(contacto => ({ ...contacto, agenteid: agente as string }))
            )),
            toArray(),
            finalize(() => this.inProgress = false)
        ).subscribe(
            response => {
                this.reparto = response;
                if (!!this.onChange) {
                    this.onChange(this.reparto);
                }
            }
        )
    }

    crearNumeracionAutmatica() {
        of(true).pipe(
            tap(() => this.inProgress = true),
            debounceTime(200),
            switchMap(() => this.$dialer.crearContactosDialer(this.contactos)),
            toArray(),
            finalize(() => this.inProgress = false)
        ).subscribe(
            response => {
                console.log(response);
                this.reparto = response;
                if (!!this.onChange) {
                    this.onChange(this.reparto);
                }
            }
        )
    }

    asignarSeleccion(idagente: string) {
        const { campos, contactos } = this.contactos;
        from(this.contactosSeleccionados).pipe(
            // ubicar los contactos del reparto
            flatMap(idContacto => {
                const contactoEnCampana = this.reparto.find(r => r.idContacto == idContacto);
                if (!!contactoEnCampana) {
                    contactoEnCampana.agenteid = idagente;
                    return empty();
                } else {
                    const contacto = contactos.find(c => c._id === idContacto);
                    return of(contacto);
                }
            }),
            // Crear contactos
            filter(c => !!c),
            flatMap(nuevosContactos => this.$dialer.crearContactosDialer({ campos: campos, contactos: [nuevosContactos] })),
            map(contacto => ({ ...contacto, agenteid: idagente })),
            toArray(),
            tap(nuevosContactos => {
                this.reparto = [...this.reparto, ...nuevosContactos];
                this.onChange(this.reparto);
                // this.contactosSeleccionados = [];
                // this.seleccionTodos.nativeElement.checked = false;
            })
        ).subscribe();
    }

}
