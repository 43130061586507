import { Component, OnInit, Input } from '@angular/core';
import { IContactoReparto, EEstadoContactoDialer } from 'src/app/_interfaces/dialer';
import { Observable, from, zip, of } from 'rxjs';
import { groupBy, mergeMap, filter, toArray, map, defaultIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-campain-agents',
  templateUrl: './campain-agents.component.html',
  styleUrls: ['./campain-agents.component.css']
})
export class CampainAgentsComponent implements OnInit {

    agentes$: Observable<any>;
    private _contactos: IContactoReparto[];
    @Input() set contactos(value: IContactoReparto[]) {
        this._contactos = value;
        this.agentes$ = from(value).pipe(
            filter(reparto => !!reparto.agenteid && !reparto.agenteid.includes('N/A')),
            groupBy(reparto => reparto.agenteid),
            mergeMap(agrupado => zip(of(agrupado.key), agrupado.pipe(this.statsAgentsDialer$))),
            map(([idagente, stats]) => ({ idagente: idagente, stats: stats })),
            toArray(),
            defaultIfEmpty([])
        );
    };

    get contactos() { return this._contactos; }

    constructor() { }

    ngOnInit() {
    }

    private statsAgentsDialer$ = (inData$: Observable<IContactoReparto>) => inData$.pipe(
        toArray(),
        map(repartos => ({
            pendientes: repartos.filter(r => r.estado === EEstadoContactoDialer.EN_ESPERA).length,
            reIntentos: repartos.filter(r => r.estado === EEstadoContactoDialer.REINTENTAR).length,
            fallidos: repartos.filter(r => r.estado === EEstadoContactoDialer.FALLIDO).length,
            finalizados: repartos.filter(r => r.estado === EEstadoContactoDialer.FINALIZADO).length
        }))
    )

}
