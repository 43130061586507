import { Pipe, PipeTransform } from '@angular/core';
import { IContactoReparto } from 'src/app/_interfaces/dialer';

@Pipe({
  name: 'porAgente'
})
export class PorAgentePipe implements PipeTransform {

  transform(idagente: string, reparto: IContactoReparto[]): any {
        return reparto.filter(r => r.agenteid === idagente).length;
  }

}
