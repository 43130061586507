import { Injectable } from '@angular/core';
import { HeaderService } from './header.service';
import { Observable } from 'rxjs';
import { IDBResponse, EDBResponseType } from '../_interfaces/responses';
import { Network } from '../_interfaces/network';

@Injectable()
export class NetworkService {
    
    constructor(private $header: HeaderService){}
    
    
}