import {
   Component,
   Input,
   OnChanges,
   OnInit,
   SimpleChange,
   SimpleChanges,
} from "@angular/core";
import { IAgentStateChannel } from "src/app/_interfaces/monitor";

interface ISimpleChanges extends SimpleChanges {
   channelSnapshot: IAgentStateChannelChanges;
}

interface IAgentStateChannelChanges extends SimpleChange {
   currentValue: IAgentStateChannel;
   previousValue: IAgentStateChannel;
}

@Component({
   selector: "app-meta-info",
   templateUrl: "./meta-info.component.html",
   styleUrls: ["./meta-info.component.css"],
})
export class MetaInfoComponent implements OnInit, OnChanges {
   @Input()
   channelSnapshot: IAgentStateChannel;

   metaInfo: any;

   constructor() {}

   ngOnInit(): void {}

   ngOnChanges(changes: ISimpleChanges): void {
      if (!!changes.channelSnapshot.currentValue) {
         const meta = JSON.parse(
            decodeURIComponent(this.channelSnapshot?.meta_info || "{}")
         );

         const items = Object.keys(meta);
         this.metaInfo = items.reduce(
            (acc, i) => `${acc}<strong>${i}</strong> : ${meta[i]}<br>`,
            ""
         );
      } else {
         this.metaInfo = null;
      }
   }
}
