import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { HeaderService } from './header.service';
import { IDirectorio } from '../_interfaces/directorio';
import { defaultIfEmpty, filter, tap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IContacto } from '../_interfaces/contacto';

@Injectable({
  providedIn: 'root'
})
export class DirectorioService {

    private readonly ADDRESSBOOK_URL = 'addressbook';

    constructor(private $http: HttpClient, private $header: HeaderService) { }

    getDirectorios(): Observable<IDirectorio[]> {
        return this.$http.get<IDirectorio[]>(
            `${this.$header.getAPIurl()}/${this.ADDRESSBOOK_URL}`, 
            { headers: this.$header.getHttpHeaders() }
        );
    }

    getDirectorio(iddb: string): Observable<IDirectorio> {
        return this.$http.get<IDirectorio>(`${this.$header.getAPIurl()}/${this.ADDRESSBOOK_URL}/${iddb}`, { headers: this.$header.getHttpHeaders() });
    }

    guardarDirectorio(directorio: any, create = false): Observable<any> {
        const query$ = (url, body, headers) => create ? 
            this.$http.post(url, body, headers) :
            this.$http.put(url, body, headers); 
        
        return query$(`${this.$header.getAPIurl()}/${this.ADDRESSBOOK_URL}`, directorio, { headers: this.$header.getHttpHeaders() });
    }

    eliminarDirectorio(iddb: string): Observable<any> {
        return this.$http.delete(
            `${this.$header.getAPIurl()}/${this.ADDRESSBOOK_URL}/${iddb}`, 
            { headers: this.$header.getHttpHeaders(), responseType: 'text', observe: 'events' }
        ).pipe(
            filter(evento => evento.type === HttpEventType.Response)
        );
    }

}
