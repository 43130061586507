<form autocomplete="off" [formGroup]="propsForm">
   <div class="panel panel-default">
      <div class="panel-heading">
         <i class="fa fa-wpforms" aria-hidden="true"></i> {{propName || 'Campos y propiedades'}}<br>
         <small class="text-muted">Campos requeridos para que un lead pueda ser movido a esta
            fase.</small>
      </div>
      <div class="panel-body" formArrayName="props">
         <ng-container *ngIf="propsArray.controls.length > 0; else noProps">
            <ng-container *ngFor="let prop of propsArray.controls; let i = index">

               <div [formGroup]="prop" style="margin-bottom: 10px;">
                  <label [htmlFor]="'campo' + i">Nombre del Campo {{(i + 1 )}}</label>
                  <div class="row">
                     <div class="col-xs-5">
                        <input type="text" class="form-control" formControlName="campo" [id]="'campo' + i">
                     </div>
                     <div class="col-xs-3" style="padding-left: 0;">
                        <select class="form-control" formControlName="tipo" name="tipo">
                           <option value="">Seleccione una...</option>
                           <option value="text">Texto</option>
                           <option value="date">Fecha</option>
                           <option value="number">Número</option>
                        </select>
                     </div>
                     <div class="col-xs-3 text-right" style="padding-left: 0;">
                        <div class="btn-group">
                           <div class="input-group">
                              <span class="input-group-addon">
                                 <input type="checkbox" aria-label="..." formControlName="requerido" name="requerido">
                              </span>
                              <input type="text" name="textoRequerido" class="form-control" aria-label="..."
                                 [readOnly]="true" value="Requerido">
                           </div><!-- /input-group -->
                        </div>
                     </div>
                     <div class="col-xs-1 text-right" style="padding-left: 0;">
                        <button type="button" class="btn btn-default" (click)="remover(i)">
                           <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                     </div>
                  </div>
               </div>

            </ng-container>
         </ng-container>
         <ng-template #noProps>
            <div style="margin-top: 20px; margin-bottom: 20px;" class="text-center text-muted">
               Sin propiedades aun...
            </div>
         </ng-template>
      </div>
      <div class="panel-footer text-right">
         <button type="button" class="btn btn-info btn-sm" (click)="agregarCampo()" [disabled]="!propName!!">
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Agregar campo
         </button> &nbsp;
         <button type="button" class="btn btn-primary btn-sm" [disabled]="propsForm.invalid" (click)="finalizar()"
            [disabled]="!propName!!">Finalizar</button>
      </div>
   </div>
</form>