<div class="bs-callout bs-callout-success text-justify">
    <h5>Archivos de configuración para extensiones</h5>
    Descarga los archivos necesarios para conectar tus extensiones de manera segura a través de OpenVPN. 
</div>

<div *ngIf="archivos$ | async; let archivos">
    <div class="alert alert-warning" *ngIf="archivos.length === 0" role="alert">En el momento no hay archivos de configuración para extensiones vía VPN</div>
    <a href="javascript:void(0)" (click)="descargar('all_extension_vpn.zip')" *ngIf="archivos.length > 0"> <i class="fa fas fa-download"></i> Descargar Todos.</a>
    <table class="table table-bordered table-striped" style="margin-top: 10px">
        <thead>
            <tr>
            <th>Archivo</th>    
            <th>Fecha de Creación</th>    
            <th>Descarga</th>    
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let archivo of archivos">
                <td>{{archivo.nombre}}</td>
                <td>{{archivo.fecha | date: 'short'}}</td>
                <td>
                    <a href="javascript:void(0)" (click)="descargar(archivo.nombre)" title="Descarga de archivo">Descargar</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>