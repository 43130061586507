<div class="row" style="margin-top: 30px;">
    <div class="col-md-offset-2 col-md-7">
        <h5>Podrá configurar diferentes causas de pausa o deslogueo para los agentes de callcenter.</h5>
    </div>
    <div class="col-md-1">
        <button type="button" class="btn btn-primary btn-block" (click)="addReason()"><i class="fa fa-plus"></i></button>
    </div>
</div>
<div class="row" style="color: rgb(77, 77, 77);">
    <div class="col-md-8 col-md-push-2"><hr></div>
    <div class="col-md-offset-2 col-xs-4">
        <label>Motivo</label>
    </div>
    <div class="col-xs-2">
        <label>Tiempo máximo</label>
    </div>
    <div class="col-xs-2">
        <label>Visible <i class="fa fa-question-circle-o" title="Visible en la consola de agente?"></i></label>
    </div>
</div>
<ng-container *ngIf="reasons$ | async as reasons; else loading">
    <form autocomplete="off" novalidate [formGroup]="pauseForm" (ngSubmit)="guardar(pauseForm.value)">
        <div class="form-group" formArrayName="reasons">
            <div class="row form-group" *ngFor="let reason of reasonsArray.controls; let i = index">
                <ng-container [formGroupName]="i">
                    <input type="hidden" formControlName="idreason">
                    <div class="col-md-offset-2 col-xs-4">
                        <input type="text" formControlName="reason" class="form-control">
                    </div>
                    <div class="col-xs-2">
                        <div class="input-group">
                            <input type="number" formControlName="timeout" class="form-control">
                            <span class="input-group-addon" id="basic-addon2">(s)</span>
                        </div>
                    </div>
                    <div class="col-xs-1 text-center">
                        <div style="margin-top: 10px;">
                            <ui-toggleV1 formControlName="agentVisible"></ui-toggleV1>
                        </div>
                    </div>
                    <div class="col-xs-1 text-right">
                        <button type="button" class="btn btn-danger" (click)="removeReason(i)">
                            <i class="fa fa-trash-o"></i>
                        </button>
                    </div>
                    <div *ngIf="reason.errors && (reason.dirty || reason.touched)" style="margin-top: 5px;" class="col-md-offset-2 col-xs-8 small">
                        <span *ngIf="reason.errors.required" class="text-danger">
                            * El motivo de pausa y el tiempo máximo son requeridos.
                        </span>
                        <span *ngIf="reason.errors.min" class="text-danger">
                            * El tiempo mínimo es de 15 segundos.
                        </span>
                        <span *ngIf="reason.errors.max" class="text-danger">
                            * El tiempo máximo es de 14.400 segundos (4 Horas).
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row" style="color: rgb(77, 77, 77);">
            <div class="col-md-8 col-md-push-2 text-right"><hr>
                <div class="alert text-left" *ngIf="onProcess.msj || onProcess.err" 
                    [ngClass]="{'alert-success': onProcess.msj, 'alert-danger': onProcess.err}">
                    <span *ngIf="onProcess.msj">
                        <i class="fa fa-check-square-o" style="margin-right: 10px;"></i> Operación ejecutada correctamente.
                    </span>
                    <span *ngIf="onProcess.err">
                        <i class="fa fa-times" style="margin-right: 10px;"></i> No se pudo ejecutar la operación, intente de nuevo.
                    </span>
                </div>
                <button type="submit" 
                    [disabled]="!pauseForm.valid || onProcess.process || reasonsArray.controls.length === 0" 
                    class="btn btn-primary">
                        <i class="fa fa-save" *ngIf="!onProcess.process"></i>
                        <i class="fa fa-spinner fa-spin" *ngIf="onProcess.process"></i> Guardar
                </button>
            </div>
        </div>
    </form>
</ng-container>
<ng-template #loading>
    <div style="margin: 40px 0;" class="text-center text-muted">
        <i class="fa fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
        Cargando razones de pausa... 
    </div>
</ng-template>