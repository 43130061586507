import { Pipe, PipeTransform } from '@angular/core';
import { IContacto, EEstadoContacto } from 'src/app/_interfaces/dialer';

@Pipe({
  name: 'callStatus'
})
export class CallStatusPipe implements PipeTransform {

    transform(contactos: IContacto[], estado?: EEstadoContacto): any {
        if (!!estado) {
            const contacto = contactos.find(c => c.estado === estado);
            return !!contacto ? contacto.numero : undefined;
        } else  {
            return contactos.map(c => c.numero).join(' - ');
        }
    }

}
