<div class="row" style="padding: 10px">

   <div class="col-md-8">
      <div class="bs-callout bs-callout-success text-justify">
         <h5>
            <p>Reglas para Callback o Click2Call</p>
         </h5>
         <p>En esta sección podrás configurar varias reglas de marcación con el fin de ejecutar retornos de llamadas
            desde su página web o de manera local.</p>
         <p>Recuerde que para la ejecución de callbacks desde fuentes externas debe contar primero con <strong>API
               KEY</strong>; revise la opción en el menú: <strong><i class="fa fas fa-key"></i> API Manager</strong>
            para más información.</p>
      </div>
      <div class="panel panel-primary" style="max-height: 600px; overflow-y: auto; overflow-x: hidden">

         <div class="panel-heading">
            <i class="fa fa-list-ol"></i> Reglas actuales.
         </div>

         <ul class="list-group">
            <li *ngFor="let callback of (callback$ | async); index as i" class="list-group-item"
               style="padding: 5px 15px" [ngClass]="{'list-group-item-info': callbackSeleccionado?.id === callback.id}">
               <div class="row" style="padding: 0px">
                  <div class="col-xs-3" style="padding-top: 5px">
                     <a href="javascript: void(0)" (click)="setCallback(callback)" style="text-decoration: none">
                        <i class="fa fa-arrow-circle-o-right" style="margin-right: 5px; margin-left: 5px"></i>
                        ID: <strong>{{(callback.id)}}</strong>
                     </a>
                  </div>
                  <div class="col-xs-6" style="padding-top: 5px">
                     <code class="text-primary">Prefijos: {{callback.prefix | json}}</code>
                  </div>
                  <div class="col-xs-3 text-right">
                     <button class="btn btn-default btn-sm" (click)="eliminarCallback(callback.id)">
                        <i class="fa fa-trash"></i>
                     </button>
                  </div>
               </div>
            </li>
         </ul>
      </div>

      <hr>
      <h4>Documentación para lanzar llamadas</h4>
      <p>Para ejecutar estas reglas deberá ejecutar un POST con la siguiente información.</p>
      <div class="alert alert-info">
         <div class="row">
            <div class="col-xs-2 text-center"><span class="badge">POST</span></div>
            <div class="col-xs-9" style="font-size: 14px"><strong>{{path}}</strong></div>
            <div class="col-xs-1 text-right">
               <a href="javascript:void(0)" data-toggle="collapse" href="#detallesCallback" role="button"
                  aria-expanded="false" aria-controls="detallesCallback">
                  <i class="fa" [ngClass]=" isToggled() ? 'fa-chevron-down': 'fa-chevron-up'"></i>
               </a>
            </div>
         </div>
      </div>
      <div id="detallesCallback" class="collapse">
         <div class="row">
            <div class="col-xs-3">Cabecera o headers:</div>
            <div class="col-xs-9">
               <pre>{{cabecera | json}}</pre>
            </div>
         </div>
         <div class="row">
            <div class="col-xs-3">Cuerpo:<br>
               <small class="text-muted"><strong>extra</strong> es opcional.</small>
            </div>
            <div class="col-xs-9">
               <pre>{{body | json}}</pre>
            </div>
         </div>
         <div class="row">
            <div class="col-xs-3">Respuesta <span class="text-success">200</span></div>
            <div class="col-xs-9">
               <pre>Llamada lanzada con éxito</pre>
            </div>
         </div>
         <div class="row">
            <div class="col-xs-3">Respuesta <span class="text-danger">401</span></div>
            <div class="col-xs-9">
               <pre>No Autorizado</pre>
            </div>
         </div>
         <div class="row">
            <div class="col-xs-3">Respuesta <span class="text-danger">404</span></div>
            <div class="col-xs-9">
               <pre>El id de regla no existe</pre>
            </div>
         </div>
         <div class="row">
            <div class="col-xs-3">Respuesta <span class="text-danger">409</span></div>
            <div class="col-xs-9">
               <pre>No existe ningún patrón para este número</pre>
            </div>
         </div>
         <div class="row">
            <div class="col-xs-3">Respuesta <span class="text-warning">502</span></div>
            <div class="col-xs-9">
               <pre>La troncal para esta llamada ya no existe</pre>
            </div>
         </div>
      </div>

   </div>
   <div class="col-md-4">
      <app-regla-callback [callback]="callbackSeleccionado" (guardar)="guardarCallback($event)"></app-regla-callback>
   </div>
</div>