
import {throwError as observableThrowError,  Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Peer, PeerPerfil } from '../_interfaces/peer';
import { IPeerMonitor } from '../_interfaces/monitor';
import { HeaderService } from './header.service';
import { IDBResponse } from '../_interfaces/responses';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PeerService {
    
    constructor(private $http: HttpClient, private $headers: HeaderService) {
        
    }
    
    getPeer(usuario: string): Observable<Peer[]> {
        return this.$http.get<Peer[]>(this.$headers.getAPIurl() + '/peer/' + usuario, { headers: this.$headers.getHeaders() });
    }
    
    getPeers(): Observable<Peer[]> {
        return this.$http.get<Peer[]>(this.$headers.getAPIurl() + '/peers', { headers: this.$headers.getHeaders() });
    }
    
    /**
     * Funciones de edicion, creación y eliminacion de peers
     */
    updatePeer(data: { peer: Peer, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<IDBResponse>;
        if (data.update === undefined) {
            http$ = this.$http.delete<IDBResponse>(this.$headers.getAPIurl() + '/peer/' + data.peer.usuario, { headers: this.$headers.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post<IDBResponse>(this.$headers.getAPIurl() + '/peer', data.peer, { headers: this.$headers.getHeaders() })
        } else {
            http$ = this.$http.put<IDBResponse>(this.$headers.getAPIurl() + '/peer', data.peer, { headers: this.$headers.getHeaders() })
        }
        return http$;
    }
}
