<div class="panel panel-info">
   <div class="panel-heading">
      <a style="font-weight: 600;" href="javascript:void(0)" (click)="leadDetails()">
         <i class="fa fa-flag" aria-hidden="true"></i>
         {{leadInPipe.nombre || leadInPipe.fuente}}</a>
      <div style="word-wrap: break-word; white-space: pre-wrap;">
         <small *ngIf="leadDetails$ | async as detalis; else loading">{{detalis}}</small>
         <ng-template #loading>
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
         </ng-template>
      </div>
      <hr style="margin-top: 5px; margin-bottom: 5px;">
      <strong>Propietario:</strong> {{leadInPipe.agenteAsignado}}<br>
      <strong>Fecha creación:</strong> {{leadInPipe.fecha | amTimeAgo:true}}
   </div>
</div>