import { Pipe, PipeTransform } from "@angular/core";
import { Agente } from "src/app/_interfaces/agente";

@Pipe({
   name: "defaultAgentPenalty",
})
export class DefaultAgentPenaltyPipe implements PipeTransform {
   transform(value: string, agentes: Agente[]): string | number {
      const agente = agentes.find((a) => a.idagente === value);
      return agente.penalidad;
   }
}
