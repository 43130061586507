import { Injectable } from "@angular/core";
import { IPipeLine } from "../_interfaces/pipeline.entity";
import { HttpClient } from "@angular/common/http";
import { HeaderService } from "./header.service";
import { Observable } from "rxjs";

@Injectable({
   providedIn: "root",
})
export class PipelineService {
   constructor(private $http: HttpClient, private $header: HeaderService) {}

   guardarPipeline(pipeline: IPipeLine) {
      return this.$http.put(`${this.$header.getAPIurl()}/pipeline`, pipeline, {
         headers: this.$header.getHeaders(),
      });
   }

   getPipeline(idLine: string): Observable<IPipeLine> {
      return this.$http.get<IPipeLine>(
         `${this.$header.getAPIurl()}/pipeline/${idLine}`,
         {
            headers: this.$header.getHeaders(),
         }
      );
   }

   getPipelines(): Observable<IPipeLine[]> {
      return this.$http.get<IPipeLine[]>(
         `${this.$header.getAPIurl()}/pipeline`,
         {
            headers: this.$header.getHeaders(),
         }
      );
   }
}
