import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ApiManagerService } from "../../_services/apimanager.service";
import { Observable, of, Observer, Subject } from "rxjs";
import { IAuthKey, EApiKeyTipo } from "../../_interfaces/apikey";
import { catchError, finalize, map, switchMap, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import * as _ from "lodash";
import * as dayjs from "dayjs";

@Component({
   selector: "app-apimanager",
   templateUrl: "./apimanager.component.html",
   styleUrls: ["./apimanager.component.css"],
})
export class ApiManagerComponent implements OnInit, AfterViewInit {
   cargarKeys$ = new Subject<boolean>();
   apiKeys$: Observable<IAuthKey[]>;

   constructor(
      private readonly $apiManager: ApiManagerService,
      private $router: Router
   ) {}

   ngAfterViewInit(): void {
      this.cargarKeys$.next(true);
   }

   ngOnInit() {
      $("#modalWait_kerberus").modal();
      this.apiKeys$ = this.cargarKeys$.pipe(
         switchMap(() => this.$apiManager.getApiKeys()),
         map((keys) => _.orderBy(keys, ["fecha_creacion"], ["desc"])),
         tap(() => $("#modalWait_kerberus").modal("hide")),
         this.validationMiddle$([])
      );
   }

   nuevoApi(tipo: EApiKeyTipo) {
      this.guardarApiKey({
         fecha_creacion: dayjs().format("YYYY-MM-DD HH:mm:ss"),
         contexto: tipo === EApiKeyTipo.MANAGER_KEY ? "/manager" : "*",
         tipo: tipo,
         host: [],
      });
   }

   guardarApiKey(apiKey: IAuthKey): void {
      $("#modalWait_kerberus").modal();
      this.$apiManager
         .crearApiKey(apiKey)
         .pipe(finalize(() => this.cargarKeys$.next(true)))
         .subscribe(
            (res) => console.log(res),
            (err) => console.warn(err)
         );
   }

   eliminarApiKey(apiKey: IAuthKey): void {
      $("#modalWait_kerberus").modal();
      this.$apiManager
         .eliminarApiKey(apiKey)
         .pipe(finalize(() => this.cargarKeys$.next(true)))
         .subscribe(
            (res) => console.log(res),
            (err) => console.warn(err)
         );
   }

   /**
    * Función operable para devolver al usuario a login
    * en caso de que falle su petición con respuesta 401: Unauthorized
    */
   private validationMiddle$ = (response: Object) => <T>(
      source: Observable<T>
   ) =>
      new Observable((o: Observer<any>) => {
         return source
            .pipe(
               catchError((err: HttpErrorResponse) => {
                  $("#modalWait_kerberus").modal("hide");
                  if (err.status === 401) {
                     this.$router.navigateByUrl("/login");
                  }
                  return of(response);
               })
            )
            .subscribe({
               next(x) {
                  o.next(x);
               },
               error(e) {
                  o.error(e);
               },
               complete() {
                  o.complete();
               },
            });
      });
}
