<form autocomplete="off" novalidate class="form-group" #queryForm="ngForm">
   <div class="row">
      <div class="col-sm-4" style="margin-bottom: 15px">
         <div class="input-group" ngModelGroup="fecha">
            <div class="input-group-btn">
               <button type="button" class="btn btn-success" style="min-width: 100px">Fecha</button>
            </div>
            <input type="text" ngDatePicker ngModel name="inicio" required>
            <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
            <input type="text" ngDatePicker ngModel name="fin">
         </div>
      </div>
      <div class="col-sm-4" style="margin-bottom: 15px">
         <div class="input-group">
            <div class="input-group-btn">
               <button type="button" class="btn btn-success" style="min-width: 100px">Cola</button>
            </div>
            <span class="input-group-addon"><i class="fa fa-dashboard"></i></span>
            <select class="form-control" [(ngModel)]="queryParams.cola" name="cola"
               *ngIf="autosearch_colas$ | async as autosearch_colas; else noQueues">
               <option *ngFor="let nombreCola of autosearch_colas; index as i" [value]="nombreCola" [selected]="i == 0">
                  {{nombreCola}}
               </option>
               <option value="" *ngIf="isPrivilged">Todas las colas</option>
            </select>
            <ng-template #noQueues>
               <input type="text" ngModel name="valor" class="form-control" disabled placeholder="Consultando...">
            </ng-template>
         </div>
      </div>
      <div class="col-sm-4" style="margin-bottom: 15px">
         <div class="input-group">
            <div class="input-group-btn">
               <button type="button" class="btn btn-success" style="min-width: 100px">Resultado Petición</button>
            </div>
            <span class="input-group-addon"><i class="fa fa-dashboard"></i></span>
            <select class="form-control" ngModel name="resultadoRequest">
               <option value="">Todos</option>
               <option *ngFor="let op of opcionesRequest" [value]="op">{{op}}</option>
            </select>

         </div>
      </div>
      <div class="col-sm-4" style="margin-bottom: 15px">
         <div class="input-group">
            <div class="input-group-btn">
               <button type="button" class="btn btn-success" style="min-width: 100px">Resultado Ejecución</button>
            </div>
            <span class="input-group-addon"><i class="fa fa-dashboard"></i></span>
            <select class="form-control" ngModel name="resultadoLog">
               <option value="">Todos</option>
               <option *ngFor="let lOpt of opcionesLog" [value]="lOpt">{{lOpt}}</option>
            </select>

         </div>
      </div>
      <div class="col-sm-8 text-right" style="margin-bottom: 15px">
         <button class="btn btn-primary" type="button" [disabled]="!queryForm.valid"
            (click)="buscar(queryForm.value)">Ejecutar Búsqueda</button>
      </div>
   </div>
   <div class="table-responsive">
      <table class="table table-striped"
         style="margin-bottom: 0; font-size: 12px; border: 1px solid #e2e2e2; min-width: 1100px">
         <thead style="background-color: #0d1e40; color: white">
            <tr>
               <td class="text-center">Callid</td>
               <td class="text-center">Fecha de Solicitud</td>
               <td class="text-center">Fecha de Programada</td>
               <td class="text-center">Cola / Campaña</td>
               <td class="text-center">CallerID</td>
               <td class="text-center">Número Ingresado</td>
               <td class="text-center">Agendado</td>
               <td class="text-center">Destino</td>
               <td class="text-center">Fecha de evento</td>
               <td class="text-center">Número Marcado</td>
               <td class="text-center">Resultado</td>
            </tr>
         </thead>
         <tbody *ngIf="queryResult$ | async as resultados; else querying">
            <ng-container *ngFor="let req of resultados | paginate: pagConfig; let i = index">
               <tr>
                  <td>{{req.requestID}}</td>
                  <td [attr.rowspan]="(req.log.length +1)">{{req.fechaSolicitud | toTimeFormat}}</td>
                  <td [attr.rowspan]="(req.log.length +1)">{{req.resultado === 'SUCCESS' ? (req.fechaProgramada |
                     toTimeFormat) : '-'}}</td>
                  <td [attr.rowspan]="(req.log.length +1)">{{req.cola}}</td>
                  <td [attr.rowspan]="(req.log.length +1)">{{req.callerid}}</td>
                  <td [attr.rowspan]="(req.log.length +1)">{{req.telefonoSolicitado}}</td>
                  <td [attr.rowspan]="(req.log.length +1)" class="text-center">{{req.resultado === 'SUCCESS' ? 'SI' :
                     'NO'}}
                  </td>
                  <td colspan="4"></td>
               </tr>
               <tr *ngFor="let log of req.log">
                  <td>{{log.callid}}</td>
                  <td>{{log.cola}}</td>
                  <td>{{log.fecha | toTimeFormat}}</td>
                  <td>{{log.numeroMarcado}}</td>
                  <td>{{log.resultado}}</td>
               </tr>
            </ng-container>
            <tr>
               <td colspan="11" class="text-right">
                  <pagination-template #p="paginationApi" [id]="pagConfig.id"
                     (pageChange)="pagConfig.currentPage = $event">

                     <div class="btn-group">
                        <button type="button" class="btn btn-default" [disabled]="p.isFirstPage()"
                           (click)="p.previous()">
                           <i class="fa fa-arrow-circle-o-left"></i>
                        </button>

                        <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                           [disabled]="p.getCurrent() === page.value"
                           [ngClass]="{'active': p.getCurrent() === page.value}" (click)="p.setCurrent(page.value)">
                           {{ page.label }}
                        </button>

                        <button type="button" class="btn btn-default" [disabled]="p.isLastPage()" (click)="p.next()">
                           <i class="fa fa-arrow-circle-o-right"></i>
                        </button>
                     </div>
                  </pagination-template>
               </td>
            </tr>
         </tbody>
         <ng-template #querying>
            <tr>
               <td colspan="11" class="text-center text-muted">
                  <div style="padding: 40px;">
                     <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
                     <span class="text-muted">Un momento por favor...</span>
                  </div>
               </td>
            </tr>
         </ng-template>
      </table>
   </div>
</form>

<hr>
<div class="panel panel-primary">
   <div class="panel-heading">
      <strong><i class="fa fa-bar-chart"></i> Estadísticas de la Consulta</strong>
   </div>
   <div class="panel-body">
      <div class="row" *ngIf="queryResult$ | async as resultados">
         <div class="col-md-3">
            <li class="list-group-item">Total Solicitudes <span class="badge">{{resultados.length || 0}}</span></li>
            <li class="list-group-item">Total Agendadas <span class="badge">{{(resultados | queueCallbackStats:
                  'SUCCESS').length || 0}}</span></li>
            <li class="list-group-item">Total Fallidas <span class="badge">{{(resultados | queueCallbackStats:
                  'FAILED').length || 0}}</span></li>
            <li class="list-group-item">Total Max. Intentos <span class="badge">{{(resultados | queueCallbackStats:
                  'MAX_ATTEMPTS').length || 0}}</span></li>
         </div>
         <div class="col-md-5">
            <li class="list-group-item">Total Llamadas Retornadas
               <span class="badge">{{(resultados | queueCallbackLogStats).length || 0}}</span>
            </li>
            <li class="list-group-item">Llamadas contestadas
               <span class="badge">{{(resultados | queueCallbackLogStats : 'ANSWERED').length || 0}}</span>
            </li>
            <li class="list-group-item">Llamadas no contestadas
               <span class="badge">{{(resultados | queueCallbackLogStats: 'NOANSWER').length || 0}}</span>
            </li>
            <li class="list-group-item">Llamadas detectadas como máquina
               <span class="badge">{{(resultados | queueCallbackLogStats: 'MACHINE').length || 0}}</span>
            </li>
            <li class="list-group-item">Llamadas no detectadas como máquina o humano
               <span class="badge">{{(resultados | queueCallbackLogStats: 'NOTSURE').length || 0}}</span>
            </li>
         </div>
         <div class="col-md-4">
            Exportación de resultados.<br>
            <small class="text-muted">
               Puede descargar la información en CSV para ser filtrada en MSExcel&trade; o Google Sheets
            </small>
            <hr>
            <div class="text-center">
               <button type="button" (click)="descargarReporte(resultados)" [disabled]="resultados.length === 0"
                  class="btn btn-warning">Descargar en *.CSV
               </button>
            </div>

         </div>
      </div>
   </div>
</div>