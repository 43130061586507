import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
   FormArray,
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from "@angular/forms";

@Component({
   selector: "app-arbol",
   templateUrl: "./arbol.component.html",
   styleUrls: ["./arbol.component.css"],
})
export class ArbolComponent implements OnInit {
   mostarOtro = true;

   @Input()
   frmPregunta: FormGroup;

   @Input()
   ordenPregunta: number;

   @Output()
   eliminarPregunta = new EventEmitter<number>();

   get frmRespuestas() {
      return this.frmPregunta.get("respuestas") as FormArray;
   }

   constructor(private $fb: FormBuilder) {}

   ngOnInit(): void {}

   delPregunta(index: number) {
      this.eliminarPregunta.emit(index);
   }

   delPreguntaArbol(indexArbol: number, index: number) {
      const arbol = this.frmRespuestas.at(indexArbol).get("next") as FormArray;
      arbol.removeAt(index);
   }

   addRespuesta(tipo: string) {
      if (!!tipo && tipo === "OTRO") {
         this.mostarOtro = false;
      }
      this.frmRespuestas.push(this.iniciarRespuesta(tipo));
   }

   delRespuesta(index: number) {
      // Chequear si es de tipo otro
      const respuesta: {
         tipo: string;
      } = (this.frmRespuestas.at(index) as FormControl).value;
      if (respuesta.tipo === "OTRO") {
         this.mostarOtro = true;
      }
      // remover
      this.frmRespuestas.removeAt(index);
   }

   agregarArbol(index: number, tipo?: string) {
      const arbol = this.frmRespuestas.at(index).get("next") as FormArray;
      const nuevaPregunta = this.$fb.group({
         orden: "",
         tipo: "",
         pregunta: ["", Validators.required],
         respuestas: this.$fb.array([this.iniciarRespuesta(tipo)]),
      });

      nuevaPregunta.patchValue({
         orden: arbol.length,
         tipo: tipo || "SELECCION",
      });

      arbol.push(nuevaPregunta);
   }

   private iniciarRespuesta(tipo?: string) {
      const respuesta = this.$fb.group({
         tipo: ["", Validators.required],
         respuesta: "",
         next: this.$fb.array([]),
      });
      respuesta.patchValue({
         tipo: tipo || "SIMPLE",
      });
      return respuesta;
   }
}
