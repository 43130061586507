import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IvrComponent } from "./ivr.component";
import { DIDComponent } from "./did/did.component";
import { SecuenciaComponent } from "./secuencia/secuencia.component";
import { FiltroPorContextoPipe } from "./pipes/contexto.pipe";

import { FileUploadModule } from "ng2-file-upload";
import { PipesModule } from "src/app/pipes/pipes.module";
import { OpcionComponent } from "./opcion/opcion.component";
import { NvrPresenterPipe } from "./pipes/nvr-presenter.pipe";
import { ApiDirectionPresenterPipe } from "./pipes/api-direction-presenter.pipe";

@NgModule({
   imports: [
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      FileUploadModule,
      PipesModule,
   ],
   declarations: [
      IvrComponent,
      DIDComponent,
      SecuenciaComponent,
      FiltroPorContextoPipe,
      OpcionComponent,
      NvrPresenterPipe,
      ApiDirectionPresenterPipe,
   ],
   exports: [
      IvrComponent,
      DIDComponent,
      SecuenciaComponent,
      FiltroPorContextoPipe,
   ],
   providers: [ApiDirectionPresenterPipe],
})
export class IvrModule {}
