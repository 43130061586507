<div class="modal fade" tabindex="-1" role="dialog" id="agente-ops">
   <div class="modal-dialog modal-md" role="document">
      <form novalidate #f="ngForm">
         <div class="modal-content">
            <div class="modal-header">
               <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                     aria-hidden="true">&times;</span></button>
               <h5 class="modal-title">
                  <i class="fa fa-info-circle"></i>
                  Agente {{agente?.idagente}} {{agente?.nombre}}
               </h5>
            </div>
            <div class="modal-body" style="font-size:13px">
               <div class="row">
                  <div class="col-md-6">
                     <label class="text-muted">Modificación de skill</label><br>
                     <button type="button" class="btn btn-default" 
                        (click)="cambiarSkill(agente.idagente, agente.modoAgente, 0, cola)"
                        [ngClass]="{ 'btn-primary active' : agentSkill === 0 }" style="margin-right: 5px; margin-bottom: 5px;" >Inbound</button>
                     <button type="button" class="btn btn-default" 
                        (click)="cambiarSkill(agente.idagente, agente.modoAgente, 100, cola)"
                        [ngClass]="{ 'btn-primary active' : agentSkill === 100 }" style="margin-right: 5px; margin-bottom: 5px;">Mixto</button>
                     <button type="button" class="btn btn-default" 
                        (click)="cambiarSkill(agente.idagente, agente.modoAgente, -1, cola)"
                        [ngClass]="{ 'btn-primary active' : agentSkill === -1 || agentSkill > 100 }" style="margin-bottom: 5px;">Outbound</button>
                     
                        <hr>

                     <label class="text-muted">Skills de prioridad</label><br>
                     <button type="button" class="btn btn-sm btn-default" 
                        style="margin-right: 5px; margin-top: 5px;" 
                        [ngClass]="{ 'btn-success active' : agentSkill === i }"
                        (click)="cambiarSkill(agente.idagente, agente.modoAgente, i, cola)"
                        *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
                        <i class="fa fa-star-o"></i> {{i}}
                     </button>

                  </div>
                  <div class="col-md-6">
                     <div class="panel panel-default" *ngIf="agente?.estado.includes('PAUSE')">
                        <div class="panel-heading">
                           <div style="line-height: 20px">
                              <strong>{{reason?.fecha}}</strong><br>
                              Tipo: {{reason?.tipo}}<br>
                              Autor: {{reason?.autor}}
                              <div class="text-justify">
                                 Motivo: {{reason?.motivo}}
                              </div>
                           </div>
                        </div>
                     </div>
                     <label class="text-muted">Cambios de estado</label>
                     <select class="form-control" [(ngModel)]="motivo.motivo" name="motivo" required>
                        <option value="">Selecciona uno...</option>
                        <option *ngFor="let motivo of motivos$ | async" [value]="motivo.reason | toUpperCase">{{motivo.reason
                           | toUpperCase}}</option>
                     </select>
                     <div style="margin-top: 10px">
                        <label class="text-muted">Descripcion</label>
                        <input type="text" [(ngModel)]="motivo.descripcion" name="descripcion" class="form-control"
                           placeholder="Ingresa un motivo" required>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <div class="text-muted text-left small">
                  * El cambio de skill sólo tendrá efecto en esta campaña
               </div>
               <button *ngIf="agente?.estado.indexOf('PAUSE') === -1"
                  [disabled]="agente?.estado.indexOf('OFFLINE') > -1 || f.invalid"
                  (click)="pausaAgente(f.value, true); f.reset()" type="button" class="btn btn-sm btn-warning"
                  data-dismiss="modal">
                  <i class="fa fa-pause"></i> Pause</button>

               <button [disabled]="f.invalid" *ngIf="agente?.estado.indexOf('PAUSE') > -1"
                  (click)="pausaAgente(f.value, false); f.reset()" type="button" class="btn btn-sm btn-warning"
                  data-dismiss="modal">
                  <i class="fa fa-play"></i> Unpause</button>

               <button [disabled]="agente?.estado.indexOf('OFFLINE') > -1" type="button" class="btn btn-sm btn-primary"
                  (click)="desloguearAgente(f.value); f.reset()"><i class="fa fa-close"></i> Desloguear</button>

               <button [disabled]="agente?.estado.indexOf('BUSY') === -1 || f.invalid" type="button"
                  class="btn btn-sm btn-danger" (click)="colgarLlamada(f.value); f.reset()">
                  <span class="glyphicon glyphicon-phone-alt"></span>
               </button>
            </div>
         </div><!-- /.modal-content -->
      </form>
   </div><!-- /.modal-dialog -->
</div><!-- /.modal -->