import { Pipe, PipeTransform } from '@angular/core';
import { ITipificacionLog } from 'src/app/_interfaces/_all';
import { from, Observable } from 'rxjs';
import { map, reduce, tap, groupBy, mergeMap, toArray } from 'rxjs/operators';

@Pipe({
   name: 'camposContacto',
})
export class CamposContactoPipe implements PipeTransform {
   transform(resultados: ITipificacionLog[]): Observable<string[]> {
      // Entregar array de idbs de formularios.
      from(resultados).pipe(
         map((resultado) => resultado.contacto),
         groupBy((contacto) => contacto.iddb),
         mergeMap((agrupado) => agrupado.pipe(toArray()))
      );

      return from(resultados).pipe(
         map((resultado) => resultado.contacto),
         map((contacto) => {
            const { _id, iddb, ...resto } = contacto;
            return Object.keys(resto).sort();
         }),
         reduce((acc, curr) => [...acc, ...curr], []),
         map((campos) => [...new Set(campos)])
      );
   }
}
