<form [formGroup]="preguntaFrm">
   <input type="hidden" formControlName="tipo" #tipoPregunta>
   <h5 style="font-weight: 600; font-size: 15px;">
      <i class="fa fa-question-circle-o" style="margin-right: 10px;"></i> {{preguntaFrm.value.enunciado}}
   </h5>

   <div [ngSwitch]="tipoPregunta.value" style="margin-left: 30px; margin-top: 15px;">
      <div *ngSwitchCase="'ABIERTA'">
         <input type="text" formControlName="respuesta" placeholder="Respuesta" style="border: 0; 
            border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
      </div>
      <div *ngSwitchCase="'NUMERO'">
         <input type="text" formControlName="respuesta" placeholder="Respuesta" style="border: 0; 
            border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
      </div>
      <div *ngSwitchCase="'TEL'">
         <input type="tel" formControlName="respuesta" placeholder="Respuesta" style="border: 0; 
            border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
      </div>
      <div *ngSwitchCase="'FECHA'">
         <input type="text" ngDatePicker formControlName="respuesta">
      </div>
      <div *ngSwitchCase="'SELECCION'">
         <ng-container *ngFor="let posible of preguntaFrm.value.respuestas">
            <div [ngSwitch]="posible.tipo">

               <div class="radio radio-success" *ngSwitchCase="'SIMPLE'">
                  <input type="radio" formControlName="respuesta" [value]="posible.respuesta"
                     [id]="'_' + posible.respuesta" (click)="agregar(posible.next || [])">
                  <label [htmlFor]="'_' + posible.respuesta">{{posible.respuesta}}</label>
               </div>

               <div class="radio radio-success" *ngSwitchDefault>
                  <input type="radio" #otro formControlName="respuesta" [value]="otroTxt.value"
                     (click)="agregar(posible.next || [])">
                  <label [htmlFor]="">Otro</label>
                  <input type="text" formControlName="respuesta" #otroTxt [readonly]="!otro.checked" placeholder="Cual?"
                     style="display: inline; border: 0; 
                        border-bottom: 1px dotted #cccccc; padding: 5px; margin-left: 10px;">
               </div>

            </div>
         </ng-container>
      </div>
      <div *ngSwitchDefault>
         <input type="text" formControlName="respuesta" placeholder="Respuesta" style="border: 0; 
                        border-bottom: 1px dotted #cccccc; padding: 5px; width: 100%">
      </div>

      <small class="text-danger"
         *ngIf="preguntaFrm.controls.respuesta.errors && (preguntaFrm.dirty || preguntaFrm.touched)">
         <h6 *ngIf="preguntaFrm.controls.respuesta.errors.required">* Este campo es necesario.</h6>
         <h6 *ngIf="preguntaFrm.controls.respuesta.errors.invalidDate">* Este valor de fecha es inválido.
         </h6>
         <h6 *ngIf="preguntaFrm.controls.respuesta.errors.hasInvalidChars">
            * Este campo contiene caracteres inválidos, solo se permiten números y guiones medios.
         </h6>
      </small>

      <div formArrayName="next">
         <ng-container *ngFor="let nPregunta of preguntasArray.controls; let i = index">
            <div [formGroupName]="i" class="form-group">
               <app-pregunta [preguntaFrm]="nPregunta"></app-pregunta>
            </div>
         </ng-container>
      </div>
   </div>
</form>