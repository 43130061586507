<!-- MENU -->
<admin-menu></admin-menu>
<!-- MENU -->

<!-- CONTENIDO -->
<div class="panel" style="margin: 20px; margin-top: 70px; margin-bottom: 65px; padding: 15px; min-height: 200px">
    <router-outlet name="view"></router-outlet>
</div>
<!-- FIN CONTENIDO -->

<div class="modal fade" tabindex="-1" role="dialog" id="modalWait_kerberus">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
            <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
            Un momento por favor...
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->