import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { AgenteService } from './agente.service';
import { Agente } from '../_interfaces/agente';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AgentResolver implements Resolve<Agente | string> {
    
    constructor(private $agente: AgenteService, private $router: Router) {}
    
    resolve(): Observable<Agente | string> {
        return this.$agente.getSesionAgente().pipe(
            catchError(err => {
                this.$router.navigateByUrl('/login');
                return of(err)
            })
        );
    }
}
