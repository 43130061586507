import { Pipe, PipeTransform } from '@angular/core';
import { IAgentMonitor } from 'src/app/_interfaces/_all';

@Pipe({
  name: 'agentAvailable'
})
export class AgentAvailablePipe implements PipeTransform {

    transform(agente: IAgentMonitor): any {
        return !agente || (!!agente && !agente.estado.includes('IDLE'));
    }

}
