import { FormControl } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ISchedule, EScheduleStatus, IScheduleJob, ETipoScheduleEvent } from 'src/app/_interfaces/schedule';
import { ScheduleService } from 'src/app/_services/schedule.service';
import { IAgentMonitor } from 'src/app/_interfaces/_all';
import { Observable, Subject, BehaviorSubject, timer, from, of, Subscription, merge } from 'rxjs';
import { map, flatMap, startWith, debounceTime, tap, delay, switchMap, filter, withLatestFrom, mapTo } from 'rxjs/operators';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit, AfterViewInit, OnDestroy {

    schedulerSubs: Subscription;
    eventos$: Observable<ISchedule[]>;

    enEdicion = false;
    seleccionFecha = new FormControl();
    scheduler$ = new Subject<ISchedule[]>();
    eventoEnEdicion = new BehaviorSubject<ISchedule>(undefined);

    @Input() agente: IAgentMonitor;
    @Output() accion = new EventEmitter<IScheduleJob>();

    get smsEvent() { return ETipoScheduleEvent.SMS }
    get callEvent() { return ETipoScheduleEvent.CALL }
    get emailEvent() { return ETipoScheduleEvent.EMAIL }
    get remainderEvent() { return ETipoScheduleEvent.RECORDATORIO }

    constructor( private $scheduler: ScheduleService) { 
        const proximoEvento$ = (horario: Date, eventos: ISchedule[], evento: ISchedule) => of(evento).pipe(
            filter(evento => 
                // Horario actual - 5 mins > el evento.
                horario.getTime() > dayjs(evento.horario).add(-5, 'minute').valueOf() && 
                // Si evento ocurren en la ventana de 15 minutos.
                evento.horario > dayjs(horario.getTime()).add(-15, 'minute').valueOf() &&
                // Si el evento aun esta programado
                evento.estado === EScheduleStatus.PROGRAMADO
            ),
            tap(() => {
                const audio = new Audio('/public/sounds/sound4.ogg');
                audio.play();
            }),
            delay(200),
            tap(evento => alert(`Próximo evento: ${evento.evento}\n${dayjs(evento.horario).format('dddd D [de] MMMM, h:mm A')}${evento.descripcion ? `\n\n${evento.descripcion}` : '' }`)),
            map(evento => ({...evento, estado: EScheduleStatus.ALERTADO})),
            tap(evento => {
                const eventosPendientes = eventos.filter(e => e.id !== evento.id);
                this.scheduler$.next([...eventosPendientes, evento]);
            }),
            flatMap(evento => this.$scheduler.guardarEvento(evento)),
        );

        const ejecucionEvento$ = (horario: Date, eventos: ISchedule[], evento: ISchedule) => of(evento).pipe(
            filter(evento => 
                // Horario actual > el evento.
                horario.getTime() > evento.horario && 
                // Si evento ocurren en la ventana de 15 minutos.
                evento.horario > dayjs(horario.getTime()).add(-15, 'minute').valueOf() &&
                // Si el evento aun esta programado
                evento.estado !== EScheduleStatus.EJECUTADO
            ),
            // Si pasa este filtro, alertarlo.
            tap(() => {
                const audio = new Audio('/public/sounds/sound4.ogg');
                audio.play();
            }),
            delay(200),
            flatMap(evento => {
                let confirma = false;
                // Removerlo del scheduler
                const eventosPendientes = eventos.filter(e => e.id !== evento.id);
                this.scheduler$.next(eventosPendientes);

                if (evento.tarea.tipoEvento === ETipoScheduleEvent.RECORDATORIO) {
                    alert(`Evento Agendado : ${evento.evento}${evento.descripcion ? `\n\n${evento.descripcion}` : '' }`);
                } else {
                    confirma = confirm(`Evento Agendado : ${evento.evento}${evento.descripcion ? `\n\n${evento.descripcion}` : '' }\n\nDesea ejecutar la tarea?`);
                }

                // Actualizarlo como Alertado.
                return of({...evento, estado: EScheduleStatus.EJECUTADO}).pipe(
                    flatMap(evento => this.$scheduler.guardarEvento(evento)),
                    // Retornar confirmación y evento.
                    map(evento => ({ confirma, evento }))
                );
            }),
            // y ejecutarlo si no es un recordatorio.
            tap(({ confirma, evento }) => {
                if (confirma && evento.tarea.tipoEvento !== ETipoScheduleEvent.RECORDATORIO) {
                    // Ejecutar 
                    this.accion.emit(evento.tarea);
                }
            })
        );

        // Scheduler
        this.schedulerSubs = timer(5000, 15 * 1000).pipe(
            map(() => new Date()),
            withLatestFrom(this.scheduler$),
            flatMap(([horarioActual, eventos]) => from(eventos).pipe(
                flatMap(evento => merge(
                    proximoEvento$(horarioActual, eventos, evento), 
                    ejecucionEvento$(horarioActual, eventos, evento)
                ))
            ))
        ).subscribe(
            response => console.log(response),
            err => console.error(err)
        );
    }

    ngOnInit(fecha?: string) {
        this.eventos$ = this.seleccionFecha.valueChanges.pipe(
            // Iniciar con la fecha actual
            startWith(fecha || dayjs().format('YYYY-MM-DD')),
            // formatear
            map((fecha: string) => new Date(`${fecha.trim()} `)),
            // Obtener eventos.
            switchMap(fecha => this.$scheduler.obtenerEventos(fecha, this.agente.idagente).pipe(
                tap(eventos => {
                    // Limpiar los eventos
                    this.scheduler$.next([]);
                    // si los eventos son de la fecha de hoy
                    if (eventos.length > 0 && fecha.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ) {
                        const ahora = new Date();
                        // Solo aquellos eventos que tengan horario mayor a la fecha actual.
                        this.scheduler$.next(eventos.filter(e => e.horario > ahora.getTime()));
                    }
                }),
                map(eventos => this.crearEspacios(fecha, eventos))
            ))
        );
    }

    crearEspacios(fecha: Date, eventos: ISchedule[]) {
        const plantilla: ISchedule[] = [];
        let horario = dayjs(fecha.setHours(5, 0, 0, 0));
        while (horario.hour() < 22) {
            const evento = eventos.find( e => e.horario === horario.valueOf());
            plantilla.push( evento || {
                estado: EScheduleStatus.PROGRAMADO,
                evento: undefined,
                horario: horario.valueOf(),
                idagente: this.agente.idagente,
                tarea: undefined
            } );
            horario = horario.add(15, 'minute');
        }
        return plantilla;
    }

    editarEvento(evento: ISchedule) {
        this.enEdicion = true;
        this.eventoEnEdicion.next(evento);
    }

    composeEnd(evento?: ISchedule) {
        this.enEdicion = false;
        if (!!evento) {
            // Recargar los nuevos eventos.
            this.ngOnInit(dayjs(evento.horario).format('YYYY-MM-DD'));
        }
    }

    ngOnDestroy() {
        if (!!this.schedulerSubs) {
            this.schedulerSubs.unsubscribe();
        }
    }

    ngAfterViewInit() {
        $('#menuAgenda').on('click', e => {
            e.stopPropagation();
        });
    }

}
