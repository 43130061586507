import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
   FormArray,
   FormGroup,
   FormBuilder,
   Validators,
   AbstractControl,
} from "@angular/forms";
import { IForma, IFormaPregunta } from "../../_interfaces/forma";
import { QueueService } from "../../_services/queue.service";
import { Subscription } from "rxjs";

const maxQueueListLenght = (
   control: AbstractControl
): { currentLenght: number } | null => {
   const queueCurrentList = control.value as string[];
   return queueCurrentList.length > 30
      ? { currentLenght: queueCurrentList.length - 30 }
      : null;
};

@Component({
   selector: "formulario-tag",
   templateUrl: "formulario.component.html",
   styleUrls: ["formulario.component.css"],
})
export class FormularioComponent implements OnInit {
   mostarOtro = true;
   queueList: string[];
   formulario: FormGroup;

   private queueSubs: Subscription;
   private formStatus: Subscription;

   @Output()
   isFormInvalid = new EventEmitter<boolean>();

   get colasControl() {
      return this.formulario.get("colas") as AbstractControl;
   }

   get frmPreguntas() {
      return this.formulario.get("pregunta") as FormArray;
   }

   constructor(private $fb: FormBuilder, private $queue: QueueService) {}

   ngOnInit() {
      this.formulario = this.$fb.group({
         id: "",
         fecha: "",
         titulo: ["", Validators.required],
         descripcion: "",
         colas: ["", maxQueueListLenght],
         pregunta: this.$fb.array([]),
      });

      if (!!this.queueSubs && !this.queueSubs.closed) {
         this.queueSubs.unsubscribe();
      }

      this.queueSubs = this.$queue.getQueues().subscribe((queues) => {
         this.queueList = [];
         queues.forEach((q) => {
            this.queueList.push(q.nombre);
         });
      });

      // Si es creado de nuevo, y aun esta suscrito
      if (!!this.formStatus && !this.formStatus.closed) {
         this.formStatus.unsubscribe();
      }

      // Iniciar el status
      this.isFormInvalid.emit(this.formulario.invalid);
      // Observar cambios en el formulario y emtirlos
      this.formStatus = this.formulario.statusChanges.subscribe((status) =>
         this.isFormInvalid.emit(status === "INVALID")
      );
   }

   getFormularioValue(): IForma {
      return this.formulario.value as IForma;
   }

   iniciarPregunta() {
      return this.$fb.group({
         orden: "",
         tipo: "",
         pregunta: ["", Validators.required],
         respuestas: this.$fb.array([this.iniciarRespuesta()]),
      });
   }

   iniciarRespuesta(tipo?: string) {
      const respuesta = this.$fb.group({
         tipo: ["", Validators.required],
         respuesta: "",
         next: this.$fb.array([]),
      });
      respuesta.patchValue({
         tipo: tipo || "SIMPLE",
      });
      return respuesta;
   }

   addPregunta(tipoPregunta?: string) {
      const pregunta = this.iniciarPregunta();
      pregunta.patchValue({
         orden: this.frmPreguntas.length,
         tipo: tipoPregunta || "SELECCION",
      });
      this.frmPreguntas.push(pregunta);
   }

   delPregunta(index: number) {
      this.frmPreguntas.removeAt(index);
   }

   addRespuesta(r: FormArray, tipo?: string) {
      if (!!tipo && tipo.indexOf("OTRO") > -1) {
         this.mostarOtro = false;
      }
      r.push(this.iniciarRespuesta(tipo));
   }

   delRespuesta(r: FormArray, index: number) {
      const _v: { tipo: string }[] = r.value;
      if (_v[index].tipo.indexOf("OTRO") > -1) {
         this.mostarOtro = true;
      }
      r.removeAt(index);
   }

   cargarFormulario(forma: IForma) {
      this.ngOnInit();

      this.formulario.patchValue({
         id: forma.id,
         fecha: forma.fecha,
         titulo: forma.titulo,
         descripcion: forma.descripcion,
         colas: forma.colas,
      });

      const frmPreguntas = this.cargarPreguntas(forma.pregunta || []);
      frmPreguntas.forEach((pregunta) => this.frmPreguntas.push(pregunta));
   }

   private cargarPreguntas(preguntas: IFormaPregunta[]) {
      return this.orden(preguntas).map((pregunta) => {
         const preguntaFrm = this.iniciarPregunta();

         preguntaFrm.patchValue({
            orden: pregunta.orden,
            tipo: pregunta.tipo,
            pregunta: pregunta.pregunta,
         });

         const respuestasFrm = preguntaFrm.get("respuestas") as FormArray;
         respuestasFrm.clear();

         const frmRespuestas = this.cargarRespuestas(pregunta.respuestas);
         frmRespuestas.forEach((respuesta) => respuestasFrm.push(respuesta));

         return preguntaFrm;
      });
   }

   private cargarRespuestas(
      respuestas: { tipo: string; respuesta: string; next?: IFormaPregunta[] }[]
   ) {
      return respuestas.map((respuesta) => {
         const frmRespuesta = this.iniciarRespuesta(respuesta.tipo);

         frmRespuesta.patchValue({
            respuesta: respuesta.respuesta,
         });

         const nextPreguntas = this.cargarPreguntas(respuesta.next || []);

         // Agregar al arreglo (anidado)
         const nextFormArray = frmRespuesta.get("next") as FormArray;
         nextPreguntas.forEach((next) => nextFormArray.push(next));

         return frmRespuesta;
      });
   }

   private orden(preguntas: IFormaPregunta[]) {
      return preguntas.sort((p1, p2) => {
         if (p1.orden > p2.orden) return 1;
         else if (p1.orden < p2.orden) return -1;
         return 0;
      });
   }
}
