import { Pipe, PipeTransform } from '@angular/core';
import { IContactoReparto, EEstadoContactoDialer } from 'src/app/_interfaces/dialer';

@Pipe({
  name: 'repartoStatus'
})
export class RepartoStatusPipe implements PipeTransform {

    transform(reparto: IContactoReparto, estado: EEstadoContactoDialer): any {
        return !!reparto && reparto.estado === estado;
    }

}
