import { Pipe, PipeTransform } from "@angular/core";
import { ILead } from "src/app/_interfaces/lead.entity";
import { IPipeLine } from "src/app/_interfaces/pipeline.entity";

@Pipe({
   name: "filterLeadByID",
})
export class FilterLeadByIDPipe implements PipeTransform {
   transform(leads: ILead[], phase: IPipeLine, agente: string): unknown {
      const leadsInPhase = leads
         .filter((l) => {
            if (!agente || (!!agente && l.agenteAsignado === agente)) {
               true;
            }
            return false;
         })
         .filter((l) => l.fasePipeline === phase.id);
      return {
         idFase: phase.id,
         nombre: phase.nombre,
         items: leadsInPhase,
      };
   }
}
