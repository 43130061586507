import { Component, OnInit, ViewChild } from '@angular/core';

import { IOperacion, ITopMenu, EVistas } from './adminmenu/adminMenu.component';
import { Usuario } from '../_interfaces/usuario';
import { UsuarioService } from '../_services/usuario.service';

@Component({
    selector: 'admin-comp', 
    styleUrls: ['admin.component.css'],
    templateUrl: 'admin.component.html'
})
export class AdminComponent implements OnInit {
    
    private nombre: string;          // Nombre de la cola
    
    private vm = EVistas;           // variable usuada en el switch
    private _menu: ITopMenu = {};
    get usuario() { return this.$usuario.usuario; }

    constructor (private $usuario: UsuarioService) { 
        console.log(`Bievenido ${this.usuario.usuario}`);
    }
    
    ngOnInit () { }
    
    addMenuOptions (opciones: string[]) {
        this._menu.menu = opciones;
    }
    
    addStats (stats: {nombre: string, valor: string}[]) {
        this._menu.stats = stats;
    }
    
    changeMenuNombre (nombre: string) {
        this._menu.nombre = nombre;
    }
} 
