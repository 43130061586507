import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "statusName",
})
export class StatusNamePipe implements PipeTransform {
   transform(estado: string): string {
      switch (estado) {
         case "OFFLINE": {
            return "Offline";
         }
         case "PAUSE": {
            return "Pausa";
         }
         case "INBOUND_BUSY_PAUSE": {
            return "Ocupado + pausa";
         }
         case "INBOUND_BUSY": {
            return "Ocupado";
         }
         case "OUTBOUND_BUSY_PAUSE": {
            return "Ocupado + pausa";
         }
         case "OUTBOUND_BUSY": {
            return "Ocupado";
         }
         case "INBOUND_IDLE": {
            return "Disponible";
         }
         case "OUTBOUND_IDLE": {
            return "Disponible";
         }
         default: {
            return "Offline";
         }
      }
   }
}
