import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ManualComponent } from './manual/manual.component';
import { IAgentMonitor } from 'src/app/_interfaces/_all';
import { ICallStatus } from 'src/app/_interfaces/callstatus';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-marcador',
  templateUrl: './marcador.component.html',
  styleUrls: ['./marcador.component.css']
})
export class MarcadorComponent implements OnInit {

    @Input() agente: IAgentMonitor;
    @Input() callStatus: ICallStatus;
    @Output() contacto = new EventEmitter<string>();

    @ViewChild(ManualComponent)
    marcadorManual: ManualComponent;

    callResponse$ = new BehaviorSubject<{callid?: string, otherCallid?: string }>({});

    get agentInQueues() {
      return this.agente.states.map(s => s.queuename)
    }

    constructor() { }

    ngOnInit() {
        // Listar las campañas que tenga en la cola y el agente de tipo MANUAL o PROGRESIVO
    }

    cargarContacto(idContacto: string) {
        this.contacto.emit(idContacto)
    }

}
