import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HeaderService } from "../../_services/header.service";
import { QueueService } from "../../_services/queue.service";

import { Subject, of, Observable, from, BehaviorSubject } from "rxjs";
import {
   switchMap,
   map,
   flatMap,
   toArray,
   finalize,
   mergeMap,
} from "rxjs/operators";
import { UsuarioService } from "src/app/_services/usuario.service";

@Component({
   selector: "callcenter-stats",
   templateUrl: "callcenter.component.html",
})
export class CallcenterStatsComponent implements OnInit {
   colas$: Observable<string[]>;

   plotDetailsForStats: Highcharts.Options;
   plotDetailsForTimeline: any;
   plotOptionsForAgentSumary: any;

   queue_stats: Highcharts.ChartObject;
   queue_timeline: Highcharts.ChartObject;
   queue_stats_date: Highcharts.ChartObject;
   queryStats$ = new Subject<IQuery>();
   queryStatsPlot$: Observable<Highcharts.Options[]>;
   queryTimelinePlot$: Observable<Highcharts.Options[]>;
   queryAgentsSumary$: Observable<Highcharts.Options[]>;

   loadingStats = new BehaviorSubject<{ loading: boolean }>({ loading: false });
   loadingTimeline = new Subject<{ loading: boolean }>();
   loadingAgentSumary = new Subject<{ loading: boolean }>();

   get usuario() {
      return {
         permisos: this.$usuario.usuario.permisos,
         colas: this.$usuario.usuario.manager.map((m) => m.cola),
      };
   }

   constructor(
      private $http: HttpClient,
      private $header: HeaderService,
      private $queue: QueueService,
      private $usuario: UsuarioService
   ) {
      this.loadingTimeline.next({ loading: false });
      this.loadingAgentSumary.next({ loading: false });

      // <editor-fold defaultstate="collapsed" desc="queueStats">
      this.plotDetailsForStats = {
         title: { text: "Informe de llamadas x Cola" },
         subtitle: {
            text: "Resumen de atención",
         },

         chart: {
            type: "bar",
            width: 900,
            height: 250,
         },
         colors: [
            "#009933",
            "#cc0000", // Abandono E
            "#ff6600", // Abandono S
            "#003300",
            "#66ff66",
            "#0099ff", // Salientes
            "#9933ff",
            "#ffcc00",
            "#ff6666",
            "#660033",
         ],
         xAxis: {
            categories: [],
            title: {
               text: null,
            },
         },
         yAxis: {
            min: 0,
            title: {
               text: "Llamadas",
               align: "high",
            },
            labels: {
               overflow: "justify",
            },
         },
         series: [],
         credits: {
            enabled: false,
         },
         plotOptions: {
            bar: {
               dataLabels: {
                  // enabled: true
               },
            },
            series: {
               stacking: "normal",
            },
         },
      };
      // </editor-fold>

      // <editor-fold defaultstate="collapsed" desc="optionsTimeLine">
      this.plotDetailsForTimeline = {
         chart: {
            width: "900",
            height: "300",
         },
         title: {
            text: "Timeline Atención de Llamadas",
         },
         subtitle: {
            text: "Recepción de llamadas por hora",
         },
         xAxis: {
            categories: [],
            crosshair: true,
         },
         yAxis: {
            min: 0,
            title: {
               text: "Llamadas x Hora",
            },
         },
         tooltip: {
            headerFormat:
               '<span style="font-size:10px">{point.key} Horas</span><table>',
            pointFormat:
               '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
               '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
         },
         plotOptions: {
            column: {
               pointPadding: 0.2,
               borderWidth: 0,
            },
         },
         series: [
            {
               name: "Recibidas",
               data: [],
            },
            {
               name: "Atendidas",
               data: [],
            },
         ],
         credits: {
            enabled: false,
         },
      };
      // </editor-fold>

      // <editor-fold defaultstate="collapsed" desc="optionsQueueAgents">
      this.plotOptionsForAgentSumary = {
         title: { text: "" },
         subtitle: {
            text: "Resumen de atención x Agente + Pausas",
         },
         chart: {
            type: "column",
            width: "900",
            height: "300",
         },
         xAxis: {
            categories: [],
            title: {
               text: null,
            },
         },
         yAxis: {
            min: 0,
            title: {
               text: "Llamadas",
               align: "high",
            },
            labels: {
               overflow: "justify",
            },
         },
         series: [
            {
               name: "Atendidas",
               data: [],
            },
            {
               name: "Pausas",
               data: [],
            },
         ],
         credits: {
            enabled: false,
         },
         plotOptions: {
            column: {
               dataLabels: {
                  enabled: true,
               },
            },
         },
      };
      // </editor-fold>
   }

   ngOnInit() {
      this.colas$ = this.$queue.getQueues().pipe(
         map((colas) => colas.map((cola) => cola.nombre)),
         mergeMap((queuesAvailable) => {
            if (this.$usuario.usuario.permisos > 1) {
               return of(this.$usuario.usuario.manager.map((m) => m.cola));
            } else {
               return of(queuesAvailable);
            }
         })
      );

      const queryFactory$ =
         <T>(URL: string) =>
         (inData$: Observable<IQuery>): Observable<T> =>
            inData$.pipe(
               switchMap((consulta) =>
                  this.$http.post<T>(this.$header.getAPIurl() + URL, consulta, {
                     headers: this.$header.getHeaders(),
                  })
               )
            );

      // Sumary
      this.queryStatsPlot$ = this.queryStats$.asObservable().pipe(
         queryFactory$<ISumaryStats[]>("/queue_stats"),
         flatMap((data) =>
            from(data).pipe(
               map((resultados) => {
                  // Clonadas las opciones.
                  return {
                     ...this.plotDetailsForStats,
                     title: {
                        text: `Informe de llamadas en ${resultados.cola}`,
                     },
                     xAxis: {
                        categories: [
                           "Entrantes",
                           "Salientes",
                           "Agenda",
                           "Callback",
                        ],
                     },
                     series: [
                        {
                           name: "Atendidas",
                           data: resultados.atendidas,
                        },
                        {
                           name: "Abandonos",
                           data: resultados.abandonos,
                        },
                        {
                           name: "Desistimientos",
                           data: resultados.desistimientos,
                        },
                     ],
                  };
               }),
               toArray(),
               finalize(() => this.loadingStats.next({ loading: false }))
            )
         )
      );

      // Time line
      this.queryTimelinePlot$ = this.queryStats$.asObservable().pipe(
         queryFactory$<IQueue_timeline[]>("/queue_stats_timeline"),
         flatMap((data) =>
            from(data).pipe(
               map((llamada) => {
                  return {
                     ...this.plotDetailsForTimeline,
                     title: {
                        text: `Timeline de ${llamada.queuename}`,
                     },
                     xAxis: {
                        categories: llamada.fecha,
                        crosshair: true,
                     },
                     series: [
                        {
                           name: "Recibidas",
                           data: llamada.recibidas,
                        },
                        {
                           name: "Atendidas",
                           data: llamada.atendidas,
                        },
                     ],
                  };
               }),
               toArray(),
               finalize(() => this.loadingTimeline.next({ loading: false }))
            )
         )
      );

      // Agents sumary
      this.queryAgentsSumary$ = this.queryStats$.asObservable().pipe(
         queryFactory$<IQueue_Agents[]>("/queue_stats_agents"),
         flatMap((data) =>
            from(data).pipe(
               map((agente) => {
                  return {
                     ...this.plotOptionsForAgentSumary,
                     title: {
                        text: `Rendimiento de Agentes en ${agente.queues}`,
                     },
                     xAxis: {
                        categories: agente.agentes,
                        title: {
                           text: null,
                        },
                     },
                     series: [
                        {
                           name: "Atentidas",
                           data: agente.atendidas,
                        },
                        {
                           name: "Pausas",
                           data: agente.pausas,
                        },
                        {
                           name: "Transferencias",
                           data: agente.xfers,
                        },
                     ],
                  };
               }),
               toArray(),
               finalize(() => this.loadingAgentSumary.next({ loading: false }))
            )
         )
      );
   }

   passChart(chart, tipo: string) {
      switch (tipo) {
         case "stats":
            {
               this.queue_stats = chart;
            }
            break;
         case "timeline":
            {
               this.queue_timeline = chart;
            }
            break;
         case "byDate": {
            this.queue_stats_date = chart;
         }
      }
   }

   ejecutarBusqueda(query: IQuery) {
      // $('#modalWait_kerberus').modal();
      if (!query.cola) {
         query.cola = this.$usuario.usuario.manager.map((c) => c.cola);
      }
      this.queryStats$.next(query);
      this.loadingStats.next({ loading: true });
      this.loadingTimeline.next({ loading: true });
      this.loadingAgentSumary.next({ loading: true });
   }

   /**
    * @deprecated
    * @param query
    */
   ejecutarQueueStats(query: IQuery): Observable<boolean> {
      return this.$http
         .post(this.$header.getAPIurl() + "/queue_stats", query, {
            headers: this.$header.getHeaders(),
         })
         .pipe(
            map((llamadas: IQueue_ststs) => {
               this.queue_stats.xAxis[0].setCategories(llamadas.queues);

               this.queue_stats.series[0].setData(llamadas.llamadasTotales);
               this.queue_stats.series[1].setData(llamadas.entrantes);
               this.queue_stats.series[2].setData(llamadas.entrantesAtendidas);
               this.queue_stats.series[3].setData(llamadas.salientes);
               this.queue_stats.series[4].setData(llamadas.agenda);
               this.queue_stats.series[5].setData(llamadas.callback);
               this.queue_stats.series[6].setData(llamadas.desistimiento);
               this.queue_stats.series[7].setData(llamadas.abandonosEntrantes);
               this.queue_stats.series[8].setData(llamadas.abandonosSalientes);
               this.queue_stats.series[9].setData(llamadas.timeouts);

               this.queue_stats.setSize(
                  900,
                  200 * llamadas.queues.length + 170,
                  true
               );
               this.queue_stats.redraw(true);
               return true;
            })
         );
   }
}

export interface IQuery {
   fecha?: {
      inicio: string;
      fin: string;
   };
   cola?: string | string[];
}

export interface IQueue_ststs {
   queues: string[];
   llamadasTotales: number[];
   entrantes: number[];
   entrantesAtendidas: number[];
   salientes: number[];
   agenda: number[];
   callback: number[];
   desistimiento: number[];
   abandonosEntrantes: number[];
   abandonosSalientes: number[];
   timeouts: number[];
}

export interface ISumaryStats {
   cola: string;
   atendidas: number[];
   abandonos: number[];
   desistimientos: number[];
}

export interface IQueue_timeline {
   queuename: string;
   fecha: string[];
   recibidas: number[];
   atendidas: number[];
}

export interface IQueue_Agents {
   queues: string;
   agentes: string[];
   atendidas: number[];
   xfers: number[];
   pausas: number[];
}
