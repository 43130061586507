    <div class="row">
        <form novalidate class="form-group form-group-sm" style="margin-bottom: 0" [formGroup]="didForm">
        <div class="col-sm-5">
            <div style="margin-bottom: 10px">
                <label>Número / DID</label>
                <input type="text" [readonly]="readOnly" class="form-control" formControlName="did" placeholder="s">              
            </div>
            <div style="margin-bottom: 10px">
                <label>Nombre / Descripción</label>
                <input type="text" class="form-control" formControlName="nombre" placeholder="DID Principal...">
            </div>
            <div style="margin-bottom: 10px">
                <label>Audio en Espera para DID</label>
                <div class="input-group">
                    <input type="text" class="form-control" aria-label="..." readonly>
                    <div class="input-group-btn">
                        <button class="btn btn-sm btn-default" [disabled]="true" (click)="seleccionarAdjunto('hold')"><i class="fa fa-folder-open"></i></button>
                        <button class="btn btn-sm btn-info" (click)="playAudio('/ivr/' + ivr.did + '_hold/hold.wav')"><i class="fa fa-play"></i></button>
                        <button class="btn btn-sm btn-info" (click)="stopAudio()"><i class="fa fa-stop"></i></button>
                        <button class="btn btn-sm btn-info" [disabled]="!readOnly" (click)="descargar()"><i class="fa fa-download"></i></button>
                    </div>
                </div>
                <div class="text-muted" *ngIf="!readOnly" style="font-size: 13px; padding-top: 10px;">* Podrás subir el audio una vez hallas guardado el DID.</div>
            </div>
            <div style="margin-bottom: 10px" class="bs-callout bs-callout-primary">
                <div class="row">
                    <div class="col-xs-8" style="padding-top: 8px"><h5><i class="fa fa-sun-o"></i> Configuración de Horarios</h5></div>
                    <div class="col-xs-4 text-right">
                        <button class="btn btn-sm btn-primary" title="Agregar un nuevo Horario" (click)="agregarHorario()"><i class="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 10px" formArrayName="horario">
                <div *ngFor="let horario of didForm.get('horario')['controls']; let i=index">
                    <div class="row" style="margin-bottom: 10px" [formGroupName]="i">
                    <div class="col-xs-7">
                        <input type="hidden" formControlName="did" [value]="ivr.did">
                        <div class="input-group input-group-sm">
                            <span class="input-group-btn">
                                <button class="btn btn-danger" type="button" (click)="eliminarHorario(i)"><i class="fa fa-close"></i></button>
                            </span>
                            <select class="form-control" formControlName="dia1">
                                <option value="{{d.day}}" *ngFor="let d of days">{{d.dia}}</option>
                            </select>
                            <span class="input-group-addon" id="sizing-addon1"> <i class="fa fa-calendar"></i> </span>
                            <select class="form-control" formControlName="dia2">
                                <option value="-{{d.day}}" *ngFor="let d of days">{{d.dia}}</option>
                                <option value="">-</option>
                            </select>
                        </div><!-- /input-group -->
                    </div>
                    <div class="col-xs-5">
                        <div class="input-group input-group-sm">
                            <span class="input-group-addon" id="sizing-addon1"> <i class="fa fa-clock-o"></i> </span>
                            <input type="text" formControlName="horario" class="form-control text-center" placeholder="08:00 - 18:00">
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 10px" class="text-right">
                <hr>
                <button type="button" class="btn btn-sm btn-default" [disabled]="!readOnly" (click)="eliminarDID(didForm.value)">Eliminar DID</button>&nbsp;
                <button type="button" class="btn btn-sm btn-success" [disabled]="didForm.invalid" (click)="guardarDID(didForm.value)">Guardar DID</button>
            </div>
        </div>
    </form>
    <div class="col-sm-7">
        <secuencia-tag 
            [secuencia]="ivr.secuencia" 
            [did]="ivr.did" 
            [readOnly]="readOnly"></secuencia-tag>
    </div>
</div>