<div class="row">
   <div class="col-md-5">
      <h4 style="color: rgb(71, 71, 71); margin-bottom: 3px;">Resultados de Tipificación.</h4>
      <small class="text-muted">Informes sobre formulario</small>
   </div>
   <div class="col-md-7">
      <div class="row">
         <form novalidate [formGroup]="formConsulta" autocomplete="off" (ngSubmit)="buscar(formConsulta.value)">
            <div class="col-md-offset-2 col-md-4">
               <div class="input-group" style="margin-bottom: 5px">
                  <div class="input-group-btn">
                     <button type="button" class="btn btn-success" style="min-width: 50px"><i
                           class="fa fa-calendar"></i> Desde</button>
                  </div>
                  <input type="text" ngDatePicker formControlName="inicio">
               </div>
            </div>
            <div class="col-md-4">
               <div class="input-group" style="margin-bottom: 5px">
                  <div class="input-group-btn">
                     <button type="button" class="btn btn-success" style="min-width: 50px"><i
                           class="fa fa-calendar"></i> Hasta</button>
                  </div>
                  <input type="text" ngDatePicker formControlName="fin">
               </div>
            </div>
            <div class="col-md-2" style="margin-bottom: 5px;">
               <button type="submit" [disabled]="formConsulta.invalid || inProgress" class="btn btn-primary btn-block">
                  <span *ngIf="!inProgress; else buscando">Consultar</span>
                  <ng-template #buscando>
                     <span><i class="fa fa-spinner fa-spin"></i></span>
                  </ng-template>
               </button>
            </div>
            <div class="col-md-offset-2 col-md-10"
               *ngIf="formConsulta.errors && (formConsulta.dirty || formConsulta.touched)">
               <small class="text-danger" *ngIf="formConsulta.errors.rango">* El rango de búsqueda debe ser menor a 1
                  mes.</small>
               <small class="text-danger" *ngIf="formConsulta.errors.mayor">* Fecha inicial no debe ser mayor a la
                  final.</small>
            </div>
         </form>
      </div>
   </div>
</div>
<hr>
<div *ngIf="resultados$ | async as resultados; else searching">
   <!-- Nav tabs -->
   <ul class="nav nav-pills" role="tablist">
      <li role="presentation" class="active">
         <a href="#results" aria-controls="results" role="tab" data-toggle="tab">
            <i class="fa fa-pie-chart"></i> Resultados</a>
      </li>
      <li role="presentation">
         <a href="#detailed" aria-controls="detailed" role="tab" data-toggle="tab">
            <i class="fa fa-list"></i> Resultados en detalle</a>
      </li>
   </ul>

   <!-- Tab panes -->
   <div class="tab-content">
      <div role="tabpanel" class="tab-pane fade in active" id="results">
         <app-graficos-tipificacion [resultados]="resultados"></app-graficos-tipificacion>
      </div>
      <div role="tabpanel" class="tab-pane fade" id="detailed">
         <app-tabla-tipificacion [resultados]="resultados"></app-tabla-tipificacion>
      </div>
   </div>
</div>
<ng-template #searching>
   <div class="text-center" style="margin: 150px 0;">
      <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
      Ejecutando consulta...
   </div>
</ng-template>