import { Pipe, PipeTransform } from '@angular/core';
import { IQueueStats } from 'src/app/_interfaces/monitor';

@Pipe({
    name: 'sl'
})
export class SLPipe implements PipeTransform {
    transform(stat: IQueueStats): any {
        if (stat.inbound !== 0) {
            return stat.SL / stat.inbound * 100;
        }
        return 0;
    }
}
