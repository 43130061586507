import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, map, switchMap, toArray } from "rxjs/operators";
import { Observable, of, Observer, from } from "rxjs";
import { ICampanaSMS, INumeroSMS, ISMSRuta } from "../_interfaces/sms";
import { HeaderService } from "./header.service";
import { Router } from "@angular/router";

@Injectable()
export class SMSService {
   constructor(
      private httpClient: HttpClient,
      private $header: HeaderService,
      private $router: Router
   ) {}

   getRutas(): Observable<ISMSRuta[]> {
      return this.httpClient.get<ISMSRuta[]>(
         this.$header.getAPIurl() + "/sms/rutas",
         { headers: this.$header.getHttpHeaders() }
      );
   }

   getCampanas(): Observable<ICampanaSMS[]> {
      return this.httpClient.get<ICampanaSMS[]>(
         this.$header.getAPIurl() + "/sms",
         { headers: this.$header.getHttpHeaders() }
      );
   }

   saveCampana(campana: ICampanaSMS): Observable<any> {
      return this.httpClient
         .post(
            this.$header.getAPIurl() + "/sms",
            this.sanitizeRequestParams(campana),
            {
               headers: this.$header.getHttpHeaders(),
            }
         )
         .pipe(this.validationMiddle$(false));
   }

   removeCampain(idx: string): Observable<any> {
      return this.httpClient.delete(this.$header.getAPIurl() + "/sms/" + idx, {
         headers: this.$header.getHttpHeaders(),
      });
   }

   getNumerosEnCampana(idx: number): Observable<INumeroSMS[]> {
      return of(idx).pipe(
         switchMap((id) =>
            this.httpClient.get<INumeroSMS[]>(
               this.$header.getAPIurl() + "/sms/numeros/" + id,
               { headers: this.$header.getHttpHeaders() }
            )
         )
      );
   }

   guardarNumeros(numeros: INumeroSMS[]): Observable<boolean> {
      return from(numeros).pipe(
         // determinar si hay valores null o undefined y eliminaros
         map((numero) => this.sanitizeRequestParams(numero)),
         toArray(),
         switchMap((nums) =>
            this.httpClient.post<boolean>(
               this.$header.getAPIurl() + "/sms/numeros",
               nums,
               { headers: this.$header.getHttpHeaders() }
            )
         )
      );
   }

   eliminarNumero(idNumero: number): Observable<boolean> {
      return this.httpClient.delete<boolean>(
         this.$header.getAPIurl() + "/sms/numeros/" + idNumero,
         { headers: this.$header.getHttpHeaders() }
      );
   }

   private sanitizeRequestParams(data: Object): Object {
      const keys = Object.keys(data);
      keys.forEach((k) => {
         if (!data[k]) {
            delete data[k];
         }
      });
      return data;
   }

   /**
    * Función operable para devolver al usuario a login
    * en caso de que falle su petición con respuesta 401: Unauthorized
    */
   private validationMiddle$ = (response: Object) => <T>(
      source: Observable<T>
   ) =>
      new Observable((o: Observer<any>) => {
         return source
            .pipe(
               catchError((err: HttpErrorResponse) => {
                  if (err.status === 401) {
                     this.$router.navigateByUrl("/login");
                  }
                  return of(response);
               })
            )
            .subscribe({
               next(x) {
                  o.next(x);
               },
               error(e) {
                  o.error(e);
               },
               complete() {
                  o.complete();
               },
            });
      });
}
