import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UsuarioService } from '../_services/usuario.service';
import { map, flatMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private $usr: UsuarioService, private $router: Router) {}
    canActivate(): Observable<boolean>  {
        return this.$usr.getSesionUsuario().pipe(
            flatMap(({ usuario }) => this.$usr.getUsuario(usuario)),
            map(usuario => !!usuario),
            catchError(err => {
                // usuario no autenticado.
                this.$router.navigateByUrl('/login');
                return of(err);
            })
        );
    }
  
}
