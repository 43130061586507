import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "nameTruncate",
})
export class NameTruncatePipe implements PipeTransform {
   transform(nombre: string, maxLenght = 18): unknown {
      if (!!nombre && nombre.length > maxLenght) {
         return `${nombre.substring(0, maxLenght)}...`;
      } else {
         return nombre;
      }
   }
}
