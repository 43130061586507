<ng-container *ngIf="errorLoading">
   <div class="alert alert-danger">
      <i class="fa fa-warning" style="margin-right: 10px;"></i>
      Se ha presentado un error en tu conexión, intenta de nuevo.</div>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
</ng-container>
<ng-container *ngIf="campanas$ | async as campanas; else loading">
    <div class="form-group">
        <label>Seleccione una campaña</label>
        <div class="input-group">
            <select class="form-control" [disabled]="!isAgentAvailable" #selCampana (change)="cargarReparto(selCampana.value, campanas)">
                <option value="">...</option>
                <option [value]="campana.idcampana" *ngFor="let campana of campanas">{{campana.nombre}}</option>
            </select>
            <span class="input-group-btn">
                <button class="btn btn-success" (click)="recargar()"><i class="fa fa-refresh"></i></button>
            </span>
        </div>
    </div>
    <div class="panel panel-default" *ngIf="repartos$ | async as repartos; else loadingReparto">
        <div class="panel-heading">
            <strong>Contactos disponibles : {{repartos.length}}</strong>
        </div>
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let reparto of repartos | paginate: config; let i = index">
                <app-discador 
                    [agente]="agente"
                    [reparto]="reparto" 
                    [callStatus]="callStatus"
                    [dialerSetup]="dialerSetup"
                    [disabled]="agente | agentAvailable"
                    (contacto)="cargarContacto($event)"
                    (actualizarReparto)="actualizarReparto($event, repartos, selCampana.value)"
                ></app-discador>
            </li>
        </ul>
        
        <div class="panel-footer text-right">
            <pagination-template #p="paginationApi"
            [id]="config.id"
            (pageChange)="config.currentPage = $event">

            <div class="btn-group">
                <button type="button" class="btn btn-default" 
                    [disabled]="p.isFirstPage()"
                    (click)="p.previous()">
                    <i class="fa fa-arrow-circle-o-left"></i>
                </button>

                <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                    [disabled]="p.getCurrent() === page.value"
                    [ngClass]="{'active': p.getCurrent() === page.value}"
                    (click)="p.setCurrent(page.value)">
                    {{ page.label }}
                </button>
                
                <button type="button" class="btn btn-default" 
                    [disabled]="p.isLastPage()"
                    (click)="p.next()">
                    <i class="fa fa-arrow-circle-o-right"></i>
                </button>
            </div>
        </pagination-template>
        </div>
    </div>
    <ng-template #loadingReparto>
        <div class="text-center text-muted" style="padding: 50px;">
            <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
            Cargando reparto...
        </div>
    </ng-template>
</ng-container>
<ng-template #loading>
   <div class="text-center" style="margin-top: 40px; margin-bottom: 37px;">
      <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
      Un momento por favor...
   </div>
</ng-template>