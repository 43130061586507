import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StartpipelineComponent } from "./startpipeline/startpipeline.component";
import { PipelineService } from "src/app/_services/pipeline.service";
import { LeadService } from "src/app/_services/lead.service";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
   providers: [PipelineService, LeadService],
   declarations: [StartpipelineComponent],
   exports: [StartpipelineComponent],
   imports: [CommonModule, ReactiveFormsModule],
})
export class AgentPipelinesModule {}
