import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IAuthKey, EApiKeyTipo } from '../_interfaces/apikey';
import { HeaderService } from './header.service';

@Injectable()
export class ApiManagerService {

    constructor(private httpClient: HttpClient, private $header: HeaderService) { }
    
    getApiKeys(): Observable<IAuthKey[]> {
        return this.httpClient.get<IAuthKey[]>(this.$header.getAPIurl() + '/apikey', { headers: this.$header.getHttpHeaders() });
    }

    crearApiKey(apikey: IAuthKey): Observable<boolean> {
        return of(apikey).pipe(
            flatMap(key => this.httpClient.post<boolean>(this.$header.getAPIurl() + '/apikey', key, { headers: this.$header.getHttpHeaders() })),
        );
    }

    eliminarApiKey(apikey: IAuthKey): Observable<boolean> {
        const restRoute = '/apikey' + (apikey.tipo === EApiKeyTipo.API_KEY ? '/api/' : '/manager/') + apikey.key;
        return of(restRoute).pipe(
            flatMap(ruta => this.httpClient.delete<boolean>( this.$header.getAPIurl() + ruta, { headers: this.$header.getHttpHeaders() } ))
        );
    }
}
