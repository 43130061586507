import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PauseReasonService } from "src/app/_services/pauseReasons.service";
import { PauseReasonsComponent } from "./pause-reasons/pause-reasons.component";
import { AgentDetailsComponent } from "./agent-details/agent-details.component";
import { AgenteEdicionComponent } from "./agente.component";
import { AgenteDetalleComponent } from "./agentedetalles.component";
import { UIModule } from "src/app/_extra/ui/ui.module";
import { PipesModule } from "src/app/pipes/pipes.module";

@NgModule({
   providers: [PauseReasonService],
   declarations: [
      PauseReasonsComponent,
      AgentDetailsComponent,
      AgenteEdicionComponent,
      AgenteDetalleComponent,
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      PipesModule,
      UIModule,
   ],
   exports: [AgenteEdicionComponent],
})
export class AgenteModule {}
