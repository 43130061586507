import { Pipe, PipeTransform } from "@angular/core";
import { IAgentStateMonitor } from "src/app/_interfaces/monitor";

@Pipe({
   name: "queueAgentStatus",
})
export class QueueAgentsStatusPipe implements PipeTransform {
   transform(
      agents: IAgentStateMonitor[],
      inverse: boolean,
      ...status: string[]
   ): number {
      return agents.filter((a) => {
         const isIncluded = status.includes(a.status);
         return !inverse ? isIncluded : !isIncluded;
      }).length;
   }
}
