<form style="margin-bottom: 0" class="form-group form-group-sm" novalidate #f="ngForm">
    <div class="row" style="font-size: 13px" *ngIf="peer && readOnly !== undefined">
        <div class="col-sm-8" style="margin-bottom: 10px">
            <div class="row">
                <div class="col-md-6">
                    <label>Protocolo *</label>
                    <input *ngIf="readOnly" type="text" [readonly]="readOnly" class="form-control" name="protocolo" [(ngModel)]="peer.protocolo" required>
                    <select *ngIf="!readOnly" class="form-control" [(ngModel)]="peer.protocolo" [disabled]="readOnly" name="protocolo" required>
                        <option value="">Selecciona uno</option>
                        <option value="SIP">SIP</option>
                        <option value="IAX2">IAX2</option>
                        <option value="DAHDI">Análogo</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <div style="margin-bottom: 10px">
                        <label>Usuario / Extensión *</label>
                        <input type="text" [readonly]="readOnly" class="form-control" name="usuario" [(ngModel)]="peer.usuario" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div style="margin-bottom: 10px">
                        <label>Contraseña *</label>
                        <div class="input-group">
                            <input type="password" class="form-control" *ngIf="!secretView" name="secret" [(ngModel)]="peer.secret" required>
                            <input type="text" class="form-control" *ngIf="secretView" name="secret" [ngModel]="peer.secret">
                            <span class="input-group-btn">
                                <button class="btn btn-sm btn-default" type="button" (mousedown)="secretView=true" (mouseup)="secretView=false"><span class="glyphicon glyphicon-eye-open"></span></button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="margin-bottom: 10px">
                        <label>Identificador / Caller ID</label>
                        <input type="text" class="form-control" name="callerid" [(ngModel)]="peer.callerid">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div style="margin-bottom: 10px">
                        <label>Email</label>
                        <input type="email" class="form-control" name="email" [(ngModel)]="peer.email" placeholder="tuemail@dominio.com">
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="margin-bottom: 10px">
                        <label>Llamadas simultáneas</label>
                        <select class="form-control" [(ngModel)]="peer.call_limit" name="call_limit" required>
                            <option value="{{i}}" *ngFor="let i of [1,2,3,4,5,6,7,8,9]">{{i}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div style="margin-bottom: 10px">
                        <label>Contraseña para autenticar llamadas</label>
                        <div class="input-group">
                            <input *ngIf="!personalView" type="password" class="form-control" name="personal" [(ngModel)]="peer.personal">
                            <input *ngIf="personalView" type="text" class="form-control" name="personal" [ngModel]="peer.personal">
                            <span class="input-group-btn">
                                <button class="btn btn-sm btn-default" type="button" (mousedown)="personalView=true" (mouseup)="personalView=false"><span class="glyphicon glyphicon-eye-open"></span></button>
                            </span>
                        </div><!-- /input-group -->
                    </div>
                    <div class="row" style="margin-bottom: 10px">
                        <div class="col-xs-6">
                            <label>Transport</label>
                            <select class="form-control" [(ngModel)]="peer.transport" name="transport" required>
                                <option value="UDP">UDP</option>
                                <option value="TLS">TLS</option>
                                <option value="WSS">Web</option>
                            </select>
                        </div>
                        <div class="col-xs-6">
                            <label>DTMF</label>
                            <select class="form-control" [(ngModel)]="peer.dtmf" name="dtmf" required>
                                <option value="rfc2833">RFC2833</option>
                                <option value="inband">Inband</option>
                                <option value="info">Info</option>
                            </select>
                        </div>
                    </div>
                    <div style="margin-bottom: 10px">
                        <label>NAT</label>
                        <select class="form-control" [(ngModel)]="peer.nat" name="nat" required>
                            <option value="auto_comedia">Auto Comedia</option>
                            <option value="comedia">Comedia</option>
                            <option value="auto_force_rport">Auto Force RPORT</option>
                            <option value="force_rport">Force RPORT</option>
                            <option value="no">No</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div style="margin-bottom: 10px" class="row">
                        <div class="col-xs-9"><label>Aceptar Llamadas Externas</label></div>
                        <div class="col-xs-3 text-right"><ui-toggleV1 name="acceptExternal" [(ngModel)]="peer.acceptExternal"></ui-toggleV1></div>
                    </div>
                    <div style="margin-bottom: 10px" class="row">
                        <div class="col-xs-8"><label>Grabar Llamadas</label></div>
                        <div class="col-xs-4 text-right"><ui-toggleV1 name="record_call" [(ngModel)]="peer.record_call"></ui-toggleV1></div>
                    </div>
                    <div style="margin-bottom: 10px" class="row">
                        <div class="col-xs-8"><label>Encripción de llamadas</label></div>
                        <div class="col-xs-4 text-right"><ui-toggleV1 name="encryption" [(ngModel)]="peer.encryption"></ui-toggleV1></div>
                    </div>
                    <div style="margin-bottom: 10px" class="row">
                        <div class="col-xs-8"><label>Correo de Voz</label></div>
                        <div class="col-xs-4 text-right"><ui-toggleV1 name="voice" [(ngModel)]="peer.voice"></ui-toggleV1></div>
                    </div>
                    <div style="margin-bottom: 10px" class="row">
                        <div class="col-xs-8"><label>Habilitar Spy</label></div>
                        <div class="col-xs-4 text-right"><ui-toggleV1 name="spy" [(ngModel)]="peer.spy"></ui-toggleV1></div>
                    </div>
                </div> 
            </div>
        </div>
        
        <div class="col-sm-4" style="margin-bottom: 10px">
             
            <div style="margin-bottom: 10px">
                 <input type="hidden" name="grupos" [ngModel]="peer.grupos">
                 <input type="hidden" name="perfil" [ngModel]="peer.perfil">
                 <div class="bs-callout bs-callout-primary">
                     <h5>Grupos para la Extensión.</h5>
                     Captura de llamadas usando <strong>8 +SEND.</strong>
                     
                     <div class="row" style="margin-top: 10px">
                        <div class="col-xs-12 text-right" style="font-size: 12px; margin-bottom: 10px">
                            <div class="input-group input-group-sm">
                                <span class="input-group-addon">Agregar nuevo grupo</span>
                                <select class="form-control" #grupo_sel name="grupo_sel" >
                                   <option value="{{grupoIndex}}" *ngFor="let grupoIndex of [1,2,3,4,5,6,7,8,9]">{{grupoIndex}}</option>
                                </select>
                                <span class="input-group-btn">
                                    <button class="btn btn-sm btn-default" (click)="addGrupo(grupo_sel.value)"><i class="fa fa-plus"></i></button>
                                </span>
                            </div>                            
                        </div>                          
                         <div class="col-xs-12">
                            <div class="col-xs-3" style="padding: 5px" *ngFor="let g of peer.grupos">
                                <div class="btn-group btn-group-justified" role="group" aria-label="...">
                                    <div class="btn-group" role="group">
                                   <button type="button" class="btn btn-xs">{{g.grupo}}</button>
                                    </div>
                                    <div class="btn-group" role="group">
                                   <button type="button" class="btn btn-xs" (click)="eliminarGrupo(g.grupo)"><i class="fa fa-close"></i></button>
                                    </div>
                               </div>
                            </div>
                         </div>
                     </div>
                 </div>
            </div>             
             <div style="margin-bottom: 10px">
                 <div class="input-group" style="margin-bottom: 10px">
                    <div class="bs-callout bs-callout-success">
                        <h5>Perfiles de Extensión</h5>
                        Permisos de llamada con limite de tiempo.
                    </div>

                    <div class="input-group input-group-sm">
                        <select class="form-control" name="_contexto" #selContexto style="min-width: 110px">
                            <option value="local">Local</option>
                            <option value="nacional">Nacional</option>
                            <option value="celular">Celular</option>
                            <option value="internacional">Internacional</option>
                        </select>
                        <span class="input-group-addon">Tiempo</span>
                        <input type="number" name="_duracion" class="form-control" #selDuracion min="60" placeholder="60..">
                        <span class="input-group-btn">
                            <button class="btn btn-default" type="button" (click)="addPerfil(selContexto.value, selDuracion.value)"><i class="fa fa-plus"></i></button>
                        </span>
                    </div><!-- /input-group -->
                    <hr style="margin-top: 5px; margin-bottom: 0">
                </div> 
                 <div class="row" style="margin: 0" *ngFor="let p of peer.perfil">
                    <div class="col-xs-11" style="font-size: 12px; padding-right: 0">
                        <i class="fa fa-check-square-o"></i> Perfil: <span class="text-capitalize">{{p.contexto}}</span> - 
                        Max: <strong>{{(p.limit/1000) | toTime | date:'HH:mm:ss'}}</strong>
                    </div>
                      <div class="col-xs-1 text-right" style="padding-left: 0">
                        <a href="javascript:void(0)" (click)="removerPerfil(p.contexto)"><i class="fa fa-trash-o"></i></a>
                    </div>
                      <div class="col-xs-12">
                          <hr style="margin-top: 3px; margin-bottom: 3px">
                     </div>
                </div>
            </div>
             <div style="margin-bottom: 10px">
                <div class="bs-callout bs-callout-warning">
                     <h5>Configuración de Desvíos</h5>
                     Desvios <strong>persistentes</strong> para extensiones.
                 </div>
                <div class="row" style="font-size: 11px">
                    <div class="col-xs-4 text-center" style="padding-right: 2px">
                        <label>Incondicional</label>                    
                        <input type="text" class="form-control text-center" name="fwd" [(ngModel)]="peer.fwd">
                    </div>
                    <div class="col-xs-4 text-center" style="padding-left: 5px; padding-right: 5px">
                        <label>Ocupado</label>
                        <input type="text" class="form-control text-center" name="busyfwd" [(ngModel)]="peer.busyfwd">
                    </div>
                    <div class="col-xs-4 text-center" style="padding-left: 2px">
                        <label>No Contesta</label>
                        <input type="text" class="form-control text-center" name="delayedfwd" [(ngModel)]="peer.delayedfwd">
                    </div>
                </div>
            </div>
        </div>
         <div class="col-xs-12">
             <hr style="margin-bottom: 15px; margin-top: 0">
        </div>
        <div class="col-xs-5">
            <span class="text-muted">Extensión conectada desde: {{peerMonitor?.host}}:{{peerMonitor?.puerto}}</span>
        </div>
        <div class="col-xs-7 text-right">
            <button type="button" [disabled]="!readOnly" class="btn btn-sm btn-danger" (click)="eliminarPeer(f.value)"><i class="fa fa-trash-o" ></i></button> &nbsp;
            <button type="button" class="btn btn-sm btn-primary" [disabled]="f.invalid" (click)="guardarPeer(f.value)">Guardar Extension</button>
        </div>
    </div>
</form>
