import { Pipe, PipeTransform } from '@angular/core';
import { IAgentStateMonitor } from 'src/app/_interfaces/monitor';

@Pipe({
  name: 'totalAttendedCalculator'
})
export class TotalAttendedCalculatorPipe implements PipeTransform {

   transform(agentStates: IAgentStateMonitor[]): Date {
      const refDate = new Date().setHours(0, 0, 0, 0);
      const totalCalls = agentStates.reduce((acc, s) => 
         acc + Number.parseInt(`${s.inbound}`) + Number.parseInt(`${s.outbound}`) + Number.parseInt(`${s.dialer}`), 0
      )
      if (totalCalls > 0) {
         const tmo_time = agentStates.reduce((acc, s) => 
            acc + Number.parseInt(`${s.tmo_inbound}`) + Number.parseInt(`${s.tmo_outbound}`) + Number.parseInt(`${s.tmo_dialer}`), 0
         )
         return new Date(( tmo_time / totalCalls ) * 1000 + refDate);
      }

      return new Date(refDate)
   }

}
