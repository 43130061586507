import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { ObjectID } from "src/app/_services/_objectID";
import { DialerService } from "src/app/_services/dialer.service";
import { SocketService } from "src/app/_services/socket.service";
import { Observable, of, throwError } from "rxjs";
import {
   ICampanaStats,
   EEstadoCampanaDialer,
} from "src/app/_interfaces/dialer";
import {
   flatMap,
   toArray,
   map,
   defaultIfEmpty,
   filter,
   retryWhen,
   delay,
   mergeMap,
} from "rxjs/operators";
import * as _ from "lodash";
@Component({
   selector: "dialer",
   templateUrl: "dialer.component.html",
})
export class DialerComponent implements OnInit, OnDestroy {
   campanas$: Observable<ICampanaStats[]>;

   constructor(
      private $router: Router,
      private $dialer: DialerService,
      private $monitor: SocketService
   ) {}

   ngOnInit() {
      this.$monitor.setRooms(["dialer"]);
      this.campanas$ = this.$dialer.getCampanas$().pipe(
         // Iniciar con stats en 0,
         map((campanas) => campanas.map((c) => this.$dialer.initStats(c))),
         // Combinar con stats del stream
         mergeMap((initCampainStats) =>
            this.$monitor.getDialerStats$().pipe(
               filter((stats) => stats.length > 0),
               flatMap((stats) => stats),
               map((stat) => {
                  const campain = initCampainStats.find(
                     (c) => c.idcampana === stat.idcampana
                  );
                  if (!!campain) {
                     // Combinar con stats recibidos
                     return { ...campain, ...stat };
                  } else {
                     // reiniciar este observable.
                     throw { id: stat.idcampana };
                  }
               }),
               toArray(),
               // combinar con los nuevos stats con initCampainStats.
               map((neoStats) => {
                  const mapedNeoStats = neoStats.map((s) => s.idcampana);
                  // Ubicar aquellas que no han recibido datos del stream
                  const sinDatos = initCampainStats.filter(
                     (c) => !mapedNeoStats.includes(c.idcampana)
                  );
                  return [...sinDatos, ...neoStats];
               }),
               defaultIfEmpty(initCampainStats),
               map((stats) => _.orderBy(stats, "nombre"))
            )
         ),
         // En caso de error por id inexisten, reiniciar. sino, dar un delay
         retryWhen((err) =>
            err.pipe(
               mergeMap((err) => (!!err.id ? of({}) : of({}).pipe(delay(2000))))
            )
         )
      );
   }

   ngOnDestroy() {
      this.$monitor.exitRooms(["dialer"]);
   }

   editarCampana(idCampana?: string) {
      this.$router.navigateByUrl(
         `dashboard/(view:dialer/setup/${idCampana || ObjectID()})`
      );
   }

   visualizarCampana(idCampana: string) {
      this.$router.navigateByUrl(
         `dashboard/(view:dialer/campain/${idCampana})`
      );
   }

   cambiarEstado(idCampana: string, estado: EEstadoCampanaDialer) {}
}
