import { Pipe, PipeTransform } from "@angular/core";
import { ECallbackRequestResult } from "src/app/_services/queue-callback.service";

@Pipe({
   name: "queueCallbackStats",
})
export class QueueCallbackStatsPipe implements PipeTransform {
   transform(
      resultados: { resultado: ECallbackRequestResult }[],
      state: ECallbackRequestResult
   ): {}[] {
      return resultados.filter((r) => r.resultado === state);
   }
}
