<ng-container *ngIf="!!reparto && reparto.length > 0; else start">
    <div class="panel panel-default">
    
        <div class="navbar" style="margin-bottom: 0; border: 0; border-bottom-left-radius: 0; border-bottom-right-radius: 0; border-bottom: 1px solid rgb(206, 206, 206);">
            <div class="container-fluid">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                      <span class="sr-only">Toggle navigation</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                </div>
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" style="padding-left: 0;">
                    <ul class="nav navbar-nav">
                        <a href="javascript:void(0)" class="btn btn-default navbar-btn" 
                        (click)="eliminarContacto()"><i class="fa fa-trash"></i></a>
                    </ul>
                    <ul class="nav navbar-nav navbar-right">
                        <li class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Opciones <span class="caret"></span></a>
                            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                            
                                <li class="dropdown-header">Opciones de asignación</li>
                                <li *ngIf="isProgressive">
                                    <a href="javascript:void(0)" (click)="crearRepartoAutomatico()" class="text-muted">
                                        <i class="fa fa-random text-muted"></i> Ejecutar asignación automática.
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" (click)="eliminarContacto()" class="text-muted">
                                        <i class="fa fa-trash-o text-muted"></i> Eliminar selección.
                                    </a>
                                </li>
                                <ng-container *ngIf="isProgressive">
                                    <li role="separator" class="divider"></li>
                                    <form novalidate autocomplete="off" #f="ngForm">
                                    <li class="dropdown-header">Asignar selección a</li>
                                    <li>
                                        <div class="form-group" style="margin-right: 10px; margin-left: 20px;">
                                            <select class="form-control" name="agenteSel" #agenteSeleccion (change)="asignarSeleccion(agenteSeleccion.value); f.reset()">
                                                <option>...</option>
                                                <option [value]="agente.idagente" *ngFor="let agente of agentes">Agente {{agente.idagente}}</option>
                                            </select>
                                        </div>
                                    </li>
                                    </form>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="text-center" colspan="2">
                            <input type="checkbox" name="seleccionTodos" #seleccionTodos (change)="seleccionaTodos(seleccionTodos.checked)"></th>
                        <th class="text-center">Agente</th>
                        <th *ngFor="let campo of contactos.campos">{{campo.nombre}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let contacto of contactos.contactos | paginate: config; let i = index">
                        <ng-container *ngIf="contacto._id | inCampain : reparto as dialerContacto">
                            
                            <td class="text-center">
                                <input type="checkbox" #seleccionContacto name="seleccionContacto" 
                                [checked]="contacto | checkBox : contactosSeleccionados"
                                (change)="seleccionarContactos(contacto._id, seleccionContacto.checked)">
                            </td>
                        
                            <td class="text-center">        
                                <i class="fa fa-check-circle-o text-success" *ngIf="!!dialerContacto.id"></i>
                                <i class="fa fa-close text-muted" *ngIf="!dialerContacto.id"></i>
                            </td>
                        
                            <td class="text-center" *ngIf="isProgressive">
                                {{dialerContacto?.agenteid || ''}}
                            </td>
                            <td class="text-center" *ngIf="!isProgressive">
                                {{'N/A'}}
                            </td>
                        </ng-container>
                        <td *ngFor="let campo of contactos.campos">{{contacto[campo.nombre] || ''}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="panel-body">
            <div class="row">
                <div class="col-md-6 small text-muted" style="font-weight: 500;">
                    Total contactos programados: {{reparto.length}}<br>
                    Total contactos disponibles: {{contactos.contactos.length}}</div>
                <div class="col-md-6 text-right">
                <pagination-template #p="paginationApi"
                    [id]="config.id"
                    (pageChange)="config.currentPage = $event">
        
                    <div class="btn-group">
                        <button type="button" class="btn btn-default" 
                            [disabled]="p.isFirstPage()"
                            (click)="p.previous()">
                            <i class="fa fa-arrow-circle-o-left"></i>
                        </button>
        
                        <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                            [disabled]="p.getCurrent() === page.value"
                            [ngClass]="{'active': p.getCurrent() === page.value}"
                            (click)="p.setCurrent(page.value)">
                            {{ page.label }}
                        </button>
                        
                        <button type="button" class="btn btn-default" 
                            [disabled]="p.isLastPage()"
                            (click)="p.next()">
                            <i class="fa fa-arrow-circle-o-right"></i>
                        </button>
                    </div>
                </pagination-template>
                </div>
                <div class="col-md-12 text-left">
                    <hr>
                    <h5 >Distribución de contactos en agentes.</h5>
                    <div style="margin-top: 10px;">
                        <div style="border-radius: 5px; background-color: #e9eff3; margin: 5px; padding: 10px;" class="col-md-2" *ngFor="let agente of agentes">
                            <i class="fa fa-user-circle-o"></i> Agente {{agente.idagente}} : <span class="badge">{{agente.idagente | porAgente: reparto}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #start>
    <div style="padding: 100px 0;" class="text-center">
        <h5>A continuación se crearán los contactos para la campaña</h5>
        <small class="text-muted">Podrá editar la asignación de llamadas de acuerdo al tipo de campaña que haya seleccionado.</small>
        <div style="margin-top: 15px;">
            <button class="btn btn-info" [disabled]="isProgressive || inProgress" (click)="crearNumeracionAutmatica()">
                Crear numeración automática</button>&nbsp;&nbsp;
            <button class="btn btn-info" [disabled]="!isProgressive || inProgress" (click)="crearRepartoAutomatico()">
                Crear reparto automático</button>
            <div class="form-group text-primary" *ngIf="inProgress" style="margin-top: 15px;">
                <i class="fa fa-2x fa-spinner fa-spinner" style="margin-bottom: 10px;"></i><br>
                Creando...
            </div>
        </div>
    </div>
</ng-template>
