<form [formGroup]="query">
   <div class="row">
      <div class="col-md-7">
         <span style="font-size: 18px;">Leads <span class="label label-primary">
               {{(totales$ | async) || '0'}}
            </span></span><br>
         <small>Fecha de consulta: {{fecha}}</small>
      </div>
      <div class="col-md-5">
         <div class="input-group">
            <div class="input-group-btn">
               <button type="button" class="btn btn-success" style="min-width: 100px">Fecha</button>
            </div>
            <input type="text" ngDatePicker formControlName="start" required>
            <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
            <input type="text" ngDatePicker formControlName="end" required>
            <div class="input-group-btn">
               <button type="button" class="btn btn-info" style="min-width: 100px"
                  (click)="ejecutarBusqueda(query.value)">
                  <i class="fa fa-refresh"></i>
               </button>
            </div>
         </div>
      </div>
   </div>
</form>
<div class="row" style="margin-top: 15px;">
   <div class="col-sm-3">
      <div class="panel panel-warning">
         <div class="panel-heading"><strong>SIN ASIGNACIÓN</strong>&nbsp;
            <i class="fa fa-info-circle" style="color: rgb(24, 97, 131);; cursor: pointer;"
               title="Nuevos leads en curso."></i><br>
         </div>
         <div class="panel-body text-center" style="font-size: 24px;">
            {{(sinAsignacion$ | async) || '0'}}
         </div>
      </div>
   </div>
   <div class="col-sm-3">
      <div class="panel panel-success">
         <div class="panel-heading"><strong>ASIGNADOS</strong>&nbsp;
            <i class="fa fa-info-circle" style="color: rgb(24, 97, 131);; cursor: pointer;"
               title="Leads asignados exitosamente a agentes."></i><br>
         </div>
         <div class="panel-body text-center" style="font-size: 24px;">
            {{(asigando$ | async) || '0'}}
         </div>
      </div>
   </div>
   <div class="col-sm-3">
      <div class="panel panel-danger">
         <div class="panel-heading"><strong>ABANDONOS</strong>&nbsp;
            <i class="fa fa-info-circle" style="color: rgb(24, 97, 131); cursor: pointer;"
               title="Leads no atendidos."></i><br>
         </div>
         <div class="panel-body text-center" style="font-size: 24px;">
            {{(abandonado$ | async) || '0'}}
         </div>
      </div>
   </div>
   <div class="col-sm-3">
      <div class="panel panel-primary">
         <div class="panel-heading"><strong>EN PIPELINE</strong>&nbsp;
            <i class="fa fa-info-circle" style="color: rgb(24, 97, 131); cursor: pointer;"
               title="Leads no atendidos."></i><br>
         </div>
         <div class="panel-body text-center" style="font-size: 24px;">
            {{(enPipe$ | async) || '0'}}
         </div>
      </div>
   </div>
</div>
<div class="table-responsive">
   <table class="table table-striped"
      style="margin-bottom: 0; font-size: 12px; border: 1px solid #e2e2e2; min-width: 1100px">
      <thead style="background-color: #0d1e40; color: white">
         <tr>
            <td class="text-center">Fecha de creación</td>
            <td>Lead ID</td>
            <td>Fuente</td>
            <td>ID Fuente</td>
            <td>Estado</td>
            <td style="width: 150px" class="text-center">Agente asignado</td>
            <td style="width: 150px;" class="text-center">En pipeline</td>
            <td style="width: 20px;"></td>
         </tr>
      </thead>
      <tbody *ngIf="queryResults$ | async as resultados; else quering">
         <ng-container *ngIf="resultados.length > 0; else emptyLeads">
            <tr *ngFor="let lead of resultados | paginate: config;">
               <td class="text-center">{{lead.fecha | date : "YYYY-MM-dd HH:mm:ss"}}</td>
               <td>{{lead.idlead}}</td>
               <td>{{lead.fuente}}</td>
               <td>
                  <div style="word-wrap: break-word; white-space: pre-wrap; max-width: 280px;">{{lead.fuente_dst}}</div>
               </td>
               <td>{{lead.estado}}</td>
               <td class="text-center">{{lead.agenteAsignado || '-'}}</td>
               <td class="text-center">
                  <ng-container *ngIf="lead.fasePipeline!!; else unAssigned">
                     <i class="fa fa-check text-success" aria-hidden="true"></i>
                  </ng-container>
                  <ng-template #unAssigned>
                     <a href="javascript:void(0)" (click)="iniciarEnPipeline(lead)">
                        <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                     </a>
                  </ng-template>
               </td>
               <td></td>
            </tr>
            <tr>
               <td colspan="7" class="text-right">
                  <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">

                     <div class="btn-group">
                        <button type="button" class="btn btn-default" [disabled]="p.isFirstPage()"
                           (click)="p.previous()">
                           <i class="fa fa-arrow-circle-o-left"></i>
                        </button>

                        <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                           [disabled]="p.getCurrent() === page.value"
                           [ngClass]="{'active': p.getCurrent() === page.value}" (click)="p.setCurrent(page.value)">
                           {{ page.label }}
                        </button>

                        <button type="button" class="btn btn-default" [disabled]="p.isLastPage()" (click)="p.next()">
                           <i class="fa fa-arrow-circle-o-right"></i>
                        </button>
                     </div>
                     &nbsp;<button type="button" class="btn btn-warning" (click)="descargar(resultados)">Descargar a
                        *.csv</button>
                  </pagination-template>
               </td>
            </tr>
         </ng-container>
         <ng-template #emptyLeads>
            <tr>
               <td colspan="8" style="padding: 20px;">
                  No hay resultados.
               </td>
            </tr>
         </ng-template>
      </tbody>
      <ng-template #quering>
         <tbody>
            <tr>
               <td colspan="8" class="text-center text-muted" style="padding-top: 40px; padding-bottom: 40px;">
                  <i class="fa fa-spinner fa-2x fa-spin text-primary" style="margin-bottom: 15px;"></i><br>
                  Un momento...
               </td>
            </tr>
         </tbody>
      </ng-template>
   </table>
</div>

<!--Iniciar el pipeline-->
<div class="modal fade" id="iniciarEnPipe" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
   <div class="modal-dialog" role="document">
      <div class="modal-content" *ngIf="leadAIniciar!!; else awaitLoad">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel"><i class="fa fa-magic" aria-hidden="true"></i> Iniciar lead en
               Pipeline</h4>
         </div>
         <div class="modal-body" style="font-size: 13px;">
            Fecha de creación: <strong>{{leadAIniciar.fecha | amDateFormat: 'dddd D [de] MMMM, h:mm a'}}</strong>
            <form autocomplete="off" novalidate #frmInicioPipe="ngForm">
               <div class="row" style="margin-top: 10px;">
                  <div class="col-sm-6">
                     <label>
                        Propietario:
                     </label>
                     <select class="form-control" style="margin-bottom: 5px; margin-bottom: 7px;"
                        [value]="leadAIniciar.agenteAsignado" ngModel name="agenteAsignado" required>
                        <option *ngFor="let agente of (agentes$ | async)" [value]="agente.idagente">
                           {{agente.idagente}} - {{agente.nombre}}
                        </option>
                     </select>
                  </div>
                  <div class="col-sm-6">
                     <label>Fase en pipeline</label>
                     <select class="form-control" style="margin-bottom: 5px;" ngModel name="fasePipeline" required>
                        <option *ngFor="let fase of (fases$ | async)" [value]="fase.id">{{fase.avance > 0 ? fase.avance
                           : 'X'}} : {{fase.nombre}}</option>
                     </select>
                  </div>
               </div>
            </form>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-success" (click)="actualizarLead(leadAIniciar, frmInicioPipe.value)"
               [disabled]="frmInicioPipe.invalid"><i class="fa fa-magic" aria-hidden="true"></i>
               Iniciar</button>
         </div>
      </div>
      <ng-template #awaitLoad>
         <div class="modal-content">
            <div class="modal-body text-center" style="padding-top: 50px; margin-bottom: 40px;">
               <i class="fa fa-spinner text-primary fa-spin fa-3x" aria-hidden="true"></i>
            </div>
         </div>
      </ng-template>
   </div>
</div>