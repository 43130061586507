import { NgModule } from "@angular/core";

import { TimePipe } from "./pipes";
import { UploadProgessPipe } from "./uploadprogess/uploadprogress.pipe";
import { NameTruncatePipe } from "./name-truncate.pipe";

@NgModule({
   imports: [],
   declarations: [TimePipe, UploadProgessPipe, NameTruncatePipe],
   exports: [TimePipe, UploadProgessPipe, NameTruncatePipe],
   providers: [],
})
export class PipesModule {}
