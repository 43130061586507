<div class="row">
    <div class="col-sm-12">
        <div class="bs-callout bs-callout-primary">
            <h5 style="font-weight: 600" class="text-primary">Informe Online de Atención</h5>
            Aquí se listan todas las colas de callcenter creadas y operando.
        </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let q of queueStats$ | async">
        <div class="panel panel-primary">            
            <div class="panel-body" style="padding: 0">
                <table class="table" style="width: 100%; margin-bottom: 0">
                    <tbody>
                    <tr>
                        <td colspan="2" style="background-color: #2195c9; color: white; border: 0">
                            <button type="button" class="close"><span aria-hidden="true" style="color: white">&times;</span></button>
                            <a href="javascript:void(0)" style="color: white" (click)="loadQueue(q.queue)">
                                <i class="fa fa-dashboard"></i> {{q.queue}}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="3" style="width: 55%; background-color: #27A9E3; color: white; border: 0">
                            <span style="font-size: 30px; font-weight: 600">{{q | sl | number: '0.0-1'}} %</span>
                            SL
                        </td>
                        <td>
                            <div class="row" style="font-size: 13px">
                                <div class="col-xs-7">
                                Atendidas 
                                </div>
                                <div class="col-xs-5 text-right">
                                    <span class="badge">{{q.inbound}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="row" style="font-size: 13px">
                                <div class="col-xs-7">
                                Abandonos 
                                </div>
                                <div class="col-xs-5 text-right">
                                    <span class="badge">{{q.abandon}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="row" style="font-size: 13px">
                                <div class="col-xs-7">
                                Salientes 
                                </div>
                                <div class="col-xs-5 text-right">
                                    <span class="badge">{{q.outbound}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="background-color: #2195c9; color: white; border: 0">
                            <span>{{q | ic | number: '0.0-1'}} %</span>
                            IC
                        </td>
                        <td>
                            <div class="row" style="font-size: 13px">
                                <div class="col-xs-6">
                                TPC (E) 
                                </div>
                                <div class="col-xs-6 text-right">
                                    <span class="badge">{{q | inProm | toTime | date:'mm:ss'}}</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngIf="hasPermission">
        <div class="panel panel-default" style="min-height: 181px; background-color: #e6e6e6;">
            <div class="panel-body text-center" style="padding: 0; margin-top: 50px">
                <a href="javascript:void(0)" (click)="crearCola()" class="text-white">
                    <i class="fa fa-3x fa-plus-circle" style="padding-bottom: 10px;"></i><br>
                    Nueva campaña de callcenter.
                </a>
            </div>
        </div>
    </div>
</div>