import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { Observable, Subscription, of, throwError } from "rxjs";
import {
   catchError,
   map,
   mergeMap,
   share,
   switchMap,
   tap,
} from "rxjs/operators";
import { ILead } from "src/app/_interfaces/lead.entity";
import { LeadService } from "src/app/_services/lead.service";
import { PipelineService } from "src/app/_services/pipeline.service";
import * as dayjs from "dayjs";
import { IPipeLine } from "src/app/_interfaces/pipeline.entity";
import { AgenteService } from "src/app/_services/agente.service";

@Component({
   selector: "app-pipelines",
   templateUrl: "./pipelines.component.html",
   styleUrls: ["./pipelines.component.css"],
})
export class PipelinesComponent implements OnInit, OnDestroy, AfterViewInit {
   showAgent = false;
   fechaConsulta: string;
   sub = new Subscription();

   queryForm: FormGroup;
   gdListener$: Observable<any>;
   leadsInPipes$: Observable<ILead[]>;

   pipeLinePhases$ = this.$pipe.getPipelines().pipe(
      map((phases) => {
         return [
            ...phases.filter((p) => p.avance >= 0),
            ...phases.filter((p) => p.avance < 0),
         ];
      }),
      share()
   );

   constructor(
      private $router: Router,
      private $fb: FormBuilder,
      private $dg: DragulaService,
      private $pipe: PipelineService,
      private $lead: LeadService
   ) {}

   ngOnInit(): void {
      this.queryForm = this.$fb.group({
         start: ["", Validators.required],
         idAuthor: "",
      });

      this.fechaConsulta = dayjs().add(-1, "year").format("YYYY-MM-DD");
      this.ejecutarBusqueda({ start: this.fechaConsulta });

      this.sub.add(
         this.$dg
            .dropModel("ITEMS")
            .pipe(
               map((data) => {
                  const destino = data.target.id;
                  const fuente = data.source.id;
                  if (destino === fuente) {
                     throwError("Same source and destination");
                  }
                  return {
                     fuente: fuente,
                     item: { ...data.item, fasePipeline: destino } as ILead,
                  };
               }),
               switchMap((data) => this.$lead.updateLead(data.item)),
               catchError((err) => {
                  return of(err);
               })
            )
            .subscribe((data) => console.log(data))
      );
   }

   ngOnDestroy(): void {
      this.sub.unsubscribe();
   }

   ngAfterViewInit(): void {
      setTimeout(() => {
         this.queryForm.patchValue({
            start: this.fechaConsulta,
         });
      }, 200);
   }

   ejecutarBusqueda(data: { idAuthor?: string; start: string }) {
      this.leadsInPipes$ = this.$lead
         .getLeadsRangeAndAuthor(data)
         .pipe(share());
   }

   editar() {
      this.$router.navigateByUrl("dashboard/(view:pipeline/edit)");
   }

   detalles(lead: ILead, fase: IPipeLine) {
      this.$router.navigateByUrl(
         `dashboard/(view:pipeline/lead/${lead.idlead})`
      );
   }

   cargarAgentes() {
      this.showAgent = true;
   }

   finalizarEditarAgente() {
      this.showAgent = false;
   }
}
