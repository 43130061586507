import { Pipe, PipeTransform } from '@angular/core';
import { IContactoReparto } from 'src/app/_interfaces/dialer';

@Pipe({
  name: 'inCampain'
})
export class InCampainPipe implements PipeTransform {

    transform(_id: string, reparto: IContactoReparto[]): any {
        const contacto = reparto.find(r => r.idContacto === _id);
        return !!contacto ? contacto : {};
    }

}
