<div class="btn-group">
   <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <i class="fa fa-calendar-plus-o" aria-hidden="true"></i> Agenda <i class="fa fa-caret-down"></i>
   </button>
   <ul class="dropdown-menu" style="padding: 15px" id="menuAgenda">
      <li>
         <h5><i class="fa fa-calendar" style="margin-right: 7px;"></i> Agendamiento de Eventos</h5>
         <hr>
         <table class="table">
            <th style="vertical-align: top;">
               <div ngDatePicker [estilo]="'none'" [formControl]="seleccionFecha"></div>
            </th>
            <th *ngIf="enEdicion">
               <div style="min-height: 360px; min-width: 370px; padding: 0px 10px;">
                  <app-schedule-compose [evento$]="eventoEnEdicion.asObservable()" (emitter)="composeEnd($event)">
                  </app-schedule-compose>
               </div>
            </th>
            <th *ngIf="!enEdicion" style="font-weight: 400; min-width: 370px;">
               <div *ngIf="eventos$ | async as eventos; else loading"
                  style="max-height: 360px; padding: 0px 10px; overflow: auto; overflow-x: hidden">
                  <div class="row" *ngFor="let evento of eventos; let i = index">
                     <div class="col-xs-10 col-xs-offset-2 text-center text-muted" *ngIf="i === 0">
                        <h4>{{evento.horario | amDateFormat: 'dddd D [de] MMMM'}}</h4>
                     </div>
                     <div class="col-xs-2 text-right" style="padding-right: 0; padding-top: 5px;">
                        {{evento.horario | amDateFormat: 'HH:mm'}}
                     </div>
                     <ng-container *ngIf="!evento.evento; else withEvent">
                        <div class="col-xs-10" *ngIf="evento.horario | amDateFormat: 'H' as hora">
                           <div class="line-dotted link-cursor" [ngClass]="{'no-office-hours': hora < 8 || hora >= 18}"
                              (click)="editarEvento(evento)"></div>
                        </div>
                     </ng-container>
                     <ng-template #withEvent>
                        <div class="col-xs-10">
                           <div class="line-dotted">
                              <div class="label" style="margin-right: 5px;" [ngClass]="{
                                                'label-info': evento.tarea.tipoEvento === callEvent, 
                                                'label-warning': evento.tarea.tipoEvento === smsEvent,
                                                'label-success': evento.tarea.tipoEvento === emailEvent,
                                                'label-default': evento.tarea.tipoEvento === remainderEvent
                                        }">
                                 <i class="fa fa-phone" *ngIf="evento.tarea.tipoEvento === callEvent"></i>
                                 <i class="fa fa-envelope-o" *ngIf="evento.tarea.tipoEvento === emailEvent"></i>
                                 <i class="fa fa-mobile-phone" *ngIf="evento.tarea.tipoEvento === smsEvent"></i>
                                 <i class="fa fa-clock-o" *ngIf="evento.tarea.tipoEvento === remainderEvent"></i>
                              </div>
                              <div class="label label-primary link-cursor" (click)="editarEvento(evento)">
                                 {{evento.evento}}</div>
                           </div>
                        </div>
                     </ng-template>
                  </div>
               </div>
               <ng-template #loading>
                  <div class="text-center text-muted" style="padding-top: 60px;">
                     <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
                     Consultando eventos...
                  </div>
               </ng-template>
            </th>
         </table>
      </li>
   </ul>
</div>