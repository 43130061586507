import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-response-message',
  templateUrl: './response-message.component.html',
})
export class ResponseMessageComponent implements OnInit {

    @Input() inProgress: any;
    constructor() { }

    ngOnInit() {
    }

}
