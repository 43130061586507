import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateNombre'
})
export class TruncateNombrePipe implements PipeTransform {
    transform(value: string, max = 19): any {
        if (!!value && value.length > max) {
            return `${value.substring(0, max)}...`;
        } else {
            return value;
        }
    }
}
