import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'sortByField'
})
export class SortByField implements PipeTransform {
    transform(data: Object[], campo: string): any {
        return _.orderBy(data, campo);
    }
}
