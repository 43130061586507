import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { IPauseReason } from '../_interfaces/pauseReason';
import { map, retryWhen, mergeMap } from 'rxjs/operators';
import { timer, of } from 'rxjs';

@Injectable()
export class PauseReasonService {
    private readonly URL = 'pause-reasons';
    constructor(private $http: HttpClient, private $header: HeaderService) { }
    
    obtenerRazones() {
        return this.$http.get<IPauseReason[]>(
            `${this.$header.getAPIurl()}/${this.URL}`, 
            { headers: this.$header.getHttpHeaders() }
        ).pipe(
            retryWhen(err => err.pipe(
                mergeMap((error, i) => {
                    if (i < 5) {
                        return timer( (i+1) * 1000 );
                    }
                    return of([]);
                })
            )),
            map(reasons => reasons.length > 0 ? reasons : this.dafaultReasons())
        );
    }

    actualizarRazones(razones: IPauseReason[]) {
        return this.$http.put<IPauseReason[]>(
            `${this.$header.getAPIurl()}/${this.URL}`, 
            razones, 
            { headers: this.$header.getHttpHeaders() }
        );
    }
    
    crearRazones(razones: IPauseReason[]) {
        return this.$http.post<IPauseReason[]>(
            `${this.$header.getAPIurl()}/${this.URL}`, 
            razones, 
            { headers: this.$header.getHttpHeaders() }
        );
    }

    private dafaultReasons() {
        return [{
            reason: 'Administrativo',
            timeout: 60 * 60,
            agentVisible: true
        }, {
            reason: 'Almuerzo',
            timeout: 2 * 60 * 60,
            agentVisible: true
        }, {
            reason: 'Baño',
            timeout: 10 * 60,
            agentVisible: true
        }, {
            reason: 'Break',
            timeout: 15 * 60,
            agentVisible: true
        }, {
            reason: 'Pausa activa',
            timeout: 20 * 60,
            agentVisible: true
        }] as IPauseReason[]
    }

}