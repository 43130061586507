import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentControllerComponent } from './agent-controller.component';
import { AgenteModoSkillPipe } from './pipes/agenteEstilo.pipe';
import { TimePipe } from './pipes/totime.pipe';

@NgModule({
  declarations: [AgentControllerComponent, AgenteModoSkillPipe, TimePipe],
    imports: [
        CommonModule
    ],
  exports: [AgentControllerComponent, AgenteModoSkillPipe, TimePipe]
})
export class AgentControllerModule { }
