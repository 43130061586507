import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "apiDirectionPresenter",
})
export class ApiDirectionPresenterPipe implements PipeTransform {
   transform(value: string, type: string): string {
      switch (type) {
         case "audio": {
            const audioName = value.split("|");
            return audioName[0] || "";
         }
         case "url": {
            const url = value.split("|")[1];
            if (url.length > 43) {
               return `${url.substring(0, 40)}...`;
            } else {
               return url || "";
            }
         }
         case "headers": {
            const headers = value.split("|")[2];
            const [name, valor] = headers.split(":");
            return `{ ${name} : *****${valor.substring(valor.length - 4)} }`;
         }
         case "headers-show": {
            const headers = value.split("|")[2];
            return headers;
         }
         default: {
            return "";
         }
      }
   }
}
