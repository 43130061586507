import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ValidationErrors } from '@angular/forms';
import { DirectorioService } from 'src/app/_services/directorio.service';
import { IDirectorio, ICampoDirectorio, ETipoCampoDirectorio } from 'src/app/_interfaces/directorio';
import { of, Observable, from } from 'rxjs';
import { debounceTime, flatMap, finalize, tap, filter, toArray, map, defaultIfEmpty } from 'rxjs/operators';

function hasValidPhones(control: FormArray): ValidationErrors | null {
    const campos = control.value as ICampoDirectorio[];
    const camposTel = campos.filter(c => c.tipo === ETipoCampoDirectorio.TELEFONO);
    return camposTel.length === 1 ? null : { validPhones: {cant: camposTel.length} }
}

function atLeasHasOnViewable(control: FormArray): ValidationErrors | null {
    const campos = control.value as ICampoDirectorio[];
    const camposVisibles = campos.filter(c => c.visible);
    return camposVisibles.length === 0 ? { noVisible: true } : null;
}

@Component({
  selector: 'app-directorio-config',
  templateUrl: './directorio-config.component.html',
  styleUrls: ['./directorio-config.component.css']
})
export class DirectorioConfigComponent implements OnInit {

    formDirectorio: FormGroup;
    directorio$: Observable<IDirectorio>;
    inProgress: { progress: boolean, err?: boolean, msj?: boolean } = { progress : false };
    get campos() { return this.formDirectorio.get('campos') as FormArray }

    constructor(
        private $router: Router,
        private $fb: FormBuilder, 
        private $route: ActivatedRoute, 
        private $directorio: DirectorioService,
    ) { }

    ngOnInit() {
        this.formDirectorio = this.$fb.group({
            iddb: ['', Validators.required],
            nombre: ['', Validators.required],
            descripcion: undefined,
            primario: ['', Validators.required],
            campos: this.$fb.array([], [hasValidPhones, atLeasHasOnViewable])
        });
        
        const { addressbookid } = this.$route.snapshot.params;

        this.directorio$ = this.$directorio.getDirectorio(addressbookid).pipe(
            filter(directorio => !!directorio && !!directorio.iddb),
            flatMap(directorio => from(directorio.campos).pipe(
                tap(campo => this.agregarCampo(campo)),
                toArray(),
                map(() => directorio)
            )),
            // En caso de que no exista el formulario = nuevo
            defaultIfEmpty({} as IDirectorio),
            tap(directorio => {
                this.formDirectorio.patchValue({
                    iddb: directorio.iddb || addressbookid,
                    nombre: directorio.nombre || '',
                    descripcion: directorio.descripcion,
                    primario: directorio.primario || ''
                });
            })
        );
    }

    agregarCampo(valores: any) {
        const campos = this.campos;
        const campo = this.$fb.group({
            nombre: ['', Validators.required],
            tipo: ['', Validators.required],
            editable: '',
            visible: ''
        });

        if (!!valores) {
            campo.patchValue({ ...valores })
        } else {
            campo.patchValue({
                editable: false,
                visible: false
            });
        }

        campos.push(campo);
    }

    removerCampo(index: number) {
        const campos = this.campos;
        campos.removeAt(index);
    }

    guardarDirectorio(directorio: IDirectorio, nuevo = false) {
        of(directorio).pipe(
            tap(() => this.inProgress = { progress: true }),
            debounceTime(200),
            map(abook => ({...abook, descripcion: !!abook.descripcion ? abook.descripcion.trim() : undefined})),
            flatMap(abook => this.$directorio.guardarDirectorio(abook, nuevo)),
        ).subscribe(
            () => this.inProgress = { progress: false, msj: true },
            err => {
                console.error(err);
                this.inProgress = { progress: false, err: true };
            }
        )
    }

    regresar() {
        this.$router.navigateByUrl(`/dashboard/(view:address-book)`);
    }

}
