<div class="row">
    <div class="col-xs-8">
        <label>Nombre.</label>
        <select class="form-control" style="margin-top: 3px" [(ngModel)]="_queueName" (change)="update()">
            <option value="{{q.nombre}}" *ngFor="let q of queues">{{q.nombre}}</option>
        </select>
    </div>
    <div class="col-xs-4" style="padding-left: 0">
        <label>Prioridad.</label>
        <select class="form-control" #queue_prio_op style="margin-top: 3px" [(ngModel)]="_queuePrio" (change)="update()">
            <option value="">Sin Prioridad</option>
            <option [value]="i" *ngFor="let i of [1,2,3,4,5,6,7,8,9]">{{i}}</option>
        </select>
    </div>
</div>