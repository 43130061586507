import { Injectable} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { API_ADDRESS } from '../../_environments/environment';

@Injectable()
export class HeaderService {
    private httpHeaders: HttpHeaders;
    private apiRoute = '/api/v0.1';
    private callbackPort = '0626';
    
    constructor() {
        this.httpHeaders = new HttpHeaders();
    }
    
    getAPIurl(): string {
        return (!!API_ADDRESS ? API_ADDRESS : '') + this.apiRoute;
    }
    
    setHeader(header: {nombre: string, valor: string}) {
        // httpHeaders es inmutable
        this.httpHeaders = this.httpHeaders.delete(header.nombre).append(header.nombre, header.valor);
    }
    
    getHeader(nombre: string): string {
        return this.httpHeaders.get(nombre);
    }
    
    getHeaders() {
        return this.httpHeaders;
    }

    getHttpHeaders() {
        return this.httpHeaders;
    }

    getCallbackPort(): string {
        return this.callbackPort;
    }
}
