export class Grupo {
   idgrupo: string;
   nombre: string;
   number: string;
   miembros: string | Miembro[] = [];
}

export class Miembro {
   protocolo: string;
   exten: string;
}
