import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'channelFormat'
})
export class ChannelFormatPipe implements PipeTransform {

    transform(value: string): any {
        if (!!value) {
            const chan = value.substring(0, value.indexOf('-'));
            return chan.substring(value.indexOf('/') + 1);
        } else {
            return value;
        }
    }

}
