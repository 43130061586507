import { Router } from "@angular/router";
import {
   Component,
   OnInit,
   Input,
   ViewChild,
   ElementRef,
   OnChanges,
   SimpleChanges,
   SimpleChange,
   Output,
   EventEmitter,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { DirectorioService } from "src/app/_services/directorio.service";
import { ContactosService } from "src/app/_services/contactos.service";
import { IContacto } from "src/app/_interfaces/contacto";
import {
   IDirectorio,
   ETipoCampoDirectorio,
} from "src/app/_interfaces/directorio";
import { Observable, of, from } from "rxjs";
import {
   map,
   toArray,
   tap,
   finalize,
   delay,
   flatMap,
   debounceTime,
   filter,
} from "rxjs/operators";
import { ObjectID } from "src/app/_services/_objectID";
import { FileHandlerService } from "src/app/_services/file.service";
import { PaginationInstance } from "ngx-pagination";

interface ISimpleChange extends SimpleChange {
   currentValue: IDirectorio;
}

interface IChanges extends SimpleChanges {
   directorio: ISimpleChange;
}

@Component({
   selector: "app-contactos",
   templateUrl: "./contactos.component.html",
   styleUrls: ["./contactos.component.css"],
})
export class ContactosComponent implements OnInit, OnChanges {
   inProgress = {
      progress: false,
      tipo: "",
      msj: "",
   };
   config: PaginationInstance = {
      id: "_id",
      itemsPerPage: 20,
      currentPage: 1,
   };

   isImported = false;
   busqueda: FormControl;
   seleccionContactos: IContacto[] = [];
   contactos$: Observable<(IContacto & Object)[]>;

   @Input() directorio: IDirectorio;
   @Output() reloadView = new EventEmitter<boolean>();
   @ViewChild("fileContacts")
   fileContacts: ElementRef;

   constructor(
      private $router: Router,
      private $directorio: DirectorioService,
      private $contactos: ContactosService,
      private $fileHanler: FileHandlerService
   ) {}

   ngOnInit() {
      this.busqueda = new FormControl("", Validators.required);
   }

   ngOnChanges(changes: IChanges) {
      // Busqueda de contactos
      const dirChanges = changes.directorio;
      if (!!dirChanges.currentValue) {
         this.inProgress = {
            tipo: "",
            progress: false,
            msj: "",
         };
         const directorio = dirChanges.currentValue;
         this.contactos$ = this.$contactos
            .getContactos(directorio.iddb)
            .pipe(tap(() => (this.isImported = false)));
      }
   }

   guardarContactos(contactos: (IContacto & Object)[]) {
      const campoTelefono = this.directorio.campos.find(
         (c) => c.tipo === ETipoCampoDirectorio.TELEFONO
      );
      const primario = this.directorio.primario;

      of(contactos)
         .pipe(
            // Indicar que se esta en proceso
            tap(
               () =>
                  (this.inProgress = {
                     msj: "",
                     progress: true,
                     tipo: "update",
                  })
            ),
            debounceTime(200),
            flatMap((contacts) =>
               this.$contactos.guardarContactos(
                  contacts,
                  primario,
                  !!campoTelefono ? campoTelefono.nombre : "telefono"
               )
            )
         )
         .subscribe(
            (response) => {
               console.log(response);
            },
            (err) => {
               console.error(err);
               this.inProgress = {
                  tipo: "updatefailed",
                  progress: false,
                  msj: "No fue posible ejecutar la operación.",
               };
            },
            () => {
               console.log("terminado.");
               this.inProgress = {
                  tipo: "update",
                  progress: false,
                  msj: "Contactos guardados exitosamente.",
               };
               this.contactos$ = this.$contactos
                  .getContactos(this.directorio.iddb)
                  .pipe(tap(() => (this.isImported = false)));
            }
         );
   }

   importar() {
      const fileContacts = this.fileContacts.nativeElement;
      fileContacts.onchange = () => {
         const nativeElementFiles = this.fileContacts.nativeElement.files;
         const file =
            nativeElementFiles.length > 0 ? nativeElementFiles[0] : undefined;
         const reader = new FileReader();
         reader.onload = (e) => {
            const data = (e.target as FileReader).result as string;
            // Procesar el archivo
            this.parseLines(data);
            // Limpiar el input file
            this.fileContacts.nativeElement.value = "";
         };
         reader.readAsText(file);
      };

      fileContacts.click();
   }

   exportar(contactos: IContacto[]) {
      const head = this.directorio.campos.map((c) => c.nombre);
      this.$fileHanler.exportCSVFile(
         head,
         contactos,
         `${this.directorio.nombre}_${new Date().getTime()}`
      );
   }

   seleccionarContacto(contacto: IContacto, selected: boolean) {
      if (
         selected &&
         !this.seleccionContactos.find((c) => c._id === contacto._id)
      ) {
         this.seleccionContactos = [...this.seleccionContactos, contacto];
      } else {
         this.seleccionContactos = [
            ...this.seleccionContactos.filter((c) => c._id !== contacto._id),
         ];
      }
   }

   editarContacto(contacto: IContacto & Object) {}

   eliminarContactosSeleccionados() {
      of([this.directorio.iddb, this.seleccionContactos] as [
         string,
         IContacto[]
      ])
         .pipe(
            // Indicar que se esta en proceso
            tap(
               () =>
                  (this.inProgress = {
                     msj: "",
                     progress: true,
                     tipo: "update",
                  })
            ),
            debounceTime(200),
            flatMap(([iddb, contactos]) =>
               this.$contactos.eliminarContactos(iddb, contactos)
            )
         )
         .subscribe(
            () => {
               this.inProgress = {
                  tipo: "update",
                  progress: false,
                  msj: "Contactos eliminados exitosamente.",
               };
               this.contactos$ = this.$contactos.getContactos(
                  this.directorio.iddb
               );
            },
            (err) => {
               console.error(err);
               this.inProgress = {
                  tipo: "updatefailed",
                  progress: false,
                  msj: "No fue posible ejecutar la operación.",
               };
            }
         );
   }

   limpiar() {
      of(true)
         .pipe(
            tap(
               () =>
                  (this.inProgress = {
                     msj: "",
                     progress: true,
                     tipo: "update",
                  })
            ),
            debounceTime(200),
            flatMap(() => this.$contactos.eliminarTodos(this.directorio.iddb))
         )
         .subscribe(
            (response) => {
               console.log(response);
               this.inProgress = {
                  tipo: "update",
                  progress: false,
                  msj: "Todos los contactos eliminados exitosamente.",
               };
               this.contactos$ = this.$contactos.getContactos(
                  this.directorio.iddb
               );
            },
            (err) => {
               console.error(err);
               this.inProgress = {
                  tipo: "updatefailed",
                  progress: false,
                  msj: "No fue posible ejecutar la operación.",
               };
            }
         );
   }

   eliminar() {
      of(
         confirm(
            "Esta seguro de eliminar este directorio y todos sus conectactos?"
         )
      )
         .pipe(
            filter((respuesta) => respuesta),
            debounceTime(200),
            tap(
               () =>
                  (this.inProgress = {
                     msj: "",
                     progress: true,
                     tipo: "",
                  })
            ),
            flatMap(() =>
               this.$directorio.eliminarDirectorio(this.directorio.iddb)
            )
         )
         .subscribe(
            (response) => {
               this.reloadView.emit(true);
               this.inProgress = {
                  msj: "",
                  tipo: "",
                  progress: false,
               };
               this.$router.navigateByUrl("dashboard/(view:address-book)");
            },
            (error) => {
               console.error(error);
               this.inProgress = {
                  msj: "No se pudo eliminar este directorio.",
                  progress: false,
                  tipo: "updatefailed",
               };
            }
         );
   }

   private parseLines(importedData: string) {
      this.inProgress = {
         progress: true,
         msj: "",
         tipo: "",
      };
      const campos = this.directorio.campos.map((c) => c.nombre) as string[];

      // Helper fn para retornar arreglo
      const splitter = (data: string[]) =>
         data.reduce((acc, curr) => {
            if (!curr.includes('"')) {
               const formatedString = curr.replace(/,/g, ";");
               return acc.concat(formatedString.split(";"));
            } else {
               return acc.concat(curr);
            }
         }, []);

      this.contactos$ = from(importedData.split("\n")).pipe(
         map((data) => {
            // ;"  -> "";
            const dataArray = splitter(
               data
                  .replace(/([\,,\;])(")/g, '#,#"')
                  .replace(/(")[\,,\;]/g, '"#,#')
                  .split("#,#")
            );

            const formattedData = campos.reduce(
               (acc, curr, index) => {
                  const dataContent = dataArray[index];
                  return {
                     ...acc,
                     [curr]: !!dataContent
                        ? dataContent.replace(/"/g, "")
                        : undefined,
                  };
               },
               {
                  _id: ObjectID(),
                  iddb: this.directorio.iddb,
               }
            );

            return formattedData;
         }),
         toArray(),
         delay(1000),
         tap(() => (this.isImported = true)),
         finalize(
            () =>
               (this.inProgress = {
                  msj: "",
                  tipo: "",
                  progress: false,
               })
         )
      );
   }
}
