import { Pipe, PipeTransform } from '@angular/core';
import { ITipificacionLog } from 'src/app/_interfaces/_all';
import { from, Observable } from 'rxjs';
import { flatMap, reduce } from 'rxjs/operators';

@Pipe({
  name: 'preguntas'
})
export class PreguntasPipe implements PipeTransform {

    transform(resultados: ITipificacionLog[]): Observable<string[]> {
        return from(resultados).pipe(
            flatMap(resultado => resultado.preguntas),
            reduce((acc, curr) => !acc.includes(curr.enunciado) ? [...acc, curr.enunciado] : acc, [])
        );
    }

}
