import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EOpcion } from "src/app/_interfaces/ivr";

@Component({
   selector: "app-opcion",
   templateUrl: "./opcion.component.html",
   styleUrls: ["./opcion.component.css"],
})
export class OpcionComponent implements OnInit {
   @Input()
   nivel: { opcion: EOpcion; valor: string };

   @Output()
   operacion = new EventEmitter<boolean>();

   @Output()
   descarga = new EventEmitter<string>();

   o = EOpcion;

   constructor() {}

   ngOnInit(): void {}

   play() {
      this.operacion.emit(true);
   }

   stop() {
      this.operacion.emit(false);
   }

   download(audioSrc: string) {
      this.descarga.emit(audioSrc);
   }
}
