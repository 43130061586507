import { Component, OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ISchedule, IScheduleJob, ETipoScheduleEvent } from 'src/app/_interfaces/schedule';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { tap, debounceTime, flatMap, delay } from 'rxjs/operators';
import { ScheduleService } from 'src/app/_services/schedule.service';

@Directive({ 
    selector: '[hasSpecialChars]',
    providers: [{
        provide: NG_VALIDATORS, useExisting: HasSpecialCharsDirective, multi: true
    }]
})
export class HasSpecialCharsDirective implements Validators {
    validate(control: FormControl): ValidationErrors | null {
        return /[^ A-Za-z0-9_{}+?!@#&%/()=\-\[\]\,\.\;\:]/.test(control.value) ? { invalidContent: true } : null;  
    }
}

@Component({
  selector: 'app-schedule-compose',
  templateUrl: './schedule-compose.component.html',
  styleUrls: ['./schedule-compose.component.css']
})
export class ScheduleComposeComponent implements OnInit {

    inProgress = {
        progress: false,
        msj: false,
        err: false
    };
    @Input() evento$: Observable<ISchedule>;
    @Output() emitter = new EventEmitter<ISchedule>();

    constructor(private $schedule: ScheduleService ) { }

    ngOnInit() {}

    guardar(evento: ISchedule) {
        of(evento).pipe(
            tap(() => this.inProgress = { err: false, msj: false, progress: true }),
            debounceTime(300),
            flatMap(e => this.$schedule.guardarEvento(e)),
            tap(() => this.inProgress = { err: false, msj: true, progress: false }),
            delay(1600),
            tap(() => this.regresar(evento))
        ).subscribe(
            () => {},
            err => {
                console.error(err);
                this.inProgress = { err: true, msj: false, progress: false }
            }
        );
    }

    eliminar(evento: ISchedule) {
        of(evento).pipe(
            tap(() => this.inProgress = { err: false, msj: false, progress: true }),
            debounceTime(300),
            flatMap(e => this.$schedule.eliminarEvento(e.id)),
            tap(() => this.inProgress = { err: false, msj: true, progress: false }),
            tap(() => this.regresar(evento))
        ).subscribe(
            () => {},
            err => {
                console.error(err);
                this.inProgress = { err: true, msj: false, progress: false }
            }
        );
    }

    regresar(evento?: ISchedule) {
        this.emitter.emit(evento)
    }

}
