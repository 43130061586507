import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IForma } from "src/app/_interfaces/_all";
import { ActivatedRoute } from "@angular/router";
import { FormasService } from "src/app/_services/formas.service";
import { map, mergeMap, startWith } from "rxjs/operators";
import { timer, combineLatest, Observable, Subject } from "rxjs";
import { QueueService } from "src/app/_services/queue.service";
import { IAgentState, IAgentStateChannel } from "src/app/_interfaces/monitor";
import { SocketService } from "src/app/_services/socket.service";

@Component({
   selector: "tipificacion-presenter",
   templateUrl: "tipifcacionPresenter.component.html",
})
export class TipificacionPresenterComponent implements OnInit {
   formularios$: Observable<IForma[]>;

   @Input()
   agente: IAgentState;

   @Input()
   channelSnapshot: IAgentStateChannel;

   @Output()
   idTipificacion = new EventEmitter<string>();

   formularioSeleccionado: IForma;
   viewSwitch$ = new Subject<string>();

   constructor(
      private $queue: QueueService,
      private $formas: FormasService,
      private $route: ActivatedRoute,
      private $socket: SocketService
   ) {}

   ngOnInit() {
      const getFormularios$ = () =>
         this.$socket.listenFormulario().pipe(
            startWith(true),
            mergeMap(() => this.$formas.getFormularios())
         );
      // timer(0, 15000).pipe(mergeMap(() => this.$formas.getFormularios()));

      const queues$ = (agente: { idagente: string }) =>
         this.$queue.getQueues().pipe(
            map((queues) =>
               queues.filter((q) => {
                  const colas = (q.agentes as { idagente: string }[]).map(
                     (a) => a.idagente
                  );
                  return colas.includes(agente.idagente);
               })
            ),
            map((queues) => queues.map((q) => q.nombre))
         );

      this.formularios$ = this.$route.data.pipe(
         mergeMap((data: { agente: { idagente: string } }) =>
            combineLatest([queues$(data.agente), getFormularios$()])
         ),
         map(([colas, formas]) => {
            const isPresent = (frmColas: string[]) => {
               for (const c of colas) {
                  if (frmColas.includes(c)) {
                     return true;
                  }
               }
               return false;
            };
            return formas.filter((f) => isPresent(f.colas as string[]));
         })
      );
   }

   // Navegar hacia el formulario,
   cargarFormulario(forma: IForma) {
      this.formularioSeleccionado = { ...forma };
      this.viewSwitch$.next("go");
   }

   clearPresenter() {
      // Limpiar el presentador.
      this.viewSwitch$.next(undefined);
   }

   emitIDTipificacion(id: string) {
      this.idTipificacion.emit(id);
   }
}
