import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactosComponent } from './contactos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactosService } from 'src/app/_services/contactos.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContactoComponent } from './contacto/contacto.component';
import { UIModule } from 'src/app/_extra/ui/ui.module';

@NgModule({
    providers: [ ContactosService ],
    declarations: [
        ContactosComponent,
        ContactoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        UIModule
    ],
    exports: [ ContactosComponent, ContactoComponent ]
})
export class ContactosModule { }
