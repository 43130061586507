import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IQueueStats } from 'src/app/_interfaces/monitor';
import { QueueService } from 'src/app/_services/queue.service';
import { SocketService } from 'src/app/_services/socket.service';
import { ObjectID } from 'src/app/_services/_objectID';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { EUsuarioRol, Usuario } from 'src/app/_interfaces/usuario';
import { Observable, from } from 'rxjs';
import * as orderBy from 'lodash/orderBy';
import { tap, map, flatMap, toArray, filter, reduce } from 'rxjs/operators';

@Component({
  selector: 'app-callcenter-dash',
  templateUrl: './consola_call.component.html',
  styleUrls: ['./consola_call.component.css']
})
export class CallcenterDashComponent implements OnInit, OnDestroy {

    queueStats$: Observable<IQueueStats[]>;
    get hasPermission() { return this.$usuario.usuario.permisos <= EUsuarioRol.MANAGER; }

    constructor(
        private $router: Router,
        private $queue: QueueService, 
        private $usuario: UsuarioService,
        private $monitor: SocketService, 
    ) { }

    ngOnInit() {
        const filterColasPermitidas$ = (usuario: Usuario) => (inData$: Observable<string>) => inData$.pipe(
            map(cola => {
                if (usuario.permisos <= EUsuarioRol.MANAGER) {
                    return cola;
                } else {
                    return usuario.manager.map(m => m.cola).includes(cola) ? cola : undefined;
                }
            }),
            filter(cola => !!cola)
        );

        this.queueStats$ = this.$queue.getQueues().pipe(
            tap(() => this.$monitor.setRooms(['queues'])),
            flatMap(queues => from(queues.map(q => q.nombre))),
            // si el rol de usuario es manager o supervisor
            filterColasPermitidas$(this.$usuario.usuario),
            map(cola => ({ 
                queue: cola,
                SL: 0,
                CI: 0,
                inbound: 0,
                outbound: 0,
                abandon: 0,
                abandon_exit: 0,
                talkInbound: 0,
                talkOutbound: 0,
                abandonHoldtime: 0,
                inboundHoldtime: 0,
                lastcall: '',
                waiting: 0
            } as IQueueStats & { waiting: number })),
            toArray(),
            flatMap(initStats => this.$monitor.listenQueueStats$.pipe(
                flatMap(streamed => from(streamed).pipe(
                    // Check if queue is in the init request
                    filter(stat => !!initStats.find(s => s.queue === stat.queue)),
                    // reduce with init the initStats
                    reduce((queueStats, queueStat) => [...queueStats.filter(q => q.queue !== queueStat.queue), queueStat], initStats),
                    map(queueStats => orderBy(queueStats, 'queue')),
                ))
            ))
        );
    }

    ngOnDestroy() {
        this.$monitor.exitRooms(['queues']);
    }

    loadQueue(queuename: string) {
        this.$router.navigateByUrl(`/dashboard/(view:callcenter/${queuename})`);
    }

    crearCola() {
        this.$router.navigateByUrl(`/dashboard/(view:callcenter/setup/${ObjectID()})`)
    }

}
