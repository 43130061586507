import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DirectorioComponent } from './directorio.component';
import { DirectorioConfigComponent } from './directorio-config/directorio-config.component';
import { UIModule } from 'src/app/_extra/ui/ui.module';
import { CampoDirectorioComponent } from './directorio-config/campo-directorio/campo-directorio.component';
import { DirectorioService } from 'src/app/_services/directorio.service';
import { ContactosModule } from './contactos/contactos.module';

@NgModule({
    providers: [DirectorioService],
    declarations: [DirectorioComponent, DirectorioConfigComponent, CampoDirectorioComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ContactosModule,
        UIModule,
    ]
})
export class DirectorioModule { }
