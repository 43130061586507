import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Queue } from '../../../_interfaces/queue';

@Component({
  selector: 'ui-queue-selector',
  templateUrl: './queue-selector.component.html',
  providers: [{provide: NG_VALUE_ACCESSOR, multi: true, useExisting: QueueSelectorComponent }]
})
export class QueueSelectorComponent implements ControlValueAccessor {
    
    _queueName: string;
    _queuePrio: string;
    _queueTimeout: string;
    _onChange: (value: any) => void;

    @Input() queues: Queue[];
        
    constructor() { }

    update() {
        const valor = this._queueName + (this._queuePrio !== '' ? ',' + this._queuePrio : '');
        this._onChange(valor);
    }

    writeValue(valor: string): void {
        const data = !!valor ? valor.split(',') : '';
        if (Array.isArray(data)) {
            this._queueName = data[0] || '';
            this._queuePrio = data[1] || '';
            this._queueTimeout = data[2] || '';
        } else {
            this._queueName = valor;   
            this._queuePrio = '';
        }
    }
    registerOnChange(fn: any): void {
        this._onChange = fn;
    }
    registerOnTouched(fn: any): void {
        
    }
    setDisabledState?(isDisabled: boolean): void {
        
    }
    
}
