import { Pipe, PipeTransform } from '@angular/core';
import { EEstadoCampanaDialer, ICampanaStats } from 'src/app/_interfaces/dialer';

@Pipe({
  name: 'campainStatus'
})
export class CampainStatusPipe implements PipeTransform {

    transform(campanas: ICampanaStats[], online = true): any {
        if (online) {
            return campanas.filter(c => c.estado === EEstadoCampanaDialer.EN_CURSO || c.estado === EEstadoCampanaDialer.EN_PAUSA);
        } else {
            return campanas.filter(c => c.estado === EEstadoCampanaDialer.DETENIDA);
        }
    }

}
