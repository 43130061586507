<form class="form" novalidate autocomplete="off" [formGroup]="frmCampana" (ngSubmit)="guardarCampana(frmCampana.value)">
    <div class="row">
        <div class="col-md-6">
            <label>Nombre de la Campaña.</label>
            <input type="hidden" formControlName="id">
            <input type="text" class="form-control" formControlName="nombre">

            <div style="margin-top: 10px">
                <label>Cuerpo del Mensaje.</label>
                <textarea 
                class="form-control" [ngClass]="{'border-danger': smsMensaje.errors && (smsMensaje.errors.invalidChar || smsMensaje.errors.maxlength)}"
                formControlName="mensaje" style="min-height: 100px" 
                placeholder="Ej: Apreciado {nombre}, recuerde..." required></textarea>
                <ng-container *ngIf="smsMensaje.errors && (smsMensaje.touched || smsMensaje.dirty) else infoMessage">
                   <small *ngIf="smsMensaje.errors.invalidChar" class="text-danger">Tu mensaje tiene caracteres especiales.<br></small>
                   <small *ngIf="smsMensaje.errors.maxlength" class="text-danger">Tu mensaje tiene {{( smsMensaje.errors.maxlength.actualLength - smsMensaje.errors.maxlength.requiredLength )}} caracteres de más.</small>
                </ng-container>
                <ng-template #infoMessage>
                  <small>Sin caracteres especiales | 
                     <span [ngClass]="{ 'text-warning text-bold' : smsMensaje.value.length > 140 }">{{smsMensaje.value.length}} </span>
                     Chars</small>
                </ng-template>
            </div>
        </div>
        <div class="col-md-6">
            <div style="margin-bottom: 10px">
                <label>Fecha de ejecución.</label>
                <div class="input-group">
                    <input type="text" ngDatePicker formControlName="fecha_ejecucion">
                    <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                </div>
            </div>
            <div style="margin-bottom: 10px" *ngIf="rutas$ | async; let rutas">
                <label>Ruta de SMS.</label>
                <select class="form-control" formControlName="rutaid" (change)="mostrarCreditosDisponibles($event.target.value, rutas)">
                    <option value="">Seleccione una...</option>
                    <option [value]="ruta.id" *ngFor="let ruta of rutas">{{ruta.nombre}}</option>
                </select>
                <small class="text-muted">Créditos aproximados disponibles: {{creditosEnRuta$ | async}}</small><br>
                <small class="text-danger" *ngIf="frmCampana.get('rutaid').errors?.credit">Esta ruta no es elegible.
                    <span *ngIf="frmCampana.get('rutaid').errors.credit"> Créditos insuficientes</span>
                </small>
            </div>
            <div style="margin-bottom: 10px">
                <label>Estado incial.</label>
                <div class="text-right" style="margin-right: 20px">
                    <ui-toggleV1 formControlName="estado"></ui-toggleV1>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-right">
            <hr>
            <button type="button" (click)="ngOnInit()" class="btn btn-default">Reset</button>&nbsp;
            <button type="submit" [disabled]="!frmCampana.valid" class="btn btn-primary">Guardar Campaña</button>
        </div>

    </div>
</form>
