<div style="color: #585858;" *ngIf="campana$ | async as campana; else loading">
    <h4>
        <i class="fa fa-rocket"></i> {{campana?.nombre}} 
    </h4>
    <hr>
    <div class="row" *ngIf="contactos$ | async as contactos; else loadingContactos">
        <div class="col-lg-3 col-md-4">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <i class="fa fa-bar-chart-o"></i> Resultados Generales
                </div>
                <ul class="list-group" *ngIf="stats$ | async as stats; else loadingStats">
                    <li class="list-group-item">
                        Pendientes <span class="badge">{{stats.enEspera || 0}}</span></li>
                    <li class="list-group-item">
                        En curso <span class="badge">{{stats.enCurso || 0}}</span></li>
                    <li class="list-group-item">
                        Reintentar <span class="badge">{{stats.reIntento || 0}}</span></li>
                    <li class="list-group-item">
                        Contactados <span class="badge">{{stats.atendidas || 0}}</span></li>
                    <li class="list-group-item">
                        Fallidos <span class="badge">{{stats.fallidas || 0}}</span></li>
                </ul>
                <ng-template #loading>
                    <div style="padding: 20px;" class="panel-body text-primary text-center">
                        <i class="fa fa-2x fa fa-spinner fa-spin" style="margin-bottom: 10px;"></i>
                    </div>
                </ng-template>
            </div>
            <app-campain-agents [contactos]="contactos"></app-campain-agents>
        </div>
        
        <div class="col-lg-9 col-md-8">
            <div class="row">
                <div class="col-md-5">
                    <h5>Tipo de campaña : {{campana.tipo}} | 
                        <span class="label" 
                        [ngClass]="{'label-warning': campana.estado !== progressEnum, 'label-success' : campana.estado === progressEnum}">{{campana.estado}}</span></h5>
                </div>
                <div class="col-md-7 text-right" style="margin-bottom: 10px;">
                    <button type="button" class="btn btn-default" (click)="editarCampana(campana.idcampana)">
                        <i class="fa fa-wrench"></i> Editar campaña
                    </button>
        
                    <div class="btn-group" style="margin-left: 10px;">
                        <button type="button" [disabled]="campana.estado === stoppedEnum || inProgress" 
                            (click)="cambiarEstado(campana, stoppedEnum)"
                            class="btn btn-info">
                            <i class="fa fa-stop"></i> 
                        </button>
                        <button type="button" class="btn btn-info" 
                            (click)="cambiarEstado(campana, progressEnum)"
                            [disabled]="campana.estado === progressEnum || inProgress">
                            <i class="fa fa-play" ></i> 
                        </button>
                        <button type="button" class="btn btn-info" 
                            [disabled]="campana.estado === stoppedEnum || campana.estado === pausedEnum || inProgress"
                            (click)="cambiarEstado(campana, pausedEnum)">
                            <i class="fa fa-pause"></i> 
                        </button>
                        <button type="button" [disabled]="inProgressReparto.progress" class="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            <i class="fa fa-refresh" [ngClass]="{'fa-spin': inProgressReparto.progress}" style="margin-right: 5px;"></i> 
                            <span *ngIf="!inProgressReparto.progress">Relanzar</span><span *ngIf="inProgressReparto.progress">Relanzando</span> 
                            <i class="fa fa-caret-down" style="margin-left: 5px;"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right">
                            <li><a href="javascript:void(0)" (click)="relanzar(contactos)">Todos</a></li>
                            <li><a href="javascript:void(0)" (click)="relanzar(contactos, repartoFallido)">Fallidos</a></li>
                            <li><a href="javascript:void(0)" (click)="relanzar(contactos, repartoFinalizado)">Contactados</a></li>
                            <ng-container *ngIf="isRootUser">
                                <li role="separator" class="divider"></li>
                                <li [ngClass]="{'disabled' : seleccionContactos.length === 0 }"><a href="javascript:void(0)" 
                                    (click)="relanzarSeleccion(repartoFallido)">Liberar selección a <strong>FALLIDO</strong></a></li>
                                <li [ngClass]="{'disabled' : seleccionContactos.length === 0 }"><a href="javascript:void(0)" 
                                    (click)="relanzarSeleccion(repartoFinalizado)">Liberar selección a <strong>FINALIZADO</strong></a></li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="inProgressReparto.err">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <i class="fa fa-warning"></i> Ocurrió un error al intentar relanzar los contactos, intentelo nuevamente.
            </div>
            <div class="alert alert-success" *ngIf="inProgressReparto.msj">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <i class="fa fa-check-square-o"></i> Operación ejecutada correctamente.
            </div>
            <div class="table-responsive">
            <table class="table table-striped table-bordered" style="font-size: 13px;">
                <thead class="text-white" style="background-color: #337ab7;">
                    <tr>
                        <th class="th" [attr.colspan]="isRootUser ? '3' : '2'">Contacto</th>
                        <th class="text-center th">Agente</th>
                        <th class="text-center th">En Curso</th>
                        <th class="text-center th">En Audio</th>
                        <th class="text-center th">En Atención</th>
                        <th class="text-center th">Resultado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of contactos | paginate: config; let i = index">
                        <td class="text-center" *ngIf="isRootUser">
                            <input type="checkbox" #sel (change)="seleccionar(item, sel.checked)">
                        </td>
                        <td class="text-center">
                            <span *ngIf="item | repartoStatus: repartoEnCurso"><i class="fa fa-circle-o-notch fa-spin text-info"></i></span>
                            <span *ngIf="item | repartoStatus: repartoEnEspera"><i class="fa fa-hourglass-1 text-muted"></i></span>
                        </td>
                        <td>{{item.contacto | callStatus}}</td>
                        <td class="text-center">{{item.agenteid || 'N/A'}}</td>
                        <td class="text-center">{{item.contacto | callStatus : enCurso}}</td>
                        <td class="text-center">{{item.contacto | callStatus : enAudio}}</td>
                        <td class="text-center">{{item.contacto | callStatus : enAtencion}}</td>
                        <td class="text-left">
                            <span *ngIf="item | repartoStatus: repartoFinalizado"><i class="fa fa-check-square-o text-success"></i> Contactado</span>
                            <span *ngIf="item | repartoStatus: repartoReintentar"><i class="fa fa-clock-o text-info"></i> Reintentar</span>
                            <span *ngIf="item | repartoStatus: repartoFallido"><i class="fa fa-times-circle text-danger"></i> Fallido</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr>
            <div style="float: left;">
                <h5>Total contactos en el reparto : {{contactos.length || 0}}</h5>
            </div>
            <div class="text-right">
                <pagination-template #p="paginationApi"
                    [id]="config.id"
                    (pageChange)="config.currentPage = $event">

                    <div class="btn-group">
                        <button type="button" class="btn btn-default" 
                            [disabled]="p.isFirstPage()"
                            (click)="p.previous()">
                            <i class="fa fa-arrow-circle-o-left"></i>
                        </button>

                        <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                            [disabled]="p.getCurrent() === page.value"
                            [ngClass]="{'active': p.getCurrent() === page.value}"
                            (click)="p.setCurrent(page.value)">
                            {{ page.label }}
                        </button>
                        
                        <button type="button" class="btn btn-default" 
                            [disabled]="p.isLastPage()"
                            (click)="p.next()">
                            <i class="fa fa-arrow-circle-o-right"></i>
                        </button>
                    </div>&nbsp;
                    <button type="button" class="btn btn-warning" 
                        (click)="descargar(enunciados, cabecerasContacto)"
                        [disabled]="!resultados || resultados.length === 0"><i class="fa fa-cloud-download"></i> Descargar en *.csv</button>
                </pagination-template>
            </div>
            </div>
        </div>
    </div>
    <ng-template #loadingContactos>
        <div style="padding: 80px;" class="text-primary text-center">
            <i class="fa fa-spinner fa-spin fa-2x text-primary" style="margin-bottom: 10px;"></i><br>
            Cargando repartos...
        </div>
    </ng-template>
</div>
<ng-template #loading>
    <div style="padding: 80px;" class="text-primary text-center">
        <i class="fa fa-2x fa fa-spinner fa-spin" style="margin-bottom: 10px;"></i><br>
        Cargando campaña, un momento...
    </div>
</ng-template>