
<div class="panel panel-default">
    <input type="hidden" name="key" [ngModel]="apiKey?.key">
    <input type="hidden" name="tipo" [ngModel]="apiKey?.tipo">
    <div class="panel-heading" *ngIf="apiKey?.key | keyType: apiKey.tipo; let k">
        <div class="row">
            <div class="col-xs-3 text-left">
                <strong>
                    <span *ngIf="!k.keypass"><i class="fa fa-key"></i>  Apikey</span>
                    <span *ngIf="!!k.keypass"><i class="fa fa-asterisk"></i>  Manager Key</span>
                </strong>
            </div>
            <div class="col-xs-8" style="padding-right: 0">
                <div *ngIf="!k.keypass">
                    <p><strong><code>{{k.keyval}}</code></strong></p>
                </div>
                <div *ngIf="!!k.keypass">
                    <strong>
                        usuario: <code>{{k.keyval}}</code> contraseña: <code>{{k.keypass}}</code>
                    </strong>
                </div>
            </div>
            <div class="col-xs-1 text-right">
                <button class="btn btn-danger" (click)="eliminarApiKey(apiKey)"><i class="fa fa-trash"></i></button>
            </div>
        </div>
        <small *ngIf="!k.keypass">Tipo: <strong>API KEY</strong> Para realizar conexiones a través de servicios REST (POST, GET, DELETE)</small>
        <small *ngIf="!!k.keypass">Tipo: <strong>MANAGER KEY</strong> Para realizar integraciones de CRMs directamente con Asterisk&trade;</small>
        <br>
        <small class="text-muted">Creado: {{apiKey?.fecha_creacion | amCalendar}}</small>
        
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-xs-3">Contexto:</div>
            <div class="col-xs-9 text-success">
                <select class="form-control" name="contexto" #contexto [ngModel]="apiKey?.contexto" (change)="modificarContexto(contexto.value, apiKey)">
                    <option *ngFor="let c of _apiContexto" [value]="c.contexto">{{c.nombre}}</option>
                </select>
            </div>
        </div>
    </div>
    <hr style="margin: 0">
    <div class="panel-body">
        <div class="row">
            <div class="col-xs-3">Host permitidos:</div>
            <div class="col-xs-9">
                <div *ngFor="let h of apiKey.host; index as i">
                    <div class="row">
                        <div class="col-xs-11"><code>{{h}}</code></div>
                        <div class="col-xs-1 text-left">
                            <a href="javascript:void(0)" (click)="eliminarHost(h, apiKey)"><i class="fa fa-close"></i></a>
                        </div>
                    </div>
                    
                    <hr style="margin-bottom: 5px; margin-top: 5px" *ngIf="i < (apiKey.host.length - 1)"></div>
                <input type="text" #inputHost [ngModel]="ipAddress" class="form-control" style="margin-top: 10px; margin-bottom: 5px" 
                    (keydown.enter)="agregarHost(inputHost.value, apiKey)">
                <small class="text-muted">Presiona <kbd>Enter</kbd> para agregar el host.</small>
            </div>
        </div>
    </div>
</div>
