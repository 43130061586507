
import {throwError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { IDBResponse } from '../_interfaces/responses';
import { Troncal } from '../_interfaces/troncal';
import * as _ from 'lodash';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class TroncalService {
    constructor(private $http: HttpClient, private $header: HeaderService) {}
    
    getTroncales(): Observable<Troncal[]> {
        return this.$http.get<Troncal[]>(this.$header.getAPIurl() + '/troncal', { headers: this.$header.getHeaders() })
    }
    
    getTroncalesPorContexto(contexto: string): Observable<Troncal[]> {
        return this.$http.get(this.$header.getAPIurl() + '/troncal', { headers: this.$header.getHeaders() })
            .pipe(
                map((troncales: Troncal[]) => {
                    return _.filter(troncales, troncal => {
                        return _.filter(troncal.prefijos, ['contexto', contexto]).length > 0
                    })
                })
            );
    }
    
    actualizarTroncal(data: { troncal: Troncal, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<IDBResponse>;
        if (data.update === undefined) {
            http$ = this.$http.delete<IDBResponse>(this.$header.getAPIurl() + '/troncal/' + data.troncal.nombre, { headers: this.$header.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post<IDBResponse>(this.$header.getAPIurl() + '/troncal', data.troncal, { headers: this.$header.getHeaders() })
        } else {
            http$ = this.$http.put<IDBResponse>(this.$header.getAPIurl() + '/troncal', data.troncal, { headers: this.$header.getHeaders() })
        }
        return http$;
    }
}

