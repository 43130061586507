import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ObjectID } from "src/app/_services/_objectID";
import { Observable, of } from "rxjs";
import { DirectorioService } from "src/app/_services/directorio.service";
import { debounceTime, delay, filter, mergeMap, tap } from "rxjs/operators";
import { ContactosService } from "src/app/_services/contactos.service";

@Component({
   selector: "app-directorio",
   templateUrl: "./directorio.component.html",
   styleUrls: ["./directorio.component.css"],
})
export class DirectorioComponent implements OnInit {
   inProcess = false;
   directorio: any;
   directorios$: Observable<any>;

   constructor(
      private $router: Router,
      private $directorio: DirectorioService,
      private $contactos: ContactosService
   ) {}

   ngOnInit() {
      this.directorio = undefined;
      this.directorios$ = this.$directorio.getDirectorios();
   }

   crearNuevoDirectorio() {
      this.$router.navigateByUrl(`/dashboard/(view:address-book/${ObjectID()}`);
   }

   editarFormulario(addressBookID) {
      this.$router.navigateByUrl(
         `dashboard/(view:address-book/${addressBookID})`
      );
   }

   eliminar(addressBookID: string) {
      of(
         confirm(
            "Esta seguro de eliminar este directorio y todos sus conectactos?"
         )
      )
         .pipe(
            filter((respuesta) => respuesta),
            debounceTime(200),
            tap(() => (this.inProcess = true)),
            mergeMap(() => this.$contactos.eliminarTodos(addressBookID)),
            mergeMap(() => this.$directorio.eliminarDirectorio(addressBookID)),
            tap(() => (this.inProcess = false)),
            tap(() => this.ngOnInit())
         )
         .subscribe(
            () => this.$router.navigateByUrl("dashboard/(view:address-book)"),
            (error) => console.error(error)
         );
   }

   seleccionarDirectorio(directorio: any) {
      this.directorio = { ...directorio };
   }
}
