import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   SimpleChange,
   SimpleChanges,
   ViewChild,
} from "@angular/core";
import { FormArray, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IVRService } from "../../../_services/ivr.service";
import { IVR, Secuencia, Horario } from "../../../_interfaces/ivr";
import * as moment from "moment";
import * as _ from "lodash";

import { SecuenciaComponent } from "../secuencia/secuencia.component";
import { FileHandlerService } from "src/app/_services/file.service";

@Component({
   selector: "did-tag",
   templateUrl: "did.component.html",
})
export class DIDComponent implements OnInit {
   @ViewChild(SecuenciaComponent)
   secuenciaComp: SecuenciaComponent;

   @Input() readOnly: boolean;
   private _ivr: IVR;
   @Input() set ivr(ivr: IVR) {
      if (!!ivr && !!ivr.nombre) {
         this._ivr = ivr;
      } else {
         this._ivr = new IVR();
      }
      this.iniciarForm(this._ivr);
   }
   get ivr() {
      return this._ivr;
   }

   @Output()
   ivrEvento = new EventEmitter<{ ivr: IVR; update: boolean }>();

   didForm: FormGroup;
   private audio = new Audio();

   private days: { dia: string; day: string }[] = [
      { dia: "LUN", day: "mon" },
      { dia: "MAR", day: "tue" },
      { dia: "MIE", day: "wed" },
      { dia: "JUE", day: "thu" },
      { dia: "VIE", day: "fri" },
      { dia: "SAB", day: "sat" },
      { dia: "DOM", day: "sun" },
   ];

   constructor(
      private $fb: FormBuilder,
      private $fileService: FileHandlerService
   ) {}

   ngOnInit() {
      // Lista de días para horarios.
      let fecha = moment();
      fecha = fecha.add(-fecha.format("e"), "days");
      this.days.forEach((day) => {
         day.dia = fecha.format("ddd");
         fecha.add(1, "days");
      });
   }

   playAudio(src: string) {
      this.audio.src = src;
      this.audio.play();
   }

   stopAudio() {
      this.audio.pause();
      this.audio.currentTime = 0;
   }

   descargar() {
      this.$fileService.download(
         `/ivr/${this.ivr.did}_hold/hold.wav`,
         `${this.ivr.did}.wav`
      );
   }

   seleccionarAdjunto(tipo: string, nombre?: string) {
      // console.log(this.tipoAudio)
      $("#audio_attach").click();
   }

   iniciarForm(ivr?: IVR) {
      // Construcción del objeto
      this.didForm = this.$fb.group({
         did: ["", Validators.required],
         nombre: ["", Validators.required],
         horario: this.$fb.array([]),
      });
      if (ivr) {
         this.didForm.patchValue({
            did: ivr.did || "",
            nombre: ivr.nombre || "",
         });

         const horarioArray = <FormArray>this.didForm.controls["horario"];
         ivr.horario.forEach((horario) => {
            const nHorario = this.crearHorario(horario);
            horarioArray.push(nHorario);
         });
      }
   }

   crearHorario(horario?: Horario): FormGroup {
      const _horario = this.$fb.group({
         did: "",
         horario: ["", Validators.required],
         dia1: "",
         dia2: "",
      });
      if (!!horario) {
         const dias = horario.dia.split("-");
         _horario.patchValue({
            did: horario.did || "",
            horario: horario.horario || "",
            dia1: dias[0] || this.days[0].day,
            dia2: dias[1] ? "-" + dias[1] : "",
         });
      } else {
         _horario.patchValue({
            did: this.ivr.did,
            horario: "",
            dia1: this.days[0].day,
            dia2: "-" + this.days[5].day,
         });
      }
      return _horario;
   }

   agregarHorario() {
      const horarioArray = <FormArray>this.didForm.controls["horario"];
      const nHorario = this.crearHorario();
      horarioArray.push(nHorario);
   }

   eliminarHorario(index: number) {
      const horarioArray = <FormArray>this.didForm.controls["horario"];
      horarioArray.removeAt(index);
   }

   // <editor-fold defaultstate="collapsed" desc="crearSecuencia(secuencia?: Secuencia): FormGroup">
   crearSecuencia(secuencia?: Secuencia): FormGroup {
      const secGrupo = this.$fb.group({
         did: "",
         contexto: "",
         index: "",
         opcion_ivr: "",
         opcion: "",
         valor: "",
         operacion: "",
      });
      if (!!secuencia) {
         secGrupo.patchValue({
            did: secuencia.did || "",
            contexto: secuencia.contexto || "",
            index: secuencia.index || "",
            opcion_ivr: secuencia.opcion_ivr || "",
            opcion: secuencia.opcion_ivr || "",
            valor: secuencia.valor || "",
            operacion: secuencia.operacion || "",
         });
      }
      return secGrupo;
   }
   // </editor-fold>

   guardarDID(ivrForm: IVR) {
      const ivrEditado = _.cloneDeep(ivrForm);
      ivrEditado.nombre = ivrEditado.nombre.split(" ").join("_");
      ivrEditado.horario.forEach((h) => {
         h.dia = h.dia1 + h.dia2;
         delete h.dia1;
         delete h.dia2;
      });
      ivrEditado.secuencia = _.cloneDeep(this.secuenciaComp.secuencia);

      this.ivrEvento.emit({
         ivr: ivrEditado,
         update: this.readOnly,
      });
   }

   eliminarDID(ivr: IVR) {
      this.ivrEvento.emit({
         ivr: ivr,
         update: undefined,
      });
   }
}
