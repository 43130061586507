import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsuarioService } from "../_services/usuario.service";
import { AgenteService } from "../_services/agente.service";
import { RELEASE_VERSION } from "../../_environments/ambient";
import { tap, finalize } from "rxjs/operators";

@Component({
   selector: "login-comp",
   templateUrl: "login.component.html",
})
export class LoginComponent implements OnInit {
   errMensaje = "";
   RELEASE = RELEASE_VERSION;
   loading = false;
   defaultOp = "AGENTE";

   constructor(
      private $route: Router,
      private $usuario: UsuarioService,
      private $agente: AgenteService
   ) {}

   ngOnInit() {}

   validar(usr: {
      usuario: string;
      password: string;
      tipo: string | ETipoUsuario;
   }) {
      this.loading = true;
      this.errMensaje = "";
      const tipo = ETipoUsuario[usr.tipo];

      // Validar usuario
      let validador$ = this.$usuario
         .validarUsuario(usr)
         .pipe(tap(() => this.$route.navigateByUrl("/dashboard")));

      if (tipo === ETipoUsuario.AGENTE) {
         validador$ = this.$agente
            .loginAgente(usr)
            .pipe(tap(() => this.$route.navigateByUrl("/agente")));
      }

      validador$.pipe(finalize(() => (this.loading = false))).subscribe(
         () => (this.errMensaje = "Un momento por favor..."),
         (err) => {
            console.log(err);
            if (!!err.data) {
               this.errMensaje = err.data;
            } else if (!!err.msj) {
               this.errMensaje = err.msj;
            } else {
               this.errMensaje = "Error de conexión, intente de nuevo.";
            }
         }
      );
   }
}

enum ETipoUsuario {
   ADMIN,
   AGENTE,
}
