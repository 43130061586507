import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManualComponent } from './manual/manual.component';
import { ProgresivoComponent } from './progresivo/progresivo.component';
import { VistaPreviaComponent } from './vista-previa/vista-previa.component';
import { MarcadorComponent } from './marcador.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DiscadorComponent } from './discador/discador.component';
import { PipesModule } from './pipes/pipes.module';
import { DialerService } from 'src/app/_services/dialer.service';

@NgModule({
    providers: [DialerService],
    declarations: [MarcadorComponent, ManualComponent, VistaPreviaComponent, ProgresivoComponent, DiscadorComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        PipesModule
    ],
    exports: [ MarcadorComponent ]
})
export class MarcadorModule { }
