export interface ILead {
   idlead: string;
   nombre?: string;
   agenteAsignado: string;
   estado: ILeadStatus;
   fuente: string;
   fuente_dst: string;
   fasePipeline: string;
   fecha: string;
   idcontacto?: string;
}

export enum ILeadStatus {
   UNASSIGNED = "UNASSIGNED",
   ASSIGNED = "ASSIGNED",
   ABANDON = "ABANDON",
}
