import {
   Component,
   OnInit,
   Input,
   OnChanges,
   SimpleChanges,
   SimpleChange,
   ViewChild,
   ElementRef,
   Output,
   EventEmitter,
} from "@angular/core";
import { DialerService } from "src/app/_services/dialer.service";
import { PaginationInstance } from "ngx-pagination";
import { Observable, from, of, concat, timer, throwError } from "rxjs";
import {
   ICampanaDialer,
   EEstadoCampanaDialer,
   ETipoCampanaDialer,
   IContactoReparto,
   EEstadoContactoDialer,
} from "src/app/_interfaces/dialer";
import { flatMap, reduce, retryWhen, catchError } from "rxjs/operators";
import { ICallStatus } from "src/app/_interfaces/callstatus";
import { IAgentState } from "src/app/_interfaces/monitor";

interface ISimpleChanges extends SimpleChanges {
   colas: ISimpleChange;
}

interface ISimpleChange extends SimpleChange {
   currentValue: string[];
   previousValue: string[];
}

@Component({
   selector: "app-vista-previa",
   templateUrl: "./vista-previa.component.html",
   styleUrls: ["./vista-previa.component.css"],
})
export class VistaPreviaComponent implements OnInit, OnChanges {
   errorLoading = null;
   dialerSetup: ICampanaDialer;
   campanas$: Observable<ICampanaDialer[]>;
   repartos$: Observable<IContactoReparto[]>;
   config: PaginationInstance = { id: "id", itemsPerPage: 6, currentPage: 1 };

   @Input() colas: string[] = [];
   @Input() agente: IAgentState;
   @Input() callStatus: ICallStatus;
   @Output() contacto = new EventEmitter<string>();

   @ViewChild("selCampana")
   selCampana: ElementRef;

   get isAgentAvailable() {
      return this.agente.estado.includes("IDLE");
   }

   constructor(private $dialer: DialerService) {}

   ngOnInit() {
      this.repartos$ = of([]);
   }

   ngOnChanges(cambios: ISimpleChanges) {
      const { colas } = cambios;
      if (!!colas) {
         const hashPrev = JSON.stringify(colas.previousValue);
         const hashCurr = JSON.stringify(colas.currentValue);
         if (hashPrev !== hashCurr && Array.isArray(colas.currentValue)) {
            this.cargarCampanas(colas.currentValue);
         }
      }
   }

   cargarCampanas(colas: string[]) {
      this.errorLoading = null;
      this.campanas$ = from(colas).pipe(
         flatMap((cola) =>
            this.$dialer.consultarCampanas(
               EEstadoCampanaDialer.EN_CURSO,
               ETipoCampanaDialer.MANUAL,
               cola
            )
         ),
         retryWhen((err) =>
            err.pipe(
               flatMap((_, index) =>
                  index <= 4 ? timer(1000 * index) : throwError(err)
               )
            )
         ),
         flatMap((data) => from(data)),
         // add every campain but remove which already are there.
         reduce(
            (acc, curr) => [
               ...acc.filter((c) => c.nombre !== curr.nombre),
               curr,
            ],
            [] as ICampanaDialer[]
         ),
         catchError((err) => {
            this.errorLoading = err;
            return of([]);
         })
      );
   }

   cargarReparto(idcampana: string, campanas?: ICampanaDialer[]) {
      if (!!idcampana) {
         this.repartos$ = concat(
            this.$dialer.cargarReparto(
               idcampana,
               this.agente.idagente,
               EEstadoContactoDialer.EN_CURSO
            ),
            this.$dialer.cargarReparto(
               idcampana,
               this.agente.idagente,
               EEstadoContactoDialer.REINTENTAR
            ),
            this.$dialer.cargarReparto(
               idcampana,
               this.agente.idagente,
               EEstadoContactoDialer.EN_ESPERA
            )
         ).pipe(reduce((acc, curr) => [...acc, ...curr]));
         this.dialerSetup = !!campanas
            ? campanas.find((c) => c.idcampana === idcampana)
            : undefined;
      }
   }

   cargarContacto(idContacto: string) {
      this.contacto.emit(idContacto);
   }

   recargar() {
      this.selCampana.nativeElement.value = "";
      this.repartos$ = of([]);
      this.cargarCampanas(this.colas);
   }

   actualizarReparto(
      { reparto, remove }: { reparto: IContactoReparto; remove?: boolean },
      repartos: IContactoReparto[],
      idcampana: string
   ) {
      of(reparto as IContactoReparto)
         .pipe(
            flatMap((item) =>
               this.$dialer.actualizarItemReparto(idcampana, item)
            )
         )
         .subscribe(
            (resultado) => {
               if (remove) {
                  this.repartos$ = of([
                     ...repartos.filter((r) => r.id !== resultado.id),
                  ]);
               } else {
                  const index = repartos.findIndex(
                     (r) => r.id === resultado.id
                  );
                  repartos[index] = resultado;
                  this.repartos$ = of([...repartos]);
               }
            },
            (err) => {
               console.error(err);
            }
         );
   }
}
