
import {throwError as observableThrowError,  Observable ,  of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { HeaderService } from './header.service';
import { switchMap, flatMap, tap, map, toArray, groupBy, mergeMap, catchError } from 'rxjs/operators';

export interface IEncuentaFrm {
    id: string, 
    nombre: string, 
    descripcion: string, 
    ext_directa: string
    preguntas?: IPreguntaEncuesta[]
}

export interface IPreguntaEncuesta {
    pregunta: string, 
    audio: string
    orden?: number,
    minimo: number,    
    maximo: number,    
}

export interface IPollResults {
    id: number; 
    uniqueid: string; 
    pollid: string; 
    cola: string; 
    agente: string;
    pregunta: string; 
    respuesta: number; 
    fecha: string; 
    origen: string;
    fuente: string;
}

@Injectable()
export class PollsService {

    constructor(private $http: HttpClient, private $headers: HeaderService) { }

    getEncuestas(): Observable<IEncuentaFrm[]> {
        return of('').pipe(
            switchMap(() => this.$http.get<IEncuentaFrm[]>(this.$headers.getAPIurl() + '/polls', { headers: this.$headers.getHttpHeaders() }))
        );
    }

    getEncuesta(encuesta: IEncuentaFrm): Observable<IEncuentaFrm> {
        return of(encuesta.id).pipe(
            switchMap(id => this.$http.get<IPreguntaEncuesta[]>(this.$headers.getAPIurl() + '/polls/' + id, { headers: this.$headers.getHeaders() })),
            map(preguntas => Object.assign({}, encuesta, {preguntas: preguntas})),
        );
    }
        
    guardarEncuenta(encuesta: IEncuentaFrm): Observable<boolean> {        
        return of(encuesta).pipe(
            map(poll => {
                poll.preguntas.forEach((pregunta, indx) => {
                    pregunta.orden = indx;
                    const [archivo, ext] = pregunta.audio.split('.');
                    if (!!ext) {
                        pregunta.audio = archivo
                    }
                })
                return poll;
            }),
            switchMap(poll => this.$http.post(this.$headers.getAPIurl() + '/polls', poll, { headers: this.$headers.getHeaders(), observe: 'response' })),
            map(res => res.status === 200)
        );
    }
    
    eliminarEncuesta(id: string): Observable<boolean> {
        return of(id).pipe(
            switchMap(idEncuesta => this.$http.delete( this.$headers.getAPIurl() + '/polls/' + idEncuesta, { headers: this.$headers.getHeaders(), observe: 'response' } )),
            map(res => res.status === 200)
        )
    }

    cargarResultados(queryFrm: object): Observable<Array<IPollResults[]>> {
        return of(queryFrm).pipe(
            switchMap(query => this.$http.post(this.$headers.getAPIurl() + '/polls/results', query, { headers: this.$headers.getHeaders(), responseType: 'text' }) ),
            map(res => JSON.parse(`[${res.substring(1)}]`) as IPollResults[]),
            // Agrupar por cola de llamadas
            flatMap(data => data),
            groupBy(pollResult => pollResult.cola),
            mergeMap(grupo => grupo.pipe(toArray<IPollResults>())),
            // Agrupados, crear un un areglo de los tres resultados.
            toArray()
        )
    }

    audioPollExiste(src: string): Observable<boolean> {
        return of(src).pipe(
            switchMap(query => this.$http.get(this.$headers.getAPIurl() + '/polls/audioExists/' + src, { headers: this.$headers.getHeaders(), observe: 'response' }) ),
            map(data => data.status === 200)
        )
    }
}
