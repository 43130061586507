<div class="panel panel-primary" style="margin-bottom: 10px" *ngIf="grupo">
    <div class="panel-heading">
        
        <span *ngIf="edicion === false">
            <div class="row" style="margin: 0px">
                <div class="col-xs-8" style="padding: 0">
                    <a href="javascript:void(0)" style="color: white" (click)="seleccion(grupo.idgrupo)">
                        <span style="font-weight: 600">#{{grupo?.idgrupo}} {{grupo?.nombre}}</span> 
                    </a>
                </div>
                <div class="col-xs-4 text-right"  style="padding: 0">
                    <a href="javascript:void(0)" style="color: white" (click)="eliminarGrupo(grupo)"><i class="fa fa-trash-o"></i></a>
                </div>
            </div>
        </span>
        <span *ngIf="edicion || edicion === undefined" ><i class="fa fa-edit"></i> Edición de Grupo</span>

    </div>
    <ul class="list-group" style="margin-bottom: 0">
        <form class="form-group form-group-sm" style="margin-bottom: 0" novalidate #f="ngForm">
             
            <li *ngIf="edicion || edicion === undefined" class="list-group-item" style="padding: 0; font-size: 13px">
                <div class="row" style="padding: 10px">
                    <div style="margin-bottom: 10px" class="col-xs-12">
                        <label>Nombre del grupo</label>
                        <input type="text" [(ngModel)]="grupo.nombre" name="nombre" class="form-control" required>
                    </div>            
                    <div class="col-xs-6">
                        <label>ID de Marcación</label>
                        <input type="number" [readonly]="edicion" [(ngModel)]="grupo.idgrupo" name="idgrupo" placeholder="100..." class="form-control" required>
                    </div>
                    <div class="col-xs-6" style="padding-top: 22px">
                        <button class="btn btn-sm btn-block btn-primary" [disabled]="f.invalid" (click)="guardar(f.value)">Guardar</button>
                    </div>
                </div>
                <input type="hidden" [(ngModel)]="grupo.miembros" name="miembros">
            </li>
        
            <li class="list-group-item" style="padding: 5px" *ngFor="let g of grupo.miembros">
                <div class="row" style="margin: 0px">
                    <div class="col-xs-9" style="padding: 5px">
                       <i class="fa fa-phone" style="margin-right: 6px"></i> Extensión {{g.exten}}
                    </div>
                    <div class="col-xs-3 text-right" style="padding: 5px">
                       <button class="btn btn-xs btn-info" [disabled]="edicion === false" (click)="removerMiembro(g.exten)">
                           <i class="fa fa-close"></i>
                       </button>
                    </div>
                </div>
            </li>
            
        </form>
    </ul>    
</div>