import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialerSetupComponent } from './dialer-setup.component';
import { RepartoComponent } from './reparto/reparto.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UIModule } from 'src/app/_extra/ui/ui.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { PorAgentePipe } from './reparto/pipes/por-agente.pipe';
import { CheckBoxPipe } from './reparto/pipes/check-box.pipe';
import { InCampainPipe } from './reparto/pipes/in-campain.pipe';
import { ResponseMessageComponent } from './response-message/response-message.component';

@NgModule({
    declarations: [
        DialerSetupComponent, 
        RepartoComponent, 
        ResponseMessageComponent, PorAgentePipe, CheckBoxPipe, InCampainPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        UIModule
    ]
})
export class DialerSetupModule { }
