import {
   Component,
   Input,
   OnChanges,
   SimpleChanges,
   SimpleChange,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
   selector: "ui-toggle",
   template:
      '<input type="checkbox" id="toggle_{{id}}" [ngModel]="value" [ngClass]="style" class="hidden"/><label for="toggle_{{id}}" class="lbl"></label>',
   providers: [
      { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ToggleComponent },
   ],
   styles: [
      `
         .lbl {
            position: relative;
            display: inline-block;
            height: 15px;
            width: 40px;
            background: #898989;
            border-radius: 100px;
            cursor: pointer;
            transition: all 0.3s ease;
         }

         .lbl:after {
            position: absolute;
            left: -2px;
            top: -3px;
            display: block;
            width: 21px;
            height: 21px;
            border-radius: 100px;
            background: #ddd;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
            content: "";
            transition: all 0.3s ease;
         }

         .lbl:active:after {
            transform: scale(1.15, 0.85);
         }

         .cbx:checked ~ label {
            background: #99bd99;
         }

         .cbx:checked ~ label:after {
            left: 20px;
            background: #5cb85c;
         }

         .cbx:disabled ~ label {
            background: #d5d5d5;
            pointer-events: none;
         }

         .cbx:disabled ~ label:after {
            background: #bcbdbc;
         }

         .hidden {
            display: none;
         }

         .cbx-success:checked ~ label {
            background: #99bd99;
         }
         .cbx-success:checked ~ label:after {
            left: 20px;
            background: #5cb85c;
         }

         .cbx-primary:checked ~ label {
            background: #7f9eb8;
         }
         .cbx-primary:checked ~ label:after {
            left: 20px;
            background: #337ab7;
         }
      `,
   ],
})
export class ToggleComponent implements OnChanges, ControlValueAccessor {
   value: boolean;
   id: string;

   private _style = "cbx-success";
   @Input() set style(v) {
      this._style = v || "cbx-success";
   }
   get style() {
      return this._style;
   }

   @Input() checked: boolean;
   private _onChange: (value: any) => void;

   constructor() {
      this.id = this.guid();
   }

   ngOnChanges(changes: ISimpleChanges) {
      if (changes._value !== undefined) {
         this.value = changes._value.currentValue;
      }
   }

   writeValue(value: any) {
      this.value = value;
   }

   registerOnChange(fn: (value: any) => void) {
      this._onChange = fn;
   }

   registerOnTouched() {}

   private guid() {
      function s4() {
         return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
      }
      return (
         s4() +
         s4() +
         "-" +
         s4() +
         "-" +
         s4() +
         "-" +
         s4() +
         "-" +
         s4() +
         s4() +
         s4()
      );
   }
}

interface ISimpleChanges extends SimpleChanges {
   _value: SimpleChange;
}
