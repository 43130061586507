import { Subscription, from, of } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
   ICall_logQuery,
   EQueryOperacion,
   ECallDisposicion,
   ICallLogQueryResult,
   EQueryType,
} from "../../_interfaces/informes";
import { PaginationInstance } from "ngx-pagination";
import * as _ from "lodash";
import * as moment from "moment";

import { HeaderService } from "../../_services/header.service";
import { FileHandlerService } from "../../_services/file.service";
import { SocketService, EComando } from "../../_services/socket.service";
import {
   map,
   toArray,
   tap,
   finalize,
   switchMap,
   flatMap,
   delay,
} from "rxjs/operators";

@Component({
   selector: "call_log",
   templateUrl: "call_log.component.html",
})
export class CallLogComponent implements OnInit {
   llamada: ICallLogQueryResult;
   llamadas: ICallLogQueryResult[];
   _q = EQueryType;
   o = EQueryOperacion;
   d = ECallDisposicion;

   queryParams: {
      fuente?: string;
      destino?: string;
      modoAgente?: string;
      contexto?: string;
   } = {
      fuente: this.o.EXACTO.toString(),
      destino: this.o.EXACTO.toString(),
      contexto: this.o.EXACTO.toString(),
   };

   private campo = "";

   public config: PaginationInstance = {
      id: "custom",
      itemsPerPage: 12,
      currentPage: 1,
   };

   constructor(
      private $http: HttpClient,
      private $header: HeaderService,
      private $monitor: SocketService,
      private $file: FileHandlerService
   ) {}

   ngOnInit() {}

   ejecutarBusqueda(_query: ICall_logQuery, qType: EQueryType) {
      $("#modalWait_kerberus").modal();
      const busqueda = _.cloneDeep(_query);
      busqueda.queryType = qType;

      if (busqueda.fuente.valor === "") {
         delete busqueda.fuente;
      }
      if (busqueda.destino.valor === "") {
         delete busqueda.destino;
      }
      if (busqueda.contexto.valor === "") {
         delete busqueda.contexto;
      }

      of(busqueda)
         .pipe(
            switchMap((query) =>
               this.$http.post(this.$header.getAPIurl() + "/call_log", query, {
                  headers: this.$header.getHeaders(),
                  responseType: "text",
               })
            ),
            map(
               (res) =>
                  JSON.parse(`[${res.substring(1)}]`) as
                     | ICallLogQueryResult[]
                     | { filename: string }
            )
         )
         .subscribe(
            (resultado) => {
               if (qType === EQueryType.LIST) {
                  this.campo = "";
                  resultado = _.sortBy(<ICallLogQueryResult[]>resultado, [
                     "calldate",
                  ]).reverse();
                  this.llamadas = <ICallLogQueryResult[]>resultado;
               } else {
                  const _res = <{ filename: string }>resultado;
                  window.open(_res.filename);
               }

               $("#modalWait_kerberus").modal("hide");
            },
            (err) => console.log(err)
         );
   }

   sortBy(campo: string) {
      if (this.campo !== campo) {
         this.llamadas = _.cloneDeep(_.sortBy(this.llamadas, [campo]));
      } else {
         this.llamadas = _.cloneDeep(this.llamadas.reverse());
      }
      this.campo = campo;
   }

   descargarCalllog(llamadas: ICallLogQueryResult[]) {
      const auxFecha = moment().set({ hours: 0, minutes: 0, seconds: 0 });
      // Crear cabeceras
      $("#modalWait_kerberus").modal();
      from(llamadas)
         .pipe(
            map((call) => ({
               CALLID: call.uniqueid,
               FECHA: moment(call.calldate).format("YYYY-MM-DD"),
               HORA: moment(call.calldate).format("HH:mm:ss"),
               CALLERID: call.clid,
               FUENTE: call.src,
               ["CANAL FUENTE"]: call.channel,
               DESTINO: call.dst,
               ["CANAL DESTINO"]: call.dstchannel,
               DISPOSICION: ECallDisposicion[call.disposition],
               CONTEXTO: call.dcontext,
               DURACION: _.cloneDeep(auxFecha)
                  .add(call.duration, "seconds")
                  .format("HH:mm:ss"),
               BILLSEC: _.cloneDeep(auxFecha)
                  .add(call.billsec, "seconds")
                  .format("HH:mm:ss"),
            })),
            toArray(),
            tap((data) =>
               this.$file.exportCSVFile(
                  Object.keys(data[0]),
                  data,
                  "CallLog_" + new Date().getTime()
               )
            ),
            finalize(() => $("#modalWait_kerberus").modal("hide"))
         )
         .subscribe();
   }

   escuchar(llamada: ICallLogQueryResult) {
      this.llamada = llamada;
      const audioSrc = llamada.userfield.split(";");
      let idLlamada = "";

      if (audioSrc.length > 0) {
         while (true) {
            idLlamada = audioSrc.shift();
            if (
               (!!idLlamada && idLlamada.length > 0) ||
               audioSrc.length === 0
            ) {
               break;
            }
         }
      } else {
         idLlamada = llamada.userfield;
      }

      $("#modalWait_kerberus").modal({
         backdrop: "static",
         keyboard: false,
      });

      of(idLlamada)
         .pipe(
            switchMap((id) =>
               this.$http.get(this.$header.getAPIurl() + "/audio/" + id, {
                  headers: this.$header.getHeaders(),
               })
            ),
            flatMap((response: { src: string }) => {
               if (response.src.indexOf(".gsm") > -1) {
                  return this.$monitor
                     .enviarComando({
                        comando: EComando.AUDIO_XFORM,
                        data: {
                           in: response.src,
                           out: response.src.replace(".gsm", ".mp3"),
                        },
                     })
                     .pipe(map(() => response.src.replace(".gsm", ".mp3")));
               } else {
                  return of(response.src);
               }
            }),
            tap(() => $("#modalWait_kerberus").modal("hide")),
            delay(800)
         )
         .subscribe(
            (src: string) => {
               this.llamada.audio = src;
               $("#modalAudioLog").modal({
                  backdrop: "static",
                  keyboard: false,
               });
            },
            (err) => {
               $("#modalWait_kerberus").modal("hide");
               console.log(err);
            }
         );
   }
}
