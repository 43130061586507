export interface IDBResponse {
    tipo: EDBResponseType, 
    data: string | number | Object[];
}

export enum EDBResponseType {    
    CONN_ERR,
    CONN_OK,
    AUTH_OK,
    AUTH_ERR,    
    QUERY_ERR,
    QUERY_OK,
    ERR,
    OK
}
