import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   SimpleChanges,
   SimpleChange,
   OnChanges,
} from "@angular/core";
import {
   IContactoReparto,
   EEstadoContactoDialer,
   EEstadoContacto,
   IContacto,
   ICampanaDialer,
   ETipoCampanaDialer,
} from "src/app/_interfaces/dialer";
import {
   SocketService,
   EComando,
   EResponse,
} from "src/app/_services/socket.service";
import { ICallStatus, ECallStatus } from "src/app/_interfaces/callstatus";
import { IAgentMonitor } from "src/app/_interfaces/_all";
import { of, throwError, timer } from "rxjs";
import {
   debounceTime,
   map,
   flatMap,
   takeUntil,
   defaultIfEmpty,
   delay,
   mapTo,
   mergeMap,
} from "rxjs/operators";
import { DialerService } from "src/app/_services/dialer.service";
import { IAgentStateMonitor } from "src/app/_interfaces/monitor";
import { INBOUND_SKILL, MIXED_SKILL } from "src/app/_interfaces/agente";
import { EOutgoingCallType } from "../entities/Outgoing.enum";
import { AgenteService } from "src/app/_services/agente.service";

interface ISimpleChanges extends SimpleChanges {
   callStatus: ISimpleChange;
}

interface ISimpleChange extends SimpleChange {
   currentValue: ICallStatus;
   previousValue: ICallStatus;
}

@Component({
   selector: "app-discador",
   templateUrl: "./discador.component.html",
   styleUrls: ["./discador.component.css"],
})
export class DiscadorComponent implements OnInit, OnChanges {
   // Stateless component
   @Input() disabled = false;
   @Input() agente: IAgentMonitor;
   @Input() callStatus: ICallStatus;
   @Input() reparto: IContactoReparto;
   @Input() dialerSetup: ICampanaDialer;

   @Output() contacto = new EventEmitter<string>();
   @Output() actualizarReparto = new EventEmitter<{
      reparto: IContactoReparto;
      remove?: boolean;
   }>();

   get reintentarEnum() {
      return EEstadoContactoDialer.REINTENTAR;
   }
   get progressEnum() {
      return EEstadoContactoDialer.EN_CURSO;
   }
   get successEnum() {
      return EEstadoContactoDialer.FINALIZADO;
   }
   get failedEnum() {
      return EEstadoContactoDialer.FALLIDO;
   }

   get isFailed() {
      return this.reparto.estado === EEstadoContactoDialer.FALLIDO;
   }
   get isSuccess() {
      return this.reparto.estado === EEstadoContactoDialer.FINALIZADO;
   }
   get isReintento() {
      return this.reparto.estado === EEstadoContactoDialer.REINTENTAR;
   }
   get isInProgress() {
      return this.reparto.estado === EEstadoContactoDialer.EN_CURSO;
   }
   get isRepartoCalling() {
      return this.$dialer.getRepartoState().id === this.reparto.id;
   }
   get hasRepartoState() {
      if (!!this.$dialer.getRepartoState().id) {
         return true;
      } else {
         return this.disabled;
      }
   }

   constructor(
      private $monitor: SocketService,
      private $agente: AgenteService,
      private $dialer: DialerService
   ) {}

   ngOnInit() {}

   /**
    * Función para detectar cambios en las llamadas
    * @param changes
    */
   ngOnChanges(changes: ISimpleChanges) {
      const { callStatus } = changes;
      if (!!callStatus && !callStatus.isFirstChange()) {
         const { currentValue, previousValue } = callStatus;
         if (
            currentValue.estado !== previousValue.estado &&
            this.isRepartoCalling
         ) {
            // console.log(callStatus.previousValue, callStatus.currentValue);

            const isCallSuccess =
               currentValue.estado === ECallStatus.CALLING ||
               currentValue.estado === ECallStatus.IN_CALL;
            if (previousValue.estado === ECallStatus.NO_CALL && isCallSuccess) {
               // actualizar a en_curso.
               const { numero } = currentValue;
               const contacto = {
                  numero: numero,
                  estado: EEstadoContacto.EN_CURSO,
               };
               const repartoActualizado = this.construirReparto(
                  this.reparto,
                  EEstadoContactoDialer.EN_CURSO,
                  contacto
               );

               this.actualizarReparto.emit({ reparto: repartoActualizado });
            } else if (
               previousValue.estado === ECallStatus.CALLING &&
               currentValue.estado === ECallStatus.IN_CALL
            ) {
               // Actualizar a en_llamada
               const { numero } = currentValue;
               const contacto = {
                  numero: numero,
                  estado: EEstadoContacto.EN_LLAMADA,
               };
               const repartoActualizado = this.construirReparto(
                  this.reparto,
                  EEstadoContactoDialer.EN_CURSO,
                  contacto
               );
               this.actualizarReparto.emit({ reparto: repartoActualizado });
            } else if (
               previousValue.estado === ECallStatus.IN_CALL &&
               currentValue.estado === ECallStatus.NO_CALL
            ) {
               // Actualizar a FINALIZADO
               const { numero } = previousValue;
               const contacto = {
                  numero: numero,
                  estado: EEstadoContacto.FINALIZADO,
               };
               // Verfificar si el reparto hay mas numeros sin conectar.

               const hasMoreContacts =
                  this.reparto.contacto.filter(
                     (c) => c.estado === EEstadoContacto.EN_ESPERA
                  ).length > 0;
               // Si el tipo de campaña es automática.
               const isAutomatic =
                  this.dialerSetup.tipo === ETipoCampanaDialer.PREDICTIVO;

               const repartoActualizado = this.construirReparto(
                  this.reparto,
                  hasMoreContacts && isAutomatic
                     ? EEstadoContactoDialer.EN_CURSO
                     : EEstadoContactoDialer.FINALIZADO,
                  contacto
               );

               this.$dialer.clearReaprtoState();
               this.actualizarReparto.emit({ reparto: repartoActualizado });
            } else if (
               previousValue.estado === ECallStatus.CALLING &&
               currentValue.estado === ECallStatus.NO_CALL
            ) {
               // Actualizar a FALLIDA
               const { numero } = previousValue;
               this.actualizarEstadoFallido(numero);
            }
         }
      }
   }

   ejecutarLlamadaSaliente(
      { numero },
      queueMembership = this.dialerSetup.cola,
      outboundCallType = EOutgoingCallType.DIALER_PREVIEW
   ) {
      this.$dialer.setRepartoState(this.reparto);
      const agentState = this.agente.states.find(
         (st) => st.queuename === queueMembership
      );
      const isAgentOutboundOrMixed =
         agentState.penalty < 0 || agentState.penalty > INBOUND_SKILL.MAX;

      const canalDeAgente = `Local/${this.agente.idagente}@agent${
         agentState.modalidad_agente.includes("RINGBACK") ? "Callback/n" : ""
      }`;

      of({ numero, queueMembership, agentState })
         .pipe(
            debounceTime(600),
            mergeMap((evento) =>
               isAgentOutboundOrMixed
                  ? of(evento)
                  : this.$agente
                       .cambiarSkill(
                          this.agente.idagente,
                          evento.agentState.modalidad_agente,
                          -1,
                          evento.queueMembership
                       )
                       .pipe(
                          mapTo(evento),
                          delay(1200)
                          // Si falla??
                       )
            ),
            // formatear
            map(({ numero, queueMembership }) => ({
               numero,
               queueMembership,
               agentInterface: canalDeAgente,
               fuente: outboundCallType,
               idcontacto: this.reparto.idContacto,
               ruta: `${this.dialerSetup.troncal}/${
                  this.dialerSetup.prefijo || ""
               }`,
            })),
            mergeMap((request) => this.$agente.lanzarLlamada(request))
         )
         .subscribe(
            (commandResponse) => {
               console.log(commandResponse);
            },
            (err) => {
               this.actualizarEstadoFallido(err.numero);
               console.error(err);
            }
         );
   }

   cargarContacto() {
      this.contacto.emit(this.reparto.idContacto);
   }

   actualizar(estado: EEstadoContactoDialer) {
      if (confirm(`Esta seguro de actualizar este registro a ${estado}?`)) {
         // remover todos excepto los que sean de reintentar
         const remove = estado !== EEstadoContactoDialer.REINTENTAR;
         this.actualizarReparto.emit({
            reparto: { ...this.reparto, estado: estado },
            remove: remove,
         });
      }
   }

   private actualizarEstadoFallido(numero: string) {
      // Actualizar a FALLIDA
      const contacto = { numero: numero, estado: EEstadoContacto.FALLIDO };

      // Verfificar si el reparto hay mas numeros sin conectar.
      let estadoDelContacto: EEstadoContactoDialer;
      const hasMoreContacts =
         this.reparto.contacto.filter(
            (c) => c.estado === EEstadoContacto.EN_ESPERA
         ).length > 0;
      const allNumbersFailed =
         this.reparto.contacto.filter(
            (c) => c.estado === EEstadoContacto.FALLIDO
         ).length ===
         this.reparto.contacto.length - 1;

      if (hasMoreContacts) {
         estadoDelContacto = EEstadoContactoDialer.EN_CURSO;
      } else if (!hasMoreContacts && allNumbersFailed) {
         estadoDelContacto = EEstadoContactoDialer.FALLIDO;
      } else if (!hasMoreContacts && !allNumbersFailed) {
         estadoDelContacto = EEstadoContactoDialer.FINALIZADO;
      }
      const repartoActualizado = this.construirReparto(
         this.reparto,
         estadoDelContacto,
         contacto
      );

      this.$dialer.clearReaprtoState();
      this.actualizarReparto.emit({ reparto: repartoActualizado });
   }

   private construirReparto(
      actual: IContactoReparto,
      estadoReparto: EEstadoContactoDialer,
      contacto: IContacto
   ): IContactoReparto {
      const contactos = [
         ...actual.contacto.filter((c) => c.numero !== contacto.numero),
         contacto,
      ];
      return {
         ...actual,
         estado: estadoReparto,
         contacto: contactos,
      };
   }
}
