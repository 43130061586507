import { Pipe, PipeTransform } from '@angular/core';
import { IQueueStats } from 'src/app/_interfaces/monitor';

@Pipe({
    name: 'outProm'
})
export class OutboundAveragePipe implements PipeTransform {
    transform(stat: IQueueStats): any {
        if ((stat.outbound) !== 0) {
            return stat.talkOutbound / stat.outbound;
        }
        return 0;
    }
}
