import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
   selector: "pregunta-poll",
   templateUrl: "pregunta.component.html",
})
export class PreguntaComponent implements OnInit {
   private audioControl = new Audio();

   @Input()
   preguntaFrm: FormGroup;

   @Output() audio = new EventEmitter<string>();

   constructor() {}

   ngOnInit() {}

   seleccionarAudio(): void {
      this.audio.emit();
      $("#audio_polls").click();
   }

   play(src: string) {
      try {
         this.audioControl.src = "polls/" + src + ".wav";
         this.audioControl.play();
      } catch (e) {
         console.log("Primero debes subir el audio para poder escuchalo");
      }
   }

   stop() {
      this.audioControl.pause();
      this.audioControl.currentTime = 0;
   }
}
