import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
   selector: "app-fase",
   templateUrl: "./fase.component.html",
   styleUrls: ["./fase.component.css"],
})
export class FaseComponent implements OnInit {
   @Input()
   fase: FormGroup;

   @Input()
   index: number;

   @Input()
   indexInProps: number;

   @Output()
   delRequest = new EventEmitter<number>();

   @Output()
   loadPropsRequest = new EventEmitter<number>();

   get shouldBeDisabled() {
      return this.index === this.indexInProps;
   }

   constructor() {}

   ngOnInit(): void {}

   remover() {
      this.delRequest.emit(this.index);
   }

   loadProps() {
      this.loadPropsRequest.emit(this.index);
   }
}
