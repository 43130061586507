import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
   selector: "app-fase-props",
   templateUrl: "./fase-props.component.html",
   styleUrls: ["./fase-props.component.css"],
})
export class FasePropsComponent implements OnInit {
   private _propsForm = this.$fb.group({
      index: ["", Validators.required],
      name: ["", Validators.required],
      props: this.$fb.array([]),
   });

   get propsForm() {
      return this._propsForm;
   }

   @Input() set propsForm(val: FormGroup) {
      if (val!!) {
         this._propsForm = val;
      }
   }

   @Output()
   camposRequest = new EventEmitter<{
      index: number;
      props: FormArray;
   }>();

   get propName() {
      return this.propsForm.get("nombre").value;
   }

   get indexProp() {
      return this.propsForm.get("index").value;
   }

   get propsArray() {
      return this.propsForm.get("props") as FormArray;
   }

   constructor(private $fb: FormBuilder) {}

   agregarCampo() {
      const newProp = this.$fb.group({
         campo: ["", Validators.required],
         tipo: ["", Validators.required],
         requerido: [""],
      });

      newProp.patchValue({
         requerido: false,
      });

      this.propsArray.push(newProp);
   }

   ngOnInit(): void {}

   remover(index: number) {
      this.propsArray.removeAt(index);
   }

   finalizar() {
      this.camposRequest.emit({
         index: this.indexProp,
         props: this.propsArray,
      });
   }
}
