import {
   Component,
   Input,
   OnChanges,
   OnInit,
   SimpleChange,
   SimpleChanges,
} from "@angular/core";
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from "@angular/forms";
import { Observable, Subject, of } from "rxjs";
import { debounceTime, finalize, map, switchMap, tap } from "rxjs/operators";
import { ILead } from "src/app/_interfaces/lead.entity";
import { IAgentStateChannel } from "src/app/_interfaces/monitor";
import { AgenteService } from "src/app/_services/agente.service";
import { LeadService } from "src/app/_services/lead.service";
import { PipelineService } from "src/app/_services/pipeline.service";

interface ISimpleChanges extends SimpleChanges {
   channelSnapshot: IAgentChannelChange;
   tipificacion: ITipificacionChange;
}

interface IAgentChannelChange extends SimpleChange {
   previousValue: IAgentStateChannel;
   currentValue: IAgentStateChannel;
}

interface ITipificacionChange extends SimpleChange {
   previousValue: { id: string; channelid: string };
   currentValue: { id: string; channelid: string };
}

@Component({
   selector: "app-startpipeline",
   templateUrl: "./startpipeline.component.html",
   styleUrls: ["./startpipeline.component.css"],
})
export class StartpipelineComponent implements OnInit, OnChanges {
   @Input()
   channelSnapshot: IAgentStateChannel;

   @Input()
   tipificacion: { id: string; channelid: string };

   pipes$ = this.$pipe.getPipelines().pipe(
      map((pipes) => {
         return [
            ...pipes.filter((p) => p.avance >= 0),
            ...pipes.filter((p) => p.avance < 0),
         ];
      })
   );

   leadInitiator$ = new Subject<string>();
   leadBH$: Observable<ILead[]>;
   leadBH = [] as ILead[];

   contactoBH = undefined;
   tipificacionBH = undefined;
   channelBH: IAgentStateChannel;

   frmInitPipe: FormGroup;
   leadAIniciar = new FormControl("");
   leadAIniciar$: Observable<string>;

   saving = false;

   constructor(
      private $lead: LeadService,
      private $pipe: PipelineService,
      private $fb: FormBuilder,
      private $agent: AgenteService
   ) {}

   ngOnInit(): void {
      this.leadBH$ = this.leadInitiator$.pipe(
         debounceTime(400),
         switchMap((callerid) => this.$lead.getLeadByCallerID(callerid)),
         // TODO: Filtrar por agente
         tap(
            (leads) => (this.leadBH = [...leads.filter((l) => !l.fasePipeline)])
         )
      );

      this.frmInitPipe = this.$fb.group({
         leadid: new FormControl("", [Validators.required]),
         faseid: new FormControl("", [Validators.required]),
         nombre: new FormControl(""),
         notas: new FormControl("", [Validators.required]),
      });

      this.leadAIniciar$ = this.leadAIniciar.valueChanges.pipe(
         debounceTime(300),
         tap((value) =>
            this.frmInitPipe.patchValue({
               leadid: value,
            })
         ),
         tap((d) => console.log(d))
      );
   }

   ngOnChanges(changes: ISimpleChanges): void {
      // Proceso para obtener contact_id a pesar de que la llamada haya terminado.
      if (!!changes.channelSnapshot && !!changes.channelSnapshot.currentValue) {
         const { currentValue, previousValue } = changes.channelSnapshot;
         // Si es primer llamada
         if (changes.channelSnapshot.isFirstChange()) {
            if (!!currentValue.contact_id) {
               this.channelBH = { ...currentValue };
            }
         } else if (!!previousValue) {
            if (
               currentValue.channelid === previousValue.channelid &&
               !!currentValue.contact_id
            ) {
               this.channelBH = { ...currentValue };
               this.contactoBH = currentValue.contact_id;
            } else if (currentValue.channelid !== previousValue.channelid) {
               this.channelBH = { ...currentValue };
               this.contactoBH = currentValue?.contact_id || "";
            }
         }

         // Iniciar busqueda del lead
         this.leadInitiator$.next(
            changes.channelSnapshot.currentValue.callerid.number
         );
      }

      if (
         !!changes.tipificacion &&
         !!changes.tipificacion.currentValue &&
         !!this.channelBH
      ) {
         this.tipificacionBH = changes.tipificacion.currentValue.id;
      }
   }

   iniciarLeadInPipeline(data: {
      leadid: string;
      faseid: string;
      nombre?: string;
      notas: string;
   }) {
      // 1 ro actualizar el lead.
      this.saving = true;
      of(this.leadBH.find((l) => l.idlead === data.leadid))
         .pipe(
            map((lead) => ({
               ...lead,
               fasePipeline: data.faseid,
               nombre: data?.nombre,
               idcontacto: this.contactoBH,
            })),
            switchMap((lead) =>
               this.$lead
                  .updateLead(lead)
                  .pipe(
                     switchMap(() =>
                        this.$lead.agregarActividad(
                           { Notas: data.notas },
                           lead.agenteAsignado,
                           lead.idlead
                        )
                     )
                  )
            ),
            finalize(() => {
               this.saving = false;
               this.leadInitiator$.next(undefined);
            })
         )
         .subscribe(
            (_) => {},
            (err) => console.error(err)
         );
   }
}
