<div class="row">
   <div class="col-md-3">
      <small>
         <div class="row">
            <div class="col-xs-6">
               <a href="javascript:void(0)" (click)="regresar()">
                  <i class="fa fa-angle-left" aria-hidden="true"></i> Regresar
               </a>
            </div>
            <div class="col-xs-6 text-right">
               <a href="javascript:void(0)" (click)="confirmarElimminar()">
                  <i class="fa fa-trash-o" aria-hidden="true"></i> Eliminar lead
               </a>
            </div>
         </div>
      </small>
      <ng-container *ngIf="lead$ | async as lead; else loadingLead">
         <!--Nombre del lead-->
         <h4 *ngIf="!nombreEnEdicion; else enEdicion">
            {{lead.nombre || lead.fuente}}
            <a href="javascript:void(0)" (click)="editarNombre()" title="Editar nombre">
               <i class="fa fa-edit" aria-hidden="true"></i>
            </a>
         </h4>

         <ng-template #enEdicion>
            <div class="row" style="margin-top: 5px;">
               <div class="col-xs-12">
                  <label for="enEdicionLabel">Nombre de lead</label>
               </div>
               <div class="col-xs-9" style="padding-right: 0;">
                  <input type="text" required class="form-control" name="enEdicionLabel" [value]="lead.nombre"
                     [formControl]="nombreLead" placeholder="Nombre a asignar..." style="margin-bottom: 10px;">
               </div>
               <div class="col-xs-3">
                  <button type="button" class="btn btn-success btn-block" [disabled]="nombreLead.invalid"
                     (click)="guardarNombre(nombreLead.value, lead)">
                     <i class=" fa fa-check-circle-o" aria-hidden="true"></i>
                  </button>
               </div>
            </div>
         </ng-template>
         <!---->

         Etapa: <span style="color: rgb(112, 112, 112);"> <strong>{{etapa$ | async}}</strong></span>

         <hr style="margin-top: 10px; margin-bottom: 10px;">
         <div style="margin-bottom: 10px;">
            <small style="color: rgb(72, 111, 143);">Fuente: </small><br>
            {{lead.fuente}}
         </div>

         <div style="margin-bottom: 10px;">
            <small style="color: rgb(72, 111, 143);">Propietario: </small><br>
            {{(propietario$ | async) || lead.agenteAsignado}}
         </div>

         <div style="margin-bottom: 10px; ">
            <small style="color: rgb(72, 111, 143);">Fecha de creación: </small><br>
            {{lead.fecha | amDateFormat: 'dddd D [de] MMMM, h:mm a'}}
         </div>

         <div style="margin-bottom: 10px; word-wrap: break-word; white-space: pre-wrap;">
            <small style="color: rgb(72, 111, 143);">Detalles: </small><br>
            <ng-container *ngIf="leadDetails$ | async as detalles; else normal">
               <div [innerHTML]="detalles"></div>
            </ng-container>
            <ng-template #normal>
               {{lead.fuente_dst}}
            </ng-template>
         </div>
      </ng-container>
      <ng-template #loadingLead>
         <div class="text-center" style="margin-top: 40px;">
            <i class="fa fa-spinner fa-spin fa-3x text-primary" aria-hidden="true"></i> <br><br>
            <span class="text-muted">Un momento... </span>
         </div>
      </ng-template>
      <hr>
      <div class="panel panel-info" style="font-size: 13px;">
         <div class="panel-heading">
            <span style="font-weight: 600; font-size: 14px;">Propiedades de la fase</span>
         </div>
         <div class="panel-body">
            <ng-container *ngIf="phase$ | async as faseDetalles; else cargadoFase">

               <!--FRM Fases-->
               <form #frmCampos="ngForm" novalidate autocomplete="off">
                  <ng-container *ngFor="let props of faseDetalles.campos">
                     <label [htmlFor]="props.campo">
                        {{props.campo}}
                     </label>
                     <div [ngSwitch]="props.tipo" style="margin-bottom: 10px;">
                        <div *ngSwitchCase="'text'">
                           <input type="text" class="form-control" placeholder="..." [id]="props.campo"
                              [name]="props.campo" [required]="props.requerido" ngModel>
                        </div>
                        <div *ngSwitchCase="'date'">
                           <input type="text" ngDatePicker ngModel [name]="props.campo" [required]="props.requerido">
                        </div>
                        <div *ngSwitchCase="'number'">
                           <input type="number" class="form-control" [id]="props.campo" [name]="props.campo"
                              [required]="props.requerido" ngModel>
                        </div>
                     </div>
                  </ng-container>
                  <ng-container *ngIf="faseDetalles.campos.length > 0">
                     <hr>
                     <div class="text-right">
                        <button type="button" class="btn btn-success"
                           [disabled]="frmCampos.invalid || saving || savingNotes"
                           (click)="guardarLog(frmCampos.value); frmCampos.reset()">

                           <i class="fa fa-plus-circle" aria-hidden="true" *ngIf="!saving"></i>
                           <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="saving"></i>
                           Anexar
                        </button>
                     </div>
                  </ng-container>
               </form>
               <!---->
            </ng-container>
            <ng-template #cargadoFase>
               <div class="text-center text-primary" style="padding-top: 40px; margin-bottom: 4px;">
                  <i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
               </div>
            </ng-template>
         </div>
      </div>
   </div>
   <!-- ACTIVIDAD -->
   <div class="col-md-5 actividad">
      <div style="margin-bottom: 10px;">
         <small>Actividad</small>
      </div>

      <ng-container *ngIf="(leadLog$ | async) as leadLogs; else loadingLog">
         <div class="panel panel-info" *ngFor="let log of leadLogs">
            <div class="panel-body" style="color: rgb(83, 83, 83);">
               <div style="text-align: right;  font-size: 12px;">
                  {{log.fecha | amDateFormat: 'dddd D [de] MMMM, h:mm a'}}
               </div>
               <!--actividad-->
               <div *ngIf="!!log.actividad" [innerHTML]="log.actividad" style="font-size: 13px;"></div>
               <!--id tipifcación-->
               <div *ngIf="!!log.idTipificacion" [innerHTML]="log.actividad"></div>

               <div style="text-align: right;  font-size: 12px;">Autor: {{log.idAutor}}</div>
            </div>
         </div>
      </ng-container>
      <ng-template #loadingLog>
         ...
      </ng-template>

      <div class="panel panel-info">
         <div class="panel-body" style="font-size: 13px;">
            <div style="color: rgb(72, 111, 143);">
               <label for="notas">
                  <strong>Notas</strong></label>
            </div>
            <hr style="margin-top: 10px; margin-bottom: 10px;">
            <div class="row">
               <div class="col-sm-9">
                  <input type="text" class="form-control" name="notas" id="notas" [formControl]="notas">

               </div>
               <div class="col-sm-3" style="padding-left: 0;">
                  <button type="button" class="btn btn-info btn-block" [disabled]="saving || notas.invalid"
                     (click)="guardarNotas(notas.value)">
                     <i class="fa fa-save" aria-hidden="true" *ngIf="!saving"></i>
                     <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="saving"></i>
                     Guardar
                  </button>
               </div>
            </div>
         </div>
      </div>

   </div>
   <!---->
   <div class="col-md-4">
      <div class="panel panel-primary">
         <div class="panel-heading">
            <h3 class="panel-title">Detalles del contacto</h3>
         </div>
         <div class="panel-body" style="line-height: 25px; color: rgb(94, 94, 94);">
            <ng-container *ngIf="contacto$ | async as contacto; else cargadoContacto">
               <div [innerHTML]="contacto"></div>
            </ng-container>
            <ng-template #cargadoContacto>
               <div class="text-center text-primary" style="padding-top: 40px; margin-bottom: 40px;">
                  <i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i>
               </div>
            </ng-template>
         </div>
      </div>
   </div>
</div>

<div class="modal fade" id="eliminar" tabindex="-1" role="dialog" aria-labelledby="eliminar">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-body">
            Deseas eliminar este proceso en el pipeline?<br>
            <small>Se eliminará del pipe más no se eliminará el lead.</small>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-default btn-sm" data-dismiss="modal"
               [disabled]="saving">Cancelar</button>&nbsp;
            <button type="button" class="btn btn-warning btn-sm" (click)="eliminar()" [disabled]="saving">
               <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf="saving"></i>
               <i class="fa fa-times" aria-hidden="true" *ngIf="!saving"></i>
               Eliminar</button>
         </div>
      </div>
   </div>
</div>