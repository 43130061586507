<div class="row" style="padding: 10px">
    <div class="col-md-3">
        <div class="bs-callout bs-callout-success text-justify">
            <h5>IVR & DID's</h5>
            En esta sección podrás crear IVR con cada DID en tu sistema de telefonía, establecer reglas y subir audios.
            <hr style="margin-bottom: 10px; margin-top: 10px">
            <div class="text-right">
                <button type="button" class="btn btn-sm btn-success" (click)="crearNuevoDID()"><i class="fa fa-plus"></i> Crear nuevo DID</button>
            </div>
        </div>
        <div class="panel panel-primary" style="max-height: 600px; overflow-y: auto; overflow-x: hidden">
            <div class="panel-heading">
                <i class="fa fa-random"></i> DID's Disponibles
            </div>
            <ul class="list-group">
                <a href="javascript: void(0)" (click)="cargarDid(d.did)" style="padding: 5px" class="list-group-item" 
                   [ngClass]="{'list-group-item-info': ivrEdicion.did === d.did}"
                   *ngFor="let d of (ivrs$ | async)">
                   <i class="fa fa-arrow-circle-o-right" style="margin-right: 5px; margin-left: 5px" ></i> 
                    {{d.did}} <span style="font-size: 13px" [ngClass]="{'text-muted': ivrEdicion.did !== d.did}" >{{d.nombre}}</span>
                </a>
            </ul>
        </div>
        
    </div>
    <div class="col-md-9">
        <did-tag [ivr]="ivrEdicion" [readOnly]="readOnly" (ivrEvento)="guardarIVR($event)"></did-tag>
    </div>
</div>