<form autocomplete="off" [formGroup]="fase">
   <!-- PhaseID -->
   <input type="hidden" formControlName="id" #phaseID>
   <!---->
   <div class="row" style="margin-bottom: 15px;">
      <div class="col-xs-1 text-center" [style.color]="shouldBeDisabled ? 'red' : ''">
         <i class="fa fa-list-ul" aria-hidden="true" style="cursor: move; padding-top: 12px;"></i>
      </div>
      <div class="col-xs-5">
         <input class="form-control" formControlName="nombre" name="descripcion" placeholder="Descripción de la fase..."
            [readOnly]="shouldBeDisabled">
      </div>
      <div class="col-xs-3 col-sm-3 col-md-2 col-lg-3">
         <select class="form-control text-center" formControlName="avance" name="avance">
            <option value="">%</option>
            <option value="10">10 %</option>
            <option value="20">20 %</option>
            <option value="30">30 %</option>
            <option value="40">40 %</option>
            <option value="50">50 %</option>
            <option value="60">60 %</option>
            <option value="70">70 %</option>
            <option value="80">80 %</option>
            <option value="90">90 %</option>
            <option value="100">Alcanzado</option>
            <option value="-1">Fallido</option>
         </select>

      </div>
      <div class="col-sm-3 col-md-4 col-lg-3 hidden-sm hidden-xs text-right">
         <button type="button" class="btn btn-info" (click)="loadProps()" [disabled]="fase.invalid">
            <i class="fa fa-wpforms" aria-hidden="true"></i> Props.
         </button> &nbsp;
         <button type="button" class="btn btn-default" (click)="remover()">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
         </button>
      </div>
      <div class="col-sm-2 col-xs-3 hidden-md hidden-lg text-right">
         <button type="button" class="btn btn-info">
            <i class="fa fa-wpforms" aria-hidden="true"></i></button> &nbsp;
         <button type="button" class="btn btn-default"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
      </div>
   </div>
</form>