import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToFechaPipe, TextLimitPipe, Upper } from "./tipificaciones.component";
import { ResumentTipificacionComponent } from "./resumen.component";
import { FormularioComponent } from "./formulario.component";
import { UIModule } from "../../_extra/ui/ui.module";

import { DatePickerModule } from "../../_extra/datepicker.module";
import { NgxPaginationModule } from "ngx-pagination";
import { ChartModule } from "angular2-highcharts";
import * as highcharts from "highcharts";
import { ResultadosTipificacionComponent } from "./resultados-tipificacion/resultados-tipificacion.component";
import { TablaTipificacionComponent } from "./tabla-tipificacion/tabla-tipificacion.component";
import { GraficosTipificacionComponent } from "./graficos-tipificacion/graficos-tipificacion.component";
import { InCallPipe } from "./pipes/in-call.pipe";
import { PreguntasPipe } from "./pipes/preguntas.pipe";
import { RespuestasPipe } from "./pipes/respuestas.pipe";
import { CamposContactoPipe } from "./pipes/campos-contacto.pipe";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ArbolComponent } from "./arbol/arbol.component";
import { ExisteOtroPipe } from "./pipes/existe-otro.pipe";

@NgModule({
   imports: [
      CommonModule,
      BrowserModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      ChartModule.forRoot(highcharts),
      DatePickerModule,
      NgxPaginationModule,
      UIModule,
   ],
   declarations: [
      ToFechaPipe,
      TextLimitPipe,
      Upper,
      ResumentTipificacionComponent,
      FormularioComponent,
      ResultadosTipificacionComponent,
      TablaTipificacionComponent,
      GraficosTipificacionComponent,
      InCallPipe,
      PreguntasPipe,
      RespuestasPipe,
      CamposContactoPipe,
      ArbolComponent,
      ExisteOtroPipe,
   ],
   exports: [
      ToFechaPipe,
      TextLimitPipe,
      Upper,
      ResumentTipificacionComponent,
      FormularioComponent,
   ],
})
export class TipificacionModule {}
