<form novalidate autocomplete="off" [formGroup]="formulario">
   <input type="hidden" #frm_id formControlName="id">
   <input type="hidden" formControlName="fecha">
   <div style="padding-bottom: 10px">
      <input type="text" style="font-weight: 600; font-size: 16px" class="text-input" formControlName="titulo"
         placeholder="Título para éste formulario">
   </div>
   <div style="padding-bottom: 10px">
      <textarea class="text-input" formControlName="descripcion" placeholder="Descripción del formulario"
         style="font-size: 13px"></textarea>
   </div>
   <div class="row" style="padding-bottom: 10px">
      <div class="col-sm-8">
         <auto-search [styleClass]="'text-input-dotted'" [style]="'12px'" [header]="'Colas disponibles'"
            [opciones]="queueList" formControlName="colas"></auto-search>
         <span class="text-muted" style="font-size: 11px; padding-left: 10px"
            *ngIf="formulario.controls.colas.value.length === 0">
            * Este formulario será visible para todos los agentes.
         </span>
         <small *ngIf="colasControl.errors" class="text-danger" style="font-weight: 600;">
            La lista de campañas es de máximo 30.<br>Actualmente tienes {{colasControl.errors.currentLenght}} de más.
         </small>
      </div>
      <div class="col-sm-4 text-right" style="padding-top: 5px">
         <div class="dropdown">
            <button type="button" class="btn btn-xs" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
               Agregar pregunta <i class="fa fa-caret-down"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1" style="font-size: 13px">
               <li><a href="javascript:void(0)" (click)="addPregunta('ABIERTA')"><i
                        class="fa fa-text-width text-muted"></i> Respuesta abierta</a></li>
               <li><a href="javascript:void(0)" (click)="addPregunta('NUMERO')"><i class="fa fa-hashtag text-muted"></i>
                     Respuesta numerica</a></li>
               <li><a href="javascript:void(0)" (click)="addPregunta()"><i class="fa fa-dot-circle-o text-muted"></i>
                     Selección múltiple</a></li>
               <li><a href="javascript:void(0)" (click)="addPregunta('FECHA')"><i
                        class="fa fa-calendar-check-o text-muted"></i> Respuesta con fecha</a></li>
            </ul>
         </div>
      </div>
   </div>

   <div formArrayName="pregunta">
      <div class="panel" style="background-color: #f7f7f7; border: 1px solid #f2f2f2"
         *ngFor="let p of frmPreguntas.controls; let i = index">
         <div [formGroupName]="i" class="panel-body">
            <app-arbol [frmPregunta]="p" [ordenPregunta]="i" (eliminarPregunta)="delPregunta($event)"></app-arbol>
         </div>
      </div>
   </div>
</form>