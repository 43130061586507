import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { SocketService } from "../_services/socket.service";
import { AgenteService } from "../_services/agente.service";
import { QueueService } from "../_services/queue.service";
import { GruposService } from "../_services/grupo.service";
import { PeerService } from "../_services/peer.service";
import { IVRService } from "../_services/ivr.service";
import { TroncalService } from "../_services/troncal.service";
import { NetworkService } from "../_services/network.service";
import { PollsService } from "../_services/polls.service";

import { AdminMenuComponent } from "./adminmenu/adminMenu.component";
import {
   TroncalSetupComponent,
   StartsWithLettersValidator,
} from "./troncal/troncal.component";
import { UsuarioComponent } from "./usuarios/usuarios.component";
import { TipificacionesComponent } from "./tipificacion/tipificaciones.component";
import { GrupoComponent } from "./grupos/grupo.component";
import { GruposComponent } from "./grupos/grupos.component";

import { SistemaComponent } from "./sistema/sistema.component";
import { TipificacionModule } from "./tipificacion/tipificacion.module";
import { FileUploadModule } from "ng2-file-upload";
import { ChartModule } from "angular2-highcharts";
import { MomentModule } from "ngx-moment";
import { PipesModule } from "../pipes/pipes.module";

import { UIModule } from "../_extra/ui/ui.module";
import { PollsModule } from "./polls/polls.module";
import { PollsComponent } from "./polls/polls.component";
import { ConsolaComponent } from "./consola-ext/consola-ext.component";
import { ConsolaExtModule } from "./consola-ext/consola-ext.module";
import { ConsolaCallModule } from "./consola_call/consola_call.module";
import { CallbackComponent } from "./callback/callback.component";
import { CallbackModule } from "./callback/callback.module";
import { RouterModule } from "@angular/router";
import { ApiManagerModule } from "./apimanager/apimanager.module";
import { ApiManagerComponent } from "./apimanager/apimanager.component";
import { DialerModule } from "./dialer/dialer.module";
import { NgxPaginationModule } from "ngx-pagination";
import { SmsModule } from "./sms/sms.module";
import { SmsComponent } from "./sms/sms.component";
import { SMSService } from "../_services/sms.service";
import { AdminGuard } from "./admin.guard";
import { AdminComponent } from "./admin.component";
import { DirectorioModule } from "./directorio/directorio.module";
import { AgenteModule } from "./agente/agente.module";
import { ScheduleModule } from "./schedule/schedule.module";
import { ScheduleService } from "../_services/schedule.service";
import { MarketingModule } from "./marketing/marketing.module";

@NgModule({
   providers: [
      AdminGuard,
      SocketService,
      AgenteService,
      QueueService,
      GruposService,
      PeerService,
      IVRService,
      TroncalService,
      NetworkService,
      PollsService,
      SMSService,
      ScheduleService,
   ],
   imports: [
      RouterModule,
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      FileUploadModule,
      ChartModule,
      UIModule,
      ConsolaCallModule,
      ConsolaExtModule,
      DialerModule,
      CallbackModule,
      TipificacionModule,
      PollsModule,
      SmsModule,
      ApiManagerModule,
      DirectorioModule,
      ScheduleModule,
      AgenteModule,
      PipesModule,
      MomentModule,
      NgxPaginationModule,
      MarketingModule,
   ],
   declarations: [
      AdminMenuComponent,
      ConsolaComponent,
      GrupoComponent,
      GruposComponent,
      TroncalSetupComponent,
      StartsWithLettersValidator,
      UsuarioComponent,
      CallbackComponent,
      SmsComponent,
      SistemaComponent,
      ApiManagerComponent,
      TipificacionesComponent,
      PollsComponent,
      AdminComponent,
   ],
   exports: [
      AdminMenuComponent,
      ConsolaComponent,
      GrupoComponent,
      GruposComponent,
      TroncalSetupComponent,
      StartsWithLettersValidator,
      CallbackComponent,
      UsuarioComponent,
      SistemaComponent,
      SmsComponent,
      ApiManagerComponent,
      TipificacionesComponent,
      PollsComponent,
      AdminComponent,
   ],
})
export class AdminModule {}
