import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription, Observable, combineLatest, of, from } from "rxjs";
import { sortBy } from "lodash";

import { SocketService, EComando } from "../../_services/socket.service";
import { PeerService } from "../../_services/peer.service";
import { UsuarioService } from "../../_services/usuario.service";
import { EUsuarioRol } from "../../_interfaces/usuario";
import { Peer } from "../../_interfaces/peer";
import { IPeerMonitor, ETipoPeer } from "../../_interfaces/monitor";
import { VpnTableComponent } from "./vpn-table/vpn-table.component";
import {
   map,
   flatMap,
   withLatestFrom,
   mergeMap,
   switchMap,
   toArray,
} from "rxjs/operators";

@Component({
   selector: "consola-ext",
   templateUrl: "./consola-ext.component.html",
})
export class ConsolaComponent implements OnInit, OnDestroy {
   @ViewChild(VpnTableComponent)
   vpnKeysListTableComponenet: VpnTableComponent;

   get usuario() {
      return this.$usuario.usuario;
   }
   _rol = EUsuarioRol;
   readOnly: boolean;
   peerEdicion: IPeerMonitor;

   peers$: Observable<{}[]>;
   troncales$: Observable<IPeerMonitor[]>;

   constructor(
      private $monitor: SocketService,
      private $peer: PeerService,
      private $usuario: UsuarioService
   ) {
      this.peers$ = this.$monitor.getChannels$().pipe(
         withLatestFrom(
            this.$monitor
               .getPeers()
               .pipe(
                  map((peers) =>
                     peers.filter((p) => p.tipo === ETipoPeer[ETipoPeer.PEER])
                  )
               )
         ),
         mergeMap(([channels, peers]) =>
            from(peers).pipe(
               map((peer) => {
                  const channel = channels.find((c) =>
                     c.Channel.includes("/" + peer.usuario + "-")
                  );

                  if (!!channel) {
                     const localTime = new Date();
                     localTime.setHours(0, 0, 0, 0);

                     const inCallTime =
                        localTime.getTime() +
                        Number.parseInt(channel.Duration) * 1000;

                     const extraData =
                        channel.Application === "AppDial"
                           ? {
                                tiempo: inCallTime,
                                canal:
                                   channels.find(
                                      (c) =>
                                         c.Bridged === channel.Bridged &&
                                         c.UniqueID !== channel.UniqueID
                                   )?.CallerID ?? "Entrante",
                                app: channel.Application,
                             }
                           : {
                                tiempo: inCallTime,
                                canal: "O: " + channel.Exten,
                                app: channel.Application,
                             };

                     return {
                        ...peer,
                        estado: channel.Stats,
                        extra: extraData,
                        estado_class: "btn-danger",
                     };
                  }
                  return {
                     ...peer,
                     estado_class:
                        peer.estado.includes("ONLINE") ||
                        peer.estado.includes("LAGGED")
                           ? "btn-success"
                           : "btn-default",
                  };
               }),
               toArray()
            )
         ),
         map((peers) => sortBy(peers, "usuario"))
      );

      this.troncales$ = this.$monitor.getChannels$().pipe(
         withLatestFrom(
            this.$monitor
               .getPeers()
               .pipe(
                  map((peers) =>
                     peers.filter((p) => p.tipo === ETipoPeer[ETipoPeer.TRUNK])
                  )
               )
         ),
         mergeMap(([channels, trunks]) =>
            from(trunks).pipe(
               map((trunk) => {
                  return {
                     ...trunk,
                     channels: channels.filter((c) =>
                        c.Channel.includes(`/${trunk.usuario}-`)
                     ),
                     estado_class:
                        trunk.estado.includes("ONLINE") ||
                        trunk.estado.includes("LAGGED")
                           ? "btn-success"
                           : "btn-default",
                  };
               }),
               toArray()
            )
         ),
         map((peers) => sortBy(peers, "usuario"))
      );
   }

   ngOnInit() {
      // Escuchar eventos
      this.$monitor.setRooms([`peers`, `channels`]);
   }

   ngOnDestroy() {
      // dejar de escuchar
      this.$monitor.exitRooms([`peers`, `channels`]);
   }

   recargarArchvos() {
      this.vpnKeysListTableComponenet.listarArchivos();
   }

   nuevaExt() {
      this.readOnly = false;
      this.peerEdicion = <IPeerMonitor>{};
      $("#modalEditPeer").modal();
   }

   editarExt(peer: IPeerMonitor) {
      this.readOnly = true;
      this.peerEdicion = peer;
      $("#modalEditPeer").modal();
   }

   actualizarPeer(data: { peer: Peer; update: boolean }) {
      this.$peer
         .updatePeer(data)
         .pipe(
            map(() => data.peer.protocolo),
            flatMap((protocolo) =>
               this.$monitor.enviarComando({
                  comando: EComando.KERBERUS_FILE,
                  data: { tipo: protocolo + "_CONF" },
               })
            ),
            flatMap(() =>
               this.$monitor.enviarComando({
                  comando: EComando.KERBERUS_FILE,
                  data: { tipo: "EXTEN_EXTRA" },
               })
            )
         )
         .subscribe((res) => {
            console.log(res);
            this.recargarArchvos();
            $("#modalEditPeer").modal("hide");
         });
   }
}
