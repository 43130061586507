import { Component, OnInit } from "@angular/core";
import { customAlphabet } from "nanoid";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PipelineService } from "src/app/_services/pipeline.service";
import { catchError, map, switchMap, tap, toArray } from "rxjs/operators";
import { of, throwError } from "rxjs";

@Component({
   selector: "app-pipeline",
   templateUrl: "./pipeline.component.html",
   styleUrls: ["./pipeline.component.css"],
})
export class PipelineComponent implements OnInit {
   fasesForm = this.$fb.group({
      fases: this.$fb.array([]),
   });

   propsForm: FormGroup;
   inProcess: { progress: boolean; msj?: string } = { progress: false };
   unknown = "Se ha presentando un error desconocido";

   private nanoID = customAlphabet("1234567890abcdef", 14);

   get fasesArray() {
      return this.fasesForm.get("fases") as FormArray;
   }

   get currentIndex() {
      return this.propsForm.get("index").value;
   }

   get isSaveDisabled() {
      return (
         this.fasesForm.invalid ||
         this.fasesArray.controls.length === 0 ||
         this.currentIndex > -1 ||
         this.inProcess.progress
      );
   }

   // Cargar las fases desde el API
   initFasesForm$ = this.$pipe.getPipelines().pipe(
      switchMap((phases) => phases),
      map((phase) => {
         const camposArrayControls = phase.campos.map((c) => {
            const campoGroup = this.$fb.group({
               campo: ["", Validators.required],
               tipo: ["", Validators.required],
               requerido: [""],
            });

            campoGroup.patchValue({
               campo: c.campo,
               tipo: c.tipo,
               requerido: c.requerido,
            });

            return campoGroup;
         });
         const camposGroup = this.$fb.array(camposArrayControls);

         const newPhaseGroup = this.$fb.group({
            id: ["", Validators.required],
            nombre: ["", Validators.required],
            avance: ["", Validators.required],
            campos: camposGroup,
         });

         newPhaseGroup.patchValue({
            id: phase.id,
            nombre: phase.nombre,
            avance: phase.avance,
         });

         return newPhaseGroup;
      }),
      tap((phasesGroup) => this.fasesArray.push(phasesGroup)),
      toArray(),
      catchError((err) => {
         this.inProcess = { ...this.inProcess, msj: err };
         return throwError(err);
      })
   );

   constructor(
      private $fb: FormBuilder,
      private $router: Router,
      private $pipe: PipelineService
   ) {}

   ngOnInit(): void {
      this.initPropsForm();
   }

   agregarFase() {
      const newPhaseGroup = this.$fb.group({
         id: ["", Validators.required],
         nombre: ["", Validators.required],
         avance: ["", Validators.required],
         campos: this.$fb.array([]),
      });

      newPhaseGroup.patchValue({
         id: this.nanoID(),
      });

      this.fasesArray.push(newPhaseGroup);
   }

   initPropsForm() {
      this.propsForm = this.$fb.group({
         index: ["", Validators.required],
         nombre: ["", Validators.required],
         props: this.$fb.array([]),
      });

      // Util para determinar si se habilita el boton de guardar
      this.propsForm.patchValue({
         index: -1,
      });
   }

   removerFase(index: number) {
      this.initPropsForm();
      this.fasesArray.removeAt(index);
   }

   loadProps(index: number) {
      const fase = this.fasesArray.at(index) as FormGroup;
      this.propsForm.setControl("props", fase.get("campos") as FormArray);

      this.propsForm.patchValue({
         index: index,
         nombre: fase.get("nombre").value,
      });
   }

   agregarCampos(data: { index: number; props: FormArray }) {
      const fase = this.fasesArray.at(data.index) as FormGroup;
      fase.setControl("campos", data.props);
      this.initPropsForm();
   }

   guardarPipe() {
      console.log(this.fasesArray.value);

      of(this.fasesArray.value)
         .pipe(
            tap(() => (this.inProcess = { progress: true, msj: undefined })),
            switchMap((pipeline) => this.$pipe.guardarPipeline(pipeline)),
            catchError((err) => {
               this.inProcess = { ...this.inProcess, msj: err };
               return of(err);
            }),
            tap(() => (this.inProcess = { ...this.inProcess, progress: false }))
         )
         .subscribe();
   }

   regresar() {
      this.$router.navigateByUrl("/dashboard/(view:pipeline)");
   }
}
