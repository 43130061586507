import {
   Component,
   OnInit,
   Input,
   OnChanges,
   SimpleChanges,
   SimpleChange,
   Output,
   EventEmitter,
} from "@angular/core";
import { FileUploader, FileItem, Headers } from "ng2-file-upload";
import { HeaderService } from "../../../_services/header.service";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Subject, Observable, Observer, from, of } from "rxjs";
import { DragulaService } from "ng2-dragula";
import { PollsService, IEncuentaFrm } from "../../../_services/polls.service";
import { IDBResponse } from "../../../_interfaces/_all";
import { SocketService, EComando } from "../../../_services/socket.service";
import { tap, flatMap, switchMap, map, reduce, finalize } from "rxjs/operators";

interface IChanges extends SimpleChanges {
   encuesta: IChange;
}

interface IChange extends SimpleChange {
   currentValue: IEncuentaFrm;
   previusValue: IEncuentaFrm;
}

@Component({
   selector: "poll-tag",
   templateUrl: "poll.component.html",
})
export class PollComponent implements OnInit, OnChanges {
   uploader: FileUploader;
   encuestaFrm: FormGroup;
   private preguntasArray: FormArray;
   private audioSelect$ = new Subject<number>();

   @Input()
   encuesta: IEncuentaFrm;

   @Output()
   eventos = new EventEmitter<boolean>();

   constructor(
      private $headers: HeaderService,
      private $fb: FormBuilder,
      private $ds: DragulaService,
      private $polls: PollsService,
      private $monitor: SocketService
   ) {}

   ngOnChanges(changes: IChanges): void {
      // Construír el formulario con los datos actuales.
      const cambios = changes.encuesta;
      if (!!cambios && !!cambios.currentValue) {
         this.encuestaFrm.patchValue({
            id: cambios.currentValue.id,
            nombre: cambios.currentValue.nombre,
            descripcion: cambios.currentValue.descripcion,
            ext_directa: cambios.currentValue.ext_directa,
         });

         // Limpiar
         while (this.preguntasArray.length) {
            this.preguntasArray.removeAt(0);
         }

         // Consultar en la Db por las preguntas
         $("#modalWait_kerberus").modal();
         this.$polls
            .getEncuesta(cambios.currentValue)
            .pipe(
               flatMap((encuestaFull) => encuestaFull.preguntas),
               map((pregunta) => this.initPregunta(pregunta))
            )
            .subscribe(
               (control) => {
                  this.preguntasArray.push(control);
                  $("#modalWait_kerberus").modal("hide");
               },
               (err) => $("#modalWait_kerberus").modal("hide")
            );
      }
   }

   ngOnInit() {
      const headers = <Headers[]>[
         {
            name: "authorization",
            value: this.$headers.getHeader("authorization"),
         },
      ];
      this.uploader = new FileUploader({
         url: this.$headers.getAPIurl() + "/polls/audio",
         headers: headers,
         itemAlias: "audio_polls",
      });

      this.preguntasArray = this.$fb.array([]);

      this.encuestaFrm = this.$fb.group({
         id: "",
         nombre: ["", Validators.required],
         descripcion: ["", Validators.required],
         ext_directa: "",
         preguntas: this.preguntasArray,
      });

      // Observable para establecer el audio.
      this.audioSelect$
         .pipe(
            switchMap(
               (indx) =>
                  new Observable((o: Observer<[FileItem, number]>) => {
                     this.uploader.onAfterAddingFile = (filename) =>
                        o.next([filename, indx]);
                  })
            ),
            // Eliminar el audiocon nombre en esa posición
            tap((data) => {
               const [fileItem, indx] = data;
               this.eliminarAudioEnQueue(indx);
            })
         )
         .subscribe((data) => {
            const [fileItem, orden] = data;
            // Cambiar el nombre del archivo
            fileItem.file.name =
               new Date().getTime() +
               "_" +
               fileItem.file.name.replace(/ /g, "");

            // Obtener el formgroup en la posición "orden"
            this.preguntasArray.controls[orden].patchValue({
               audio: fileItem.file.name,
            });
         });

      // Actualizar el array en caso de cambios.
      this.$ds
         .dropModel()
         .pipe(
            tap(() => {
               while (this.preguntasArray.length) {
                  this.preguntasArray.removeAt(0);
               }
            }),
            flatMap((drop) => drop.targetModel)
         )
         .subscribe((formGroup) => this.preguntasArray.push(formGroup));
   }

   resetFrm(): void {
      this.eventos.emit(true);
      while (this.preguntasArray.length) {
         this.preguntasArray.removeAt(0);
      }
      this.encuestaFrm.reset();
   }

   initPregunta(pregunta?: {
      pregunta: string;
      audio: string;
      minimo: number;
      maximo: number;
   }): FormGroup {
      const frmGroup = this.$fb.group({
         pregunta: ["", Validators.required],
         audio: ["", Validators.required],
         minimo: ["", Validators.required],
         maximo: ["", Validators.required],
      });

      if (!!pregunta) {
         frmGroup.patchValue({
            pregunta: pregunta.pregunta,
            audio: pregunta.audio,
            minimo: pregunta.minimo,
            maximo: pregunta.maximo,
         });
      }

      return frmGroup;
   }

   agregarPregunta(): void {
      this.preguntasArray.push(this.initPregunta());
   }

   eliminarPregunta(indx: number): void {
      // Eliminar de la cola el archivo en caso de que esté
      this.eliminarAudioEnQueue(indx);
      // Eliminar pregunta
      this.preguntasArray.removeAt(indx);
   }

   eliminarAudioEnQueue(indx: number) {
      const nombreArchivoEnFrm = this.preguntasArray.controls[indx].get("audio")
         .value;
      if (nombreArchivoEnFrm !== "") {
         const itemEnQueue = this.uploader.queue.find(
            (item) => item.file.name === nombreArchivoEnFrm
         );
         if (!!itemEnQueue) {
            itemEnQueue.remove();
         }
      }
   }

   tieneAudiosParaSubir(uploader: FileUploader): boolean {
      return uploader.queue.filter((item) => !item.isUploaded).length > 0;
   }

   guardarEncuenta(encuesta: IEncuentaFrm) {
      // Subir audios que esten en cola
      $("#modalWait_kerberus").modal();
      new Observable((o: Observer<any>) => {
         if (this.tieneAudiosParaSubir(this.uploader)) {
            this.uploader.onCompleteItem = (
               item,
               respuestaAPI,
               status,
               headers
            ) => {
               item.remove();
               const response: IDBResponse = JSON.parse(respuestaAPI);
               o.next([response.data, item.file.name]);
            };
            this.uploader.onCompleteAll = () => {
               o.complete();
            };
            this.uploader.uploadAll();
         } else {
            o.next(undefined);
            o.complete();
         }
      })
         .pipe(
            // Transformar cada audio a su destino final y retornar respuesta + archivo
            flatMap((data) => {
               if (!!data) {
                  const [fileInServer, archvoOriginal] = data;
                  const [fileName, exten] = (archvoOriginal as string).split(
                     "."
                  );

                  return this.$monitor
                     .enviarComando({
                        comando: EComando.AUDIO_FORMAT,
                        data: {
                           in: fileInServer,
                           out: "polls/" + fileName + ".wav",
                        },
                     })
                     .pipe(flatMap(() => of(fileName)));
               } else {
                  return of("");
               }
            }),
            reduce((acc, curr) => [...acc, curr], []),
            // Guardar la encuesta
            flatMap(() => this.$polls.guardarEncuenta(encuesta)),
            tap((response) => this.resetFrm()),
            finalize(() => $("#modalWait_kerberus").modal("hide"))
         )
         .subscribe();
   }

   verResultados(id?: string): void {
      $("#modal_pollResults").modal();
   }
}
