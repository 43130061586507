import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HeaderService } from "./header.service";
import { Observable, of } from "rxjs";
import { ILead } from "../_interfaces/lead.entity";
import {
   defaultIfEmpty,
   filter,
   isEmpty,
   map,
   switchMap,
   tap,
   toArray,
} from "rxjs/operators";
import * as dayjs from "dayjs";
import { IleadLog } from "../_interfaces/leadLog.entity";

import { customAlphabet } from "nanoid";

@Injectable({
   providedIn: "root",
})
export class LeadService {
   private nanoID = customAlphabet("0123456789abcdehijklmnopqrs");

   constructor(private $http: HttpClient, private $header: HeaderService) {}

   getLead(idLead: string): Observable<ILead> {
      return this.$http
         .get<ILead>(`${this.$header.getAPIurl()}/lead/${idLead}`, {
            headers: this.$header.getHeaders(),
         })
         .pipe(
            map((lead) => ({
               ...lead,
               fuente_dst: decodeURIComponent(lead.fuente_dst),
            }))
         );
   }

   getLeadByCallerID(callerid: string): Observable<ILead[]> {
      return of(callerid).pipe(
         map((callerID) => `${dayjs().format("YYYY-MM-DD")}.${callerID}`),
         switchMap((idLead) =>
            this.$http.get<ILead>(
               `${this.$header.getAPIurl()}/lead/${idLead}`,
               {
                  headers: this.$header.getHeaders(),
               }
            )
         ),
         switchMap((lead) => {
            if (!!lead) return of([lead]);
            else {
               return of(callerid).pipe(
                  switchMap((callerID) =>
                     this.$http.get<ILead[]>(
                        `${this.$header.getAPIurl()}/lead/${callerID}/asCallerID`,
                        {
                           headers: this.$header.getHeaders(),
                        }
                     )
                  )
               );
            }
         }),
         filter((leads) => !!leads && leads.length > 0),
         map((leads) =>
            leads.map((lead) => ({
               ...lead,
               fuente_dst: decodeURIComponent(lead.fuente_dst),
            }))
         ),
         defaultIfEmpty([])
      );
   }

   getLeads(fecha: string): Observable<ILead[]> {
      return of(fecha).pipe(
         switchMap((fecha) =>
            this.$http.get<ILead[]>(
               `${this.$header.getAPIurl()}/lead/d/${fecha}`,
               {
                  headers: this.$header.getHeaders(),
               }
            )
         ),
         switchMap((data) => data),
         map((lead) => ({
            ...lead,
            fuente_dst: decodeURIComponent(lead.fuente_dst),
         })),
         toArray()
      );
   }

   getLeadsRange(fecha: { start: string; end?: string }): Observable<ILead[]> {
      return of(fecha).pipe(
         switchMap((fecha) =>
            this.$http.post<ILead[]>(
               `${this.$header.getAPIurl()}/lead/byDate`,
               fecha,
               {
                  headers: this.$header.getHeaders(),
               }
            )
         ),
         switchMap((data) => data),
         map((lead) => ({
            ...lead,
            fuente_dst: decodeURIComponent(lead.fuente_dst),
         })),
         toArray()
      );
   }

   getLeadsRangeAndAuthor(query: {
      idAuthor?: string;
      start: string;
      end?: string;
   }): Observable<ILead[]> {
      return of(query).pipe(
         switchMap((queryData) =>
            this.$http.post<ILead[]>(
               `${this.$header.getAPIurl()}/lead/byDateAndAuthor`,
               queryData,
               {
                  headers: this.$header.getHeaders(),
               }
            )
         ),
         switchMap((data) => data),
         map((lead) => ({
            ...lead,
            fuente_dst: decodeURIComponent(lead.fuente_dst),
         })),
         toArray()
      );
   }

   updateLead(lead: ILead) {
      return of(lead).pipe(
         map((data) => ({
            ...data,
            fuente_dst: encodeURIComponent(lead.fuente_dst),
            fecha: dayjs(lead.fecha).format("YYYY-MM-DD HH:mm:ss"),
         })),
         switchMap((leadFormed) =>
            this.$http.post(`${this.$header.getAPIurl()}/lead`, leadFormed, {
               headers: this.$header.getHeaders(),
            })
         )
      );
   }

   deleteLogs(idlead: string) {
      return this.$http.delete(
         `${this.$header.getAPIurl()}/lead/${idlead}/log`,
         {
            headers: this.$header.getHeaders(),
         }
      );
   }

   getLogs(idLead: string): Observable<IleadLog[]> {
      return this.$http.get<IleadLog[]>(
         `${this.$header.getAPIurl()}/lead/${idLead}/log`,
         {
            headers: this.$header.getHeaders(),
         }
      );
   }

   agregarActividad(
      actividad: { [key: string]: string },
      autor: string,
      idlead: string
   ) {
      const data = Object.keys(actividad);
      const htmlData = data
         .map((key) => `${key} : <strong>${actividad[key]}</strong>`)
         .join("<br>");

      const leadLog: IleadLog = {
         idAutor: autor,
         idLead: idlead,
         idLeadLog: this.nanoID(),
         fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
         actividad: htmlData,
      };
      return this.$http.put(`${this.$header.getAPIurl()}/lead/log`, leadLog, {
         headers: this.$header.getHeaders(),
      });
   }
}
