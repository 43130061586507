<form class="form" novalidate autocomplete="off" [formGroup]="frmNumero" (ngSubmit)="guadarNumero(frmNumero.value)">
    <div class="row">
        <input type="hidden" formControlName="idcampana">
        <input type="hidden" formControlName="idnumero">
        <input type="hidden" formControlName="fecha_mod">
        <div class="col-md-12">
            <label>Número a agregar.</label>
            <input type="tel" class="form-control" aria-label="..." formControlName="numero">
        </div>
        <div class="col-md-12" style="margin-top: 10px" formArrayName="variable">
            <div *ngFor="let _var of frmNumero.get('variable')['controls']; let i = index">
                <div class="panel panel-default" [formGroupName]="i">
                    <div class="panel-heading">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="eliminarVariable(i)"><span aria-hidden="true">&times;</span></button>
                        <strong>Variable {{i+1}}</strong>
                    </div>
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-xs-6">
                        <label>Nombre de la Variable</label>
                        <input type="text" class="form-control" formControlName="nombre">
                        <small class="text-muted">Use nombres propios sin acentos. Ej: <strong>nombre</strong></small>
                            </div>
                            <div class="col-xs-6">
                        <label>Valor de la Variable</label>
                        <input type="text" class="form-control" formControlName="valor">
                        <small class="text-muted">Sin acentos o caracteres especiales.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 text-right">
            <button type="button" class="btn btn-default" (click)="iniciarFormulario()">Reset</button>&nbsp;
            <button type="button" class="btn btn-info" [disabled]="frmNumero.get('variable')['controls'].length >= 4" (click)="agregarVariable()"><i class="fa far fa-plus"></i> Variable</button>&nbsp;
            <button type="submit" class="btn btn-success" [disabled]="!frmNumero.valid"><i class="fa fas fa-check"></i> Agregar número</button>&nbsp;
            <hr>
            <table class="table table-striped" *ngIf="numeros$ | async; let numeros; else loading">
                <thead class="bg-primary">
                    <tr>
                        <td class="text-center" colspan="2">Número</td>
                        <td style="min-width: 30%;" class="text-center">Variables</td>
                        <td colspan="2" class="text-center">Estado</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let numero of numeros">
                        <td class="text-center"><i class="fa fa-redo"></i></td>
                        <td class="text-left">
                            <a href="javascript:void(0)" (click)="editarNumero(numero)">
                            {{numero.numero}}
                            </a>
                        </td>
                        <td class="text-left">
                            <div *ngFor="let _var of numero.variable">
                               {{'{' + _var.nombre + '}'}}: {{_var.valor}}
                            </div>
                        </td>
                        <td class="text-center">{{numero.estado}}</td>
                        <td class="text-center">
                            <a href="javascript:void(0)" class="text-danger" title="Eliminar número"
                                *ngIf="numero.estado === estados.EN_ESPERA"
                                (click)="eliminarNumero(numero)">
                                <i class="fa fa-trash-o"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table> 
            <ng-template #loading>
                <div class="text-center text-muted">
                    <div style="margin-bottom: 10px">
                    <i class="fa fa-spinner fa-spin text-primary fa-2x" aria-hidden="true"></i>
                    </div>
                    Cargando numeración...
                </div>
            </ng-template>          
        </div>
    </div>
</form>
