import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'uploadProgress'
})
export class UploadProgessPipe implements PipeTransform {
    transform(event: { fileType: string, progress: number }, type: string): any {
        if (!!event && event.fileType.includes(type)) {
            return event;
        }
        return undefined;
    }
}
