<div class="panel panel-primary">
    <div class="panel-heading">
        <button type="button" class="close" *ngIf="!isStoped" 
            (click)="cambiarEstadoCampana(campana.estado)"
        >
            <i class="fa" [ngClass]="{'fa-pause-circle-o' : !isPaused, 'fa-play-circle-o' : isPaused}"></i>
        </button>
        <a href="javascript:void(0)" style="color: white; text-decoration: none;" (click)="verDetalles(campana.idcampana)">
            <i class="fa fa-rocket"></i> {{campana?.nombre}}
        </a>
    </div>
    <div class="panel-body" style="padding: 0; margin: 0;">
        <table class="table" style="width: 100%; margin-bottom: 0">
            <tbody>
            <tr>
                <td *ngIf="!isStoped" rowspan="3" 
                style="width: 45%; border: 0" class="paused">
                    <div style="margin-bottom: 5px;">
                        <span *ngIf="!isPaused && !isStoped" class="label label-success">En curso</span>
                        <span *ngIf="isPaused && !isStoped" class="label label-warning">En pausa</span>
                        <span *ngIf="isStoped" class="label label-default">Detenida</span>
                    </div>
                    Efectividad<br>
                    <span style="font-size: 30px; font-weight: 600">0 %</span>
                </td>
                <td>
                    <div class="row" style="font-size: 13px">
                        <div class="col-xs-7">
                        Pendientes
                        </div>
                        <div class="col-xs-5 text-right">
                            <span class="badge">{{campana?.pendientes}}</span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="row" style="font-size: 13px">
                        <div class="col-xs-7">
                        Atendidas
                        </div>
                        <div class="col-xs-5 text-right">
                            <span class="badge">{{campana?.atendidas}}</span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="row" style="font-size: 13px">
                        <div class="col-xs-7">
                        Fallidas 
                        </div>
                        <div class="col-xs-5 text-right">
                            <span class="badge">{{campana?.fallidas}}</span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td [ngClass]="{'stopped': isStoped, 'paused': !isStoped }" style=" font-weight: 600;">
                    <div class="row" style="font-size: 13px">
                        <div class="col-xs-7">
                        Total 
                        </div>
                        <div class="col-xs-5 text-right">
                            {{campana?.total}}
                        </div>
                    </div>
                </td>
                <td *ngIf="!isStoped">
                    <div class="row" style="font-size: 13px">
                        <div class="col-xs-7">
                        En curso <i class="fa fa-info-circle text-muted" 
                        data-toggle="tooltip" data-placement="bottom" 
                        title="Llamadas en curso + en audio + en atención"></i>
                        </div>
                        <div class="col-xs-5 text-right">
                            <span class="badge">{{campana | campainEnCurso}}</span>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>