import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HeaderService } from './header.service';

export interface IVPNFile {
    nombre: string;
    fecha: number
}

@Injectable()
export class VPNService {
    constructor(private httpClient: HttpClient, private $header: HeaderService) { }
    
    listarArchivosVPN(): Observable<IVPNFile[]> {
        return this.httpClient.get<IVPNFile[]>(this.$header.getAPIurl() + '/vpn', { headers: this.$header.getHttpHeaders() })
            .pipe(
                catchError(err => {
                    console.warn(err);
                    return of([]);
                })
            );
    }

    obtenerArchivo(archivo: string): Observable<any> {
        return this.httpClient.get(this.$header.getAPIurl() + '/vpn/' + archivo, 
            { 
                headers: this.$header.getHttpHeaders(),
                responseType: 'blob' 
            })
            .pipe(
                catchError(err => {
                    console.warn(err);
                    return of(undefined);
                })
            );
    }
}
