import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tmoCalculator'
})
export class TmoCalculatorPipe implements PipeTransform {

  transform(time: string | number, quantity: string | number): Date {
     const refDate = new Date().setHours(0, 0, 0, 0);
     const tmo_time = Number.parseInt(`${time}`)
     const divisor = Number.parseInt(`${quantity}`)
     
     if (divisor > 0 ) {
        const tmo = (tmo_time / divisor) * 1000 + refDate
        return new Date(tmo)
     }
    return new Date(refDate);
  }

}
