import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "hidePhone",
})
export class HidePhonePipe implements PipeTransform {
   transform(numero: string | number): any {
      const formattedNumber = `${numero}`;
      return formattedNumber.length > 6
         ? `${formattedNumber.substring(0, formattedNumber.length - 4)}****`
         : formattedNumber;
   }
}
