<div *ngIf="!!directorio">
   <h4 class="text-muted"><i class="fa fa-address-card-o"></i> Directorio {{directorio?.nombre}}</h4>
   <hr>
   <div class="row" *ngIf="contactos$ | async as contactos; else loading">
      <div class="col-md-7 hidden-xs text-muted" style="padding-top: 10px;">
         Total contactos : <strong>{{contactos.length}}</strong>
      </div>
      <div class="col-md-5">
         <div class="form-group input-group">
            <input type="text" [formControl]="busqueda" class="form-control" aria-label="...">
            <div class="input-group-btn">
               <button type="submit" [disabled]="!busqueda.valid || inProgress.progress"
                  class="btn btn-info">Buscar</button>
               <button type="submit" [disabled]="!isImported || inProgress.progress" class="btn btn-default"
                  (click)="guardarContactos(contactos)">
                  <i class="fa fa-save"></i>
               </button>
               <button *ngIf="!inProgress.progress" type="button" class="btn btn-default dropdown-toggle"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Edición <span
                     class="caret"></span></button>
               <ul class="dropdown-menu dropdown-menu-right text-muted">
                  <li><a href="javascript: void(0)" (click)="importar()"><i class="fa fa-cloud-upload"></i> Importar
                        contactos</a></li>
                  <li [ngClass]="{disabled: contactos.length === 0 || isImported || inProgress.progress}">
                     <a href="javascript: void(0)" (click)="exportar(contactos)"><i class="fa fa-cloud-download"></i>
                        Exportar contactos</a>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li><a href="javascript: void(0)" (click)="eliminarContactosSeleccionados()"><i
                           class="fa fa-check-square-o"></i> Eliminar Seleccionados</a></li>
                  <li><a href="javascript: void(0)" (click)="limpiar()"><i class="fa fa-refresh"></i> Limpiar
                        directorio</a></li>
               </ul>
               <button *ngIf="inProgress.progress" [disabled]="true" class="btn btn-default"> <i
                     class="fa fa-spinner fa-spin"></i> Procesando...</button>
            </div>
         </div>
         <input type="file" accept=".csv" #fileContacts name="fileContacts" id="fileContacts" style="display: none;">
      </div>
      <div class="col-md-12">
         <div *ngIf="!inProgress.progress && inProgress.tipo === 'update'" class="text-center alert alert-success">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
            {{inProgress?.msj}}
         </div>
         <div *ngIf="!inProgress.progress && inProgress.tipo === 'updatefailed'" class="text-center alert alert-danger">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
            {{inProgress?.msj}}
         </div>
         <div class="table-responsive">
            <table class="table table-striped table-sm" style="margin-top: 17px; font-size: 13px;">
               <thead class="text-white" style="background-color: #0d1e40;">
                  <tr>
                     <th class="text-center">
                        <input type="checkbox" #contactoAll name="contactoAll" id="contactoAll"
                           (change)="seleccionarContacto('', contactoAll.checked)">
                     </th>
                     <th *ngFor="let campo of directorio.campos">{{campo?.nombre}}</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let contacto of contactos | paginate: config; let i = index">
                     <td class="text-center">
                        <input type="checkbox" #contactoCheck name="contactoCheck" id="contactoCheck"
                           (change)="seleccionarContacto(contacto, contactoCheck.checked)">
                     </td>
                     <td *ngFor="let campo of directorio.campos">{{contacto[campo?.nombre] || '-'}}</td>
                     <td class="text-center">
                        <a href="javascript:void(0)" *ngIf="!isImported" (click)="editarContacto(contacto)"
                           title="Editar contacto">
                           <i class="fa fa-edit"></i>
                        </a>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div style="margin: 20px 0px" *ngIf="contactos.length > 0" class="text-right">
            <hr>
            <pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event">

               <div class="btn-group">
                  <button type="button" class="btn btn-default" [disabled]="p.isFirstPage()" (click)="p.previous()">
                     <i class="fa fa-arrow-circle-o-left"></i>
                  </button>

                  <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                     [disabled]="p.getCurrent() === page.value" [ngClass]="{'active': p.getCurrent() === page.value}"
                     (click)="p.setCurrent(page.value)">
                     {{ page.label }}
                  </button>

                  <button type="button" class="btn btn-default" [disabled]="p.isLastPage()" (click)="p.next()">
                     <i class="fa fa-arrow-circle-o-right"></i>
                  </button>
               </div>
            </pagination-template>
         </div>
      </div>
   </div>
   <ng-template #loading>
      <div class="text-center text-muted" style="padding: 100px 0;">
         <i class="fa fa-2x fa-spin fa-spinner text-primary" style="margin-bottom: 10px;"></i><br>
         Cargando contactos...
      </div>
   </ng-template>
</div>
<div *ngIf="!directorio" class="text-center text-muted" style="padding: 60px 0px;">
   <i class="fa fa-address-card fa-5x" style="margin-bottom: 20px;"></i><br>
   Selecciona o crea un nuevo directorio.
</div>