<div class="row" style="margin-top: 20px;" *ngIf="!!resultados && resultados.length > 0; else noResults">
    <div class="row" class="col-md-6" *ngFor="let plot of plots$ | async">
        <div class="col-xs-8" style="padding-right: 0;">
            <chart [options]="plot"></chart>
        </div>
        <div class="col-xs-4" style="padding-left: 0; padding-top: 35px;">
            <div class="small text-muted" style="margin-bottom: 5px" *ngFor="let serie of plot.series[0].data; let i = index">
                <i class="fa fa-circle" [style.color]="plot.colors[i]"  style="margin-right: 7px;"></i> {{serie.name}}
            </div>
        </div>
    </div>
</div>
<ng-template #noResults>
    <div class="text-center" style="margin: 100px 0;">
        <i class="fa fa-4x fa-pie-chart" style="margin-bottom: 15; color: rgb(161, 161, 161);"></i>
        <h5 class="text-muted">Sin resultados.</h5>
    </div>
</ng-template>
<hr>
<div style="float: left;">
    <a href="javascript: void(0)" (click)="regresar()" >
        <i class="fa fa-arrow-circle-o-left"></i> Regresar
    </a>
</div>
<div class="text-right" style="margin-right: 10px;"><h5>Total resultados: {{resultados?.length || 0}}</h5></div>
