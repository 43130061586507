<form novalidate [formGroup]="encuestaFrm" (ngSubmit)="guardarEncuenta(encuestaFrm.value)">
    <input type="hidden" formControlName="id" #id>
    <div class="row">
        <div class="col-md-4">
            <label>Nombre*</label>
            <input type="text" formControlName="nombre" class="form-control" style="margin-bottom: 5px" placeholder="Nombre de la encuenta">
        </div>
        <div class="col-md-5">
            <label>Descripción *</label>
            <input type="text" formControlName="descripcion" class="form-control" style="margin-bottom: 5px" placeholder="Que buscas evaluar">
        </div>
        <div class="col-md-3">
            <label>Marcación Directa</label>
            <div class="input-group">
                <span class="input-group-addon" id="sizing-addon2">*08</span>
                <input type="number" class="form-control text-center" min="1" placeholder="" formControlName="ext_directa" aria-describedby="sizing-addon2">
            </div>
        </div>
    </div>    
    <hr style="margin-bottom: 10px; margin-top: 10px">

    <div formArrayName="preguntas" [dragula]='"bag-one"' [dragulaModel]="encuestaFrm.get('preguntas')['controls']">
        <div *ngFor="let pregunta of encuestaFrm.get('preguntas')['controls']; let i = index" class="row" [id]="i">
            <div [formGroupName]="i">
                <div class="col-sm-1 text-right" style="cursor: move">
                    <h3>{{(i + 1)}}.</h3>
                    <a href="javascript:void(0)" (click)="eliminarPregunta(i)"><i class="fa fa-trash-o"></i></a>
                </div>
                <div class="col-sm-11">
                    <!-- Componente de preguntas -->
                    <pregunta-poll 
                        [preguntaFrm]="pregunta" 
                        (audio)="audioSelect$.next(i)">
                    </pregunta-poll>
                </div>
            </div>
        </div>
    </div>

    <a href="javascript:void(0)" (click)="agregarPregunta()"><i class="fa fa-plus-square"></i> Agregar pregunta</a>
    <div class="text-right">
        <button type="button" class="btn btn-default btn-sm" (click)="resetFrm()"><i class="fa fa-refresh"></i> Reset</button>&nbsp;
        <button type="button" class="btn btn-info btn-sm" (click)="verResultados()" [disabled]="id.value === ''"><i class="fa fa-bar-chart"></i> Resultados</button>&nbsp;
        <button type="submit" class="btn btn-success btn-sm" [disabled]="!encuestaFrm.valid"><i class="fa fa-save"></i> Guardar Encuesta</button>
    </div>    
</form>

<input type="file" class="input_file" id="audio_polls" name="audio_polls"
    accept=".mp3,.wav"
    ng2FileSelect 
    [uploader]="uploader">

<div class="modal fade" tabindex="-1" role="dialog" id="modal_pollResults">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div style="height: 10px; background-color: #109618"></div>
            <div class="modal-header text-center" style="background-color: #f7f7f7">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h5 class="modal-title text-center" style="font-size: 15px"> <i class="fa fa-pie-chart"></i> Resultados de Encuesta {{encuesta?.nombre}}</h5>
        </div>
        <div class="modal-body">
            <resultados-poll [encuesta]="encuesta"></resultados-poll>
        </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
        