import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { INumeroSMS, EEstadoNumeroSMS } from 'src/app/_interfaces/sms';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-numeracion-up',
  templateUrl: './numeracion-up.component.html',
  styleUrls: ['./numeracion-up.component.css']
})
export class NumeracionUPComponent implements OnInit {

    @Input() 
    campanaId: number;
    @Output() 
    guardarNumeros = new EventEmitter<INumeroSMS[]>();
    
    prefijoCrtl = new FormControl('');
    importacionCrtl = new FormControl('', Validators.required);

    showPreview = false;
    previewNumeros: INumeroSMS[];

    constructor() { }

    ngOnInit() {
    }

    previsualizar(dataNumeros: string, prefijo: string) {
        this.previewNumeros = [];
        dataNumeros.split('\n').forEach(linea => {
            const contenido = linea.split(',');

            if (contenido.length > 0) {
                const numero = contenido.shift();
                this.previewNumeros.push({
                    numero: prefijo + '' + numero,
                    idcampana: this.campanaId,
                    variable: contenido.map(data => {
                        const [nombre, valor] = data.split(':');
                        return {
                            nombre: nombre.trim().replace(/ /g, '_').toLocaleLowerCase(),
                            valor: valor.trim()
                        }
                    }),
                    estado: EEstadoNumeroSMS.EN_ESPERA
                });
            }
        });
        this.showPreview = true;
    }

    editarImportacion() {
        this.showPreview = false;
    }

    guardarNumeracion() {
        this.guardarNumeros.emit(this.previewNumeros);
        this.showPreview = false;
        this.prefijoCrtl.setValue('');
        this.importacionCrtl.setValue('');
    }

}
