import { Pipe, PipeTransform } from "@angular/core";
import { ECallbackLogResult } from "src/app/_services/queue-callback.service";

@Pipe({
   name: "queueCallbackLogStats",
})
export class QueueCallbackLogStatsPipe implements PipeTransform {
   transform(
      resultados: {
         log: { callid?: string; resultado: ECallbackLogResult }[];
      }[],
      state?: ECallbackLogResult
   ): unknown {
      const logs = resultados.reduce(
         (acc: { callid?: string; resultado: ECallbackLogResult }[], r) =>
            r.log.length > 0 ? [...acc, ...r.log] : acc,
         []
      );

      if (!!state) {
         return logs.filter((l) => l.resultado === state);
      }

      return logs.filter((l) => !!l.callid);
   }
}
