import {
   Component,
   OnInit,
   Input,
   OnChanges,
   SimpleChange,
   SimpleChanges,
} from "@angular/core";
import { FormasService } from "../../_services/formas.service";
import {
   IResultadosQuery,
   EQueryType,
   IResultado,
} from "../../_interfaces/forma";
import { PaginationInstance } from "ngx-pagination";
import { Observable, combineLatest, from, of, ReplaySubject } from "rxjs";
import * as moment from "moment";
import * as _ from "lodash";
import { FileHandlerService } from "../../_services/file.service";
import {
   finalize,
   groupBy,
   switchMap,
   filter,
   flatMap,
   map,
   toArray,
   tap,
   mergeMap,
} from "rxjs/operators";

interface IChanges extends SimpleChanges {
   idFormulario: SimpleChange;
}

@Component({
   selector: "resumen-tipi",
   templateUrl: "resumen.component.html",
})
export class ResumentTipificacionComponent implements OnInit, OnChanges {
   querying = false;

   @Input() idFormulario: string;

   // private logs: IResultado[];
   private plotOptions: Highcharts.Options;
   // private plots: any[];
   plots$: Observable<Highcharts.Options[]>;

   query$: Observable<IResultado[]>;

   _t = EQueryType;
   public config: PaginationInstance = {
      id: "custom",
      itemsPerPage: 12,
      currentPage: 1,
   };

   constructor(
      private $forma: FormasService,
      private files$: FileHandlerService
   ) {}

   ngOnInit() {
      this.plotOptions = {
         chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            width: 400,
            height: 230,
            backgroundColor: "#fcfcfc",
         },
         colors: [
            "#109618",
            "#3366CC",
            "#FF9900",
            "#0099c6",
            "#DD4477",
            "#DB4437",
            "#4285F4",
            "#990099",
            "#00CCFF",
            "#33CC99",
            "#CC3300",
            "#666666",
         ],
         title: {
            text: "",
            align: "left",
         },
         subtitle: {
            text: "",
            align: "left",
         },
         tooltip: {
            pointFormat:
               "{series.name}: <b>{point.y}</b> ({point.percentage}%)",
         },
         plotOptions: {
            pie: {
               allowPointSelect: true,
               cursor: "pointer",
               dataLabels: {
                  enabled: true,
                  format: "{point.percentage:.1f} %: ({point.y})",
               },
            },
         },
         series: [],
         credits: {
            enabled: false,
         },
      };
   }

   ngOnChanges(cambios: IChanges) {
      if (
         !!cambios.idFormulario &&
         cambios.idFormulario.previousValue !==
            cambios.idFormulario.currentValue
      ) {
         this.query$ = of([]);
         this.plots$ = of([]);
      }
   }

   buscarResultados(query: IResultadosQuery) {
      console.log("iniciando");
      const replyData$ = new ReplaySubject<IResultado[]>();

      this.query$ = this.$forma.buscarResultados(query).pipe(
         tap((data) => {
            replyData$.next(data);
            replyData$.complete();
         })
      );

      this.plots$ = replyData$.pipe(
         switchMap((resultados) => resultados),
         filter((resultado) => resultado.tipo === "SELECCION"),

         // Agrupados por pregunta
         groupBy((resultado) => resultado.pregunta),
         mergeMap((grupo) => grupo.pipe(toArray())),

         // Construír los charts por pregunta
         flatMap((agrupadosPorPregunta) =>
            from(agrupadosPorPregunta).pipe(
               groupBy((registro) => registro.respuesta),
               mergeMap((grupo) => grupo.pipe(toArray())),

               // Construír data points de la torta
               map((agrupadosPorRespuesta) => {
                  const respuesta = agrupadosPorRespuesta[0].respuesta;
                  return {
                     name:
                        respuesta.substring(0, 1).toUpperCase() +
                        respuesta.substring(1),
                     y: agrupadosPorRespuesta.length,
                  };
               }),
               toArray(),
               // Crear el chart
               map((dataPoints) => {
                  const plotOptions = _.cloneDeep(this.plotOptions);
                  plotOptions.title.text = agrupadosPorPregunta[0].pregunta;
                  plotOptions.subtitle.text =
                     "Respuesta(s) :" + agrupadosPorPregunta.length;
                  plotOptions.series.push({
                     name: "Respuesta(s)",
                     data: dataPoints,
                  });

                  return plotOptions;
               })
            )
         ),
         toArray(),
         finalize(() => (this.querying = false))
      );
   }

   descargarResultados(resultados: IResultado[]) {
      // Definir el objeto a publicar en CSV
      const toCSV = {
         En_Llamada: "",
         Fecha: "",
         Hora: "",
         "ID Llamada": "",
         Autor: "",
         Cola: "",
         Fuente: "",
         Modalidad: "",
      };

      const obsHeaders$ = from(resultados).pipe(
         groupBy((grupo) => grupo.pregunta),
         mergeMap((agrupado) => of(agrupado.key)),
         toArray(),
         // mergeMap(agrupado => agrupado.pipe(toArray()),
         // reduce((titulos, grupo: IResultado[]) => [...titulos, grupo[0].pregunta], []),
         map((titulos) =>
            Object.assign([], [...Object.keys(toCSV), ...titulos.sort()])
         )
      );

      // Agrupar
      // const obsTipificaciones$ = Observable.from(_.orderBy(resultados.data as IResultado[], 'id'))
      const obsTipificaciones$ = from(
         _.orderBy(resultados, "id") as IResultado[]
      ).pipe(
         groupBy((data) => data.idtipificacion),
         mergeMap((grupo) => grupo.pipe(toArray())),
         map((tipiGrupo: IResultado[]) => {
            let data = {
               En_Llamada: tipiGrupo[0].callid !== "no-call" ? "SI" : "NO",
               Modalidad: tipiGrupo[0].modalidad,
               Fecha: moment(tipiGrupo[0].fecha).format("YYYY-MM-DD"),
               Hora: moment(tipiGrupo[0].fecha).format("HH:mm"),
               "ID Llamada": tipiGrupo[0].callid,
               Autor: tipiGrupo[0].autor,
               Cola: tipiGrupo[0].queuename,
               Fuente: tipiGrupo[0].src,
            };

            tipiGrupo.forEach((tipificacion) => {
               data = Object.assign({}, data, {
                  [tipificacion.pregunta]: tipificacion.respuesta,
               });
            });

            return data;
         }),
         toArray()
      );

      return combineLatest(obsHeaders$, obsTipificaciones$)
         .pipe(finalize(() => (this.querying = false)))
         .subscribe((latest) => {
            const [headers, data] = latest as [string[], object[]];
            this.files$.exportCSVFile(
               headers,
               data,
               "Tipificacion_" + new Date().getTime()
            );
         });
   }
}
