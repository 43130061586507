<div class="row">
    <div class="col-md-3">        
        <form novalidate #f="ngForm" class="form-group form-group-sm" style="margin-bottom: 0">
            <input type="hidden" [ngModel]="idFormulario" name="idformulario" >
            <div ngModelGroup="fecha">
                <div class="input-group input-group-sm" style="margin-bottom: 10px">
                    <div class="input-group-btn">
                        <button type="button" class="btn btn-success" style="min-width: 50px"><i class="fa fa-calendar"></i></button>
                    </div>
                    <datepicker-ng2 ngModel name="inicio" [estilo]="'input-group-sm'"></datepicker-ng2>
                </div>

                <div class="input-group input-group-sm" style="margin-bottom: 10px">
                    <div class="input-group-btn">
                      <button type="button" class="btn btn-success" style="min-width: 50px"><i class="fa fa-calendar"></i></button>
                    </div>
                    <datepicker-ng2 ngModel name="fin" [estilo]="'input-group-sm'"></datepicker-ng2>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-sm btn-primary" [disabled]="f.invalid" (click)="buscarResultados(f.value)">Consultar</button>
            </div>
        </form>
    </div>
    <div class="col-md-9" style="max-height: 550px; overflow: auto; overflow-x: hidden">

        <ng-template #queringTemplate>
            <div class="text-center" style="margin-top: 45px; margin-bottom: 35px">
                <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
                Un momento por favor...
            </div>
        </ng-template>
        <div *ngIf="query$ | async; else queringTemplate; let logs">
                <!-- Nav tabs -->
            <ul class="nav nav-tabs" role="tablist">
                <li role="presentation" class="active"><a href="#home" aria-controls="home" role="tab" data-toggle="tab"><i class="fa fa-pie-chart"></i> Resumen</a></li>
                <li role="presentation"><a href="#detallado" aria-controls="detallado" role="tab" data-toggle="tab"><i class="fa fa-list-ul"></i> Detallado</a></li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content" style="padding-top: 10px">

                <!-- PLOTS -->
                <div role="tabpanel" class="tab-pane active" id="home">
                    <div *ngIf="plots$ | async; else noPlots; let plots">
                        <div *ngFor="let opt of plots" class="panel panel-body" style="background-color: #fcfcfc; border: 1px solid #f5f5f5">
                            <div class="col-xs-8" style="padding: 0">
                                <chart [options]="opt" ></chart>
                            </div>
                            <div class="col-xs-4" style="font-size: 11px; padding-top: 30px">

                                <div *ngFor="let op of opt.series[0].data; let i=index">
                                    <i class="fa fa-circle" [style.color]="opt.colors[i]" style="margin-right: 10px"></i>{{op?.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #noPlots>
                        <div *ngIf="logs.length > 0" class="text-center" style="margin-top: 45px; margin-bottom: 35px">
                            <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
                            Creando gráfico...
                            <p><small class="text-muted">Es posible que en la pestaña de <strong>Detallado</strong> ya existan registros.</small></p>
                        </div>
                    </ng-template>
                </div>
                <!---->
                <!-- Table -->
                <div role="tabpanel" class="tab-pane" id="detallado">
                    <table class="table table-striped" style="margin-bottom: 0; font-size: 12px; border: 1px solid #e2e2e2">
                        <thead style="background-color: #0d1e40; color: white">
                            <th colspan="2" class="text-center">Fecha</th>
                            <th class="text-center">Agente</th>
                            <th>Pregunta</th>
                            <th>Respuesta</th>
                            <th>Cola</th>
                            <th>Fuente</th>
                        </thead>
                        <tbody *ngIf="logs.length === 0">
                            <td colspan="7" class="text-muted text-center" style="padding-top: 25px; padding-bottom: 20px">
                                No hay registros...
                            </td>
                        </tbody>
                        <tbody *ngIf="logs.length > 0">
                            <tr *ngFor="let log of logs | paginate: config; let i=index; trackBy:trackByFn">
                                <td class="text-center">
                                    <span *ngIf="log.callid.indexOf('no-call') < 0">
                                        <i title="Asociada a una llamada {{log?.fecha | toFecha:'lll'}}" class="fa fa-check-square text-success"></i>
                                    </span>
                                    <span *ngIf="log.callid.indexOf('no-call') > -1">
                                        <i title="Sin asociación" class="fa fa-question-circle text-muted"></i>
                                    </span>
                                </td>
                                <td class="text-center">{{log?.fecha | toFecha:'MM/DD HH:mm'}}</td>
                                <td class="text-center">{{log?.autor}}</td>
                                <td>{{log?.pregunta}}</td>
                                <td>{{log?.respuesta | Upper1st}}</td>
                                <td>{{log?.queuename}}</td>
                                <td>{{log?.src}}</td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-right">
                                    <pagination-template #p="paginationApi"
                                         [id]="config.id"
                                         (pageChange)="config.currentPage = $event">
                                        
                                        <div class="btn-group btn-group-sm">
                                            <button type="button" class="btn btn-default" 
                                                [disabled]="p.isFirstPage()"
                                                (click)="p.previous()">
                                                <i class="fa fa-arrow-circle-o-left"></i>
                                            </button>

                                            <button type="button" class="btn btn-default" *ngFor="let page of p.pages"
                                                [disabled]="p.getCurrent() === page.value"
                                                [ngClass]="{'active': p.getCurrent() === page.value}"
                                                (click)="p.setCurrent(page.value)">
                                                {{ page.label }}
                                            </button>

                                            <button type="button" class="btn btn-default" 
                                                [disabled]="p.isLastPage()"
                                                (click)="p.next()">
                                                <i class="fa fa-arrow-circle-o-right"></i>
                                            </button>
                                        </div>
                                        <button type="button" class="btn btn-sm btn-warning" [disabled]="!logs || logs.length === 0" (click)="descargarResultados(logs)">Exportar a *.CSV</button>
                                    </pagination-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!---->
            </div>
        </div>
    </div>
</div>