<div class="row">

    <div class="col-md-6">
        <div class="bs-callout bs-callout-primary">
            <h4 class="text-primary"><i class="fa fa-envelope-open-o"></i> Envío Masivo de Notificaciones SMS</h4>
            <p>En esta sección podrá configurar campañas para el envío masivo de SMS con el fin de hacer notificaciones en tiempo real a sus clientes.</p>

            <p class="text-warning">* Aplican cargos por envio de SMS</p>
        </div>
        <table class="table table-striped" *ngIf="campanas$ | async; let campanas; else loading">
            <thead class="bg-primary">
                <tr>
                    <th class="text-center">Campaña</th>
                    <th class="text-center">Fecha Creación</th>
                    <th class="text-center">Estado</th>
                    <th class="text-center">Opciones</th>
                </tr>
            </thead>
            <tr *ngFor="let c of campanas">
                <td>
                    <div style="padding-top: 7px">
                        <a href="javascript:void(0)" (click)="setCampanaEdicion(c)">
                        <i class="fa fa-arrow-circle-right"></i> {{c.nombre}}
                        </a>
                    </div>
                </td>
                <td class="text-center">
                    <div style="padding-top: 7px">{{c.fecha | amDateFormat:'YYYY-MM-DD HH:mm'}}</div>
                </td>
                <td class="text-center">
                    <div style="padding-top: 10px">
                        <ui-toggle name="estado" [ngModel]="c.estado" (change)="changeStateCampana($event.target.checked, c)"></ui-toggle>
                    </div>
                </td>
                <td class="text-center">
                    <button type="button" class="btn btn-success btn-sm" title="Editar" (click)="setCampanaEdicion(c)"><i class="fa fas fa-pencil"></i></button>&nbsp;
                    <button type="button" class="btn btn-info btn-sm" title="Resultados"><i class="fa fas fa-bar-chart"></i></button>&nbsp;
                    <button type="button" class="btn btn-danger btn-sm" (click)="eliminar(c.id)"><i class="fa fas fa-close"></i></button>&nbsp;
                </td>
            </tr>
        </table>
        <ng-template #loading>
            <div class="text-center text-muted" style="margin-top: 20px">
                <div style="margin-bottom: 10px">
                    <i class="fa fa-spinner fa-spin text-primary fa-2x" aria-hidden="true"></i>
                </div>
                Obteniendo campañas...
            </div>
        </ng-template>
    </div>
    <div class="col-md-6">

        <ul class="nav nav-tabs">
            <li role="presentation" #configPill class="active">
                <a href="#config" aria-controls="config" role="tab" data-toggle="tab">
                    <i class="fa fa-cog"></i> Configuración
                </a>
            </li>
            <li role="presentation" #numeracionPill *ngIf="campanaEditar">
                <a href="#numeracion" aria-controls="numeracion" role="tab" data-toggle="tab">
                    <i class="fa fa-sort-numeric-asc"></i> Numeración
                </a>
            </li>
            <li role="presentation" #importacionPill *ngIf="campanaEditar">
                <a href="#importacion" aria-controls="importacion" role="tab" data-toggle="tab">
                    <i class="fa fa-cloud-upload"></i> Importación
                </a>
            </li>
        </ul>

        <div class="tab-content" style="margin-top: 10px" id="peerTabs">
            <div role="tabpanel" class="tab-pane active" id="config" #config>
                <div *ngIf="!campanaEditar" class="alert alert-info">Podrá adicionar números una vez seleccione una campaña o cree una nueva.</div>
                <app-detalles-sms [campana]="campanaEditar" (guardar)="guardarCampana($event)" (reset)="resetEdicion()"></app-detalles-sms>
            </div>
            <div role="tabpanel" class="tab-pane" id="numeracion" #numeracion *ngIf="campanaEditar">
                <app-numeracion-sms [campanaId]="campanaEditar.id"></app-numeracion-sms>
            </div>
            <div role="tabpanel" class="tab-pane" id="importacion" #importacion *ngIf="campanaEditar">
                <app-numeracion-up [campanaId]="campanaEditar.id" (guardarNumeros)="guardarNumeros($event)"></app-numeracion-up>
            </div>
        </div>
       
    </div>
</div>