import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleComposeComponent, HasSpecialCharsDirective } from './schedule-compose/schedule-compose.component';
import { ScheduleBookComponent } from './schedule-book/schedule-book.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from 'src/app/_extra/ui/ui.module';
import { MomentModule } from 'ngx-moment';

@NgModule({
    declarations: [ScheduleComponent, ScheduleComposeComponent, ScheduleBookComponent, HasSpecialCharsDirective],
    imports: [
        UIModule,
        FormsModule,
        CommonModule,
        MomentModule,
        ReactiveFormsModule,
    ],
    exports: [ ScheduleComponent, ScheduleComposeComponent ]
})
export class ScheduleModule { }
