export interface IQueue_logQuery {
    fecha?: {
        inicio: string, 
        fin: string
    },
    fuente?: {
        operador: EQueryOperacion,
        valor: string
    },
    destino?: {
        operador: EQueryOperacion,
        valor: string
    },
    cola?: string[],
    idagente?: string,
    modoAgente: EModoAgenteQuery | number | string,
    disposicion: EQueueDisposicion | ECallDisposicion,
    queryType: EQueryType
}

export interface IAgent_logQuery {
    fecha?: {
        inicio: string, 
        fin: string
    },
    idagente: string,
    autor: string,
    disposicion: EAgentEvents,
    queryType: EQueryType
}

export interface ICall_logQuery {
    fecha?: {
        inicio: string, 
        fin: string
    },    
    fuente?: {
        operador: EQueryOperacion,
        valor: string
    },
    destino?: {
        operador: EQueryOperacion,
        valor: string
    },
    contexto?: {
        operador: EQueryOperacion,
        valor: string
    }
    disposicion: ECallDisposicion,
    queryType:  EQueryType
}

export enum EModoAgenteQuery {
    TODAS = <any>'',
    ENTRANTES = <any>'INBOUND',
    AGENDADAS = <any>'AGENDA',
    CLICK2CALL = <any>'CLICK2CALL',
    DIALER_PREDICTIVO = <any>'DIALER',
    DIALER_REPARTO = <any>'OUTBOUND_DIALER',
    SALIENTES_MANUAL = <any>'OUTBOUND_DIRECT'
}

export enum EQueryOperacion {
    EXACTO,
    COMENZANDO_CON,
    QUE_CONTENGA,
    TERMINE_EN
}

export enum EQueueDisposicion {
    TODAS = <any>'',
    CONTESTADA = <any>'COMPLETE%',
    ABANDONADA = <any>'ABANDON',
    TIMBRE_NO_CONTESTA = <any>'RINGNOANSWER',
    COLGADA_POR_AGENTE = <any>'COMPLETEAGENT',
    COLGADA_POR_USUARIO = <any>'COMPLETECALLER',
    TRANSFERENCIA_ATENDIDA = <any>'ATTENDEDTRANSFER',
    TRANSFERENCIA_CIEGA = <any>'BLINDTRANSFER',
    TIEMPO_EXPIRADO = <any>'EXITWITHTIMEOUT'
}

export enum ECallDisposicion {
    TODAS = <any>'',
    CONTESTADA = <any>'ANSWERED',
    NO_CONTESTADA = <any>'NO ANSWER',
    CONGESTION = <any>'CONGESTION',
    OCUPADO = <any>'BUSY',
    FALLIDA = <any>'FAILED',
}

export enum EAgentEvents {
    TODOS = <any>'',
    PAUSAS = <any>'PAUSE',
    LOGIN = <any>'AGENTLOGIN',
    LOGOUT = <any>'AGENTLOGOFF',
    TIMBRE_NOCONTESTA = <any>'RINGONANSWER'
}

export interface ICallLogQueryResult {
    accountcode: string,
    amaflags: number,
    billsec: number,
    calldate: string,
    channel: string,
    clid: string,
    dcontext: string
    disposition: ECallDisposicion,
    dst: string,
    dstchannel: string,
    duration: number,
    kerberus_reason: string
    lastapp: string,
    lastdata: string,
    linkedid: string,
    sequence: string,
    src: string,
    status: string,
    uniqueid: string,
    userfield: string,
    
    audio?: string
}

export interface IQueueLogResult {
    agent: string, 
    calldate: string,
    callid: string,
    linkedid: string,
    data1: string,
    data2: string,
    data3: string,
    data4: string,
    data5: string,
    dst: string,
    event: string,
    id: string,
    queuename: string,
    src: string,
    userfield: string,
    agenteNombre: string,
    audio?: string
    
}

export interface IStats { 
    fecha: string, 
    queue: IQueueStats[]
}

export interface IQueueStats {
    queuename: string,
    recibidas: number, 
    atendidas: number, 
    abandonos: number
}

export enum EQueryType {
    LIST, CVSFILE, XLSFILE
}
