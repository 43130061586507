import {
   Component,
   OnInit,
   Input,
   OnChanges,
   SimpleChanges,
   SimpleChange,
   Output,
   EventEmitter,
} from "@angular/core";
import { ICampanaSMS, ISMSRuta } from "../../../_interfaces/sms";
import {
   FormBuilder,
   FormGroup,
   Validators,
   AbstractControl,
} from "@angular/forms";
import * as dayjs from "dayjs";
import { SMSService } from "src/app/_services/sms.service";
import { Observable, Subject } from "rxjs";
import {
   map,
   shareReplay,
   switchMap,
   filter,
   defaultIfEmpty,
} from "rxjs/operators";

interface IChanges extends SimpleChange {
   previousValue: ICampanaSMS;
   currentValue: ICampanaSMS;
}

interface ISChanges extends SimpleChanges {
   campana: IChanges;
}

const isMesaageValid = (control: AbstractControl) => {
   const mensaje = control.value as string;
   return !/^[a-zA-Z0-9@!?:;_\-,.#$%&=\/\{\}\\\ ]+$/.test(mensaje) &&
      mensaje.length > 0
      ? { invalidChar: true }
      : null;
};

function validarCreditosEnRuta(rutas$: Observable<ISMSRuta[]>) {
   return (control: AbstractControl) => {
      return rutas$.pipe(
         map((rutas) => {
            const ruta = rutas.find((r) => r.id === control.value);
            return ruta && ruta.credito > 0 ? null : { credit: true };
         })
      );
   };
}

@Component({
   selector: "app-detalles-sms",
   templateUrl: "./detalles-sms.component.html",
   styleUrls: ["./detalles-sms.component.css"],
})
export class DetallesSMSComponent implements OnInit, OnChanges {
   frmCampana: FormGroup;
   creditosEnRuta: number;
   creditosEnRuta$: Observable<number>;
   calcularCreditos$: Subject<string>;
   rutas$: Observable<ISMSRuta[]>;
   @Input() campana: ICampanaSMS;
   @Output() reset = new EventEmitter<boolean>();
   @Output() guardar = new EventEmitter<ICampanaSMS>();

   get smsMensaje() {
      return this.frmCampana.get("mensaje");
   }

   constructor(private $fb: FormBuilder, private $sms: SMSService) {}

   ngOnInit() {
      this.rutas$ = this.$sms.getRutas().pipe(shareReplay(1));

      this.calcularCreditos$ = new Subject();
      this.creditosEnRuta$ = this.calcularCreditos$.pipe(
         switchMap((id) =>
            this.rutas$.pipe(
               map((rutas) => rutas.find((r) => r.id === id)),
               filter((ruta) => !!ruta),
               map((ruta) => ruta.credito / ruta.valor_pactado),
               defaultIfEmpty(0)
            )
         )
      );

      this.frmCampana = this.$fb.group({
         id: undefined,
         nombre: ["", Validators.required],
         mensaje: [
            "",
            [Validators.required, Validators.maxLength(160), isMesaageValid],
         ],
         fecha_ejecucion: ["", Validators.required],
         rutaid: ["", Validators.required, validarCreditosEnRuta(this.rutas$)],
         estado: false,
      });
      this.reset.emit(true);
   }

   ngOnChanges(cambios: ISChanges) {
      if (cambios.campana && cambios.campana.currentValue) {
         // hacer patch de valores
         const campana = cambios.campana.currentValue;
         this.frmCampana.patchValue({
            id: campana.id,
            nombre: campana.nombre,
            mensaje: campana.mensaje,
            fecha_ejecucion: dayjs(campana.fecha_ejecucion).format(
               "YYYY-MM-DD"
            ),
            rutaid: campana.rutaid,
            estado: !!campana.estado,
         });

         this.calcularCreditos$.next(campana.rutaid);
      }
   }

   guardarCampana(campana: ICampanaSMS) {
      this.ngOnInit();
      const _campana = Object.assign({}, campana, {
         fecha_ejecucion: campana.fecha_ejecucion + " 07:00:00",
      });
      this.guardar.emit(_campana);
   }

   mostrarCreditosDisponibles(idruta: string, rutas: ISMSRuta[]) {
      this.calcularCreditos$.next(idruta);
   }
}
