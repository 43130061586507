import {
   Component,
   OnInit,
   Input,
   OnChanges,
   SimpleChanges,
   SimpleChange,
   Output,
   EventEmitter,
} from "@angular/core";
import { ICallStatus, ECallStatus } from "src/app/_interfaces/callstatus";
import { Subject, Observable, of } from "rxjs";
import { tap, map, filter, defaultIfEmpty, switchMap } from "rxjs/operators";
import { QueueService } from "src/app/_services/queue.service";
import { IAgentState } from "src/app/_interfaces/monitor";

interface ISimpleChanges extends SimpleChanges {
   callStatus: ISimpleChange;
}

interface ISimpleChange extends SimpleChange {
   currentValue: ICallStatus;
   previousValue: ICallStatus;
}

@Component({
   selector: "app-agent-controller",
   templateUrl: "./agent-controller.component.html",
   styleUrls: ["./agent-controller.component.css"],
})
export class AgentControllerComponent implements OnInit, OnChanges {
   private customSkill$ = new Subject<number>();
   directorio$: Observable<{ iddb: string; numero: string }>;

   @Input() disabled = false;
   @Input() agente: IAgentState;
   @Input() callStatus: ICallStatus;
   @Output() cambioSkill = new EventEmitter<number>();
   @Output() contacto = new EventEmitter<string>();
   @Output() directorio = new EventEmitter<{ iddb: string; numero: string }>();

   get isAgentOffline() {
      return this.agente.estado.includes("OFFLINE");
   }
   get isAgentInPause() {
      return this.agente.estado.includes("PAUSE");
   }
   get agentColor() {
      const agentState = this.agente.estado;
      switch (agentState) {
         case "OFFLINE": {
            return "btn-default";
         }
         case "PAUSE": {
            return "btn-warning";
         }
         case "INBOUND_BUSY_PAUSE":
         case "INBOUND_BUSY": {
            return "btn-danger";
         }
         case "OUTBOUND_BUSY_PAUSE":
         case "OUTBOUND_BUSY": {
            return "btn-primary";
         }
         case "INBOUND_IDLE": {
            return "btn-success";
         }
         case "OUTBOUND_IDLE": {
            return "btn-info";
         }
         default: {
            return "btn-default";
         }
      }
   }

   constructor(private $queue: QueueService) {}

   ngOnInit() {
      this.directorio$ = of({ iddb: "", numero: "" });
   }

   ngOnChanges(changes: ISimpleChanges) {
      const { callStatus } = changes;
      if (!!callStatus) {
         // detectar cambios, si no hay no proseguir.
         const { currentValue, previousValue } = callStatus;
         if (!!previousValue && currentValue.estado === previousValue.estado) {
            return;
         }

         // Hubo cambio en la estado de las llamadas
         // console.log(this.agente, previousValue, currentValue);
         if (
            currentValue.estado === ECallStatus.IN_CALL &&
            !!currentValue.contactId
         ) {
            // Emitir el contacto para ser cargado.
            this.contacto.emit(currentValue.contactId);
         } else if (
            currentValue.estado !== ECallStatus.NO_CALL &&
            !currentValue.contactId
         ) {
            // En llamada saliente esta timbrando, en llamada entrante fue contestada.
            const colaLlamada = currentValue.queue;
            // Obtener el formulario que corresponde a esta cola.
            this.directorio$ = of(colaLlamada).pipe(
               filter((queue) => !!queue),
               switchMap((queue) => this.$queue.getQueue(queue)),
               map((queue) => ({
                  iddb: queue.directorio,
                  numero: currentValue.numero,
               })),
               tap((data) => this.directorio.emit(data)),
               defaultIfEmpty()
            );
         }
      }
   }

   cambiarSkill(skill: number) {
      this.cambioSkill.emit(skill);
   }
}
