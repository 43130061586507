<form autocomplete="off" novalidate class="form-group" style="margin-bottom: 0" #f="ngForm">
    <div class="row">
          <div class="col-sm-4" style="margin-bottom: 15px">
              <div class="input-group" ngModelGroup="fecha">
                  <div class="input-group-btn">
                    <button type="button" class="btn btn-success" style="min-width: 100px">Fecha</button>
                  </div>
                  <input type="text" ngDatePicker ngModel name="inicio" required>
                  <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                  <input type="text" ngDatePicker ngModel name="fin" required>                  
              </div>
          </div>
          <div class="col-sm-3" style="margin-bottom: 15px">
              <div class="input-group">
                    <div class="input-group-btn">
                      <button type="button" class="btn btn-success" style="min-width: 100px"><i class="fa fa-user-circle-o"></i> Agente</button>
                    </div>
                    <input type="text" ngModel name="idagente" class="form-control" placeholder="ID del agente">
                </div>
          </div>
          <div class="col-sm-offset-3 col-sm-2 text-right" style="margin-bottom: 15px">
              <button type="button" class="btn btn-primary" 
                [disabled]="f.invalid"
                (click)="ejecutarBusqueda(f.value)"
                >Ejecutar Búsqueda</button>
          </div>
    </div>
</form>

<h5 style="font-weight: 600" class="hidden-xs">Convenciones</h5>
<div style="margin-bottom: 10px; margin-left: 15px" class="hidden-xs table-responsive">
    <table style="width: 100%; font-size: 12px" class="text-muted">
          <tr>
              <td><i class="fa fa-arrow-circle-right text-muted"></i></td>
              <td>Logueos</td>
              <td style="width: 25px"><i class="fa fa-sign-in text-muted"></i></td>
              <td>Hora del 1er Login </td>
              <td><i class="fa fa-sign-out text-muted"></i></td>
              <td>Hora del último Logout</td>
              <td class="text-primary"><i class="fa fa-hourglass-end"></i></td>
              <td class="text-primary">Tiempo total Conectado</td>
              <td><i class="fa fa-phone text-muted"></i></td>
              <td>Total llamadas Atendidas</td>
              <td><i class="fa fa-hourglass-half text-muted"></i></td>
              <td>Tiempo total de Conversación</td>
              <td><i class="fa fa-pause-circle-o text-muted"></i></td>
              <td>Tiempo total de Pausa</td>
          </tr>
    </table>
</div>

<div class="table-responsive">
<table class="table table-striped" style="font-size: 12px">
    <tbody *ngFor="let l of logs; let i=index">
        <tr style="background-color: #148337; color: white; font-weight: 600"
            *ngIf="i === 0">
            <td></td>
            <td class="text-center" *ngFor="let d of l.data">
                {{d.fecha | toTimeFormat:'MM/DD'}}
            </td>
        </tr>
        <tr>
            <td style="background-color: #dcf5e4">
                <i class="fa fa-user-circle-o"></i> {{l.agente}}
                <br>
                <small>{{l.nombre}}</small>
            </td>
            <td *ngFor="let d of l.data">                
                <table>
                    <tr>
                        <td><i class="fa fa-arrow-circle-right text-muted"></i></td>
                        <td>{{d.cantLogueo}}</td>
                    </tr>
                    <tr>
                        <td style="width: 15px"><i class="fa fa-sign-in text-muted"></i></td>
                        <td *ngIf="d.fechaInicio !== ''">{{d.fechaInicio | toTimeFormat:'HH:mm'}}</td>
                        <td *ngIf="d.fechaInicio === ''">-</td>
                    </tr>
                    <tr>
                        <td><i class="fa fa-sign-out text-muted"></i></td>
                        <td *ngIf="d.fechaFin !== ''">{{d.fechaFin | toTimeFormat:'HH:mm'}}</td>
                        <td *ngIf="d.fechaFin === ''">-</td>
                    </tr>
                    <tr><td colspan="2"><hr style="margin-top: 3px; margin-bottom: 5px"></td></tr>
                    <tr class="text-primary">
                        <td style="margin-top: 3px"><i class="fa fa-hourglass-end"></i></td>
                        <td style="margin-top: 3px">{{d.onlineTime | toTime | date:'HH:mm:ss'}}</td>
                    </tr>
                    <tr>
                        <td><i class="fa fa-phone text-muted"></i></td>
                        <td>{{d.cantLlamadas}}</td>
                    </tr>
                    <tr>
                        <td><i class="fa fa-hourglass-half text-muted"></i></td>
                        <td>{{d.convTime | toTime | date:'HH:mm:ss'}}</td>
                    </tr>
                    <tr>
                        <td><i class="fa fa-pause-circle-o text-muted"></i></td>
                        <td>{{d.pauseTime | toTime | date:'HH:mm:ss'}}</td>
                    </tr>
                </table>
            </td>
        </tr>
    </tbody>
</table>
</div>
<div class="panel panel-primary">
  <div class="panel-heading">
      <strong><i class="fa fa-info"></i>  Consultas y operaciones</strong>
  </div>
  <div class="panel-body">
      <div class="row">
          <div class="col-md-3">
              <h5 style="font-weight: 600" >Convenciones</h5>
              <table style="margin-left: 20px">
                <tr>
                    <td><i class="fa fa-arrow-circle-right text-muted"></i></td>
                    <td>Cantidad de Logueos</td>
                </tr>
                <tr>
                    <td style="width: 25px"><i class="fa fa-sign-in text-muted"></i></td>
                    <td>Hora del primer Login del día</td>
                </tr>
                <tr>
                    <td><i class="fa fa-sign-out text-muted"></i></td>
                    <td>Hora del último Logout del día</td>
                </tr>
                <tr>
                    <td><i class="fa fa-hourglass-end text-muted"></i></td>
                    <td>Tiempo total conectado</td>
                </tr>
                <tr>
                    <td><i class="fa fa-phone text-muted"></i></td>
                    <td>Total llamadas Atendidas</td>
                </tr>
                <tr>
                    <td><i class="fa fa-hourglass-half text-muted"></i></td>
                    <td>Tiempo total de conversación</td>
                </tr>
                <tr>
                    <td><i class="fa fa-pause-circle-o text-muted"></i></td>
                    <td>Tiempo total de Pausa</td>
                </tr>
            </table>
          </div>
          <div class="col-md-5"></div>
          <div class="col-md-4">
              Puede descargar la información en CSV para ser filtrada en MS Excel&trade;
              <hr>
              <div class="text-center">
                <button type="button" class="btn btn-primary" [disabled]="!logs || logs?.length === 0" (click)="descargarAgentLog(f.value)">Detallado en *.CSV</button>
                &nbsp;
                <button type="button" 
                        (click)="descargarResumen()"
                        [disabled]="!logs || logs?.length === 0" class="btn btn-warning">Descargar en *.CSV
                </button>
              </div>
          </div>
      </div>
  </div>
</div>