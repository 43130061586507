export interface IDirectorio {
    iddb: string;
    nombre: string;
    descripcion?: string;
    primario: string;
    campos: ICampoDirectorio[]
}

export interface ICampoDirectorio {
    nombre: string;
    tipo: ETipoCampoDirectorio;
    editable: boolean;
    visible: boolean
}

export enum ETipoCampoDirectorio {
    TEXTO = 'texto',
    FECHA = 'fecha',
    NUMERO = 'numero',
    AREATEXTO = 'area',
    TELEFONO = 'tel',
    TELEFONO_ALTERNATIVO = 'alttel'
}
