import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ISchedule } from '../_interfaces/schedule';
import { HeaderService } from './header.service';
import * as dayjs from 'dayjs';

@Injectable({providedIn: 'root'})
export class ScheduleService {
    
    get SCHEDULE_URL() { return `${this.$header.getAPIurl()}/schedule` }

    constructor(private $http: HttpClient, private $header: HeaderService) { }
    
    obtenerEventos(fecha: Date, idagente?: string): Observable<ISchedule[]> {
        const url = !idagente ? 
            `${this.SCHEDULE_URL}/${dayjs(fecha.getTime()).format('YYYY-MM-DD')}` : 
            `${this.SCHEDULE_URL}/${dayjs(fecha.getTime()).format('YYYY-MM-DD')}/${idagente}`;

        return this.$http.get<ISchedule[]>(url, { headers: this.$header.getHttpHeaders() });
    }

    guardarEvento(evento: ISchedule) {
        return this.$http.post<ISchedule>(
            `${this.SCHEDULE_URL}`, 
            evento, 
            { headers: this.$header.getHttpHeaders() }
        );
    }

    eliminarEvento(eventoid: string) {
        return this.$http.delete(`${this.SCHEDULE_URL}/${eventoid}`, { headers: this.$header.getHttpHeaders() })
    }
}
