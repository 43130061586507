import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { IVR } from "../../../_interfaces/ivr";
import { Observable } from "rxjs";
import { TroncalService } from "../../../_services/troncal.service";
import { IVRService } from "../../../_services/ivr.service";
import { GruposService } from "../../../_services/grupo.service";
import { QueueService } from "../../../_services/queue.service";
import { PeerService } from "../../../_services/peer.service";
import { Troncal } from "../../../_interfaces/troncal";
import { Peer } from "../../../_interfaces/peer";
import { Grupo } from "../../../_interfaces/grupo";
import { Queue } from "../../../_interfaces/queue";
import { EOpcionesCallback, ICallBack } from "../../../_interfaces/callback";

@Component({
   selector: "app-regla-callback",
   templateUrl: "./regla-callback.component.html",
   styleUrls: ["./regla-callback.component.css"],
})
export class ReglaCallbackComponent implements OnInit {
   regla: ICallBack;
   _EnumOpIVR = EOpcionesCallback;
   troncal$: Observable<Troncal[]>;
   ivr$: Observable<IVR[]>;
   peer$: Observable<Peer[]>;
   grupo$: Observable<Grupo[]>;
   queue$: Observable<Queue[]>;
   ops = [];

   @Input()
   set callback(callback: ICallBack) {
      this.regla = !!callback
         ? Object.assign({}, callback, {
              prefix: (callback.prefix as string[]).join(", "),
           })
         : undefined;
   }
   get callback() {
      return this.regla;
   }

   @Output()
   guardar = new EventEmitter<ICallBack>();

   constructor(
      private $troncal: TroncalService,
      private $ivr: IVRService,
      private $peer: PeerService,
      private $grupo: GruposService,
      private $queue: QueueService
   ) {}

   ngOnInit() {
      this.ops = Object.keys(EOpcionesCallback).map((key, idx) => {
         return {
            nombre: key,
            operacion: EOpcionesCallback[key],
         };
      });
      this.troncal$ = this.$troncal.getTroncales();
      this.ivr$ = this.$ivr.getDids();
      this.peer$ = this.$peer.getPeers();
      this.grupo$ = this.$grupo.getGrupos();
      this.queue$ = this.$queue.getQueues();
   }

   guadarRegla(regla: ICallBack) {
      const prefix = [...(regla.prefix as string).replace(/ /g, "").split(",")];
      const callback = {
         ...regla,
         prefix: prefix,
         AMD: !!regla.AMD,
         prepend: !!regla.prepend ? regla.prepend : 0,
      };
      this.guardar.emit(callback);
   }
}
