import {
   Component,
   EventEmitter,
   Input,
   OnInit,
   Output,
   AfterViewInit,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { defaultIfEmpty, delay, filter, map } from "rxjs/operators";
import { ILead } from "src/app/_interfaces/lead.entity";
import { IPipeLine } from "src/app/_interfaces/pipeline.entity";

@Component({
   selector: "app-lead",
   templateUrl: "./lead.component.html",
   styleUrls: ["./lead.component.css"],
})
export class LeadComponent implements OnInit, AfterViewInit {
   @Input()
   leadInPipe: ILead;

   @Output()
   leadToShow = new EventEmitter<ILead>();

   leadDetails$: Observable<string>;

   constructor() {}

   ngOnInit(): void {}

   ngAfterViewInit(): void {
      this.leadDetails$ = of(this.leadInPipe).pipe(
         delay(500),
         filter((lead) => lead.fuente === "CLICK2CALL"),
         map(
            (lead) => JSON.parse(lead.fuente_dst) as { "phone-number": string }
         ),
         map((data) => data["phone-number"]),
         defaultIfEmpty(this.leadInPipe.fuente_dst)
      );
   }

   leadDetails() {
      this.leadToShow.emit(this.leadInPipe);
   }
}
