<div class="dropdown">
    <input type="text" [ngClass]="styleClass" [style.font-size]="style" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" placeholder="{{header}}" 
           [formControl]="busqueda"
           >
    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1" style="margin-top: 5px; max-height: 400px; overflow-y: auto; overflow-x: hidden">
        <li class="dropdown-header">{{header}}</li>
        <li><a href="javascript:void(0)" *ngFor="let o of (opciones$ | async)" (click)="agregar(o)">
               <i class="fa fa-check-circle-o text-muted" style="margin-right: 5px"></i> {{o}}
        </a></li>
    </ul>
</div>
<div class="" style="margin-top: 7px">
    <button style="margin-bottom: 5px; margin-right: 5px" class="btn btn-xs" *ngFor="let c of opcionesSeleccionadas$ | async" (click)="remover(c)">
        {{c}} <i class="fa fa-close"></i>
    </button>
</div>