<div class="row" *ngIf="loadingStats | async as sts">
   <div class="col-md-3">
      <div class="bs-callout bs-callout-primary">
         <h4 class="text-primary">Informes en Callcenter</h4>
         Aquí podrás evidenciar de manera gráfica la atención en colas de llamada.
      </div>
      <form autocomplete="off" novalidate class="form-group form-group-sm" style="margin-bottom: 0" #f="ngForm">
         <div ngModelGroup="fecha">
            <div class="input-group input-group-sm" style="margin-bottom: 10px">
               <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px"><i class="fa fa-calendar"></i>
                     Inicio</button>
               </div>
               <input type="text" ngDatePicker ngModel name="inicio" #fecha_inicio required>
            </div>
            <div class="input-group input-group-sm" style="margin-bottom: 10px">
               <div class="input-group-btn">
                  <button type="button" class="btn btn-success" style="min-width: 100px"><i class="fa fa-calendar"></i>
                     Final</button>
               </div>
               <input type="text" ngDatePicker ngModel name="fin">
            </div>
         </div>
         <div style="margin-bottom: 10px">
            <auto-search [header]="'Colas Disponibles'" [opciones]="colas$ | async | queuesAllowed : usuario" ngModel
               name="cola"></auto-search>
            <!-- <input type="text" ngModel name="cola" class="form-control" placeholder="Nombre de cola de Callcenter"> -->
         </div>
         <div class="text-right" style="margin-bottom: 10px">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="f.invalid || sts.loading"
               (click)="ejecutarBusqueda(f.value)">
               Ejecutar Búsqueda <i class="fa fa-spinner fa-spin" *ngIf="sts.loading"></i> </button>
         </div>
      </form>
   </div>
   <div class="col-md-9">
      <div class="panel panel-primary">
         <div class="panel-heading">
            <i class="fa fa-bar-chart"></i> Estadísticas de Callcenter
         </div>
         <div class="panel-body text-center" *ngIf="queryStatsPlot$ | async as plots; else loadingStats">
            <chart *ngFor="let plot of plots" [options]="plot" (load)="passChart($event.context, 'stats')"></chart>
         </div>

         <ng-template #loadingStats>
            <div class="text-center text-muted" style="padding: 50px;" *ngIf="!sts.loading">Realiza un consulta.</div>
         </ng-template>
      </div>
      <div class="panel panel-primary">
         <div class="panel-heading">
            <i class="fa fa-line-chart"></i> Timeline de atención
         </div>
         <div class="panel-body text-center" *ngIf="queryTimelinePlot$ | async as plots">
            <chart *ngFor="let opt of plots" [options]="opt"></chart>
         </div>
      </div>

      <div class="panel panel-primary">
         <div class="panel-heading">
            <i class="fa fa-pie-chart"></i> Rendimiento de agentes
         </div>
         <div class="panel-body text-center">
            <div *ngFor="let opt of (queryAgentsSumary$ | async)">
               <chart [options]="opt"></chart>
            </div>
         </div>
      </div>
   </div>
</div>