export interface ICampanaDialer {
   idcampana: string;
   nombre?: string;
   tipo?: ETipoCampanaDialer;
   estado?: EEstadoCampanaDialer;
   reparto?: IContactoReparto[];
   directorio?: string; // <- iddb
   cola?: string;
   troncal?: string;
   prefijo?: string;
   timeout?: number;
   amd?: boolean;
   intentos?: number;
   tiempoIntento?: number;
   tasa?: number;
   fechaCreacion?: string;
}

export interface IContactoReparto {
   id: string;
   idContacto: string;
   estado: EEstadoContactoDialer;
   contacto: IContacto[];
   agenteid?: string;
}

export interface IContacto {
   numero: string | number;
   estado: EEstadoContacto;
}

export interface ICampanaStats {
   idcampana: string;
   nombre: string;
   tipo: ETipoCampanaDialer;
   estado: EEstadoCampanaDialer;
   pendientes: number;
   atendidas: number;
   fallidas: number;
   enCurso: number;
   enAudio: number;
   enEspera: number;
   total: number;
   reIntento: number;
}

export enum EEstadoContacto {
   EN_ESPERA = "EN_ESPERA",
   EN_CURSO = "EN_CURSO",
   EN_AUDIO = "EN_AUDIO",
   EN_LLAMADA = "EN_LLAMADA",
   FINALIZADO = "FINALIZADO",
   FALLIDO = "FALLIDO",
}

export enum EEstadoContactoDialer {
   EN_ESPERA = "EN_ESPERA",
   EN_CURSO = "EN_CURSO",
   REINTENTAR = "REINTENTAR",
   FINALIZADO = "FINALIZADO",
   FALLIDO = "FALLIDO",
}

export enum ETipoCampanaDialer {
   MANUAL = "preview",
   PROGRESIVO = "progresivo",
   PREDICTIVO = "predictivo",
   PREDICTIVO_PRESS = "predictivo-press",
}

export enum EEstadoCampanaDialer {
   EN_CURSO = "EN_CURSO",
   EN_PAUSA = "EN_PAUSA",
   DETENIDA = "DETENIDA",
}

export interface IDialerCampana {
   id: string;
   fecha?: string;
   nombre?: string;
   destino?: string;
   proveedor?: string;
   tasa?: number;
   estado?: boolean;
}

export interface IDialerNumero {
   idnumero?: number;
   fecha_mod?: Date;
   idcampana: string;
   numero: number;
   variable: Object;
   estado: EEstadoNumeroDialer;
}

export enum EEstadoNumeroDialer {
   EN_ESPERA = "EN_ESPERA",
   EN_CURSO = "EN_CURSO",
   EN_ESCUCHA = "EN_ESCUCHA",
   EN_LLAMADA = "EN_LLAMADA",
   FINALIZADO = "FINALIZADO",
   FALLIDO = "FALLIDO",
}
