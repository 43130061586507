import { Pipe, PipeTransform } from '@angular/core';
import { IAgentMonitor } from 'src/app/_interfaces/_all';
import { INBOUND_SKILL, OUTBOUND_SKILL, MIXED_SKILL, EAgentSkill } from 'src/app/_interfaces/agente';

@Pipe({
    name: 'agenteModoSkill'
})
export class AgenteModoSkillPipe implements PipeTransform {
    transform(agente: IAgentMonitor): string {
        if (agente.defaultPenalty >= INBOUND_SKILL.MIN && agente.defaultPenalty <= INBOUND_SKILL.MAX) {
            return EAgentSkill.INBOUND_SKILL;
        }
        else if (agente.defaultPenalty >= MIXED_SKILL.MIN && agente.defaultPenalty <= MIXED_SKILL.MAX) {
            return EAgentSkill.MIXED_SKILL;
        }
        else if (agente.defaultPenalty < 0 || (agente.defaultPenalty >= OUTBOUND_SKILL.MIN && agente.defaultPenalty <= OUTBOUND_SKILL.MAX)) {
            return EAgentSkill.OUTBOUND_SKILL;
        }
    }
}
