<div class="">
   <div class="modal-dialog modal-sm" role="document" style="margin-top: 100px">
      <form novalidate #f="ngForm" (ngSubmit)="validar(f.value)">
         <div class="modal-content">
            <div class="modal-header text-center">
               <img src="public/img/kerberus.png" alt="Kerberus Login Logo" style="margin-bottom: 15px">
               <h5 class="modal-title text-muted">Bienvenid@ a Kerberus IPBX {{RELEASE}}</h5>
            </div>
            <div class="modal-body">
               <div class="input-group" style="margin-bottom: 10px">
                  <span class="input-group-addon"><i class="fa fa-user"></i></span>
                  <input ngModel name="usuario" type="text" class="form-control" placeholder="Usuario" required>
               </div>
               <div class="input-group" style="margin-bottom: 10px">
                  <span class="input-group-addon"><i class="fa fa-qrcode"></i></span>
                  <input ngModel name="password" type="password" class="form-control" placeholder="Contraseña" required>
               </div>
               <select [(ngModel)]="defaultOp" name="tipo" class="form-control" required>
                  <option value="AGENTE">Agente</option>
                  <option value="ADMIN">Admin / Manager</option>
               </select>
               <div [hidden]="errMensaje === ''" class="text-danger text-center" style="margin-top: 10px">
                  {{errMensaje}}
               </div>
            </div>
            <div class="modal-footer">
               <button type="submit" class="btn btn-primary" [disabled]="f.invalid">Iniciar Sesión</button>
            </div>
         </div><!-- /.modal-content -->
      </form>
   </div><!-- /.modal-dialog -->
   <div [hidden]="loading === false" class="text-center" style="margin-bottom: 15px">
      <div class="spinner">
         <div class="bounce1"></div>
         <div class="bounce2"></div>
         <div class="bounce3"></div>
      </div>
   </div>
   <div class="text-center">
      <div style="color: #a6afc1; font-size: 12px">&copy; 2006 - 2024 Kerberus Ingeniería S.A.S<br>
         <a href="javascript:void(0)">soporte@kerberus.com.co</a>
      </div>
   </div>
</div>