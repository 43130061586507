import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inCall'
})
export class InCallPipe implements PipeTransform {

    transform(id: string): boolean {
        return id.includes('no-call_');
    }

}
