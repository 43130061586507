<form [formGroup]="frmPregunta">

   <input type="hidden" formControlName="orden">
   <input type="hidden" formControlName="tipo" #tipoPregunta>

   <input formControlName="pregunta" type="text" class="text-input" placeholder="{{(ordenPregunta+1)}}. Pregunta..."
      style="margin-bottom: 10px; font-weight: 600">

   <div formArrayName="respuestas">
      <div *ngFor="let r of frmRespuestas.controls; let j = index">
         <div [formGroupName]="j">

            <div class="input-group input-group-sm" style="padding-bottom: 10px">
               <span class="input-group-addon">
                  <i class="fa"
                     [ngClass]="{'fa-circle-o': tipoPregunta.value === 'SELECCION', 'fa-text-width': tipoPregunta.value === 'ABIERTA', 'fa-calendar-check-o' : tipoPregunta.value === 'FECHA', 'fa-hashtag' : tipoPregunta.value === 'NUMERO'}"></i>
               </span>
               <input type="text" *ngIf="tipoPregunta.value === 'SELECCION' && r.value.tipo === 'SIMPLE'"
                  formControlName="respuesta" class="form-control" aria-label="..." placeholder="Opción {{(j+1)}}">
               <input type="text" *ngIf="tipoPregunta.value === 'SELECCION' && r.value.tipo === 'OTRO'"
                  formControlName="respuesta" value="" readonly class="form-control" aria-label="..."
                  placeholder="Otro...">
               <input type="text" *ngIf="tipoPregunta.value === 'ABIERTA'" formControlName="respuesta" value="" readonly
                  class="form-control" aria-label="..." placeholder="Texto de Usuario...">
               <input type="text" *ngIf="tipoPregunta.value === 'NUMERO'" formControlName="respuesta" value="" readonly
                  class="form-control" aria-label="..." placeholder="Texto numerico de Usuario ...">
               <input type="text" *ngIf="tipoPregunta.value === 'FECHA'" formControlName="respuesta" value="" readonly
                  class="form-control" aria-label="..." placeholder="Fecha a ser seleccionada">

               <span class="input-group-btn" *ngIf="tipoPregunta.value === 'SELECCION'">
                  <button class="btn btn-default" type="button" (click)="delRespuesta(j)">
                     <i class="fa fa-close"></i>
                  </button>
                  <button class="btn btn-success" type="button" data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="true">
                     <i class="fa fa-caret-down"></i>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1" style="font-size: 13px">
                     <li><a href="javascript:void(0)" (click)="agregarArbol(j, 'ABIERTA')"><i
                              class="fa fa-text-width text-muted"></i> Respuesta abierta</a></li>
                     <li><a href="javascript:void(0)" (click)="agregarArbol(j, 'NUMERO')"><i
                              class="fa fa-hashtag text-muted"></i>
                           Respuesta numerica</a></li>
                     <li><a href="javascript:void(0)" (click)="agregarArbol(j)"><i
                              class="fa fa-dot-circle-o text-muted"></i>
                           Selección múltiple</a></li>
                     <li><a href="javascript:void(0)" (click)="agregarArbol(j, 'FECHA')"><i
                              class="fa fa-calendar-check-o text-muted"></i> Respuesta con fecha</a></li>
                  </ul>
               </span>
            </div>

            <div formArrayName="next" [ngClass]="{'arbol' : frmRespuestas.controls[j].get('next').controls.length > 0}">
               <div class="panel" style="background-color: #f7f7f7; border: 1px solid #ececec"
                  *ngFor="let pregunta of frmRespuestas.controls[j].get('next').controls; let i = index">
                  <div [formGroupName]="i" class="panel-body">
                     <app-arbol [frmPregunta]="pregunta" [ordenPregunta]="i"
                        (eliminarPregunta)="delPreguntaArbol(j, $event)"></app-arbol>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>

   <div class="row">

      <div class="col-xs-6">
         <span class="btn-group">
            <button class="btn btn-xs" title="Subir pregunta" type="button" [disabled]="true"><i
                  class="fa fa-arrow-up"></i></button>
            <button class="btn btn-xs" title="Bajar pregunta" type="button" [disabled]="true"><i
                  class="fa fa-arrow-down"></i></button>
         </span>
         <!--delPregunta(formulario.controls.pregunta, i)-->
         <button type="button" class="btn btn-xs" (click)="delPregunta(ordenPregunta)">
            <i class="fa fa-trash"></i> Eliminar pregunta
         </button>
      </div>
      <div class="col-xs-6 text-right">
         <span *ngIf="tipoPregunta.value === 'SELECCION'">
            <a href="javascript:void(0)" (click)="addRespuesta('SIMPLE')">
               <i class="fa fa-check-circle-o"></i> Añadir Opción
            </a>
            <!--Chequear si la opción de otro existe-->
            <span *ngIf="mostarOtro">
               | <a href="javascript:void(0)" (click)="addRespuesta('OTRO')">
                  <i class="fa fa-pencil"></i> Añadir "OTRO"
               </a>
            </span>
         </span>
      </div>
   </div>

</form>