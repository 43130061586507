<div class="row" style="padding: 10px" *ngIf="apiKeys$ | async; let apiKeys">
    <div class="col-lg-4">
        <div class="bs-callout bs-callout-danger text-justify">
            <h5><p><i class="fa fa-key"></i> API Manager</p></h5>
            <p>En esta sección podrás configurar llaves de integración tanto para los servicios web CALLBACK cómo para el Asterisk&trade; Manager.</p>
            <hr>
            <div *ngIf="apiKeys.length <= 10; else noMoreKeys">
                <button class="btn btn-primary btn-block" (click)="nuevoApi('API_KEY')" style="margin-bottom: 10px"><i class="fa fas fa-key"></i> Nueva API Key</button>
                <button class="btn btn-warning btn-block" (click)="nuevoApi('MANAGER_KEY')"><i class="fa fa-asterisk"></i> Nueva Asterisk&trade; Manager Key</button>
            </div>
            <ng-template #noMoreKeys>
                Ya tienes suficientes llaves, trata de administrarlas mejor.
            </ng-template>
        </div>
    </div>
    <div class="col-lg-8">
        <app-apikey [apiKey]="ak" (apikeyMod)="guardarApiKey($event)" (eliminar)="eliminarApiKey($event)" *ngFor="let ak of apiKeys"></app-apikey>
    </div>

</div>