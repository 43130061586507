import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialerNumbersPipe } from './pipes/dialerNumbers.pipe';
import { DialerService } from '../../_services/dialer.service';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { DialerComponent } from './dialer.component';;
import { DialerSetupModule } from './dialer-setup/dialer-setup.module';
import { CampainStatsComponent } from './campain-stats/campain-stats.component';
import { CampainViewComponent } from './campain-view/campain-view.component';
import { CampainStatusPipe } from './pipes/campain-status.pipe';
import { CampainEnCursoPipe } from './pipes/campain-en-curso.pipe';
import { CallStatusPipe } from './pipes/call-status.pipe';
import { RepartoStatusPipe } from './pipes/reparto-status.pipe';
import { CampainAgentsComponent } from './campain-agents/campain-agents.component';
@NgModule({
    providers: [DialerService],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MomentModule,
        NgxPaginationModule,
        DialerSetupModule
    ],
    exports: [DialerNumbersPipe],
    declarations: [DialerNumbersPipe, DialerComponent, CampainStatsComponent, CampainViewComponent, CampainStatusPipe, CampainEnCursoPipe, CallStatusPipe, RepartoStatusPipe, CampainAgentsComponent],
})
export class DialerModule { }
