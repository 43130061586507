import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "toDate",
})
export class ToDatePipe implements PipeTransform {
   transform(value: string): any {
      return !!value ? new Date(Number.parseInt(`${value}`)) : new Date();
   }
}
