import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from "dayjs";

@Pipe({
   name: "timeFromNow",
})
export class TimeFromNowPipe implements PipeTransform {
   transform(currentTime: number, timeInMs: string | number): Date {
      const time = Number.parseInt(`${timeInMs}`);
      const auxTime = new Date().setHours(0, 0, 0, 0);

      if (currentTime - time < 0) {
         return new Date(auxTime);
      }
      return new Date(auxTime + (currentTime - time));
   }
}
