import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { map, flatMap, switchMap, catchError, tap } from 'rxjs/operators';
import { EUsuarioRol } from 'src/app/_interfaces/usuario';

@Injectable({
  providedIn: 'root'
})
export class QueueVisualizeGuard implements CanActivate {

    constructor(private $usr: UsuarioService, private $router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        
        return of(next.params as ({ queueid })).pipe(
            map(params => params.queueid),
            // Obtener los permisos del usuario
            map(queueid => {
                if (this.$usr.usuario.permisos <= EUsuarioRol.MANAGER) {
                    return true;
                } else  {
                    const hasQueue = this.$usr.usuario.manager.map(m => m.cola).includes(queueid);
                    const isUsuarioSuper = this.$usr.usuario.permisos === EUsuarioRol.SUPER;
                    
                    if (hasQueue && state.url.includes('callcenter/setup')) {
                        return true;
                    } else if (hasQueue && isUsuarioSuper) {
                        return true;
                    } else {
                        throw "403";
                    }
                }
            }),
            catchError(err => {
                this.$router.navigateByUrl('/dashboard/(view:callcenter)');
                return of(err);
            })
        );
    }
  
}
