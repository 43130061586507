import {Directive, AfterViewInit, ElementRef, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: '[ngDatePicker]', 
    providers: [
        {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DatePickerDirective }
    ]
})
export class DatePickerDirective implements AfterViewInit, ControlValueAccessor {
    private id: string;
    private placeholder: string;
    private _onChange: (value: any) => void;    
    
    private _fecha: string;
    private _formato = 'yyyy-mm-dd';
    private _idioma = 'es';
    
    private _estilo = 'form-control';
    @Input() set estilo(estilo) {
        
        if (!!estilo && estilo !== 'none') {
            this.render.addClass(this.el.nativeElement, estilo);
        } else if (!!estilo && estilo === 'none') {
            this.render.removeClass(this.el.nativeElement, 'form-control');
        }
    }
    get estilo() { return this._estilo }
    
    @Input() set fechaInicial(fecha) {
        if (!!fecha) {
            fecha = moment( fecha ).format('YYYY-MM-DD');
            this._fecha = fecha;
        } else {
            this._fecha = undefined;
        }
    }
    get fechaInicial() { return this._fecha }
    
    @Input() set idioma(idioma) {
        this._idioma = (idioma) || 'es';
    }
    get idioma() { return this._idioma; }
    
    @Input() set formato(formato) {
        this._formato = (formato) || 'yyyy-mm-dd';
    }
    get formato() { return this._formato; }
    
    constructor(private el: ElementRef, private render: Renderer2) { 
        this.id = this.guid();
        this.render.setAttribute(this.el.nativeElement, 'id', 'datepicker_' + this.id);
        this.render.setAttribute(this.el.nativeElement, 'placeholder', moment().format(this.formato.toUpperCase()));
        this.render.addClass(this.el.nativeElement, this.estilo);
        this.render.setStyle(this.el.nativeElement, 'text-align', 'center');
    }
    
    ngAfterViewInit() {
        this.placeholder = moment().format(this.formato.toUpperCase());
        $('#datepicker_' + this.id).datepicker({
            format: this.formato,
            autoclose: true, 
            language: this.idioma,
            todayHighlight: true,
            weekStart: 0
        }).on('changeDate', e => {            
            this.fechaInicial = e.format(this.formato);
            if (!!this._onChange)
                this._onChange(this.fechaInicial);
        });

        this.manualChangeDetection();

        if (!!this.fechaInicial) {
            $('#datepicker_' + this.id).datepicker('update', moment( this.fechaInicial ).format('YYYY-MM-DD'))
        }
    }
    
    writeValue(value: any) {
        if (!!value) {
            $('#datepicker_' + this.id).datepicker('update', value);
            this.fechaInicial = value;
        }
    }
    
    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    
    registerOnTouched() {}

    /**
     * Función para detectar cambios efectuados en la fecha de manera manual
     */
    private manualChangeDetection() {
        $('#datepicker_' + this.id).change(() => {
            this.fechaInicial = <string>$('#datepicker_' + this.id).val();
            this._onChange(this.fechaInicial);
        });
    }
    
    private guid() {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
}
