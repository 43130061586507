export enum ECallStatus {
   CALLING = "Ringing",
   NO_CALL = "",
   IN_CALL = "Up",
}

export interface ICallStatus {
   queue: string;
   numero: string;
   duracion: string;
   estado: ECallStatus;
   fechaStatus?: number;
   contactId: string;
}
