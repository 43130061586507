import { Component, OnInit } from '@angular/core';
import { ICallBack } from '../../_interfaces/callback';
import { CallbackService } from '../../_services/callback.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HeaderService } from '../../_services/header.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

    path: string;
    readonly body = {
        id: 'ID de la regla Ej: aa11ee22ii',
        numero: '3009005040',
        testmode: false,
        extra: {
            mi_variable_1: 'valor 1',
            mi_variable_2: 'valor 2',
            mi_variable_n: 'valor n',
        }
    };
    readonly cabecera = {
        Apikey: 'api key Ej: aaaaccccc'
    }

    callbackSeleccionado: ICallBack;
    callback$: Observable<ICallBack[]>;

    constructor(private $callback: CallbackService, private $headers: HeaderService) { }

    ngOnInit() {
        const path = document.location.origin;
        this.path = path + this.$headers.getAPIurl() + '/callback';
        this.path = this.path.substring(this.path.lastIndexOf('http:'));

        $('#modalWait_kerberus').modal();
        this.callback$ = this.$callback.getCallbacks()
            .pipe(tap(() => $('#modalWait_kerberus').modal('hide')))
        this.callbackSeleccionado = undefined;
    }

    guardarCallback(callback: ICallBack) {
        if (callback) {
            $('#modalWait_kerberus').modal();
            this.$callback.guardarCallback(callback)
                .pipe(
                    finalize(() => this.ngOnInit())
                )
                .subscribe(
                    res => console.log(res),
                    err => console.warn(err)
                );
        } 
    }

    setCallback(callback: ICallBack) {
        this.callbackSeleccionado = Object.assign({}, callback);
    }

    eliminarCallback(id: string) {
        this.$callback.eliminarCallbackRule(id)
            .pipe(
                finalize(() => this.ngOnInit())
            )
            .subscribe(
                res => console.log(res),
                err => console.warn(err)
            );
    }

    isToggled() {
        return !$('#detallesCallback').hasClass('in');
    }

}
