import { Pipe, PipeTransform } from '@angular/core';
import { IQueueStats } from 'src/app/_interfaces/monitor';

@Pipe({
    name: 'abandonProm'
})
export class AbandonAveragePipe implements PipeTransform {
    transform(stat: IQueueStats): any {
        if ((stat.abandon) !== 0) {
            return stat.abandonHoldtime / stat.abandon;
        }
        return 0;
    }
}