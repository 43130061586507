<div class="bs-callout bs-callout-success">
    <div class="row">
        <div class="col-sm-8">
            <h5 class="text-success">Campañas de Marcación Automática.</h5>
            Listado de campañas de marcación automática.
        </div>
        <div class="col-sm-4 text-right hidden-xs">
            <button type="button" class="btn btn-success" (click)="editarCampana()">
                <i class="fa fa-plus-circle"></i> Crear nueva campaña</button>
        </div>
    </div>
</div>
<div class="row" *ngIf="campanas$ | async as campanas; else loading">
    <ng-container *ngIf="campanas.length > 0; else noCampains">
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let campana of campanas | campainStatus : true">
        <app-campain-stats 
            (nuevoEstado)="cambiarEstado(campana.idcampana, $event)" 
            (verCampana)="visualizarCampana($event)" 
            [campana]="campana">
        </app-campain-stats>
    </div>
    <div class="col-xs-12">
        <h5 class="text-muted"><i class="fa fa-map-o"></i> Campañas detenidas</h5>
        <hr>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" *ngFor="let campana of campanas | campainStatus : false">
        <app-campain-stats 
            (nuevoEstado)="cambiarEstado(campana.idcampana, $event)" 
            (verCampana)="visualizarCampana($event)" 
            [campana]="campana">
        </app-campain-stats>
    </div>
    </ng-container>
    <ng-template #noCampains>
        <div class="text-center" style="padding: 100px 0px; color: rgb(180, 180, 180);">
            <i class="fa fa-4x fa-rocket" style="margin-bottom: 10px;"></i><br>
            Aun no tienes campañas creadas. <a href="javascript:void(0)" (click)="editarCampana()">Crear nueva campaña.</a>
        </div>
    </ng-template>
</div>
<ng-template #loading>
    <div class="text-center text-muted" style="padding: 100px 0px;">
        <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
        Cargando campañas...
    </div>
</ng-template>