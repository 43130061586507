<div class="bs-callout bs-callout-success">
   <div class="row">
      <div class="col-sm-8">
         <h4 class="text-success">
            <i class="fa fa-check" aria-hidden="true"></i> Fases dentro del Pipeline
         </h4>
         Edición de las fases entre los que podrán moverse los leads.

      </div>
      <div class="col-sm-4 text-right">
         <button class="btn btn-primary" (click)="agregarFase()">
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Agregar fase
         </button>
      </div>
   </div>
</div>
<form autocomplete="off">
   <div class="row" [formGroup]="fasesForm">
      <div class="col-md-6" formArrayName="fases" *ngIf="initFasesForm$ | async; else loadingPhases">
         <!-- Fases del pipeline-->
         <ng-container *ngIf="fasesArray.controls.length > 0; else noFases">
            <app-fase [fase]="fase" [index]="i" [indexInProps]="currentIndex" (delRequest)="removerFase($event)"
               (loadPropsRequest)="loadProps($event)"
               *ngFor="let fase of fasesArray.controls; let i = index"></app-fase>
         </ng-container>
         <!-- Fases del pipeline -->

         <ng-template #noFases>
            <div class="text-center text-muted" style="margin-top: 60px; margin-bottom: 60px;">
               <i class="fa fa-wpforms fa-4x" aria-hidden="true"></i>
               <h5>Aun no hay fases para este pipeline...</h5>
            </div>
         </ng-template>
      </div>
      <ng-template #loadingPhases>
         <div class="col-md-6">
            <div class="text-center text-muted" style="padding-top: 80px; padding-bottom: 20px;">
               <i class="fa fa-spinner fa-2x fa-spin text-primary" style="margin-bottom: 15px;"></i><br>
               Cargando fases, un momento por favor...
            </div>
         </div>
      </ng-template>

      <!-- Edición de propiedades -->
      <div class="col-md-6">
         <app-fase-props [propsForm]="propsForm" (camposRequest)="agregarCampos($event)"></app-fase-props>
      </div>
      <!---->
   </div>
   <hr>
   <div class="text-right">

      <!-- msj fallas -->
      <div class="alert alert-danger alert-dismissible text-left" role="alert"
         *ngIf="!inProcess.progress && inProcess.msj!!">
         <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
               aria-hidden="true">&times;</span></button>
         <strong><i class="fa fa-warning" aria-hidden="true"></i> Error</strong><br>
         {{inProcess.msj?.message || unknown}}
      </div>
      <!---->

      <button type="button" class="btn btn-success" [disabled]="isSaveDisabled" (click)="guardarPipe()">
         <ng-container *ngIf="!inProcess.progress; else progress">
            <i class="fa fa-save" aria-hidden="true"></i> Guardar cambios
         </ng-container>
         <ng-template #progress>
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Guardando cambios
         </ng-template>
      </button> &nbsp;
      <button type="button" class="btn btn-primary" [disabled]="inProcess.progress" (click)="regresar()">
         <i class="fa fa-arrow-circle-left" aria-hidden="true"></i> Regresar
      </button>
   </div>
</form>