import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject, combineLatest, of, zip } from "rxjs";
import {
   catchError,
   defaultIfEmpty,
   delay,
   filter,
   finalize,
   map,
   share,
   switchMap,
   tap,
   withLatestFrom,
} from "rxjs/operators";
import { Agente } from "src/app/_interfaces/agente";
import { IContacto } from "src/app/_interfaces/contacto";
import { ITipificacionLog } from "src/app/_interfaces/forma";
import { ILead } from "src/app/_interfaces/lead.entity";
import { IleadLog } from "src/app/_interfaces/leadLog.entity";
import { IPipeLine } from "src/app/_interfaces/pipeline.entity";
import { AgenteService } from "src/app/_services/agente.service";
import { ContactosService } from "src/app/_services/contactos.service";
import { LeadService } from "src/app/_services/lead.service";
import { PipelineService } from "src/app/_services/pipeline.service";
import { UsuarioService } from "src/app/_services/usuario.service";

@Component({
   selector: "app-lead-full",
   templateUrl: "./lead-full.component.html",
   styleUrls: ["./lead-full.component.css"],
})
export class LeadFullComponent implements OnInit {
   lead$: Observable<ILead>;
   leadLog$: Observable<IleadLog[]>;
   leadDetails$: Observable<any>;
   propietario$: Observable<string>;
   etapa$: Observable<string>;
   contacto$: Observable<string>;
   loadLog$: Observable<IleadLog[]>;

   tipificacion$: Observable<ITipificacionLog>;

   phase$: Observable<IPipeLine>;
   initLog: Subject<boolean>;
   saving = false;
   nombreEnEdicion = false;

   notas = new FormControl("", [Validators.required]);
   nombreLead = new FormControl("", [Validators.required]);

   constructor(
      private $router: Router,
      private $route: ActivatedRoute,
      private $lead: LeadService,
      private $pipe: PipelineService,
      private $agente: AgenteService,
      private $contact: ContactosService,
      private $usuario: UsuarioService
   ) {}

   ngOnInit(): void {
      const { idLead } = this.$route.snapshot.params;
      this.lead$ = this.$lead.getLead(idLead).pipe(share());

      this.phase$ = this.lead$.pipe(
         switchMap((lead) => this.$pipe.getPipeline(lead.fasePipeline)),
         share()
      );

      this.etapa$ = this.phase$.pipe(map((phase) => phase.nombre));

      this.propietario$ = this.lead$.pipe(
         switchMap((lead) => this.$agente.getAgente(lead.agenteAsignado)),
         filter((agente) => !!agente),
         map((agente) => `${agente.idagente} - ${agente.nombre}`)
      );

      this.leadDetails$ = this.lead$.pipe(
         filter((lead) => lead.fuente === "CLICK2CALL"),
         map(
            (lead) => JSON.parse(lead.fuente_dst) as { "phone-number": string }
         ),
         map((data) => {
            const items = Object.keys(data);
            return items.reduce(
               (acc, i) => `${acc}<strong>${i}</strong> : ${data[i]}<br>`,
               ""
            );
         }),
         defaultIfEmpty()
      );

      this.contacto$ = this.lead$.pipe(
         filter((lead) => !!lead.idcontacto),
         switchMap((lead) => this.$contact.getContacto(lead.idcontacto)),
         map((contacto) => {
            delete contacto._id;
            delete contacto.iddb;

            const llaves = Object.keys(contacto);
            const response = llaves.reduce(
               (acc, item) =>
                  `${acc}<div class="contacto">${item} : <strong>${contacto[item]}</strong></div>`,
               ""
            );
            return response;
         }),
         defaultIfEmpty("Sin datos de contacto")
      );

      this.loadLogs(idLead);
   }

   guardarLog(data: any) {
      const { idLead } = this.$route.snapshot.params;

      this.saving = true;
      combineLatest([
         of({ idlead: idLead, autor: this.$usuario.usuario.usuario }),
         this.phase$,
      ])
         .pipe(
            map(([extra, pipeLine]) => {
               return [extra, { Fase: pipeLine.nombre, ...data }];
            }),
            switchMap(([extra, dataMod]) =>
               this.$lead.agregarActividad(dataMod, extra.autor, extra.idlead)
            ),
            catchError((err) => of(err)),
            delay(500)
         )
         .subscribe(
            () => {
               this.saving = false;
               this.loadLogs(idLead);
            },
            (err) => console.error(err)
         );
   }

   guardarNotas(texto: string) {
      this.guardarLog({ Nota: texto });
   }

   regresar() {
      this.$router.navigateByUrl("dashboard/(view:pipeline)");
   }

   confirmarElimminar() {
      $("#eliminar").modal("show");
   }

   eliminar() {
      this.saving = true;
      this.lead$
         .pipe(
            map((lead) => ({ ...lead, fasePipeline: null })),
            switchMap((lead) =>
               this.$lead.updateLead(lead).pipe(
                  // eliminar lead_log
                  switchMap(() => this.$lead.deleteLogs(lead.idlead))
               )
            ),
            delay(400),
            finalize(() => {
               $("#eliminar").modal("hide");
               this.regresar();
               this.saving = false;
            })
         )
         .subscribe();
   }

   editarNombre() {
      this.nombreEnEdicion = true;
   }

   guardarNombre(nombre: string, lead: ILead) {
      this.nombreEnEdicion = false;
      const leadUpdated = { ...lead, nombre: nombre };
      of(leadUpdated)
         .pipe(
            switchMap((data) => this.$lead.updateLead(data)),
            catchError((err) => {
               console.error(err);
               return of(err);
            }),
            finalize(() => {
               this.nombreEnEdicion = false;
               this.lead$ = this.$lead.getLead(lead.idlead).pipe(share());
            })
         )
         .subscribe();
   }

   private loadLogs(idLead: string) {
      this.leadLog$ = this.$lead.getLogs(idLead).pipe(share());
   }
}
