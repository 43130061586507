<div class="row">
    <div class="col-md-3">
        <div class="bs-callout bs-callout-primary">
            <h4 class="text-primary"><i class="fa fa-user-circle"></i> Usuarios & Permisos</h4>
            Aquí podrás crear nuevos usuarios y definir los permisos asociados a cada uno así como la posibilidad de atar a un usuario a una
            o múltiples colas de callcenter con el fin de recibir alertas y notificaciones.
            
            <div style="margin-top: 10px">
                <button type="button" class="btn btn-sm btn-primary" (click)="nuevoUsuario()"><i class="fa fa-plus"></i> Crear nuevo usuario</button>
            </div>
            <hr>
            <div class="list-group" style="max-height: 500px; overflow-y: auto; overflow-x: hidden; margin-bottom: 0; padding-bottom: 0">
                <a href="javascript:void(0)" style="padding: 7px 15px" (click)="cargarUsuario(u)" 
                   [ngClass]="{'active': u.usuario === usuario.usuario}"
                   *ngFor="let u of (usuarios$ | async)"
                   class="list-group-item">
                   <i class="fa fa-user-circle-o" style="margin-right: 5px"></i> {{u.usuario}}
                </a>
            </div>
        </div>
    </div>
    <div class="col-md-9">
        <div class="row">
            <div class="col-md-6">
                <div class="bs-callout bs-callout-success">
                    <h5 class="text-success">Datos de Usuarios</h5>
                    Configura los detalles y permisos de usuario.
                </div>
                <form class="form-group form-group-sm" style="margin-bottom: 0" novalidate #f="ngForm">
                    <div class="row">
                        <div style="margin-bottom: 10px" class="col-xs-10">
                            <label>Nombre de Usuario *</label>
                            <input type="text" class="form-control" name="usuario" placeholder="Nombre de Usuario" 
                                   [readonly]="readOnly"
                                   [(ngModel)]="usuario.usuario" required>
                        </div>
                        <div style="margin-bottom: 10px" class="col-xs-2">
                            <label>Activo?</label>
                            <div style="margin-top: 5px" class="text-center">
                                <input type="checkbox"  name="estado" [(ngModel)]="usuario.estado">
                            </div>
                        </div>
                    </div>
                    <div style="margin-bottom: 10px">
                        <label>Contraseña *</label>
                        <div class="input-group">
                            <input type="password" class="form-control" *ngIf="!secretView" name="password" [(ngModel)]="usuario.password" placeholder="Contraseña para logueo" [required]="!readOnly">
                            <input type="text" class="form-control" *ngIf="secretView" name="password" [ngModel]="usuario.password">
                            <span class="input-group-btn">
                                <button class="btn btn-sm btn-default" type="button" (mousedown)="secretView=true" (mouseup)="secretView=false" (mouseout)="secretView=false">
                                    <span class="glyphicon glyphicon-eye-open"></span>
                                </button>
                            </span>
                        </div><!-- /input-group -->
                        <small class="text-muted">* Tu contraseña se almacena de manera encriptada.</small>
                    </div>  
                    <div style="margin-bottom: 10px">
                        <label>Email </label>
                        <input type="email" class="form-control" name="email" [(ngModel)]="usuario.email" placeholder="Email para notificaciones">
                    </div>
                    <div style="margin-bottom: 10px">
                        <label>Tipo de Usuario</label>
                        <select class="form-control" name="permisos" [disabled]="usuario.usuario === 'admin'" [(ngModel)]="usuario.permisos" required>
                            <option value="">Selecciona uno</option>
                            <option value="{{rol.ADMIN}}">Administrador</option>
                            <option value="{{rol.MANAGER}}">Manager</option>
                            <option value="{{rol.SUPER}}">Supervisor</option>
                            <option value="{{rol.AUDITOR}}">Auditor</option>
                            <option value="{{rol.GUEST}}">Invitado</option>
                        </select>
                    </div>
                    <div style="margin-bottom: 10px" class="text-right" >
                        <hr>
                        <button type="button" class="btn btn-sm btn-danger" [disabled]="usuario.usuario === 'admin' || !readOnly"
                                (click)="eliminarUsuario()"
                                >Eliminar Usuario</button>
                        <button type="button" class="btn btn-sm btn-success" 
                                [disabled]="f.invalid"
                                (click)="guardarUsuario()"
                                >Guardar Usuario</button>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div class="bs-callout bs-callout-warning">
                    <h5>Colas de Callcenter</h5>
                    <p>Agrega diferentes colas a la cual este usuario podrá ser manager</p>
                    <div class="input-group input-group-sm" style="margin-bottom: 0px">
                        <select #colaSel class="form-control">
                            <option value="">Selecciona una cola...</option>
                            <option value="{{q.nombre}}" *ngFor="let q of (queues$ | async)">{{q.nombre}}</option>
                        </select>
                        <span class="input-group-btn">
                            <button class="btn btn-default btn-sm" type="button" 
                                    [disabled]="usuario.permisos > rol.AUDITOR || usuario.usuario === ''"
                                    (click)="agregarCola(colaSel.value)"><i class="fa fa-plus"></i> Agregar</button>
                        </span>                
                    </div><!-- /input-group -->
                </div>                
                <hr style="margin-bottom: 10px; margin-top: 10px">
                <label>Manager en las siguientes colas.</label>
                <div style="max-height: 390px; overflow-y: auto; overflow-x: hidden">

                    <div style="padding: 5px" *ngFor="let q of usuario.manager"> 
                        <div class="row panel" style="margin: 1px; background-color: #f1f1f1">
                            <div class="col-xs-9" style="padding: 8px">
                               <i class="fa fa-dashboard" style="margin-right: 6px"></i> {{q.cola}}
                            </div>
                            <div class="col-xs-3 text-right" style="padding: 5px">
                               <button class="btn btn-xs btn-info" (click)="removerCola(q.cola)">
                                   <i class="fa fa-close"></i>
                               </button>
                            </div>
                       </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>