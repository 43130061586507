
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';

import { IDBResponse } from '../_interfaces/responses';
import { Queue } from '../_interfaces/queue';
import * as moment from 'moment';
import { map, flatMap, filter, first, switchMap, concat } from 'rxjs/operators';

export interface IPerformance {
    cola: string,
    hora: string[],
    agente: {
        name: string,
        llamadas: {
            cant: number,
            tiempo: number
        }[]
    }[]
}

@Injectable()
export class QueueService {
    
    private upload$ = new Subject<IDBResponse>();
    
    constructor(private $http: HttpClient, private $header: HeaderService) {
        
    }
    getUploaderEvent(): Observable<IDBResponse> {
        return this.upload$.asObservable();
    }
    
    getQueue(nombre: string): Observable<Queue> {
        return this.$http.get<Queue>(this.$header.getAPIurl() + '/queue/' + nombre, { headers: this.$header.getHeaders() });
    }
    
    getQueues(): Observable<Queue[]> {
        return this.$http.get<Queue[]>(this.$header.getAPIurl() + '/queues', { headers: this.$header.getHeaders() });
    }
    
    modificarQueue(data: { queue: Queue, update: boolean}): Observable<any> {

        let http$: Observable<IDBResponse>;
        
        if (data.update === undefined) {
            http$ = this.$http.delete<IDBResponse>(this.$header.getAPIurl() + '/queue/' + data.queue.nombre, { headers: this.$header.getHeaders() });
            
        } else if (data.update === true) {
            http$ = this.$http.post<IDBResponse>(this.$header.getAPIurl() + '/queue/', data.queue, { headers: this.$header.getHeaders() });
            
        } else if (data.update === false) {
            http$ = this.$http.put<IDBResponse>(this.$header.getAPIurl() + '/queue/', data.queue, { headers: this.$header.getHeaders() });
        }    
        return http$;
    }
    
    getPerformace(queue: string, fecha: string): Observable<IPerformance> {
        const query = {
            fecha: {
                inicio: (!!fecha && fecha !== '' ? fecha : moment().format('YYYY-MM-DD')),
                fin: (!!fecha && fecha !== '' ? fecha : moment().format('YYYY-MM-DD'))
            },
            cola: queue
        };
        
        return this.$http.post<IPerformance[]>(this.$header.getAPIurl() + '/agent_performance', query, { headers: this.$header.getHeaders() }).pipe(
            map(response => response.shift())
        );
    }
    
    getCallHistory(callid: string): Observable<Object[]> {
        return this.$http.get<Object[]>(this.$header.getAPIurl() + '/queue/call/' + callid, { headers: this.$header.getHeaders() });
    }
    
    // No lanzar llamada, borrarla de la lista de llamadas pendientes
    cancelarLlamada(llamada: {phone: string, queuename: string; id: string}): Observable<Object> {
        return this.$http.post(this.$header.getAPIurl() + '/queue/callback', llamada, { headers: this.$header.getHeaders() });
    }
    
    devolverLlamada(llamada: {phone: string, queuename: string; id: string}): Observable<Object> {
        
        // TODO: 
        // Hacer solicitud de las reglas
        return this.$http.get<{operacion: string, valor: string, id: string, prefix: string[]}[]>(this.$header.getAPIurl() + '/callback', { headers: this.$header.getHeaders() }).pipe(
            // Encontrar cuales de las reglas tiene el prefix
            flatMap(rules => rules ),
    
            // Filtrar solo aquellas que tengan el nombre de la cola y su operación sea Queue
            filter((rule: {operacion: string, valor: string, id: string, prefix: string[]}) => {
    
                const reglaQueCoincide = rule.prefix.find(prefix => {
                    const prefijo = prefix.replace(/X/g, '');
                    const regEx = new RegExp('^' + prefijo + '[0-9]{' + prefix.replace(prefijo, '').length + '}$');
                    return regEx.test(llamada.phone);
                });
    
                return !!reglaQueCoincide && rule.operacion.indexOf('Queue') > -1 && rule.valor.indexOf(llamada.queuename) > -1 
            }),
    
            // Solo la primera que haga match
            first(),
            
            // Concatenar dos observables que dependen del mismo map || filter
            switchMap(rule => {
                console.log(rule)
                // Lanzar la llamada de callback con destino
                return this.$http.post(this.$header.getAPIurl() + '/callback', {numero: llamada.phone, id: rule.id}, { headers: this.$header.getHeaders() })
                    .pipe(
                        // Actualizar en db que la llamada ya fue lanzada
                        concat(this.$http.post(this.$header.getAPIurl() + '/queue/callback', llamada, { headers: this.$header.getHeaders() }))
                    )
            })
        );
    }
}
