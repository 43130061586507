import { Component, OnInit, ViewChild } from '@angular/core';
import { PollsService, IEncuentaFrm } from '../../_services/polls.service';
import { Observable } from 'rxjs';
import { PollComponent } from './poll/poll.component';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'polls-tag',
    templateUrl: 'polls.component.html'
})

export class PollsComponent implements OnInit {
    
    polls$: Observable<IEncuentaFrm[]>
    encuestaSeleccionada: IEncuentaFrm;

    @ViewChild(PollComponent)
    pollComponent: PollComponent;

    constructor(private $polls: PollsService) { }

    ngOnInit() { 
        $('#modalWait_kerberus').modal();
        this.polls$ = this.$polls.getEncuestas().pipe(
            tap(data => $('#modalWait_kerberus').modal('hide'))
        );
        this.encuestaSeleccionada = undefined;
    }

    setEncuesta(encuesta: IEncuentaFrm) {
        this.encuestaSeleccionada = encuesta;
    }

    eliminarEncuesta(id: string) {
        this.$polls.eliminarEncuesta(id).pipe(
            tap(() => this.pollComponent.resetFrm())
        )
        .subscribe(() => this.ngOnInit())
    }
}
