import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as moment from 'moment';
@Pipe({name: 'toTimeFormat'})
export class TimeFormatPipe implements PipeTransform {

    transform(value: string, format?: string, addSeconds?: string) {
        if (value !== undefined) {
            try {
                const fecha = !!addSeconds ? dayjs(value).add(Number.parseInt(addSeconds), 'second') : dayjs(value);
                value = fecha.format((format || 'YYYY-MM-DD HH:mm:ss'));
            } catch (e) {}
        }
        return value;
    }
}
