<div class="row" style="padding: 10px">
    
    <div class="col-sm-2">
        <div class="text-muted text-justify" style="font-size: 13px">
            <span style="font-weight: 600" class="text-success">Administración de Grupos</span>
            <hr style="margin-top: 10px; margin-bottom: 10px">
            <p>
                En esta sección puedes crear y editar grupos de llamada que requieras, una vez creados podrás marcarle a multiples extensiones.
            </p>
            <p>Para marcar a estos grupos, solo debes <strong>marcar # + el ID de marcación + SEND.</strong></p>
        </div><br>
            <button class="btn btn-sm btn-success" (click)="crearNuevoGrupo()"><i class="fa fa-plus"></i> Crear nuevo Grupo</button>
        <hr class="visible-xs">
    </div>
    
    <div class="col-sm-10">
        <span style="font-size:18px; font-weight: 600"><i class="fa fa-phone-square"></i> Grupos disponibles</span>
        <hr style="margin-top: 5px">
        <div class="col-md-3" *ngFor="let g of (grupos$ | async)">
            <grupo-tag [grupo]="g" [edicion]="false" (editar)="editarGrupo($event)" 
                (grupoEmitter)="guardarGrupo($event)"
                ></grupo-tag>
        </div>
    </div>
    
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="modelGrupo">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h5 class="modal-title">Edición de Grupo {{grupoEdicion?.nombre}}</h5>
        </div>
        <div class="modal-body">
            <div class="row" >
                <div class="col-md-6" style="max-height: 600px; overflow-y: auto; overflow-x: hidden">
                    <h5 style="font-weight: 600"><i class="fa fa-phone"></i> Extensiones Disponibles</h5>
                    <hr style="margin-top: 10px">
                    <div class="panel panel-default">
                        <ul class="list-group" style="font-size: 13px">
                            <li class="list-group-item" style="padding: 7px" *ngFor="let e of (peers$ | async)">
                                <div class="row">
                                  <div class="col-xs-8"  style="padding-top: 3px; padding-left: 20px">
                                      <i class="fa fa-phone"></i> Extension {{e.usuario}}
                                  </div>
                                  <div class="col-xs-4 text-right">
                                      <button class="btn btn-xs btn-success" (click)="agregarExten(e)"><i class="fa fa-arrow-circle-right"></i></button>
                                  </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <grupo-tag [grupo]="grupoEdicion" [edicion]="edicion" (grupoEmitter)="guardarGrupo($event)"></grupo-tag>
                </div>
            </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->