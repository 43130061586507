import {
   Component,
   OnInit,
   Input,
   OnChanges,
   SimpleChange,
   SimpleChanges,
} from "@angular/core";
import {
   IAgentMonitor,
   EDBResponseType,
   IAgentStatusReason,
} from "../../../_interfaces/_all";
import {
   SocketService,
   EComando,
   EResponse,
} from "../../../_services/socket.service";
import { AgenteService } from "../../../_services/agente.service";
import { UsuarioService } from "../../../_services/usuario.service";
import * as uuid from "../../../_services/_objectID";
import * as dayjs from "dayjs";
import * as moment from "moment";
import { of, Observable } from "rxjs";
import { finalize, flatMap } from "rxjs/operators";
import { PauseReasonService } from "src/app/_services/pauseReasons.service";
import { IPauseReason } from "src/app/_interfaces/pauseReason";
@Component({
   selector: "agent-op",
   templateUrl: "./auxiliary.component.html",
})
export class AuxiliaryComponent implements OnInit, OnChanges {
   motivos$: Observable<IPauseReason[]>;
   motivo: { motivo: string; descripcion: string } = {
      motivo: "",
      descripcion: "",
   };
   @Input() agente: IAgentMonitor;
   @Input() cola: string;

   reason: IAgentStatusReason;

   get agentSkill() {
      const penalty = Number.parseInt(`${this.agente?.defaultPenalty ?? 0}`);
      return penalty;
   }

   constructor(
      private $agente: AgenteService,
      private $monitor: SocketService,
      private $usuario: UsuarioService,
      private $pauseSevrice: PauseReasonService
   ) {}

   ngOnInit() {}

   ngOnChanges(cambios: IChange) {
      if (
         !!cambios.agente &&
         !!cambios.agente.currentValue &&
         !!this.agente.estado_idreason
      ) {
         this.$agente.getReason(this.agente.estado_idreason).subscribe(
            (response) => {
               if (response.length > 0) {
                  this.reason = response[0];
                  if (!(this.reason.fecha instanceof Date)) {
                     this.reason.fecha = moment(this.reason.fecha).format(
                        "lll"
                     );
                  }
               }
            },
            (err) => console.log(err)
         );
      }
   }

   lanzarOps() {
      this.motivo = {
         motivo: "",
         descripcion: "",
      };
      this.motivos$ = this.$pauseSevrice.obtenerRazones();
      $("#agente-ops").modal();
   }

   cambiarSkill(
      idagent: string,
      modoAgente: string,
      skill: number,
      queue: string
   ) {
      this.$agente
         .cambiarSkill(idagent, modoAgente, skill, queue)
         .pipe(
            finalize(() => {
               $("#agente-ops").modal("hide");
            })
         )
         .subscribe(
            (_) => {},
            (err) => console.error(err)
         );
   }

   colgarLlamada(motivo: { motivo: string; descripcion: string }) {
      const idreason = uuid.ObjectID();
      this.$agente
         .setReason({
            idreason: idreason,
            tipo: motivo.motivo,
            motivo: motivo.descripcion,
            autor: this.$usuario.usuario.usuario,
            fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
         })
         .pipe(
            flatMap(() =>
               this.$monitor.enviarComando({
                  comando: EComando.HANGUP_CALL,
                  data: {
                     callid: this.agente.channel.UniqueID,
                     channel: this.agente.channel?.Channel,
                     agente: this.agente.idagente,
                     reason: idreason,
                  },
               })
            )
         )
         .subscribe((res) => console.log(res));

      $("#agente-ops").modal("hide");
   }

   desloguearAgente(motivo: { motivo: string; descripcion: string }) {
      this.$monitor
         .enviarComando({
            comando: EComando.AGENT_LOGOUT,
            data: {
               agente: this.agente.idagente,
               modoAgente: this.agente.modoAgente,
            },
         })
         .subscribe((res) => console.log(res));

      $("#agente-ops").modal("hide");
   }

   pausaAgente(motivo: { motivo: string; descripcion: string }, tipo: boolean) {
      const idreason = uuid.ObjectID();
      this.$agente
         .setReason({
            idreason: idreason,
            tipo: motivo.motivo,
            motivo: motivo.descripcion,
            autor: this.$usuario.usuario.usuario,
            fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
         })
         .pipe(
            flatMap((response) => {
               if (response.tipo === EDBResponseType.QUERY_OK) {
                  const data = {
                     agente: this.agente.idagente,
                     modo: tipo ? "pause" : "unpause",
                     modoAgente: this.agente.modoAgente,
                     cola: this.cola,
                     reason: idreason,
                  };

                  return this.$monitor.enviarComando({
                     comando: EComando.AGENT_PAUSE,
                     data: data,
                  });
               } else {
                  return of({
                     tipo: EResponse.FALLIDO,
                     data: response.data,
                  });
               }
            })
         )
         .subscribe(
            (response) => {
               console.log(response);
               $("#agente-ops").modal("hide");
            },
            (err) => console.log(err)
         );
   }
}

interface IChange extends SimpleChanges {
   agente: SimpleChange;
}
