import { Pipe, PipeTransform } from '@angular/core';
import { IQueueStats } from 'src/app/_interfaces/monitor';

@Pipe({
    name: 'ic'
})
export class ICPipe implements PipeTransform {
    transform(stat: IQueueStats): any {
        if ((stat.inbound + stat.abandon) !== 0) {
            return stat.CI / (stat.inbound + stat.abandon) * 100;
        }
        return 0;
    }
}
