import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetallesSMSComponent } from './detalles-sms/detalles-sms.component';
import { NumeracionSMSComponent } from './numeracion-sms/numeracion-sms.component';
import { NumeracionUPComponent } from './numeracion-up/numeracion-up.component';
import { UIModule } from '../../_extra/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule
  ],
  declarations: [DetallesSMSComponent, NumeracionSMSComponent, NumeracionUPComponent],
  exports: [DetallesSMSComponent, NumeracionSMSComponent, NumeracionUPComponent]
})
export class SmsModule { }
