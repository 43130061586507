import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HidePhonePipe } from './hide-phone.pipe';
import { AgentAvailablePipe } from './agent-available.pipe';
import { SortByStatusPipe } from './sort-by-status.pipe';

@NgModule({
  declarations: [HidePhonePipe, AgentAvailablePipe, SortByStatusPipe],
  imports: [
    CommonModule
  ],
  exports: [HidePhonePipe, AgentAvailablePipe, SortByStatusPipe]
})
export class PipesModule { }
