import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FileUploadModule } from "ng2-file-upload";
import {
   QueueSetupComponent,
   QueuenameValidatorDirective,
} from "./queuesetup/queuesetup.component";
import { AuxiliaryComponent } from "./auxiliary/auxiliary.component";
import { PipesModule } from "../../pipes/pipes.module";
import { ICPipe } from "./pipes/indiceCumplimiento.pipe";
import { SLPipe } from "./pipes/serviceLevel.pipe";
import { InboundAveragePipe } from "./pipes/InboundAverage.pipe";
import { OutboundAveragePipe } from "./pipes/OutboundAverage.pipe";
import { InboundHoldAveragePipe } from "./pipes/InboundHoldAverage.pipe";
import { AbandonAveragePipe } from "./pipes/AbandonAverage.pipe";
import { TruncateNombrePipe } from "./pipes/TruncateNombre.pipe";
import { CallcenterDashComponent } from "./consola_call.component";
import { QueueComponent } from "./queue/queue.component";
import { QueueAgentsStatusPipe } from "./pipes/queueAgentsStatus.pipe";
import { UIModule } from "src/app/_extra/ui/ui.module";
import { ToUpperCasePipe } from "./pipes/to-upper-case.pipe";
import { AgenteStateComponent } from "./agente-state/agente-state.component";
import { TimeFromNowPipe } from "./pipes/time-from-now.pipe";
import { TmoCalcPipe } from "./pipes/tmo-calc.pipe";
import { DefaultAgentPenaltyPipe } from './pipes/default-agent-penalty.pipe';

@NgModule({
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FileUploadModule,
      UIModule,
      PipesModule,
   ],
   declarations: [
      QueueSetupComponent,
      AuxiliaryComponent,
      ICPipe,
      SLPipe,
      QueueAgentsStatusPipe,
      InboundAveragePipe,
      OutboundAveragePipe,
      InboundHoldAveragePipe,
      AbandonAveragePipe,
      TruncateNombrePipe,
      CallcenterDashComponent,
      QueueComponent,
      QueuenameValidatorDirective,
      ToUpperCasePipe,
      AgenteStateComponent,
      TimeFromNowPipe,
      TmoCalcPipe,
      DefaultAgentPenaltyPipe,
   ],
   exports: [
      QueueSetupComponent,
      AuxiliaryComponent,
      ICPipe,
      SLPipe,
      QueueAgentsStatusPipe,
      InboundAveragePipe,
      OutboundAveragePipe,
      InboundHoldAveragePipe,
      AbandonAveragePipe,
      TruncateNombrePipe,
      CallcenterDashComponent,
      QueueComponent,
   ],
   providers: [],
})
export class ConsolaCallModule {}
