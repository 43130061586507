import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PipelinesComponent } from "./pipelines.component";
import { MomentModule } from "ngx-moment";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { LeadComponent } from "./lead/lead.component";
import { UIModule } from "src/app/_extra/ui/ui.module";
import { PipelineComponent } from "./pipeline/pipeline.component";
import { FaseComponent } from "./fase/fase.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FasePropsComponent } from "./fase-props/fase-props.component";
import { PipelineService } from "src/app/_services/pipeline.service";
import { ToUpperPipe } from "./pipes/to-upper.pipe";
import { FilterLeadByIDPipe } from "./pipes/filter-lead-by-id.pipe";
import { LeadFullComponent } from './lead-full/lead-full.component';

@NgModule({
   providers: [DragulaService, PipelineService],
   declarations: [
      PipelinesComponent,
      LeadComponent,
      PipelineComponent,
      FaseComponent,
      FasePropsComponent,
      ToUpperPipe,
      FilterLeadByIDPipe,
      LeadFullComponent,
   ],
   imports: [
      CommonModule,
      MomentModule,
      DragulaModule.forRoot(),
      ReactiveFormsModule,
      FormsModule,
      UIModule,
   ],
})
export class PipelinesModule {}
