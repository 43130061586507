import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toTime'})
export class TimePipe implements PipeTransform {

    transform(value: number) {
        if (value !== undefined) {
            try {
                return new Date().setHours(0, 0, value, 0);
            } catch (e) {}
        }
        return value;
    }
}
