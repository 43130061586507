import { Pipe, PipeTransform } from '@angular/core';
import { INBOUND_SKILL, EAgentSkill, MIXED_SKILL, OUTBOUND_SKILL } from 'src/app/_interfaces/agente';

@Pipe({
  name: 'statsSkillsNames'
})
export class StatsSkillsNamesPipe implements PipeTransform {

   transform(penalty: string | number): string {
      if (Number.parseInt(`${penalty}`) >= INBOUND_SKILL.MIN && Number.parseInt(`${penalty}`) <= INBOUND_SKILL.MAX) {
          return EAgentSkill.INBOUND_SKILL;
      }
      else if (Number.parseInt(`${penalty}`) >= MIXED_SKILL.MIN && Number.parseInt(`${penalty}`) <= MIXED_SKILL.MAX) {
          return EAgentSkill.MIXED_SKILL;
      }
      else if (Number.parseInt(`${penalty}`) < 0 || (Number.parseInt(`${penalty}`) >= OUTBOUND_SKILL.MIN && Number.parseInt(`${penalty}`) <= OUTBOUND_SKILL.MAX)) {
          return EAgentSkill.OUTBOUND_SKILL;
      }
  }

}
