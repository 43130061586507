import { Pipe, PipeTransform } from '@angular/core';
import { ICampanaStats } from 'src/app/_interfaces/dialer';

@Pipe({
  name: 'campainEnCurso'
})
export class CampainEnCursoPipe implements PipeTransform {

  transform(campana: ICampanaStats): any {
    const { enCurso, enAudio } = campana;
    return (enCurso + enAudio);
  }

}
