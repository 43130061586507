<form autocomplete="off">
   <div class="row" [formGroup]="queryForm">
      <div class="col-sm-6">
         <span style="font-size: 18px;">
            Pipeline</span><br>
         <small class="text-muted">
            Desde: {{fechaConsulta | amDateFormat:'LL'}} | <a href="javascript:void(0)" style="font-weight: 600;"
               (click)="editar()"><i class="fa fa-edit" aria-hidden="true"></i> Editar pipeline</a>
         </small>
      </div>
      <div class="col-sm-2 text-right">
         <div class="input-group">
            <div class="input-group-btn">
               <button type="button" class="btn btn-success" style="min-width: 100px">Agente</button>
            </div>
            <input type="text" class="form-control" formControlName="idAuthor" required>
         </div>
      </div>
      <div class="col-sm-4 text-right">
         <div class="input-group">
            <div class="input-group-btn">
               <button type="button" class="btn btn-success" style="min-width: 100px">Fecha</button>
            </div>
            <input type="text" ngDatePicker formControlName="start" required>
            <div class="input-group-btn">
               <button type="button" class=" btn btn-info" (click)="ejecutarBusqueda(queryForm.value)">
                  <i class="fa fa-refresh" aria-hidden="true"></i>
               </button>
            </div>
         </div>
      </div>
   </div>
</form>
<hr>
<div class="table-responsive" style="padding-left: 5px; padding-right: 5px;">

   <table class="table" *ngIf="pipeLinePhases$ | async as pipeLinesPhases; else loading">
      <thead>
         <th *ngFor="let phase of pipeLinesPhases">
            <div class="row" style="margin-right: 1px;">
               <div class="col-xs-8">{{phase.nombre | toUpper}}
               </div>
               <div class="col-xs-4 text-right">
                  <span class="label label-default">0</span>
               </div>
            </div>
         </th>
      </thead>

      <tbody *ngIf="leadsInPipes$ | async as leads; else loadingItems">
         <tr>
            <td *ngFor="let phase of pipeLinesPhases" style="max-width: 250px;">

               <ng-container *ngIf="(leads | filterLeadByID : phase) as leadsInPhase">

                  <!--Leads por fase-->
                  <div style="min-height: 100px; padding: 5px;" dragula="ITEMS" [(dragulaModel)]="leadsInPhase.items"
                     [id]="phase.id">
                     <ng-container *ngFor="let lead of leadsInPhase.items">
                        <app-lead [leadInPipe]="lead" (leadToShow)="detalles($event, phase)"></app-lead>
                     </ng-container>
                  </div>

               </ng-container>

            </td>

         </tr>
      </tbody>
      <ng-template #loadingItems>
         <tr>
            <td [colSpan]="pipeLinesPhases.length">
               <div class="text-center text-muted" style="padding-top: 80px; padding-bottom: 60px;">
                  <i class="fa fa-spinner fa-2x fa-spin text-primary" style="margin-bottom: 15px;"></i><br>
                  Cargado leads, un momento por favor...
               </div>
            </td>
         </tr>
      </ng-template>
   </table>

   <ng-template #loading>
      <div class="text-center text-muted" style="padding-top: 80px; padding-bottom: 60px;">
         <i class="fa fa-spinner fa-2x fa-spin text-primary" style="margin-bottom: 15px;"></i><br>
         Cargando fases, un momento por favor...
      </div>
   </ng-template>
</div>