<div *ngIf="evento$ | async as evento">
    <i class="fa fa-clock-o"></i> {{evento.horario | amDateFormat: 'dddd D [de] MMMM h:mm a'}}
    <hr>
<form novalidate autocomplete="off" #frmSchedule="ngForm" (ngSubmit)="guardar(frmSchedule.value)" style="color: rgb(68, 68, 68); font-weight: 400;">
    <input type="hidden" [ngModel]="evento.id || ''" name="id" >
    <input type="hidden" [ngModel]="evento.estado" name="estado">
    <input type="hidden" [ngModel]="evento.horario"  name="horario">
    <input type="hidden" [ngModel]="evento.idagente" name="idagente">
    <div class="form-group">
        <label>Nombre del evento</label>
        <input type="text" class="form-control" [ngModel]="evento.evento || ''" name="evento" #nombre="ngModel" required>
        <small class="text-danger" *ngIf="nombre.errors && (nombre.touched || nombre.dirty)">
            * Se requiere un nombre para este evento.
        </small>
    </div>
    <div ngModelGroup="tarea">
        <div class="form-group">
            <label>Tipo de evento</label>
            <select class="form-control" name="tipoEvento" [ngModel]="evento.tarea?.tipoEvento || ''" #tipo="ngModel" required>
                <option value="">...</option>
                <option value="call">Llamada telefónica</option>
                <option value="sms" disabled>Mensaje de texto</option>
                <option value="email" disabled>Email</option>
                <option value="remainder">Recordatorio</option>
            </select>
            <small class="text-danger" *ngIf="tipo.errors && (tipo.touched || tipo.dirty)">
                * Debes seleccionar un tipo de evento válido.
            </small>
        </div>
        <ng-container [ngSwitch]="tipo.value">
            <div *ngSwitchCase="'call'" class="form-group">
                <div class="form-group">
                    <label>Número de contacto</label>
                    <input type="tel" [ngModel]="evento.tarea?.numero || ''" name="numero" class="form-control" #tel="ngModel" required>
                    <small class="text-danger" *ngIf="tel.errors && (tel.touched || tel.dirty)">
                        * El número de teléfono es requerido.
                    </small>
                </div>
            </div>
            <div *ngSwitchCase="'sms'" class="form-group">
                <div class="form-group">
                    <label>Número de contacto</label>
                    <input type="tel" [ngModel]="evento.tarea?.numero || ''" name="numero" class="form-control" required>
                    <small class="text-danger" *ngIf="tel.errors && (tel.touched || tel.dirty)">
                        * El número de teléfono es requerido.
                    </small>
                </div>
                <div class="form-group">
                    <label>Texto del mensaje</label>
                    <textarea class="form-control" style="min-height: 90px;" name="texto" [ngModel]="evento.tarea?.texto || ''" 
                        #chars="ngModel" maxlength="150" required hasSpecialChars>
                    </textarea>
                    <small class="text-danger" *ngIf="chars.errors && (chars.touched || chars.dirty)">
                        <span *ngIf="chars.errors.required">* Este campo es requerido</span>
                        <span *ngIf="chars.errors.invalidContent">* No estan los caracteres especiales.</span>
                        <span *ngIf="chars.errors.maxlength">* El campo tiene más de 150 caracteres.</span>
                    </small>
                    <div class="small text-info text-right" style="font-weight: 400; margin-top: 4px;">
                        Caracteres disponibles: {{ 150 - (chars.value?.length || 0)}}
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="'email'" class="form-group">
                <div style="padding: 30px 0;" class="text-muted text-center">Habilitado próximamemte.</div>
            </div>
            <div *ngSwitchDefault></div>
        </ng-container>
    </div>
    <div class="form-group">
        <label>Descripción del evento</label> <small class="text-muted"> * Opcional</small>
        <textarea class="form-control" [ngModel]="evento.descripcion" name="descripcion" style="min-height: 100px;"></textarea>
    </div>
    <hr>
    <div class="text-right">
        <div class="alert text-left" *ngIf="!inProgress.progress && (inProgress.msj || inProgress.err)" 
            [ngClass]="{'alert-success': inProgress.msj, 'alert-danger': inProgress.err}">
            <span *ngIf="inProgress.err">
                <i class="fa fa-warning"></i> Se produjo un error al tratar de guardar este evento.
            </span>
            <span *ngIf="inProgress.msj">
                <i class="fa fa-check-square-o"></i> Evento guardado exitosamente.
            </span>
        </div>
        <div style="float: left;" *ngIf='!!evento.id'>
            <button type="button" class="btn btn-danger" 
                [disabled]="inProgress.progress"
                (click)="eliminar(evento)"><i class="fa fa-trash-o"></i>
            </button>
        </div>
        <button type="button" class="btn btn-default" (click)="regresar(evento)">Cancelar</button>&nbsp;
        <button type="submit" [disabled]="!frmSchedule.valid || inProgress.progress" class="btn btn-primary">
            <i class="fa fa-spinner fa-spin" *ngIf="inProgress.progress"></i>
            Guardar evento
        </button>
    </div>
</form>
</div>