import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LeadsComponent } from "./leads/leads.component";
import { UIModule } from "src/app/_extra/ui/ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LeadService } from "src/app/_services/lead.service";
import { NgxPaginationModule } from "ngx-pagination";
import { PipelinesModule } from "./pipelines/pipelines.module";
import { InPipelinePipe } from "./leads/in-pipeline.pipe";
import { MomentModule } from "ngx-moment";

@NgModule({
   providers: [LeadService],
   declarations: [LeadsComponent, InPipelinePipe],
   imports: [
      CommonModule,
      UIModule,
      FormsModule,
      PipelinesModule,
      ReactiveFormsModule,
      NgxPaginationModule,
      MomentModule,
   ],
})
export class MarketingModule {}
