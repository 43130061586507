import { Component, Input, OnInit } from "@angular/core";
import {
   AbstractControl,
   FormArray,
   FormBuilder,
   FormControl,
   FormGroup,
   ValidationErrors,
   Validators,
} from "@angular/forms";
import * as dayjs from "dayjs";
import { from, of } from "rxjs";
import { map, tap, toArray } from "rxjs/operators";
import { IFormaPregunta } from "src/app/_interfaces/forma";

@Component({
   selector: "app-pregunta",
   templateUrl: "./pregunta.component.html",
   styleUrls: ["./pregunta.component.css"],
})
export class PreguntaComponent implements OnInit {
   private _preguntaFrm: FormGroup;

   @Input()
   set preguntaFrm(value: FormGroup) {
      if (!!value) {
         this._preguntaFrm = value;
         this.preguntasArray = value.get("next") as FormArray;
      }
   }

   get preguntaFrm() {
      return this._preguntaFrm;
   }

   preguntasArray: FormArray;

   constructor(private $fb: FormBuilder) {}

   ngOnInit(): void {}

   agregar(nextQ: IFormaPregunta[]) {
      this.preguntasArray.clear({ emitEvent: true });

      from(
         // Ordenar y emitir valor por valor.
         nextQ.sort((q1, q2) => {
            if (q1.orden > q2.orden) return 1;
            else if (q1.orden < q2.orden) return -1;
            return 0;
         })
      )
         .pipe(
            map((pregunta) => {
               // crear el grupo
               const control = this.$fb.group({
                  tipo: "",
                  enunciado: "",
                  respuestas: [],
                  respuesta: ["", this.validadoresRespuesta(pregunta.tipo)],
                  next: this.$fb.array([]),
               });

               // Establecer los valores
               control.patchValue({
                  tipo: pregunta.tipo,
                  enunciado: pregunta.pregunta,
                  respuestas: pregunta.respuestas,
               });

               return control;
            }),
            // agregar las respuestas
            tap((control) => this.preguntasArray.push(control)),
            toArray()
         )
         .subscribe();
   }

   private validadoresRespuesta(
      tipo: string
   ): ((control: AbstractControl) => ValidationErrors)[] {
      switch (tipo) {
         case "NUMERO":
            return [Validators.required, isvalidNumber];
         case "FECHA":
            return [Validators.required, isValidDate];
         default:
            return [Validators.required];
      }
   }
}

function isvalidNumber(control: FormControl): ValidationErrors | null {
   return /[^0-9-]/.test(control.value) ? { hasInvalidChars: true } : null;
}

function isValidDate(control: FormControl): ValidationErrors | null {
   return !dayjs(control.value.trim()).isValid() ? { invalidDate: true } : null;
}
