import { Pipe, PipeTransform } from "@angular/core";
import { EOpcion } from "src/app/_interfaces/ivr";

@Pipe({
   name: "nvrPresenter",
})
export class NvrPresenterPipe implements PipeTransform {
   transform(value: { valor: string; opcion: EOpcion }): string {
      const data = value.valor.split("&");
      return ` ${EOpcion[value.opcion]} : ${data[0]}<br>
         <small><strong>args</strong> : ${data[1]
            .replace(/ /g, "")
            .replace(/,/g, " - ")}</small>
      `;
   }
}
