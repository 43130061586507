import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progresivo',
  templateUrl: './progresivo.component.html',
  styleUrls: ['./progresivo.component.css']
})
export class ProgresivoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
