<div class="row">
    <div class="col-md-3">        
        <form novalidate #f="ngForm" class="form-group form-group-sm" style="margin-bottom: 0">
            <input type="hidden" [ngModel]="encuesta?.id" name="idEncuesta" >
            <div ngModelGroup="fecha">
                <div class="input-group input-group-sm" style="margin-bottom: 10px">
                    <div class="input-group-btn">
                        <button type="button" class="btn btn-success" style="min-width: 50px"><i class="fa fa-calendar"></i></button>
                    </div>
                    <datepicker-ng2 ngModel name="inicio" [estilo]="'input-group-sm'"></datepicker-ng2>
                </div>

                <div class="input-group input-group-sm" style="margin-bottom: 10px">
                    <div class="input-group-btn">
                        <button type="button" class="btn btn-success" style="min-width: 50px"><i class="fa fa-calendar"></i></button>
                    </div>
                    <datepicker-ng2 ngModel name="fin" [estilo]="'input-group-sm'"></datepicker-ng2>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-sm btn-primary" [disabled]="f.invalid" (click)="ejecutarBusqueda(f.value)">Consultar</button>
            </div>
        </form>
    </div>
    <div class="col-md-9" style="max-height: 550px; overflow: auto; overflow-x: hidden">
        <div *ngIf="uiPollResults$ | async; else loading; let uiPollResults" >
            <!-- Nav tabs -->
            <div *ngIf="uiPollResults.length > 0">
                <ul class="nav nav-tabs" role="tablist" >
                    <li *ngFor="let poll of uiPollResults; let i = index" role="presentation" [ngClass]="{'active': i === 0}">
                        <a href="javascript:void(0)" (click)="tabActivo = i" role="tab" data-toggle="tab">
                            <i class="fa fa-random"></i> {{poll.cola}}
                        </a>
                    </li>
                </ul>

                <!-- tabs -->
                <div class="tab-content" style="border: 1px solid #CCCCCC; border-top: 0px" *ngIf="uiPollResults.length > 0">
                    <div role="tabpanel" style="padding: 15px" class="tab-pane" [ngClass]="{'active': tabActivo === i}" [id]="'tab' + i" *ngFor="let poll of uiPollResults; let i = index">
                        <!-- plots -->
                        <div class="panel panel-body" style="background-color: #fcfcfc; border: 1px solid #f5f5f5" *ngFor="let opt of poll.plots">
                            <div class="col-xs-8" style="padding: 0">
                                <chart [options]="opt" ></chart>
                            </div>
                            <div class="col-xs-4" style="font-size: 11px; padding-top: 30px">                                
                                <div *ngFor="let op of opt.series[0].data; let i=index">
                                        <i class="fa fa-circle" [style.color]="opt.colors[i]" style="margin-right: 10px"></i>{{op?.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right" style="margin-top: 15px">
                    <button type="button" class="btn btn-warning btn-sm" (click)="exportarResultados(pollResults)"><i class="fa fa-save"></i> Exportar</button>
                </div>
            </div>
            <div class="text-center" *ngIf="uiPollResults.length === 0" style="margin-top: 45px; margin-bottom: 35px">
                <h4 class="text-muted">Sin registros.</h4>
            </div>
        </div>
        <ng-template #loading>
            <div class="text-center" style="margin-top: 45px; margin-bottom: 35px">
                <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
                Un momento por favor...
            </div>
        </ng-template>
    </div>
</div>