
export class Peer {
    usuario: number;
    secret: number;
    personal: number;
    protocolo: string;
    transport = 'UDP';
    dtmf = 'rfc2833';
    nat = 'auto_comedia';
    call_limit = 2;
    fwd = '';
    record_call = true;
    encryption = false;
    busyfwd = '';
    delayedfwd = '';
    email = '';
    callerid = 'Extension';
    voice = false;
    spy = false;
    acceptExternal = true;
    grupos: string | { grupo: string }[] = [{grupo: '1'}];
    // 
    perfil: PeerPerfil[] = [];
    
    constructor() {}
}

export class PeerPerfil {
    usuario: number;
    contexto: string;
    limit: number;
    
    constructor(usuario: number) {
        this.usuario = usuario;
    }
}
