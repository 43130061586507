import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ICampanaStats, EEstadoCampanaDialer } from 'src/app/_interfaces/dialer';
import { DialerService } from 'src/app/_services/dialer.service';

@Component({
  selector: 'app-campain-stats',
  templateUrl: './campain-stats.component.html',
  styleUrls: ['./campain-stats.component.css']
})
export class CampainStatsComponent implements OnInit, AfterViewInit {

    @Input() campana: ICampanaStats;
    @Output() verCampana = new EventEmitter<string>();
    @Output() nuevoEstado = new EventEmitter<EEstadoCampanaDialer>();

    get isStoped() { return this.campana.estado === EEstadoCampanaDialer.DETENIDA }
    get isPaused() { return this.campana.estado === EEstadoCampanaDialer.EN_PAUSA }

    constructor(private $dialer: DialerService) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    cambiarEstadoCampana(estado: EEstadoCampanaDialer) {
        if (estado === EEstadoCampanaDialer.EN_CURSO) {
            this.nuevoEstado.emit(EEstadoCampanaDialer.EN_PAUSA);
        } else {
            this.nuevoEstado.emit(EEstadoCampanaDialer.EN_CURSO);
        }
    }

    verDetalles(idcampana: string) {
        this.verCampana.emit(idcampana);
    }

}
