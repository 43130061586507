import { Pipe, PipeTransform } from '@angular/core';
import { IQueueStats } from 'src/app/_interfaces/monitor';

@Pipe({
    name: 'inHoldProm'
})
export class InboundHoldAveragePipe implements PipeTransform {
    transform(stat: IQueueStats): any {
        if ((stat.inbound) !== 0) {
            return stat.inboundHoldtime / stat.inbound;
        }
        return 0;
    }
}