import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "tmoCalc",
})
export class TmoCalcPipe implements PipeTransform {
   transform(accumulatedTime: string, totalCalls: string): Date {
      const seconds = Number.parseInt(accumulatedTime);
      const quantity = Number.parseInt(totalCalls);

      const tmo = quantity > 0 ? Math.floor(seconds / quantity) : 0;
      const auxTime = new Date().setHours(0, 0, 0, 0);

      return new Date(auxTime + tmo * 1000);
   }
}
