import { Component, OnInit, Input } from "@angular/core";
import {
   FormControl,
   ControlValueAccessor,
   NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Observable, BehaviorSubject } from "rxjs";
import { filter, debounceTime, map } from "rxjs/operators";

@Component({
   selector: "auto-search",
   templateUrl: "autosearch.component.html",
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: AutoSearchComponent,
      },
   ],
})
export class AutoSearchComponent implements OnInit, ControlValueAccessor {
   busqueda = new FormControl();

   private _header = "Opciones";
   private _onChange: (value: any) => void;

   @Input() set header(h) {
      this._header = h || "Opciones";
   }
   get header() {
      return this._header;
   }
   @Input() opciones: string[] = [];
   @Input() styleClass = "form-control";
   @Input() style = "";

   opciones$: Observable<string[]>;
   opcionesSeleccionadas$ = new BehaviorSubject<String[]>([]);

   constructor() {
      this.opciones$ = this.busqueda.valueChanges.pipe(
         filter((textoBusqueda: string) => textoBusqueda.length > 2),
         debounceTime(150),
         map((texto) =>
            this.opciones.filter((opt) =>
               opt.toLowerCase().includes(texto.toLowerCase())
            )
         )
      );
   }

   ngOnInit() {}

   agregar(opcionSeleccionada: string) {
      const valores = this.opcionesSeleccionadas$.value;
      if (!valores.includes(opcionSeleccionada)) {
         this.opcionesSeleccionadas$.next([
            ...this.opcionesSeleccionadas$.value,
            opcionSeleccionada,
         ]);
         this._onChange(this.opcionesSeleccionadas$.value);
      }
   }

   remover(opcionRemover: string) {
      if (this.opcionesSeleccionadas$.value.includes(opcionRemover)) {
         this.opcionesSeleccionadas$.next([
            ...this.opcionesSeleccionadas$.value.filter(
               (opt) => opt !== opcionRemover
            ),
         ]);
         this._onChange(this.opcionesSeleccionadas$.value);
      }
   }

   writeValue(value: string[]) {
      this.busqueda.setValue("");
      this.opcionesSeleccionadas$.next([]);

      if (!!value) {
         this.opcionesSeleccionadas$.next(value);
      }
   }

   registerOnChange(fn: (value: any) => void) {
      this._onChange = fn;
   }

   registerOnTouched() {}
}
