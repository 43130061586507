import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsuarioService } from "../../_services/usuario.service";
import { EUsuarioRol } from "../../_interfaces/usuario";

@Component({
   selector: "admin-menu",
   styleUrls: ["../admin.component.css", "./adminMenu.component.css"],
   templateUrl: "./adminMenu.component.html",
})
export class AdminMenuComponent implements OnInit {
   get usuario() {
      return this.$usuario.usuario;
   }
   get vm() {
      return EVistas;
   } // variable usada en la vista
   get _rol() {
      return EUsuarioRol;
   }

   constructor(private $usuario: UsuarioService, private $router: Router) {}

   ngOnInit() {}

   /**
    * Función para retornar a la vista por defecto
    */
   home(): void {
      this.$router.navigateByUrl("/dashboard");
   }

   navigateTo(view?: string, outlet?: string) {
      if (!!outlet) {
         this.$router.navigateByUrl(`/dashboard/(${outlet}:${view})`);
      } else if (!!view) {
         this.$router.navigateByUrl(`/dashboard/${view}`);
      } else {
         this.$router.navigateByUrl(`/dashboard`);
      }
   }

   /**
    * Función para cerrar sesión
    */
   cerrarSesion(): void {
      this.$usuario.cerrarSesion().subscribe(
         () => {
            console.log("out");
            this.$router.navigateByUrl("/login");
         },
         (err) => console.warn(err)
      );
   }

   isManagerOfQueue(queueName: string): boolean {
      return !!this.$usuario.usuario.manager.find((q) => q.cola === queueName);
   }
}

export interface ITopMenu {
   current_view?: EVistas;
   nombre?: string;
   menu?: string[];
   stats?: {
      nombre: string;
      valor: string;
   }[];
}

export interface IOperacion {
   vista: EVistas;
   subVista: string;
}

export enum EVistas {
   EXTENSIONES = <any>"Extensiones",
   GRUPOS = <any>"Grupos de Extensiones",
   INFORMES = <any>"Registros de llamadas",
   ESTADISTICAS = <any>"Estadísticas",
   AGENTES = <any>"Agentes",
   CALLCENTER = <any>"Callcenter",
   CALLCENTER_REPORTES = <any>"Registros de llamadas Callcenter",
   AGENTES_REPORTES = <any>"Informe de Agentes",
   CALLCENTER_STATS = <any>"Informes de Callcenter",
   IVR = <any>"IVR & DID's",
   TRONCALES = <any>"Troncales",
   SISTEMA = <any>"Sistema",
   USUARIO = <any>"Usuarios & Permisos",
   TIPIFICACION = <any>"Tipificación & Clasificación",
   CALLBACK = <any>"Callback | Click2Call",
   POLLS = <any>"Encuestas de Servicio",
   DIALER = <any>"Kerberus Dialer",
   SMS = <any>"Envio masivo de SMS",
   APIMANAGER = <any>"API Key Manager",
   MARKETING = <any>"Herramientas de Marketing",
}
