<form novalidate autocomplete="off" [formGroup]="campo">
    <div class="row" style="color: rgb(80, 80, 80);">
        <div class="col-md-4" style="margin-bottom: 10px;">
            <input type="text" class="form-control" formControlName="nombre" placeholder="Nombre del campo...">
        </div>
        <div class="col-md-3" style="margin-bottom: 10px;">
            <select class="form-control" formControlName="tipo">
                <option value="">Seleccione un tipo...</option>
                <option value="texto">Texto</option>
                <option value="fecha">Fecha</option>
                <option value="email">Email</option>
                <option value="numero">Numerico</option>
                <option value="direccion">Dirección</option>
                <option value="area">Área de texto</option>
                <option value="tel">Teléfono principal</option>
                <option value="alttel">Teléfono secundario</option>
            </select>
        </div>
        <div class="col-md-2" style="margin-bottom: 10px;">
            <div style="display: inline-block; margin-top: 10px;"> 
                <ui-toggleV1 formControlName="editable"></ui-toggleV1> 
            </div>
            <div style="position: relative; display: inline; top: -6px; margin-left: 10px;"> Editable</div>
        </div>
        <div class="col-md-2" style="margin-bottom: 10px;">
            <div style="display: inline-block; margin-top: 10px;"> 
                <ui-toggleV1 formControlName="visible"></ui-toggleV1> 
            </div>
            <div style="position: relative; display: inline; top: -6px; margin-left: 10px;"> Visible</div>
        </div>
        <div class="col-md-1" style="margin-bottom: 10px;">
            <button type="button" class="btn btn-danger btn-block" (click)="eliminarCampo()"><i class="fa fa-trash"></i></button>
        </div>
    </div>
</form>