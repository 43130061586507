<div *ngIf="directorio$ | async as directorio; else loading">
    <h4 class="text-muted"><i class="fa fa-address-card-o"></i> Directorio {{directorio?.nombre || 'nuevo'}}</h4>
    <hr>
    <div class="row">
        <form novalidate autocomplete="off" [formGroup]="formDirectorio" (submit)="guardarDirectorio(formDirectorio.value, !directorio.nombre)">
        <div class="col-md-3">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <i class="fa fa-edit"></i> Datos básicos del directorio
                </div>
                <div class="panel-body">
                    <input type="hidden" formControlName="iddb">
                    <div class="form-group">
                        <label>Nombre del directorio de contactos.</label>
                        <input type="text" class="form-control" formControlName="nombre">
                    </div>
                    <div class="form-group">
                        <label>Descripción.</label>
                        <input type="text" class="form-control" formControlName="descripcion">
                        <small class="text-muted">* Opcional.</small>
                    </div>
                    <div>
                        <label>Índice de búsqueda primario.</label>
                        <select formControlName="primario" class="form-control">
                            <option value="">...</option>
                            <ng-container *ngFor="let campo of campos.controls">
                                <option *ngIf="campo.valid" [value]="campo.value.nombre">{{campo.value.nombre}}</option>
                            </ng-container>
                        </select>
                        <small class="text-muted">Campo para hacer búsquedas por un id único.</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <!-- Nav tabs -->
            <ul class="nav nav-pills" role="tablist">
                <li role="presentation" class="active"><a href="#fields" aria-controls="fields" role="tab" data-toggle="tab">
                    <i class="fa fa-file-text-o"></i> Campos del directorio
                </a></li>
                <li role="presentation"><a href="#integrations" aria-controls="integrations" role="tab" data-toggle="tab">
                    <i class="fa fa-link"></i> Integraciones
                </a></li>
                <li role="presentation"><a href="javascript:void(0)" (click)="agregarCampo()"> <i class="fa fa-plus-circle"></i> Agregar campo</a></li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
                <div role="tabpanel" class="tab-pane fade in active" id="fields" formArrayName="campos" style="padding-top: 15px;">
                    <div class="alert alert-info">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        <i class="fa fa-info-circle"></i> Información sobre campos.<br>
                        <small>Los campos <strong>Editables</strong> serán editables por los agentes al igual que los campos 
                            <strong>Visibles</strong> solo serán visibles si estan habilitados.
                        </small>
                    </div>
                    
                    <div *ngFor="let campo of campos.controls; let i = index">
                        <app-campo-directorio [campo]="campo" [index]="i" (eliminar)="removerCampo($event)"></app-campo-directorio>
                    </div>

                    <div class="small" style="margin-top: 10px; color: rgb(199, 149, 57);" 
                        *ngIf="campos.errors && (campos.dirty || campos.touched)">
                        <ng-container *ngIf="campos.errors.validPhones">
                            <i class="fa fa-warning"></i> 
                            <span *ngIf="campos.errors.validPhones.cant === 0"> Debe seleccionar uno de los campos de tipo <strong>Teléfono Principal.</strong></span>
                            <span *ngIf="campos.errors.validPhones.cant > 0"> Solo puede seleccionar 1 campo como <strong>Teléfono Principal.</strong></span>
                        </ng-container>
                        <div *ngIf="campos.errors.noVisible">
                            <i class="fa fa-warning"></i> 
                            Al menos uno de los campos debe ser visible, de lo contrario el formulario no cargará información de contacto.
                        </div>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane" id="integrations">...</div>
            </div>
        </div>
        <div class="col-md-offset-3 col-md-9 text-right">
            <hr>
            <div class="text-left alert" *ngIf="!!inProgress.msj || !!inProgress.err" [ngClass]="{'alert-danger' : inProgress.err, 'alert-success' : inProgress.msj }">
                <span *ngIf="inProgress.msj"><i class="fa fa-info-circle"></i> Operación realizada exitosamente</span>
                <span *ngIf="inProgress.err"><i class="fa fa-warning"></i> No se pudo realizar la operación, intente nuevamente.</span>
            </div>
            <button type="button" class="btn btn-default" (click)="regresar()">Cancelar</button> &nbsp;
            <button type="submit" 
                [disabled]="!formDirectorio.valid || inProgress.progress" 
                class="btn btn-success">
                <span *ngIf="!inProgress.progress">Guardar cambios</span>
                <span *ngIf="inProgress.progress"><i class="fa fa-spinner fa-spin"></i> Guardando cambios...</span>
            </button>
        </div>
        </form>
    </div>
</div>
<ng-template #loading>
    <div style="padding: 100px 0;" class="text-center">
        <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
        Un momento por favor...
    </div>
</ng-template>