import { Component, OnInit } from "@angular/core";
import { PauseReasonService } from "src/app/_services/pauseReasons.service";
import {
   FormBuilder,
   FormGroup,
   Validators,
   FormArray,
   ValidationErrors,
   FormControl,
} from "@angular/forms";
import { IPauseReason } from "src/app/_interfaces/pauseReason";
import { tap, map, debounceTime, flatMap } from "rxjs/operators";
import { Observable, of } from "rxjs";

function validateReason(formGroup: FormGroup): ValidationErrors | null {
   if (!!formGroup) {
      const razon = formGroup.get("reason") as FormControl;
      const timeout = formGroup.get("timeout") as FormControl;
      if (!razon.value || !timeout.value) {
         return { required: true };
      } else if (!!timeout.value && timeout.value < 15) {
         return { min: true };
      } else if (!!timeout.value && timeout.value > 14401) {
         return { max: true };
      }
   }
   return null;
}

@Component({
   selector: "app-pause-reasons",
   templateUrl: "./pause-reasons.component.html",
   styleUrls: ["./pause-reasons.component.css"],
})
export class PauseReasonsComponent implements OnInit {
   pauseForm: FormGroup;
   reasons$: Observable<IPauseReason[]>;
   onProcess = { process: false, msj: false, err: false };
   get reasonsArray() {
      return this.pauseForm.get("reasons") as FormArray;
   }
   constructor(
      private $fb: FormBuilder,
      private $pauseReasons: PauseReasonService
   ) {}

   ngOnInit() {
      this.reasons$ = this.$pauseReasons
         .obtenerRazones()
         .pipe(tap((reasons) => this.initFrm(reasons)));
   }

   initFrm(pauseReasons?: IPauseReason[]) {
      const reasonsArray = this.$fb.array([]);
      if (!!pauseReasons && Array.isArray(pauseReasons)) {
         for (const reason of pauseReasons) {
            const reasonGr = this.$fb.group(
               {
                  idreason: "",
                  reason: [""],
                  agentVisible: [""],
                  timeout: [""],
               },
               { validators: validateReason }
            );
            reasonGr.patchValue({
               idreason: reason.idreason,
               reason: reason.reason,
               timeout: reason.timeout,
               agentVisible: reason.agentVisible,
            });
            reasonsArray.push(reasonGr);
         }
      }
      this.pauseForm = this.$fb.group({
         reasons: reasonsArray,
      });
   }

   addReason() {
      const reasonGr = this.$fb.group(
         {
            idreason: "",
            agentVisible: [""],
            reason: ["", Validators.required],
            timeout: [""],
         },
         { validators: validateReason }
      );
      reasonGr.patchValue({ agentVisible: true });
      this.reasonsArray.push(reasonGr);
   }

   removeReason(index: number) {
      this.reasonsArray.removeAt(index);
   }

   guardar(frmData: { reasons: IPauseReason[] }) {
      this.onProcess = { process: true, err: false, msj: false };
      of(frmData.reasons)
         .pipe(
            debounceTime(400),
            flatMap((reasons) => this.$pauseReasons.actualizarRazones(reasons))
         )
         .subscribe(
            (response) => {
               console.log(response);
               this.onProcess = { process: false, err: false, msj: true };
            },
            (err) => {
               console.log(err);
               this.onProcess = { process: false, err: true, msj: false };
            }
         );
   }
}
