
import {Observable } from 'rxjs';
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from './header.service';
import { IDBResponse} from '../_interfaces/responses';
import { IVR } from '../_interfaces/ivr';
import { map } from 'rxjs/operators';

@Injectable()
export class IVRService {
    
    constructor(private $http: HttpClient, private $header: HeaderService) {}
    
    getDids(): Observable<IVR[]> {
        return this.$http.get<IDBResponse>(this.$header.getAPIurl() + '/ivrs', { headers: this.$header.getHeaders() }).pipe(
            map(ivrs => ivrs.data as IVR[])
        );
    }
    
    getDid(did: string): Observable<IVR> {
        return this.$http.get<IVR[]>(this.$header.getAPIurl() + '/ivr/' + did, { headers: this.$header.getHeaders() }).pipe(
            map(ivr => ivr.shift())
        );
    }
    
    actualizarIVR(data: { ivr: IVR, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<IDBResponse>;
        console.log(data.update)
        if (data.update === undefined) {
            http$ = this.$http.delete<IDBResponse>(this.$header.getAPIurl() + '/ivr/' + data.ivr.did, { headers: this.$header.getHeaders() })
        } else if (!data.update) {
            http$ = this.$http.put<IDBResponse>(this.$header.getAPIurl() + '/ivr', data.ivr, { headers: this.$header.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post<IDBResponse>(this.$header.getAPIurl() + '/ivr', data.ivr, { headers: this.$header.getHeaders() })
        }
        return http$;
    }
}
