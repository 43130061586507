import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
   name: "toMinutes",
})
export class ToMinutesPipe implements PipeTransform {
   transform(value: number): string {
      if (value!!) {
         const seconds = `${value % 60}`.padStart(2, "0");
         const minutes = `${Math.floor(value / 60)}`.padStart(2, "0");
         return `${minutes}:${seconds}`;
      }
      return "00:00";
   }
}
