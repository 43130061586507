<div class="row">
    <div class="col-xs-8">
        <span class="badge">{{troncal?.channels.length}}</span> 
        <span *ngIf="troncal.protocolo === 'DAHDI'">Canal </span>
        {{troncal?.usuario}}    
    </div>
    <div class="col-xs-4 text-right">
        <button class="btn btn-xs btn-block" [ngClass]="troncal.estado_class">
            <span *ngIf="troncal?.estado_class.indexOf('default') > -1">
                <i class="fa fa-close"></i> Offline
            </span>
            <span *ngIf="troncal?.estado_class.indexOf('success') > -1">
                <i class="fa fa-check"></i> Online
            </span>
            <span *ngIf="troncal?.estado_class.indexOf('danger') > -1">
                <i class="fa fa-check"></i> Busy
            </span>            
            <span *ngIf="troncal?.estado_class.indexOf('none') > -1">
                <i class="fa fa-close"></i> -
            </span>
        </button>
    </div>
</div>
<div style="margin-top: 5px" *ngIf="troncal?.channels">
    <div style="font-size: 13px" class="text-muted" *ngFor="let chan of troncal?.channels">
        <span *ngIf="chan.Data.indexOf('Outgoing') > -1">
            <i class="fa fa-arrow-circle-left"></i> Destino:
        </span>
        <span *ngIf="chan.Data.indexOf('Outgoing') === -1">
            <i class="fa fa-arrow-circle-right"></i> Origen: 
        </span>
         {{chan.CallerID}} | {{chan.Duration | toTime | date:'HH:mm:ss'}}
    </div>
</div>