<span [ngSwitch]="nivel.opcion">
   <span *ngSwitchCase="'BACKGROUND'">
      <span class="btn-group" role="group" aria-label="..." style="margin-left: 3px;">
         <button class="btn btn-xs btn-info"><i class="fa fa-music" style="margin-right: 5px"></i>
            {{nivel.valor}}</button>
         <button class="btn btn-xs btn-primary" (click)="play(nivel.valor)"><i class="fa fa-play"></i></button>
         <button class="btn btn-xs btn-primary" (click)="stop()"><i class="fa fa-stop"></i></button>
         <button class="btn btn-xs btn-danger" style="margin-right: 10px" (click)="download(nivel.valor)"><i
               class="fa fa-download"></i></button>
      </span>
   </span>
   <span *ngSwitchCase="'PLAYBACK'">
      <span class="btn-group" role="group" aria-label="..." style="margin-left: 3px;">
         <button class="btn btn-xs btn-info"><i class="fa fa-music" style="margin-right: 5px"></i>
            {{nivel.valor}}</button>
         <button class="btn btn-xs btn-primary" (click)="play(nivel.valor)"><i class="fa fa-play"></i></button>
         <button class="btn btn-xs btn-primary" (click)="stop()"><i class="fa fa-stop"></i></button>
         <button class="btn btn-xs btn-danger" style="margin-right: 10px" (click)="download(nivel.valor)"><i
               class="fa fa-download"></i></button>
      </span>
   </span>
   <span *ngSwitchCase="'API_DIRECTION'">
      <span style="margin-left: 3px;">
         <span class="badge badge-success"><i class="fa fa-globe"></i> GET</span> {{nivel.valor | apiDirectionPresenter
         : 'url'}}?q=
      </span>
      <span class="btn-group" role="group" aria-label="..."
         *ngIf="nivel.valor | apiDirectionPresenter : 'audio' as audioName">
         <button class="btn btn-xs btn-info"><i class="fa fa-music" style="margin-right: 5px"></i>
            Audio</button>
         <button class="btn btn-xs btn-primary" (click)="play(audioName)"><i class="fa fa-play"></i></button>
         <button class="btn btn-xs btn-primary" style="margin-right: 10px" (click)="stop()"><i
               class="fa fa-stop"></i></button>
      </span>
      <div style="margin-left: 31px;" *ngIf="(nivel.valor | apiDirectionPresenter : 'headers-show') !== ':'">
         <span class="badge">
            <i class="fa fa-flag-o"></i> Headers</span> {{nivel.valor |
         apiDirectionPresenter
         : 'headers'}}
      </div>
   </span>
   <span *ngSwitchCase="'NVR'">
      <!-- Pasas por un pipe el valor -->
      <span style="margin-left: 3px" [innerHtml]="nivel | nvrPresenter"></span>
   </span>
   <span *ngSwitchDefault>
      <span style="margin-left: 3px">
         {{o[nivel.opcion]}}: <strong>{{nivel.valor}}</strong>
      </span>
   </span>
</span>