import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'respuestas'
})
export class RespuestasPipe implements PipeTransform {

    transform(preguntas: { enunciado: string, respuesta: string }[], enunciadoBusqueda: string): any {
        const pregunta = preguntas.find(p => p.enunciado === enunciadoBusqueda);
        return !!pregunta ? pregunta.respuesta : 'N/A';
    }

}
