<div class="bs-callout bs-callout-warning text-justify" [formGroup]="preguntaFrm">
    <label for="">Pregunta *</label>
    <div class="input-group" style="margin-top: 3px" >
        <input type="text" class="form-control" formControlName="pregunta"  placeholder="Enunciado de la pregunta...">
        <span class="input-group-btn">
            <button class="btn btn-primary" type="button" (click)="play(audioFrm.value)" [disabled]="audioFrm.value === ''"><i class="fa fa-play"></i></button>
            <button class="btn btn-primary" type="button" (click)="stop()" [disabled]="audioFrm.value === ''"><i class="fa fa-stop"></i></button>
            <button class="btn btn-default" type="button"
                (click)="seleccionarAudio()"
            >
                <i class="fa fa-folder-open"></i>
            </button>
        </span>
    </div><!-- /input-group -->
    <small class="text-muted">Se usara para mostrar los resultados.</small>
    <div>
        <i class="fa" [ngClass]="audioFrm.value !== '' ? 'fa-check text-success' : 'fa-ban text-danger'"></i> Audio: {{audioFrm.value}}
        <input type="hidden" formControlName="audio" #audioFrm>
    </div>
    <hr>
    <label for="">Rango de Calificaciones Posibles</label>
    <div class="row">
        <div class="col-md-6">
            <input type="number" formControlName="minimo" class="form-control text-center" min="0" max="9" placeholder="0">
            <small class="text-muted">Mínimo</small>
        </div>
        <div class="col-md-6">
            <input type="number" formControlName="maximo" class="form-control text-center" min="0" max="9" placeholder="5">
            <small class="text-muted">Máximo</small>
        </div>
    </div>
    
</div>
