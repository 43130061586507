<ng-container *ngIf="!queryContact; else loading">
   <small class="text-muted">Por favor llene todos los campos editables y requeridos.</small>
   <div *ngIf="addressBook.controls.length === 0" class="alert alert-info" style="margin: 10px;">
      <i class="fa fa-info-circle"></i> El formulario se desplegará si selecciona un contacto de los marcadores, o si
      ingresa o realiza una llamada.
   </div>
   <div class="row" *ngIf="addressBook.controls.length > 0">
      <div class="col-lg-offset-1 col-lg-10" style="margin-top: 20px;">
         <form novalidate autocomplete="off" [formGroup]="formContacto"
            (ngSubmit)="guardarInformacion(formContacto.value)">
            <input type="hidden" formControlName="_id">
            <input type="hidden" formControlName="iddb" #iddb>
            <ng-container formArrayName="addressBook">
               <ng-container [formGroupName]="i" *ngFor="let control of addressBook.controls; index as i">
                  <input type="hidden" formControlName="nombreCampo" #nombreCampo>
                  <input type="hidden" formControlName="tipoCampo" #tipoCampo>

                  <div class="form-group" [ngSwitch]="tipoCampo.value">
                     <!-- label -->
                     <label>{{nombreCampo.value}}</label>
                     <!-- Campo -->
                     <ng-container *ngSwitchCase="'texto'">
                        <input type="text" class="form-control" formControlName="campo">
                     </ng-container>
                     <ng-container *ngSwitchCase="'numero'">
                        <input type="number" class="form-control" formControlName="campo">
                     </ng-container>
                     <ng-container *ngSwitchCase="'tel'">
                        <input type="tel" class="form-control" formControlName="campo">
                     </ng-container>
                     <ng-container *ngSwitchCase="'alttel'">
                        <input type="tel" class="form-control" formControlName="campo">
                     </ng-container>
                     <ng-container *ngSwitchCase="'fecha'">
                        <input type="text" ngDatePicker formControlName="campo">
                     </ng-container>
                     <ng-container *ngSwitchCase="'area'">
                        <textarea class="form-control" style="min-height: 100px;" formControlName="campo"></textarea>
                     </ng-container>
                     <ng-container *ngSwitchDefault>
                        <input type="text" class="form-control" formControlName="campo">
                     </ng-container>
                  </div>
               </ng-container>
            </ng-container>
            <hr>
            <div *ngIf="!inProgress.progress && (inProgress.msj || inProgress.err)" class="alert"
               [ngClass]="{'alert-success': inProgress.msj, 'alert-danger': inProgress.err}">
               <span *ngIf="inProgress.msj">
                  <i class="fa fa-check-square-o"></i> Operación realizada correctamente.</span>
               <span *ngIf="inProgress.err">
                  <i class="fa fa-warning"></i> Hubo un error al realizar esta operación.</span>
            </div>
            <div class="text-right">
               <button type="button" class="btn btn-default"
                  (click)="limpiarFormulario(iddb.value)">Reset</button>&nbsp;
               <button type="submit"
                  [disabled]="!formContacto.valid || inProgress.progress || addressBook.controls.length === 0"
                  class="btn btn-primary">
                  <i class="fa fa-spinner fa-spin" *ngIf="inProgress.progress" style="margin-right: 5px;"></i>
                  <span *ngIf="isNewContact">Crear contacto</span>
                  <span *ngIf="!isNewContact">Guardar cambios</span>
               </button>
            </div>
         </form>
      </div>
   </div>
</ng-container>
<ng-template #loading>
   <div class="text-center text-muted" style="margin-top: 40px; margin-bottom: 37px;">
      <i class="fa fa-2x fa-spinner fa-spin text-primary" style="margin-bottom: 10px;"></i><br>
      Consultando...
   </div>
</ng-template>