<div style="margin-bottom: 10px">
    <label>Prefijo</label>
    <input type="number" class="form-control" autocomplete="off" [formControl]="prefijoCrtl">
    <small class="text-muted">Si sus números tienen un prefijo establecido, puede ponerlo en este campo: Ej: 57 para Colombia</small>
</div>

<div id="idv_carga" *ngIf="!showPreview">
    <label>Lista de números y variables</label>
    <div class="alert alert-info">
        <strong>Formato:</strong> numero , variable1 : mi_valor, variable2 : mi otro valor , variable_n : mi_valor_n<br>
        <small>Datos separados por comas</small>
    </div>
    <textarea class="form-control" 
        [formControl]="importacionCrtl"
        placeholder="numero , variable1 : mi_valor , variable2 : mi otro valor , variable_n : mi_valor_n" style="min-height: 300px"></textarea>

    <hr>
    <div class="text-right">
        <button type="button" class="btn btn-default">Reset</button>&nbsp;
        <button type="button" class="btn btn-info"
            [disabled]="!importacionCrtl.valid || !prefijoCrtl.valid"
            (click)="previsualizar(importacionCrtl.value, prefijoCrtl.value)">Previsualizar & Validar</button>
    </div>
</div>
<div id="div_preview" *ngIf="showPreview">
    <table class="table table-striped table-bordered">
        <thead class="bg-primary">
            <tr>
                <td class="text-center">Número</td>
                <td style="min-width: 300px;" class="text-center">Variables</td>
                <td colspan="2" class="text-center">Valido</td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let numero of previewNumeros">
                <td>{{numero.numero}}</td>
                <td>
                    <table class="table table-bordered table-striped text-left" style="margin-bottom: 0">
                        <tr *ngFor="let _var of numero.variable">
                            <td style="padding: 3px; padding-left: 5px; width: 40%">{{_var.nombre}}</td>
                            <td style="padding: 3px; padding-left: 5px">{{_var.valor}}</td>
                        </tr>
                    </table>
                </td>
                <td></td>
            </tr>
        </tbody>
    </table>
    <div class="text-right">
        <button type="button" (click)="editarImportacion()" class="btn btn-default">Regresar</button>&nbsp;
        <button type="button" 
        (click)="guardarNumeracion()"
        [disabled]="!importacionCrtl.valid || !prefijoCrtl.valid"
        class="btn btn-primary">Guardar Numeración</button>
    </div>
</div>
