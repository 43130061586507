import { Pipe, PipeTransform } from "@angular/core";
import { Agente } from "src/app/_interfaces/_all";

@Pipe({
   name: "serializeAgent",
})
export class AgentNamePipe implements PipeTransform {
   transform(idagente: string, agentes: Agente[]): any {
      if (!!agentes && agentes.length > 0) {
         const agente = agentes.find((a) => a.idagente === idagente);
         return agente?.nombre || "-";
      }
      return "-";
   }
}
