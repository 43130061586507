import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-campo-directorio',
  templateUrl: './campo-directorio.component.html',
  styleUrls: ['./campo-directorio.component.css']
})
export class CampoDirectorioComponent implements OnInit {

    @Input() index: number;
    @Input() campo: FormGroup;
    @Output() eliminar = new EventEmitter<number>();
    
    constructor() { }

    ngOnInit() {}

    eliminarCampo() {
        this.eliminar.emit(this.index);
    }

}
