<div class="row" style="padding: 10px">
    <div class="col-md-4">
        <div class="bs-callout bs-callout-success text-justify">
            <h5>Encuestas</h5>
            En esta sección podrás crear encuestas de servicio que podrás ejecutar al finalizar llamadas.
            <hr style="margin-bottom: 10px; margin-top: 10px">            
        </div>

        <div class="panel panel-primary" style="max-height: 600px; overflow-y: auto; overflow-x: hidden">
            <div class="panel-heading">
                <i class="fa fa-list-ol"></i> Encuestas disponibles
            </div>
            
            <ul class="list-group">
                <li *ngFor="let encuesta of (polls$ | async)" class="list-group-item" style="padding: 5px 15px" 
                    [ngClass]="{'list-group-item-info': encuestaSeleccionada?.id === encuesta.id}"
                >
                    <div class="row" style="padding: 0px" >
                        <div class="col-xs-9" style="padding-top: 5px">
                            <a href="javascript: void(0)" (click)="setEncuesta(encuesta)" style="text-decoration: none">
                                <i class="fa fa-arrow-circle-o-right" style="margin-right: 5px; margin-left: 5px" ></i> 
                                {{encuesta.nombre}}
                            </a>
                        </div>  
                        <div class="col-xs-3 text-right">
                            <button class="btn btn-default btn-sm" (click)="eliminarEncuesta(encuesta.id)">
                                <i class="fa fa-trash"></i> Eliminar
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-8">
        <poll-tag [encuesta]="encuestaSeleccionada" (eventos)="ngOnInit()"></poll-tag>
    </div>
</div>
  