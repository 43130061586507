import { Component, OnInit } from "@angular/core";
import { from, Observable, of, zip } from "rxjs";
import {
   finalize,
   map,
   mergeMap,
   reduce,
   shareReplay,
   switchMap,
   tap,
} from "rxjs/operators";
import { EUsuarioRol } from "src/app/_interfaces/usuario";
import { PaginationInstance } from "ngx-pagination";
import { QueueService } from "src/app/_services/queue.service";
import { UsuarioService } from "src/app/_services/usuario.service";
import {
   ECallbackLogResult,
   ECallbackRequestResult,
   QueueCallbackService,
} from "src/app/_services/queue-callback.service";
import { FileHandlerService } from "src/app/_services/file.service";
import * as dayjs from "dayjs";

interface IQueueCallbackLog {
   requestID: string;
   callerid: string;
   cola: string;
   fechaSolicitud: Date;
   fechaProgramada: Date;
   telefonoSolicitado?: string;
   resultado: ECallbackRequestResult;
   log: {
      callid: string;
      numeroMarcado: string;
      fecha: Date;
      cola: string;
      resultado: ECallbackLogResult;
   }[];
}

@Component({
   selector: "app-queue-callback-log",
   templateUrl: "./queue-callback-log.component.html",
   styleUrls: ["./queue-callback-log.component.css"],
})
export class QueueCallbackLogComponent implements OnInit {
   queryResult$: Observable<{}>;
   opcionesLog = Object.keys(ECallbackLogResult);
   opcionesRequest = Object.keys(ECallbackRequestResult);
   autosearch_colas$: Observable<string[]>;
   queryParams: {
      cola?: string;
   } = {
      cola: "",
   };

   pagConfig: PaginationInstance = {
      id: "custom",
      itemsPerPage: 10,
      currentPage: 1,
   };

   get isPrivilged() {
      return this.$usr.usuario.permisos <= EUsuarioRol.MANAGER;
   }

   constructor(
      private $usr: UsuarioService,
      private $queue: QueueService,
      private $file: FileHandlerService,
      private $queueCallback: QueueCallbackService
   ) {}

   ngOnInit(): void {
      this.autosearch_colas$ = this.$queue.getQueues().pipe(
         map((queues) => queues.map((q) => q.nombre)),
         mergeMap((queuesAvailable) => {
            if (this.$usr.usuario.permisos > 1) {
               const allowedQueues = this.$usr.usuario.manager.map(
                  (m) => m.cola
               );
               this.queryParams = {
                  ...this.queryParams,
                  cola: allowedQueues.length > 0 ? allowedQueues[0] : "-",
               };
               return of(allowedQueues);
            } else {
               return of(queuesAvailable);
            }
         })
      );

      this.queryResult$ = of([]);
   }

   buscar(params: {
      fecha: { inicio: string; fin?: string };
      cola?: string;
      resultadoLog?: string;
      resultadoRequest?: string;
   }) {
      this.queryResult$ = of(params).pipe(
         switchMap((raw) => this.$queueCallback.query(raw)),
         shareReplay()
      );
   }

   descargarReporte(resultados: {}[]) {
      from(resultados)
         .pipe(
            tap(() => $("#modalWait_kerberus").modal()),
            // headers
            map((data: IQueueCallbackLog) => {
               const body = {
                  ["Request id"]: data.requestID,
                  ["Fecha de solicitud"]: dayjs(data.fechaSolicitud).format(
                     "YYYY-MM-DD HH:mm:ss"
                  ),
                  ["Cola de solicitud"]: data.cola,
                  ["Caller ID"]: data.callerid,
                  ["Fecha programada"]:
                     data.resultado === ECallbackRequestResult.EXITOSO
                        ? dayjs(data.fechaProgramada).format(
                             "YYYY-MM-DD HH:mm:ss"
                          )
                        : "N/A",
                  ["Nro. Solicitado"]: data.telefonoSolicitado || "N/A",
                  ["Resultado"]: data.resultado,
               };

               return data.log.length === 0
                  ? [
                       {
                          ...body,
                          ["ID Llamada retornada"]: "N/A",
                          ["Número marcado"]: "N/A",
                          ["Fecha de llamada"]: "N/A",
                          ["Cola de destino"]: "N/A",
                          ["Resultado de llamada"]: "N/A",
                       },
                    ]
                  : data.log.map((log) => ({
                       ...body,
                       ["ID Llamada retornada"]: log.callid || "N/A",
                       ["Número marcado"]: log.numeroMarcado || "N/A",
                       ["Fecha de llamada"]: !!log.fecha
                          ? dayjs(log.fecha).format("YYYY-MM-DD HH:mm:ss")
                          : "N/A",
                       ["Cola de destino"]: log.cola || "N/A",
                       ["Resultado de llamada"]: log.resultado || "N/A",
                    }));
            }),
            reduce((acc, curr) => [...acc, ...curr], []),
            tap((data) =>
               this.$file.exportCSVFile(
                  Object.keys(data[0]),
                  data,
                  "QueueCallbackLog_" + new Date().getTime(),
                  ";"
               )
            ),
            finalize(() => $("#modalWait_kerberus").modal("hide"))
         )
         .subscribe();
   }
}
