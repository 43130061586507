import { of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, switchMap, tap } from "rxjs/operators";
import { HeaderService } from "./header.service";

@Injectable({
   providedIn: "root",
})
export class QueueCallbackService {
   constructor(private $http: HttpClient, private $headers: HeaderService) {}

   query(params: {
      fecha: { inicio: string; fin?: string };
      cola?: string;
      resultadoLog?: string;
      resultadoRequest?: string;
   }) {
      return of(params).pipe(
         // convertir a valid enums
         map((raw) => ({
            ...raw,
            resultadoRequest: ECallbackRequestResult[raw.resultadoRequest],
            resultadoLog: ECallbackLogResult[raw.resultadoLog],
         })),
         // Maperar el objeto correctamente
         map((raw) =>
            Object.keys(raw).reduce(
               (acc, curr) =>
                  !!raw[curr] ? { ...acc, [curr]: raw[curr] } : acc,
               {}
            )
         ),
         switchMap((query) =>
            this.$http.post(
               `${this.$headers.getAPIurl()}/queue-callback/query`,
               query,
               {
                  headers: this.$headers.getHttpHeaders(),
               }
            )
         )
      );
   }
}

export enum ECallbackLogResult {
   CONTESTADA = "ANSWERED",
   NO_CONTESTADA = "NOANSWER",
   INDEFINIDO = "NOTSURE",
   MAQUINA = "MACHINE",
}

export enum ECallbackRequestResult {
   EXITOSO = "SUCCESS",
   FALLIDO = "FAILED",
   MAX_INTENTOS = "MAX_ATTEMPTS",
}
