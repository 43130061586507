
import {throwError as observableThrowError,  Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Grupo } from '../_interfaces/grupo';
import { HeaderService } from './header.service';
import { IDBResponse } from '../_interfaces/responses';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class GruposService {
    
    constructor(private $http: HttpClient, private $headers: HeaderService) {}
    
    getGrupos(): Observable<Grupo[]> {
        return this.$http.get<Grupo[]>(this.$headers.getAPIurl() + '/grupos', { headers: this.$headers.getHeaders() });
    }
    
    /**
     * Funciones de edicion, creación y eliminacion de grupos
     */
    updateGrupo(data: { grupo: Grupo, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<IDBResponse>;
        if (data.update === undefined) {
            http$ = this.$http.delete<IDBResponse>(this.$headers.getAPIurl() + '/grupo/' + data.grupo.idgrupo, { headers: this.$headers.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post<IDBResponse>(this.$headers.getAPIurl() + '/grupo', data.grupo, { headers: this.$headers.getHeaders() })
        } else {
            http$ = this.$http.put<IDBResponse>(this.$headers.getAPIurl() + '/grupo', data.grupo, { headers: this.$headers.getHeaders() })
        }
        return http$;
    }
}
