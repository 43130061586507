import { Pipe, PipeTransform } from '@angular/core';
import { IContactoReparto } from 'src/app/_interfaces/dialer';
import { IContacto } from 'src/app/_interfaces/contacto';

@Pipe({
  name: 'checkBox'
})
export class CheckBoxPipe implements PipeTransform {

    transform(value: IContacto, ids: string[]): any {
        return ids.includes(value._id);
    }

}
