import { Component, OnInit } from '@angular/core';
import { VPNService, IVPNFile } from '../../../_services/vpn.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderService } from '../../../_services/header.service';

@Component({
  selector: 'app-vpn-table',
  templateUrl: './vpn-table.component.html',
  styleUrls: ['./vpn-table.component.css']
})
export class VpnTableComponent implements OnInit {

    archivos$: Observable<IVPNFile[]> 

    constructor(private $vpn: VPNService, private $header: HeaderService) { }

    ngOnInit() {
    }
    
    listarArchivos(): void  {
        this.archivos$ = this.$vpn.listarArchivosVPN();
    }

    descargar(archivo: string) {
        this.$vpn.obtenerArchivo(archivo)
            .subscribe(
                response => {
                    console.log(response);
                    if (!!response) {
                        this.descargarArchivo(response, 'application/zip', archivo);
                    }
                },
                err => console.warn(err)
            );
    }

    descargarArchivo(blob: any, tipo: string, nombreArchivo) {
        const url = window.URL.createObjectURL(new Blob([blob], { type: tipo }));
        const link = document.createElement('a');
        link.setAttribute('style', 'display:none');
        document.body.appendChild(link);
        link.href = url;
        link.download = nombreArchivo;
        link.click();
        // document.body.removeChild(link);
    }

}
