import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HeaderService } from "./header.service";

@Injectable({
   providedIn: "root",
})
export class IvrTransactionalService {
   constructor(private $http: HttpClient, private $header: HeaderService) {}

   getIVRs(): Observable<{ token: string; descripcion: string }[]> {
      return this.$http.get<{ token: string; descripcion: string }[]>(
         this.$header.getAPIurl() + "/nvr/transactionals",
         { headers: this.$header.getHeaders() }
      );
   }
}
