

export interface ISchedule {
    id?: string;
    horario: number;
    evento: string;
    idagente: string;
    descripcion?: string;
    estado: EScheduleStatus;
    tarea: IScheduleJob
}

export type IScheduleJob = IScheduleCallJob | IScheduleSMSJob | IScheduleEmailJob | IScheduleRemainderJob;

export interface IScheduleCallJob {
    tipoEvento: ETipoScheduleEvent.CALL;
    numero: string
}

export interface IScheduleSMSJob {
    tipoEvento: ETipoScheduleEvent.SMS;
    numero: string;
    texto: string;
}

export interface IScheduleEmailJob {
    tipoEvento: ETipoScheduleEvent.EMAIL;
    destinatarios: string[];
    asunto: string;
    texto: string;
}

export interface IScheduleRemainderJob {
    tipoEvento: ETipoScheduleEvent.RECORDATORIO
}

export enum EScheduleStatus {
    PROGRAMADO = 'PROGRAMADO',
    ALERTADO = 'ALERTADO',
    EJECUTADO = 'EJECUTADO'
}

export enum ETipoScheduleEvent {
    SMS = 'sms',
    CALL = 'call',
    EMAIL = 'email',
    RECORDATORIO = 'remainder'
}
